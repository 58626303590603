import React, { useEffect } from "react";
import { ZoomMtg } from "@zoom/meetingsdk";

const ZoomMeeting = () => {
  useEffect(() => {
    window.FreshworksWidget?.("hide", "launcher");
    const url = new URL(window.location.href);
    const signature = url.searchParams.get("signature");
    const meetingNumber = url.searchParams.get("meetingNumber");
    const userName = url.searchParams.get("userName");
    const apiKey = url.searchParams.get("apiKey");
    const userEmail = url.searchParams.get("userEmail");
    const meetingPassword = url.searchParams.get("password");
    let leaveUrl = url.searchParams.get("leaveUrl");
    if (window.location.href.indexOf("localhost") >= 0) {
      leaveUrl = "http://localhost:7015/dashboard";
    }

    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    ZoomMtg.i18n.load("en-US");
    ZoomMtg.init({
      leaveUrl: leaveUrl, // window.location.href,
      isSupportAV: true,
      success: function () {
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          sdkKey: apiKey,
          userEmail: userEmail ? userEmail : "",
          passWord: meetingPassword,
          success: function (success) {
            console.log(success);
          },
          error: function (error) {
            console.log(error);
          },
        });
      },
    });
  }, []);

  return <div>{/* Add any JSX content you want to render */}</div>;
};

export default ZoomMeeting;
