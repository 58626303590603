import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { utils } from '../../utility/utils';
import { PAGE_HEADINGS } from '../../constants/constants'; 
import { setPageHeading } from '../../actions/utilAction';


class MyGroupSS extends Component {
    constructor(props) {
		super(props);
        this.state = {
            to_dashboard : false,
            res_card_width : 2000,
        }
    }
    
    componentDidMount() {
        this.props.setPageHeading(PAGE_HEADINGS.MY_GROUP);
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        var res_e = document.getElementsByClassName("grp-card")[0];
        if(res_e){
            this.setState({
                res_card_width : res_e.offsetWidth,
            })
        }  
    }
    resize = () => {
        var res_e = document.getElementsByClassName("grp-card")[0];
        if(res_e){
            this.setState({
                res_card_width : res_e.offsetWidth,
            })
        }
    }
    
    componentDidUpdate(prev_props){
        if(this.props.user_j_groups != prev_props.user_j_groups){
            if(!this.props.user_j_groups || this.props.user_j_groups.length == 0){
                this.setState({
                    to_dashboard : true
                })
            }
            else if(this.props.user_j_groups && this.props.user_j_groups.length > 0){
                this.props.setPageHeading(PAGE_HEADINGS.MY_GROUP);
                this.resize();
            }
        }
    }
    
    render() {
        
            return (
                <div className="dashboard-content-wrapper">
                    <div className="container-fluid dashboard-content">
                        <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <p className="content-header">My Groups</p>
                        </div>
    
                        <div className="container-fluid my-grp-container ">
                            <div className="row my-grp-row">

                                            <div className="col-xs-12 col-lg-4 col-md-4 col-sm-6">
                                                <div className="grp-card" style={{"--height": this.state.res_card_width.toString() + 'px'}}>
                                                    <div className="card-cover"></div>
                                                    <div className="grp-img-container">
                                                    </div>
                                                    <div className="grp-name-container">
                                                        <p className="grp-name"></p>
                                                        <p className="view-btn"></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-lg-4 col-md-4 col-sm-6">
                                                <div className="grp-card" style={{"--height": this.state.res_card_width.toString() + 'px'}}>
                                                    <div className="card-cover"></div>
                                                    <div className="grp-img-container">
                                                    </div>
                                                    <div className="grp-name-container">
                                                        <p className="grp-name"></p>
                                                        <p className="view-btn"></p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-xs-12 col-lg-4 col-md-4 col-sm-6">
                                                <div className="grp-card" style={{"--height": this.state.res_card_width.toString() + 'px'}}>
                                                    <div className="card-cover"></div>
                                                    <div className="grp-img-container">
                                                    </div>
                                                    <div className="grp-name-container">
                                                        <p className="grp-name"></p>
                                                        <p className="view-btn"></p>
                                                    </div>
                                                </div>
                                            </div>

                            </div>
                        </div>
                    </div>
                </div>
            );
        
    }
}

const mapStateToProps = (state) => (
	{
		profiles		: state.profile.profiles,
		default_pid		: state.profile.default_pid,
        user_j_groups   : state.j_grp.groups,
        is_pr_sw        : state.profile.is_pr_sw
	}
)

const mapDispatchToProps = {
    setPageHeading,
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(MyGroupSS));


// export default MyGroup;
