import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

function QualityScale(props) {
  const [answerSetOptionList, setAnswerSetOptionList] = useState([]);

  useEffect(() => {
    if (props && props.answerSetOptionList) {
      // setRatingLength(props.answerSetOptionList.length)
      setAnswerSetOptionList([...props.answerSetOptionList]);
    }
  }, []);
  const handleOnChange = (event) => {
    props.handleSelectOption(
      props.ques,
      event.target.value,
      event.target.value
    );
  };
  return (
    <div>
      <FormControl>
        {/* <FormLabel id="demo-radio-buttons-group-label">Quality Scale</FormLabel> */}
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="Quality Scale"
          name="radio-buttons-group"
          onChange={handleOnChange}
        >
          {answerSetOptionList &&
            answerSetOptionList.map((opt, index) => {
              return (
                <FormControlLabel
                  value={opt.opt_point}
                  control={<Radio />}
                  label={index + 1}
                />
              );
            })}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default QualityScale;
