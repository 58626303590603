import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBWvYqb5M5zeoa-UVW7wNmquZqfvPPPeP8",
  authDomain: "imarticus-learning-775e9.firebaseapp.com",
  projectId: "imarticus-learning-775e9",
  storageBucket: "imarticus-learning-775e9.appspot.com",
  messagingSenderId: "468177004941",
  appId: "1:468177004941:web:c25852ca25aba92e10b5c4",
  measurementId: "G-MH459QVDGY",
};
// const firebaseConfig = {
//   apiKey: "AIzaSyBLfTapsZdoJxs4rwSKcyuDjTecOp6H7rQ",
//   authDomain: "fir-notifications-2dc8d.firebaseapp.com",
//   projectId: "fir-notifications-2dc8d",
//   storageBucket: "fir-notifications-2dc8d.appspot.com",
//   messagingSenderId: "622343759822",
//   appId: "1:622343759822:web:cfa0831df1d847e9c2ed9f",
//   measurementId: "G-QMCMCTSY03",
// };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
const messaging = getMessaging(app);

export const getTokens = (setTokenFound, setToken) => {
  console.log("\n=============== In GetTokens ===============");
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    return getToken(messaging, {
      vapidKey:
        "BAWW6BcxE7nm72w-opCYlKXUUncnmqGXRY-MqdQDlvcag-v8TXgBAeQxOCvjrbg7N_da4Tun6JuJFRbuH-5ppG8",
      // vapidKey:
      // "BJiyxGQqqDYF8HVgIeMM64pmsGs4LqLbSC_lA8mgM8ho8VCH7VVMl9CEZhjz6sIGkeaudU52xII29Lwa3eEAXe8",
    })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          setTokenFound(true);
          setToken(currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          setTokenFound(false);
          setToken(currentToken);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token ====> ", err);
        // catch error while creating client token
      });
  } else if (Notification.permission !== "denied") {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        return getToken(messaging, {
          vapidKey:
            "BAWW6BcxE7nm72w-opCYlKXUUncnmqGXRY-MqdQDlvcag-v8TXgBAeQxOCvjrbg7N_da4Tun6JuJFRbuH-5ppG8",
          // vapidKey:
          // "BJoeWt91BfgRCEEsv5QEorDrQ4ohsh9qlx3PDj4mbAiSpG4IvAJIkRWI-E_sjsKI7XIaidoaVjft1qHXFXXl4TM",
        })
          .then((currentToken) => {
            if (currentToken) {
              console.log("current token for client: ", currentToken);
              setTokenFound(true);
              setToken(currentToken);
              // Track the token -> client mapping, by sending to backend server
              // show on the UI that permission is secured
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
              setTokenFound(false);
              // shows on the UI that permission is required
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token ====> ", err);
            // catch error while creating client token
          }); // …
      }
    });
  }
  return getToken(messaging, {
    vapidKey:
      "BAWW6BcxE7nm72w-opCYlKXUUncnmqGXRY-MqdQDlvcag-v8TXgBAeQxOCvjrbg7N_da4Tun6JuJFRbuH-5ppG8",
    // vapidKey:
    // "BJoeWt91BfgRCEEsv5QEorDrQ4ohsh9qlx3PDj4mbAiSpG4IvAJIkRWI-E_sjsKI7XIaidoaVjft1qHXFXXl4TM",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(true);
        setToken(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token ====> ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    console.log(
      "\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\ Got a new message from AWS \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\"
    );
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
