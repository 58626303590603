import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { showSidebarMB, setSidebar } from "../../actions/utilAction";
import small_left from "../../images/left-arr-white.svg";
import { withRouter } from "react-router";
import queryString from "query-string";
import white_cross from "../../images/white-cross.svg";
import { getProfileInfo } from "../../actions/profileAction";
import { SIDEBARS, TABS, CONFIGS } from "../../constants/constants";
import { Link, useLocation } from "react-router-dom";
import {
  fetchGroups,
  setSelectedGroupDetails,
  setGSettingBack,
  resetGSettingBack,
} from "../../actions/groupAction";

class PlacementSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crs_pg_id: "",
    };
  }
  componentDidMount() {
    this.props.setSidebar(SIDEBARS.GROUP_SIDEBAR);
    var query_params = queryString.parse(this.props.location.search);
    this.setState({
      current_gid: query_params.gid,
    });
  }
  componentDidUpdate(prev_props) {
    if (
      this.props.location != prev_props.location &&
      this.props.gsetting_is_back
    ) {
      this.props.history.goBack();
    }
  }

  closeSidebar = () => {
    this.props.showSidebarMB(false);
  };
  goBack = () => {
    this.props.setGSettingBack();
    this.props.history.goBack();
  };
  goToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard/",
    });
  };

  // goToBasicDetails = () => {
  //   // /gradebook/:pid/:gid/:cid'
  //   // let pid = cookies.get("pid");
  //   let location = useLocation();
  //   let searchParams = new URLSearchParams(location.search);
  //   let crs_pgid = searchParams.get('crs_pg_id');
  //   let url =
  //   `${CONFIGS.WEB_DASHBOARD_BASE_URL}/placement-module/basic_details/?crs_pg_id=${crs_pgid}`;
  //     console.log({ url });
  //     window.location.replace(url);
  // };

  render() {
    /* PROFILE SETTINGS */
    console.log({ propsSidebar: this.props });
    return (
      <Fragment>
        <div className="group-sidebar">
          <Link to="/dashboard">
            <div
              className="logo-container center-all "
              style={{
                backgroundColor: `${
                  this.props.brand && this.props.brand.whiteBackground
                    ? "white"
                    : ""
                }`,
              }}
            >
              {!this.props.is_data_loading && (
                <img
                  src={
                    this.props.brand
                      ? this.props.brand.pic
                      : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                  }
                  className="side-bar__logo-img"
                  style={{
                    maxHeight: "50px",
                    margin: "unset",
                  }}
                />
              )}
            </div>
          </Link>
          <Link to="/placement-portal">
            <p className="back-btn">
              <img className="back-img" src={small_left}></img>Back
            </p>
          </Link>
          <div>
            <p className="grp-heading">Placement Profile</p>
            <Link to={"/placement-module/basic_details"}>
              <div
                className={
                  this.props.current_tab == TABS.BASIC_DETAILS
                    ? "g-name-wrapper active"
                    : "g-name-wrapper"
                }
              >
                <p
                  className={
                    this.props.current_tab == TABS.BASIC_DETAILS
                      ? "g-name"
                      : "g-name"
                  }
                >
                  Basic Details
                </p>
              </div>
            </Link>
            <Link to={"/placement-module/basic-details"}>
              <div
                className={
                  this.props.current_tab == TABS.BASIC_DETAILS
                    ? "g-name-wrapper active"
                    : "g-name-wrapper"
                }
              >
                <p
                  className={
                    this.props.current_tab == TABS.BASIC_DETAILS
                      ? "g-name"
                      : "g-name"
                  }
                >
                  Basic Details
                </p>
              </div>
            </Link>
            <Link to={"/placement-module/education_details"}>
              <div
                className={
                  this.props.current_tab == TABS.EDUCATION_DETAILS
                    ? "g-name-wrapper active"
                    : "g-name-wrapper"
                }
              >
                <p
                  className={
                    this.props.current_tab == TABS.EDUCATION_DETAILS
                      ? "g-name"
                      : "g-name"
                  }
                >
                  Education Details
                </p>
              </div>
            </Link>
            <Link to={"/placement-module/education-details"}>
              <div
                className={
                  this.props.current_tab == TABS.EDUCATION_DETAILS
                    ? "g-name-wrapper active"
                    : "g-name-wrapper"
                }
              >
                <p
                  className={
                    this.props.current_tab == TABS.EDUCATION_DETAILS
                      ? "g-name"
                      : "g-name"
                  }
                >
                  Education Details
                </p>
              </div>
            </Link>
            <Link to={"/placement-module/professional_details"}>
              <div
                className={
                  this.props.current_tab == TABS.PROFESSIONAL_DETAILS
                    ? "g-name-wrapper active"
                    : "g-name-wrapper"
                }
              >
                <p
                  className={
                    this.props.current_tab == TABS.PROFESSIONAL_DETAILS
                      ? "g-name"
                      : "g-name"
                  }
                >
                  Professional Details
                </p>
              </div>
            </Link>
            <Link to={"/placement-module/professional-details"}>
              <div
                className={
                  this.props.current_tab == TABS.PROFESSIONAL_DETAILS
                    ? "g-name-wrapper active"
                    : "g-name-wrapper"
                }
              >
                <p
                  className={
                    this.props.current_tab == TABS.PROFESSIONAL_DETAILS
                      ? "g-name"
                      : "g-name"
                  }
                >
                  Professional Details
                </p>
              </div>
            </Link>
            <Link to={"/placement-module/resume"}>
              <div
                className={
                  this.props.current_tab == TABS.RESUME
                    ? "g-name-wrapper active"
                    : "g-name-wrapper"
                }
              >
                <p
                  className={
                    this.props.current_tab == TABS.RESUME ? "g-name" : "g-name"
                  }
                >
                  Resume
                </p>
              </div>
            </Link>
            <Link to={"/placement-module/resume-details"}>
              <div
                className={
                  this.props.current_tab == TABS.RESUME
                    ? "g-name-wrapper active"
                    : "g-name-wrapper"
                }
              >
                <p
                  className={
                    this.props.current_tab == TABS.RESUME ? "g-name" : "g-name"
                  }
                >
                  Resume
                </p>
              </div>
            </Link>
          </div>
        </div>
        <div
          onClick={this.closeSidebar}
          className={this.props.show_sidebar_mb ? "demo" : "hide"}
        ></div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  show_sidebar_mb: state.utils.show_sidebar_mb,
  current_tab: state.utils.current_tab,
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  gsetting_is_back: state.j_grp.gsetting_is_back,
  brand: state.brand?.brand || state.course.brand,
  is_data_loading: state.utils.is_data_loading,
});

const mapDispatchToProps = {
  setSidebar,
  showSidebarMB,
  getProfileInfo,
  fetchGroups,
  setSelectedGroupDetails,
  setGSettingBack,
  resetGSettingBack,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PlacementSidebar)
);
