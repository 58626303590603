import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditEmailInput from "./EditEmailInput";
import EditEmailSuccessFull from "./EditEmailSuccess";
import EditEmailVerify from "./EditEmailVerify";
const EditEmailWindow = () => {
  const [step, setStep] = useState(0);
  const { primary_email } = useSelector((state) => state.primaryEmail);
  const [email, setEmail] = useState(primary_email);
  const elements = [
    <EditEmailInput
      email={email}
      setEmail={setEmail}
      step={step}
      setStep={setStep}
    />,
    <EditEmailVerify email={email} step={step} setStep={setStep} />,
    <EditEmailSuccessFull />,
  ];
  return (
    <>
      <div className="group-db-content-wrapper">
        <div className="container-fluid dashboard-content">
          <div className=" profile-setting-container-row">
            <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row justify-content-center">
                <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 ">
                  {elements[step]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditEmailWindow;
