import React, { useState } from "react";
import Button from "reactstrap/lib/Button";
import { editPrimaryMailSendOtp } from "../../../apis/editapis";
import { utils } from "../../../utility/utils";
import { POPOVER_TYPE } from "../../../constants/constants";
import InlineLoader from "../../utils/InlineLoader";
import { useDispatch } from "react-redux";

const EditEmailInput = ({ email, setEmail, step, setStep }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const submitData = async () => {
    try {
      setLoading(true);
      //const data = await editPrimaryMailSendOtp({ email });
      editPrimaryMailSendOtp({ email })
        .then(() => {
          utils.showPopover(
            "Email Sent!",
            "Please check your email",
            POPOVER_TYPE.SUCCESS,
            dispatch
          );
          setLoading(false);
          setStep(step + 1);
        })
        .catch((err) => {
          utils.showPopover(
            "EMAIL VERIFICATION FAILED",
            err,
            POPOVER_TYPE.ERROR,
            dispatch
          );
          setLoading(false);
          setError(error);
        });
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  return (
    <div className="pf-content-container">
      <p className="edit-profile-heading">Edit Email</p>

      <div className="ps-inp-container">
        <p className="pf-name">Enter Email</p>
        <input
          className={"ps-inp"}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        ></input>
        <Button
          color="primary"
          disabled={loading}
          onClick={submitData}
          className="pe-cnf-btn__otp-2"
        >
          {!loading ? "Edit Email" : <InlineLoader />}
        </Button>
      </div>
      {error && <p align="center">{error}</p>}
    </div>
  );
};

export default EditEmailInput;
