import React, { Component } from "react";
import Footer from "../footers/Footer";
import ImageCrop from "../utils/ImageCrop";
// import Cp from '../utils/croppie';
import { connect } from "react-redux";
import {
  updatePasswordToBackend,
  verifyOtpFromBackend,
  getOtpCall,
  resendOtpFromBackend,
} from "../../actions/passwordActions";
import EditProfileModal from "../modals/EditProfileModal";
import {
  POPOVER_CONTENT,
  COUNTRY_CODES,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
  URLS,
  PIC_TYPE,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";
import LoadingModal from "../modals/LoadingModal";
import err_chat from "../../images/err-chat.svg";
import success from "../../images/success.svg";
import { PAGE_HEADINGS } from "../../constants/constants";
import { setPageHeading } from "../../actions/utilAction";
import Loader from "../utils/Loader";

class EditPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_id: "",
      pwd: "",
      cnf_pwd: "",
      is_email_null: false,
      is_pwd_null: false,
      is_pwd_match: true,
      is_dpm_open: false,
      is_pwd_valid: true,
      is_email_valid: true,
      otp_received: false,
      otp_verified: false,
      is_otp_null: false,
      otp: "",
      timer: "0:00",
      time: 300,
    };
  }
  componentDidUpdate(prev_props) {
    if (
      prev_props.otp_received != this.props.otp_received &&
      this.props.otp_received
    ) {
      this.setState({ otp_received: this.props.otp_received });
      if (this.state.time > 0) {
        setInterval(() => {
          var ms = this.state.time;
          var min = Math.floor((ms / 60) << 0);
          var sec = Math.floor(ms % 60);
          var time = this.state.time - 1;
          this.setState({ timer: min + ":" + sec, time: time });
        }, 1000);
      }
    }
    if (
      prev_props.otp_verified != this.props.otp_verified &&
      this.props.otp_verified
    ) {
      this.setState({ otp_verified: this.props.otp_verified });
    }
  }
  componentDidMount() {
    this.props.setPageHeading(PAGE_HEADINGS.PROFILE_SETTINGS);
    this.setState({
      otp_received: this.props.otp_received,
      otp_verified: this.props.otp_verified,
    });
  }
  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };

  setEmailId = (value) => {
    this.setState({
      email_id: value,
      is_email_null: false,
      is_email_valid: true,
    });
  };

  setPassword = (value) => {
    if (value === "") {
      this.setState({
        pwd: value,
        is_pwd_null: true,
      });
    } else if (value.length > 20) {
      value = this.state.pwd;
      this.displayPopover(
        POPOVER_HEADINGS.PASSWORD_ERROR,
        POPOVER_CONTENT.PASSWORD_ERROR,
        POPOVER_TYPE.ERROR
      );
    } else {
      this.setState({
        pwd: value,
        is_pwd_null: false,
        is_pwd_valid: true,
      });
    }
  };
  setConfirmPassword = (value) => {
    if (value === "") {
      this.setState({
        cnf_pwd: "",
        is_pwd_null: true,
      });
    } else if (value.length > 20) {
      value = this.state.cnf_pwd;
      this.displayPopover(
        POPOVER_HEADINGS.PASSWORD_ERROR,
        POPOVER_CONTENT.PASSWORD_ERROR,
        POPOVER_TYPE.ERROR
      );
    } else {
      this.setState({
        cnf_pwd: value,
        is_pwd_valid: true,
        is_pwd_null: false,
        is_pwd_match: true,
      });
    }
  };

  validateEmail = (email) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  updatePassword = () => {
    if (this.state.email_id === "") {
      this.setState({ is_email_null: true });
    }
    if (!this.validateEmail(this.state.email_id)) {
      this.setState({ is_email_valid: false });
    } else if (this.state.pwd === "" || this.state.cnf_pwd === "") {
      this.setState({ is_pwd_null: true });
    } else if (
      this.state.pwd.length < 8 ||
      this.state.cnf_pwd.length < 8 ||
      this.state.pwd.length > 20 ||
      this.state.cnf_pwd.length > 20
    ) {
      this.setState({ is_pwd_valid: false });
    } else if (this.state.pwd !== this.state.cnf_pwd) {
      this.setState({ is_pwd_match: false });
    } else {
      var update_obj = {
        email: this.state.email_id,
        password: this.state.pwd,
        confirm_password: this.state.cnf_pwd,
      };
      this.props.updatePasswordToBackend(update_obj);
    }
  };

  verifyOTP = () => {
    if (this.state.otp === "") {
      this.setState({ is_otp_null: true });
    } else {
      var otp_obj = {
        otp: this.state.otp,
        password: this.state.pwd,
      };
      this.props.verifyOtpFromBackend(otp_obj);
    }
  };
  setOTP = (value) => {
    this.setState({ otp: value, is_otp_null: false });
  };

  getCall = () => {
    var otp_ob = {
      cl: "W",
      lng: "en",
      vrsn: "1.0.0",
    };
    this.props.getOtpCall(otp_ob);
  };

  resendOTP = () => {
    this.props.resendOtpFromBackend();
  };

  render() {
    if (!this.props.is_loading) {
      return (
        <div className="group-db-content-wrapper">
          <div className="container-fluid dashboard-content">
            {/* <div className="row tab-row"> */}
            <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <p className="content-header">Profile Settings</p>
            </div>
            {/* </div> */}
            {!this.state.otp_verified ? (
              !this.state.otp_received ? (
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                      <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <p className="chng-pwd-heading">Update Password</p>
                        <div className="pf-content-container">
                          <div className="ps-inp-container">
                            <div className="pwd-inp">
                              <input
                                type="email"
                                placeholder="Enter Email ID"
                                className={
                                  !this.state.is_email_null
                                    ? "ps-inp"
                                    : "ps-inp  err-inp"
                                }
                                value={this.state.email_id}
                                onChange={(e) =>
                                  this.setEmailId(e.target.value)
                                }
                              ></input>
                            </div>
                            <input
                              pattern="^[^-\s]{8,20}$"
                              placeholder="Enter New Password"
                              className={
                                !this.state.is_pwd_null
                                  ? "ps-inp"
                                  : "ps-inp err-inp"
                              }
                              type="password"
                              value={this.state.pwd}
                              onChange={(e) => this.setPassword(e.target.value)}
                              autocomplete="off"
                            ></input>
                            <input
                              pattern="^[^-\s]{8,20}$"
                              placeholder="Confirm New Password"
                              className={
                                this.state.is_pwd_match
                                  ? "ps-inp"
                                  : "ps-inp err-inp"
                              }
                              type="password"
                              value={this.state.cnf_pwd}
                              onChange={(e) =>
                                this.setConfirmPassword(e.target.value)
                              }
                              autocomplete="off"
                            ></input>
                            <p
                              className={
                                "epf-err-msg " +
                                (this.state.is_email_null ? "err-msg" : "hide")
                              }
                            >
                              <img src={err_chat} className="err-icon"></img>{" "}
                              Email can't be empty!
                            </p>
                            <p
                              className={
                                "epf-err-msg " +
                                (!this.state.is_email_valid
                                  ? "err-msg"
                                  : "hide")
                              }
                            >
                              <img src={err_chat} className="err-icon"></img>{" "}
                              Email is not valid!
                            </p>
                            <p
                              className={
                                "epf-err-msg " +
                                (this.state.is_pwd_null ? "err-msg" : "hide")
                              }
                            >
                              <img src={err_chat} className="err-icon"></img>{" "}
                              Password can't be empty!
                            </p>
                            <p
                              className={
                                "epf-err-msg " +
                                (!this.state.is_pwd_match ? "err-msg" : "hide")
                              }
                            >
                              <img src={err_chat} className="err-icon"></img>{" "}
                              Password don't Match!
                            </p>
                            <p
                              className={
                                "epf-err-msg " +
                                (!this.state.is_pwd_valid ? "err-msg" : "hide")
                              }
                            >
                              <img src={err_chat} className="err-icon"></img>{" "}
                              Password length must be between 8 digit to 20
                              digits!!
                            </p>
                          </div>
                          <input
                            className="ps-update-btn"
                            type="button"
                            onClick={this.updatePassword}
                            value="Send OTP"
                          ></input>
                          <div className="info-msg">
                            *To change the password, you need to confirm the
                            email first.
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                      <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <p className="chng-pwd-heading">Verify OTP</p>
                        <div className="pf-content-container">
                          <div className="ps-inp-container">
                            <input
                              placeholder="One Time Password"
                              className={
                                !this.state.is_pwd_null
                                  ? "ps-inp"
                                  : "ps-inp err-inp"
                              }
                              type="text"
                              value={this.state.otp}
                              onChange={(e) => this.setOTP(e.target.value)}
                            ></input>
                            <div className="pwd-otp">
                              {/* <span
                                className="pwd-otp-gt-cl"
                                onClick={this.getCall}
                              >
                                {" "}
                                Get on call
                              </span> */}
                              <span className="pwd-otp-tmr">
                                {" "}
                                {this.state.timer}
                              </span>
                              <span
                                className="pwd-otp-rs"
                                onClick={this.resendOTP}
                              >
                                {" "}
                                Resend OTP
                              </span>
                            </div>
                            <p
                              className={
                                "epf-err-msg " +
                                (this.state.get_call ? "info-msg" : "hide")
                              }
                            >
                              You Will Recieve Call Shortly!
                            </p>
                            <p
                              className={
                                "epf-err-msg " +
                                (this.state.is_otp_null ? "err-msg" : "hide")
                              }
                            >
                              <img src={err_chat} className="err-icon"></img>{" "}
                              OTP can't be empty!
                            </p>
                          </div>
                          <input
                            className="ps-update-btn"
                            type="button"
                            onClick={this.verifyOTP}
                            value="Verify OTP"
                          ></input>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                    <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <p className="chng-pwd-heading">
                        <img src={success} className="pwd-scs" />
                        <br />
                        Awesome!
                      </p>

                      <div className="pf-content-container">
                        <div className="ps-inp-container">
                          <div className="pwd-msg-sc">
                            Your password has been reset successfully.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                  </div>
                </div>
              </div>
            )}
            <LoadingModal />
          </div>
          <Footer />
        </div>
      );
    } else {
      return <Loader />;
    }
  }
}

// export default EditPassword;

const mapStateToProps = (state) => ({
  email_id: state.password.email_id,
  pwd: state.password.pwd,
  cnf_pwd: state.password.confirm_pwd,
  otp_received: state.password.otp_received,
  otp_verified: state.password.otp_verified,
  is_loading: state.password.is_loading,
});

const mapDispatchToProps = {
  updatePasswordToBackend,
  verifyOtpFromBackend,
  getOtpCall,
  resendOtpFromBackend,
  showPopover,
  setPageHeading,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPassword);
