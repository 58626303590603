import React, { Component, Fragment } from "react";
// import prev_btn from "../../images/carousel-prev.svg";
// import next_btn from "../../images/carousel-next.svg";
// import prev_btn_d from "../../images/carousel-prev-d.svg";
// import next_btn_d from "../../images/carousel-next-d.svg";
import CardHeading from "../utils/CardHeading";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { redirectToGroupPage } from "../../actions/joinGroupAction.js";
import { getGroupInfo } from "../../actions/joinGroupAction.js";
import group_default from "../../images/groups-01-01.svg";
import { RESOLUTION_MODES, CONFIGS } from "../../constants/constants.js";
import GroupCarouselSS from "../skeletonscreens/GroupCarouselSS";
import { setTrendingGroupsToRender } from "../../actions/exploreAction";
import { disableGroupDetailRedirection } from "../../actions/groupAction";
import queryString from "query-string";

let prev_btn = "https://cdn.pegasus.imarticus.org/index/carouselNext.svg",
  next_btn = "https://cdn.pegasus.imarticus.org/index/carouselNext.svg",
  prev_btn_d =
    "https://cdn.pegasus.imarticus.org/index/carouselNextDisabled.svg",
  next_btn_d =
    "https://cdn.pegasus.imarticus.org/index/carouselNextDisabled.svg";
class FreeCourseCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      width_to_scroll: "",
      // length: fc_dump.length,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    if (document.getElementById(this.props.ref_id)) {
      var join_grp_container_width = document.getElementById(
        this.props.ref_id
      ).offsetWidth;
      this.setState({
        width_to_scroll: join_grp_container_width + 26,
      });
    }
  }

  componentDidUpdate(prev_props) {
    if (prev_props.tr_grp_to_render != this.props.tr_grp_to_render) {
      this.resize();
    }
    if (prev_props.tr_grp != this.props.tr_grp) {
      if (
        window.innerWidth < 1024 &&
        this.state.mode != RESOLUTION_MODES.TABLET
      ) {
        //code to change the data in 3 3 pairs
        this.mapFreeCoursesForTablet();
      } else if (
        window.innerWidth >= 1024 &&
        this.state.mode != RESOLUTION_MODES.WEB
      ) {
        //code to change the data in 4 4 pairs
        this.mapFreeCoursesForWeb();
      }
    }
  }
  redirectToCoursePage = (u_tag) => {
    if (u_tag) {
      var crs_url = CONFIGS.COURSE_PAGE_BASE_URL + u_tag;
      var win = window.open(crs_url);
      win.focus();
    }
  };

  mapFreeCoursesForWeb = () => {
    var tr_grp = this.props.tr_grp;
    // [ [{},{},{}], [{},{},{}], [{},{},{}] ]
    var tr_grp_to_render = [];
    var crs_in_section = 0;
    var section = [];
    for (var i = 0; i < tr_grp.length; i++) {
      if (crs_in_section == 0 || crs_in_section == 1) {
        section.push(tr_grp[i]);
        crs_in_section++;
      } else {
        section.push(tr_grp[i]);
        tr_grp_to_render.push(section);
        crs_in_section = 0;
        section = [];
      }
      if (i == tr_grp.length - 1 && crs_in_section != 0) {
        tr_grp_to_render.push(section);
        section = [];
      }
    }
    this.props.setTrendingGroupsToRender(tr_grp_to_render);
  };

  mapFreeCoursesForTablet = () => {
    var tr_grp = this.props.tr_grp;
    // [ [{},{},{}], [{},{},{}], [{},{},{}] ]
    var tr_grp_to_render = [];
    var crs_in_section = 0;
    var section = [];
    for (var i = 0; i < tr_grp.length; i++) {
      if (crs_in_section == 0) {
        section.push(tr_grp[i]);
        crs_in_section++;
      } else {
        section.push(tr_grp[i]);
        tr_grp_to_render.push(section);
        crs_in_section = 0;
        section = [];
      }
      if (i == tr_grp.length - 1 && crs_in_section != 0) {
        tr_grp_to_render.push(section);
        section = [];
      }
    }
    this.props.setTrendingGroupsToRender(tr_grp_to_render);
  };

  resize() {
    if (document.getElementById(this.props.ref_id)) {
      var join_grp_container_width = document.getElementById(
        this.props.ref_id
      ).offsetWidth;
      if (
        window.innerWidth < 1024 &&
        this.state.mode != RESOLUTION_MODES.TABLET
      ) {
        //code to change the data in 3 3 pairs
      } else if (
        window.innerWidth > 1024 &&
        this.state.mode != RESOLUTION_MODES.WEB
      ) {
        //code to change the data in 4 4 pairs
      }
      this.setState({
        width_to_scroll: join_grp_container_width + 26,
        count: 0,
      });
      var join_grp_container_width = document.getElementById(
        this.props.ref_id
      ).offsetWidth;
      var sec_elems = document.getElementsByClassName(
        "fc-carousel-sec-container"
      );
      for (var i = 0; i < sec_elems.length; i++) {
        sec_elems[i].style.width = join_grp_container_width + 26 + "px";
      }
      var car_container = document.getElementById("tg_carousel_container_id");
      if (car_container) {
        car_container.style.marginLeft = 0 + "px";
      }
    }
  }

  showImg = (e) => {
    e.target.style.display = "block";
  };

  onGroupClicked = (gid, gcode) => {
    if (this.props.joined_gids[gid]) {
      this.redirectToGrpDetailPage(gid);
    } else {
      // this.props.redirectToGroupPage(gcode);
      this.props.getGroupInfo(gcode);
    }
  };

  redirectToGrpDetailPage = (gid) => {
    this.props.history.push({
      pathname: "/dashboard/group-detail",
      search: queryString.stringify(
        Object.assign({}, queryString.parse(this.props.location.search), {
          gid,
        })
      ),
    });
  };

  nextSlide = () => {
    var car_container = document.getElementById("tg_carousel_container_id");

    if (Number.isNaN(parseInt(car_container.style.marginLeft))) {
      car_container.style.marginLeft = 0 + "px";
    }
    if (
      parseInt(car_container.style.marginLeft) - this.state.width_to_scroll !=
      car_container.offsetWidth * -1
    ) {
      car_container.style.marginLeft =
        parseInt(car_container.style.marginLeft) -
        this.state.width_to_scroll +
        "px";
      this.setState({
        count: this.state.count + 1,
      });
    }
  };

  prevSlide = () => {
    var car_container = document.getElementById("tg_carousel_container_id");
    if (Number.isNaN(parseInt(car_container.style.marginLeft))) {
      car_container.style.marginLeft = 0 + "px";
    }
    if (parseInt(car_container.style.marginLeft) != 0) {
      car_container.style.marginLeft =
        parseInt(car_container.style.marginLeft) +
        this.state.width_to_scroll +
        "px";
      this.setState({
        count: this.state.count - 1,
      });
    }
  };
  render() {
    this.props.disableGroupDetailRedirection();
    if (this.props.tr_grp_to_render) {
      return (
        <Fragment>
          <div className="carousel-header">
            <div className="carousel-heading">Trending Groups</div>
            <div className="carousel-btn-container">
              <button
                onClick={this.prevSlide}
                disabled={this.state.count == 0 ? true : false}
                className="prev-btn"
              >
                <img
                  className="btn-src"
                  src={this.state.count == 0 ? prev_btn_d : prev_btn}
                ></img>
              </button>
              <button
                onClick={this.nextSlide}
                disabled={
                  this.state.count == this.props.tr_grp_to_render.length - 1
                    ? true
                    : false
                }
                className="next-btn"
              >
                <img
                  className="btn-src"
                  src={
                    this.state.count == this.props.tr_grp_to_render.length - 1
                      ? next_btn_d
                      : next_btn
                  }
                ></img>
              </button>
            </div>
          </div>
          <div className="carousel-wrapper">
            <div
              className="fc-carousel-container"
              id="tg_carousel_container_id"
            >
              {this.props.tr_grp_to_render.map((section, dump_index) => {
                return (
                  <div className="fc-carousel-sec-container">
                    {section.map(
                      (
                        { gpic, name, size, u_tag, mem, _id, code },
                        section_index
                      ) => {
                        return (
                          <div className="fc-carousel-card-container">
                            <div
                              className="fc-carousel-card"
                              onClick={() => this.onGroupClicked(_id, code)}
                            >
                              {/* <div className="fc-cover" ></div> */}
                              <div className="single-card-img-container">
                                <img
                                  className="single-card-img"
                                  onLoad={(e) => this.showImg(e)}
                                  src={
                                    gpic
                                      ? gpic
                                      : "https://cdn.pegasus.imarticus.org/index/group_default.svg"
                                  }
                                ></img>
                              </div>
                              <div className="card-content">
                                <div className="card-heading-container">
                                  <CardHeading text={name} />
                                </div>
                                <p className="card-enroll">
                                  {mem} {mem > 1 ? "Members" : "Member"}
                                </p>
                                <button
                                  className={
                                    this.props.joined_gids[_id]
                                      ? "vw-card-btn"
                                      : "jn-card-btn"
                                  }
                                >
                                  {this.props.joined_gids[_id]
                                    ? "View Group"
                                    : "Join Group"}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <GroupCarouselSS heading={"Trending Groups"} />;
    }
  }
}

const mapStateToProps = (state) => ({
  tr_grp: state.explore.tr_grp,
  tr_grp_to_render: state.explore.tr_grp_to_render,
  joined_gids: state.explore.joined_gids,
});

const mapDispatchToProps = {
  // fetchExploreData,
  setTrendingGroupsToRender,
  redirectToGroupPage,
  disableGroupDetailRedirection,
  getGroupInfo,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FreeCourseCarousel)
);
