import React from "react";
import { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import blankProfile from "./img/blank_profile.png";
import uploadIcon from "./img/uploadIcon.svg";

export default function UploadImage({
  PID,
  toggleUploadImage,
  toggleUploadImageHandler,
  uploadFile,
  handleUploadedImage,
  uploadStatus,
  fileName,
}) {
  const handleChange = (event) => {
    console.log(event.target.files);
    if (event.target.files.length > 0) {
      handleUploadedImage(event.target.files[0]);
    } else return false;
  };

  const imageNameCheck = (imgName) => {
    if (imgName.length > 12) {
      let split = imgName.split(".");
      let ext = split[split.length - 1];
      let toRenderName = imgName.substring(0, 10);
      return toRenderName + "...." + ext;
    } else {
      return imgName;
    }
  };

  return (
    <Modal
      isOpen={toggleUploadImage}
      toggle={toggleUploadImageHandler}
      size={"sm"}
      style={{ width: "400px" }}
    >
      <ModalHeader toggle={toggleUploadImageHandler}>Upload Image</ModalHeader>
      <ModalBody
        style={{
          maxHeight: "400px",
          overflowY: "scroll",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <span class="select-wrapper">
          <input
            type="file"
            name="file"
            id="image_src"
            className="image_src"
            onChange={handleChange}
            accept=".jpg, .jpeg"
          />
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "basline",
          }}
        >
          <label
            for="image_src"
            style={{ alignSelf: "center", cursor: "pointer" }}
          >
            {fileName
              ? imageNameCheck(fileName)
              : "Click me to upload an image!"}
          </label>
          <small> Max size: 1 MB | File Type: jpeg or jpg</small>
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center" }}>
        <Button
          className={uploadStatus && "disableButton"}
          color={!uploadStatus ? "primary" : "disabled"}
          onClick={uploadFile}
        >
          {!uploadStatus ? "Upload" : uploadStatus}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
