const initialState = {
	show_menu : false,
}

const accountDropdownReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SHOW_MENU':
			return { ...state, show_menu : action.show_menu }
		default:
			return state;
	}
}

export default accountDropdownReducer;
