import axios from "axios";
import { ROUTES } from "../constants/routes";
import { utils } from "../utility/utils";
import { CONFIGS, POPOVER_HIDETIME } from "../constants/constants";
import Cookies from "universal-cookie";
import cookie from "react-cookies";
import { logoutUser } from "./accountDropdownAction";

const cookies = new Cookies();

export const setSearch = (value) => (dispatch) => {
  dispatch({
    type: "SET_SEARCH",
    payload: {
      value,
    },
  });
};

export const setLoading = (value) => (dispatch) => {
  dispatch({
    type: "SET_LOADING",
    payload: {
      value,
    },
  });
};

export const showSidebarMB = (value) => (dispatch) => {
  dispatch({
    type: "SHOW_SIDEBAR_MB",
    payload: {
      value,
    },
  });
};

export const setCurrentTab = (current_tab) => (dispatch) => {
  dispatch({
    type: "SET_CURRENT_TAB",
    payload: {
      current_tab,
    },
  });
};

export const setRole = (role) => (dispatch) => {
  dispatch({
    type: "SET_ROLE",
    payload: {
      role,
    },
  });
};

export const setNotFound = (val) => (dispatch) => {
  dispatch({
    type: "SET_NOT_FOUND",
    val: val,
  });
};

export const openErrorModal = (val, err_msg) => (dispatch) => {
  dispatch({
    type: "ERROR_OCCURED",
    val: val,
    err_msg: err_msg,
  });
};

export const setNetworkError = () => (dispatch) => {
  dispatch({
    type: "ERROR_OCCURED",
    val: true,
  });
};

export const renewAccessToken = (cb) => {
  console.log(" --- Renewing access token --- ");
  return new Promise((resolve, reject) => {
    var data = {
      headers: {
        "Content-Type": "application/json",
      },
      a_id: utils.getAid(),
      d_id: window.fingerprint.md5hash,
      at: utils.getAt(),
      cl: "W",
      rt: utils.getRefreshToken(),
      k_ey: utils.getSecret(),
    };
    axios
      .post(ROUTES.RENEW_ACCESS_TOKEN, data)
      .then((res) => {
        console.log({ data: res.data.data });
        var at = res.data.data.at;
        var expiredate = new Date();
        expiredate.setDate(expiredate.getDate() + 180);
        // cookies.set("at", at, {
        //   expires: expiredate,
        //   domain: CONFIGS.DOMAIN,
        //   path: "/",
        // });
        return resolve(at);
      })
      .catch((err) => {
        console.error(err);
        logoutUser();
        // utils.removeAuthCookies();
        window.location.href = CONFIGS.ECKOVATION_WEB_ENDPOINT;
        return reject("");
      });
  });
};
export const setHomeTab = (val) => (dispatch) => {
  dispatch({
    type: "SET_SHOW_HOME",
    value: val,
  });
};

export const setCourseTab = (val) => (dispatch) => {
  dispatch({
    type: "SET_SHOW_MY_COURSE",
    value: val,
  });
};

export const setGroupTab = (val) => (dispatch) => {
  dispatch({
    type: "SET_SHOW_MY_GROUP",
    value: val,
  });
};

export const resetLoadingData = () => (dispatch) => {
  dispatch({
    type: "RESET_DATA_LOADING",
  });
};

export const setLoadingData = () => (dispatch) => {
  dispatch({
    type: "SET_DATA_LOADING",
  });
};
export const setSlidingStrip = (msg) => (dispatch) => {
  dispatch({
    type: "SET_SLIDING_STRIP",
    ss_msg: msg,
  });
  setTimeout(function () {
    dispatch({
      type: "RESET_SLIDING_STRIP",
    });
  }, POPOVER_HIDETIME);
};
export const resetSlidingStrip = () => (dispatch) => {
  dispatch({
    type: "RESET_SLIDING_STRIP",
  });
};
export const showPopover = (heading, msg, pover_type) => (dispatch) => {
  dispatch({
    type: "SHOW_POPOVER",
    heading: heading,
    msg: msg,
    pover_type: pover_type,
  });
  setTimeout(function () {
    dispatch({
      type: "CLOSE_POPOVER",
    });
  }, POPOVER_HIDETIME);
};

export const closePopover = () => (dispatch) => {
  dispatch({
    type: "CLOSE_POPOVER",
  });
};
export const resetProfileChangedCounter = () => (dispatch) => {
  dispatch({
    type: "RESET_PROFILE_CHANGED",
  });
};
export const setPageHeading = (pg_heading) => (dispatch) => {
  dispatch({
    type: "SET_PAGE_HEADING",
    pg_heading: pg_heading,
  });
};
export const setSidebar = (sidebar) => (dispatch) => {
  dispatch({
    type: "SET_SIDEBAR",
    sidebar: sidebar,
  });
};
export const setBackBtn = (back_url) => (dispatch) => {
  dispatch({
    type: "SET_BACK_BTN",
    back_url,
  });
};
export const resetBackBtn = () => (dispatch) => {
  dispatch({
    type: "RESET_BACK_BTN",
  });
};
