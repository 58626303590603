import React from "react";
import axios from "axios";
import { CONFIGS } from "../../constants/constants";
import { useLocation } from "react-router";
import NotFound from "../errors/NotFound";

const UnsubscribeNotification = () => {
  const { search } = useLocation();
  console.log({ search });
  let aid = search.split("aid=")[1];
  const unsubscribe = () => {
    axios
      .post(`${CONFIGS.API_API_ENDPOINT}notifications/unsubscribeEmail`, {
        aid: aid,
      })
      .then((res) => {
        console.log("unsubscribeEmail ::: ", res.data.data);
      })
      .catch((err) => {
        console.log("unsubscribeEmail Error ::: ", err);
      });
  };
  if (!aid) {
    return <NotFound />;
  }
  return (
    <div style={{ padding: "25px" }}>
      <img src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg" />
      <br />
      <br />
      <br />
      <p style={{ fontSize: "22px" }}>
        Are you sure you want to unsubscribe from Imarticus Notifications?
      </p>
      <button className="btn btn-success" onClick={unsubscribe}>
        Click here to unsubscribe
      </button>
    </div>
  );
};

export default UnsubscribeNotification;
