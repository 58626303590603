import React from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { connect } from "react-redux";
import {
  openPrimaryEmailModal,
  confirmPrimaryEmailToken,
  openVerifyEmailModal,
  confirmPrimaryEmail,
  addNewSecondaryEmail,
  openAddNewEmailModal,
  isPrimaryEmailVerified,
} from "../../actions/primaryEmailActions";
import EmailImg2 from "../../images/email-alert-01.png";
import EmailImg3 from "../../images/email-failed-01.png";
import EmailImg4 from "../../images/email-success-01.png";
import {
  CONFIGS,
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";

class PrimaryEmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_modal: false,
      is_waiting: true,
      email_verified: false,
      verify_link: "",
    };
    // this.props.isPrimaryEmailVerified();
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.setState({
      is_waiting: this.props.email_waiting,
      email_verified: this.props.email_verified,
    });
  }
  componentDidUpdate(prev_props) {
    if (prev_props.verify_link != this.props.verify_link) {
      console.log("link update:", this.props.verify_link);
      this.setState({ verify_link: this.props.verify_link });
      if (this.props.verify_link !== "") {
        this.props.confirmPrimaryEmailToken(this.props.verify_link);
      }
    }
    if (prev_props.open_modal != this.props.open_modal) {
      this.setState({ open_modal: this.props.open_modal });
    }
    if (prev_props.email_waiting != this.props.email_waiting) {
      this.setState({ is_waiting: this.props.email_waiting });
    }
    if (prev_props.email_verified != this.props.email_verified) {
      this.setState({ email_verified: this.props.email_verified });
    }
  }
  toggle() {
    this.props.openPrimaryEmailModal(false);
    this.props.openVerifyEmailModal(false);
    window.location.reload();
  }

  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };

  render() {
    return (
      <>
        {this.state.email_verified && (
          <Modal isOpen={this.props.open_modal} className="pe">
            <ModalBody>
              <img src={EmailImg4} className="pe-img" />
              <div className="pe-head">Congratulations!</div>
              <div className="pe-vrfy-info">
                You have successfully verified your email address
              </div>
              <Button
                color="primary"
                className="pe-vrfy-btn"
                onClick={this.toggle}
              >
                Finish
              </Button>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  open_modal: state.primaryEmail.open_status_modal,
  verify_link: state.primaryEmail.verify_link,
  email_verified: state.primaryEmail.email_verified,
  email_waiting: state.primaryEmail.waiting_verification,
});

const mapDispatchToProps = {
  openPrimaryEmailModal,
  isPrimaryEmailVerified,
  showPopover,
  openAddNewEmailModal,
  addNewSecondaryEmail,
  confirmPrimaryEmail,
  openVerifyEmailModal,
  confirmPrimaryEmailToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryEmailModal);
