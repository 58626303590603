import Axios from "axios";
import { withRouter, useHistory } from "react-router";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { CONFIGS } from "../../../constants/constants";
import ProfileDetailCard from "../../utils/ProfileDetailCard";
import { utils } from "../../../utility/utils";
import momentjs from "moment-timezone";
import PDFReader from "../../PDFReader/PDFReader";
import Loader from "../../utils/Loader.js";
import "./placementprofile.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { CCODS, TABS } from "../../../constants/constants";
import { uploadUserResume } from "../../../actions/profileAction";
import { setCurrentTab } from "../../../actions/utilAction";
import { Link, useLocation } from "react-router-dom";
import small_left from "../../../images/left-arr-white.svg";
import {
  getPlacementProfile,
  getProfileInfo,
  getProfileInfoOnly,
  saveResumeDetails,
  deleteUserResume,
} from "../../../actions/profileAction";
const ResumeDetails = (props) => {
  // const dispatch = useDispatch();
  let fileInput = useRef(null);
  const history = useHistory();
  let profile = props.profile;
  let courses = props.courses;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const [ifSubscribed, setIfSubscribed] = useState(profile.ifSubscribed);

  const [loading, setLoading] = useState(true);
  const [crs_pg_id, setCrsPgId] = useState("");
  const [resume, setResume] = useState([]);
  const [dragging, setDragging] = useState(false);
  const [initialLoading, setIntitalLoading] = useState(false);
  const [percCompleted, setPercCompleted] = useState(0);
  const [learnerAccess, setLearnerAccess] = useState(false);
  const [redirectVal, setRedirectVal] = useState("");
  const [chosenResume, setChosenResume] = useState();
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [openResumeUpload, setOpenResumeUpload] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openZeroModal, setOpenZeroModal] = useState(false);
  const [openResumeConfirm, setOpenResumeConfirm] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [resumeURL, setResumeURL] = useState("");
  const [docresume, setDocResume] = useState({
    url: "",
  });
  const [vidresume, setVidResume] = useState({
    url: "",
  });
  console.log({ profile });
  let creationDate = new Date(profile.profile_map.cat).toString();
  useEffect(() => {
    props.setCurrentTab(TABS.RESUME);
    if (props.uid == "") {
      props.getProfileInfo();
    }
    const crs_pgid = searchParams.get("crs_pg_id");
    setCrsPgId(crs_pgid);
    const redirect = searchParams.get("redirect");
    setRedirectVal(redirect);
    setIntitalLoading(true);
  }, []);

  useEffect(() => {
    console.log("useEffect ran");
    if (initialLoading) {
      getPlacementProfile("professional-details", crs_pg_id)
        .then(function (res) {
          setLoading(false);
          console.log(res, ":response data");
          if (res && res.learnerResume) {
            setResume(res.learnerResume);
          }
          if (res && res.perc_completed) {
            setPercCompleted(res.perc_completed);
          }
          setLearnerAccess(res.learnerAccess);
        })
        .catch(function (err) {
          setLoading(false);
          console.log(err);
        });
    }
  }, [initialLoading]);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleSkipSubmit = () => {
    if (percCompleted == 0 && !docresume.url && !vidresume.url) {
      setOpenZeroModal(true);
    } else {
      setOpenSuccessModal(true);
    }
  };

  const handleSaveSubmit = () => {
    if (percCompleted == 0 && !docresume.url && !vidresume.url) {
      setOpenZeroModal(true);
    } else {
      saveResume();
      // setOpenSuccessModal(true);
    }
  };

  let chooseResume = (selectedFile) => {
    const file = selectedFile;
    let res = validResume(file);

    if (!res.valid) {
      window.alert(res.errorString);
    } else {
      setChosenResume(file);
      uploadUserResume(file)
        .then(({ success, data, message }) => {
          // setIsLoading(false)
          console.log({ success: success });
          if (!success) {
            if (message) {
              window.alert(message);
            } else {
              window.alert("Something went wrong. Please try again.");
            }
          } else {
            setChosenResume(data.img_url);
            setDocResume({
              url: data.img_url,
            });
            setOpenResumeUpload(false);
            setOpenResumeConfirm(true);
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
          // Handle any errors that occurred during the async operation
        });
    }
  };
  const extensions = [".pdf", ".docx", ".xlsx", ".txt"];

  const getFileName = (url) => {
    return extensions.find((ext) => url.endsWith(ext))
      ? url.substring(url.lastIndexOf("/") + 1)
      : null;
  };

  const handleSubmit = () => {
    if (selectedFile) {
      chooseResume(selectedFile);
    } else {
      alert("Please select a file before submitting.");
    }
  };

  const handleCancel = () => {
    setOpenResumeUpload(false);
    setSelectedFile(null);
  };

  let validResume = (file) => {
    const supportedFileTypes = ["application/pdf"];

    let errorList = [];

    if (file.size > 500 * 1024) {
      errorList.push("File Size exceeds limit");
    }

    if (supportedFileTypes.indexOf(file.type) == -1) {
      errorList.push("File type is unsupported");
    }

    let errorString = errorList.join(" and ");
    if (errorList.length > 0) {
      return {
        valid: false,
        errorString,
      };
    } else {
      return {
        valid: true,
      };
    }
  };

  const previewResumeClick = (url) => {
    setResumeURL(url);
    setTogglePDFDoc(true);
  };

  const deleteResume = (resume_id, aid) => {
    deleteUserResume(resume_id, aid).then((res) => {
      if (res && res.data.success) {
        window.alert("Successfully deleted the resume");
        window.location.reload();
      }
    });
  };

  const redirectToCourse = () => {
    const course = courses.find((course) => course.crs_pg_id === crs_pg_id);
    if (course) {
      history.push(`/view/?cid=${course._id}`);
    }
  };

  const saveResume = async () => {
    // if (!docresume.url && !vidresume.url) {
    //   alert("No resume added to save");
    //   return;
    // }
    saveResumeDetails(docresume, vidresume).then((res) => {
      if (res && res.data.success) {
        setOpenSuccessModal(true);
        // history.push(
        //   `/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`
        // );
      }
    });
  };

  return (
    <>
      <div className="group-sidebar">
        <Link to="/dashboard">
          <div
            className="logo-container center-all "
            style={{
              backgroundColor: `${
                props.brand && props.brand.whiteBackground ? "white" : ""
              }`,
            }}
          >
            {!props.is_data_loading && (
              <img
                src={
                  props.brand
                    ? props.brand.pic
                    : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                }
                className="side-bar__logo-img"
                style={{
                  maxHeight: "50px",
                  margin: "unset",
                }}
              />
            )}
          </div>
        </Link>
        <p className="back-btn" onClick={redirectToCourse}>
          <img className="back-img" src={small_left}></img>Back
        </p>
        <div>
          <p className="grp-heading">Placement Profile</p>
          {redirectVal != "portal" && (
            <Link
              to={`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}&redirect=${redirectVal}`}
            >
              <div className={"g-name-wrapper"}>
                <p className={"g-name"}>Profile Preview</p>
              </div>
            </Link>
          )}
          {/* <Link to={`/placement-module/basic_details/?crs_pg_id=${crs_pg_id}`}> */}
          <Link
            // to={"#"}
            to={`/placement-module/basic_details/?crs_pg_id=${crs_pg_id}&redirect=${redirectVal}`}
            // onClick={(e) => {
            //   e.preventDefault();
            // }}
            // style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Basic Details</p>
            </div>
          </Link>
          {/* <Link
            to={`/placement-module/education_details/?crs_pg_id=${crs_pg_id}`}
          > */}
          <Link
            // to={"#"}
            to={`/placement-module/education_details/?crs_pg_id=${crs_pg_id}&redirect=${redirectVal}`}
            // onClick={(e) => {
            //   e.preventDefault();
            // }}
            // style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Education Details</p>
            </div>
          </Link>
          {/* <Link
            to={`/placement-module/professional_details/?crs_pg_id=${crs_pg_id}`}
          > */}
          <Link
            // to={"#"}
            to={`/placement-module/professional_details/?crs_pg_id=${crs_pg_id}&redirect=${redirectVal}`}
            // onClick={(e) => {
            //   e.preventDefault();
            // }}
            // style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Professional Details</p>
            </div>
          </Link>
          <Link
            to={`/placement-module/resume/?crs_pg_id=${crs_pg_id}&redirect=${redirectVal}`}
          >
            <div className={"g-name-wrapper active"}>
              <p className={"g-name"}>Resume</p>
            </div>
          </Link>
          {/* <Link
            to={`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Profile Preview</p>
            </div>
          </Link> */}
        </div>
      </div>
      {!loading ? (
        <>
          <div className="group-db-content-wrapper-2">
            <div className="container-fluid dashboard-content">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row justify-content-left profile-progress-bar">
                    <h5 className="profile-bar-heading col-md-12">
                      Tell us a little more about yourself
                    </h5>
                    <div className="profile-bar-detail col-md-12">
                      <p>Profile Progress</p>
                      <div
                        className="row"
                        style={{ height: "100%", marginLeft: "4px" }}
                      >
                        <div className="profile-bar-progress col-md-10">
                          <div
                            className="profile-bar-completed"
                            style={{ width: `${percCompleted}%` }}
                          ></div>
                        </div>
                        <div className="col-md-2" style={{ marginTop: "-5px" }}>
                          {percCompleted}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="group-db-content-wrapper-2">
            <div className="container-fluid ">
              <h4 className="profile-setting-container-3">Upload Resume</h4>
            </div>
          </div>
          <div className="group-db-content-wrapper">
            <div className="container-fluid dashboard-content-2">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-7 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="justify-content-center">
                    <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                      <div className="edit-window-profile-details">
                        <li className="row">
                          <div className="col-md-12">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Doc Resume
                              <span style={{ color: "red", fontSize: "18px" }}>
                                *
                              </span>
                            </h5>
                            {chosenResume && (
                              <a className="file-name" href={chosenResume}>
                                Selected Resume to Upload
                              </a>
                            )}

                            <FormGroup>
                              <div
                                className="upload-div-2 drop-zone"
                                onClick={() => setOpenResumeUpload(true)}
                              >
                                <div className="upload-div-profile ">
                                  <img
                                    src="https://webcdn.imarticus.org/Placement/material-symbols_upload1.svg"
                                    className="as-dl-logo-job-1"
                                    // onClick={() =>
                                    //   previewResumeClick(file.resume_url)
                                    // }
                                  />
                                </div>
                                <div className="upload-div-profile-content">
                                  <div className="upload-div-txt">
                                    <h5>Upload File</h5>{" "}
                                  </div>
                                  <div className="upload-div-txt-2">
                                    <p>
                                      Accepted format: .pdf | Max Size 500KB
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </FormGroup>
                            {resume
                              .filter((file) => file.resume_type === 2)
                              .map((file, index) => (
                                <div key={index}>
                                  <div className="upload-div-2 drop-zone">
                                    <div className="upload-div-profile">
                                      <img
                                        className="resume-icon-2"
                                        src="https://webcdn.imarticus.org/Placement/fluent_document-pdf-24-filled.svg"
                                      />
                                    </div>
                                    <div className="resume-flex-wrap">
                                      <div className="upload-div-profile-content">
                                        <div className="upload-div-txt">
                                          <h5 className="resume-heading">
                                            Resume {index + 1}
                                          </h5>
                                        </div>
                                        <div className="upload-div-txt-2">
                                          <p>
                                            {momentjs(file.createdAt).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        className="upload-div-profile-content"
                                        style={{ display: "flex" }}
                                      >
                                        <div className="upload-div-txt">
                                          <img
                                            src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                            className="as-dl-logo-job"
                                            onClick={() =>
                                              previewResumeClick(
                                                file.resume_url
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="upload-div-txt">
                                          <img
                                            src="https://webcdn.imarticus.org/Placement/material-symbols_delete-outline.svg"
                                            className="as-dl-logo-job"
                                            onClick={() =>
                                              deleteResume(file._id)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              ))}
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid dashboard-content-2">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="justify-content-center">
                    <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                      <div className="edit-window-profile-details">
                        <li className="row">
                          <div className="col-md-12">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Video Resume
                            </h5>
                          </div>
                        </li>
                        <li className="row">
                          <div className="col-md-12">
                            <h5
                              class="modal-title profile-heading"
                              htmlFor="thumbImg"
                              style={{ marginTop: "1rem" }}
                            >
                              Enter Video URL (Only Youtube & Vimeo Allowed)
                            </h5>
                            <FormGroup>
                              <Input
                                type="text"
                                name="text"
                                id="name"
                                value={vidresume.url}
                                onChange={(event, oppID) => {
                                  setVidResume({ url: event.target.value });
                                }}
                                style={{ width: "98%" }}
                                // className="profile-box"
                              />
                            </FormGroup>
                            {resume
                              .filter((file) => file.resume_type === 1)
                              .map((file, index) => (
                                <div key={index}>
                                  <div className="upload-div-2 drop-zone">
                                    <div className="upload-div-profile">
                                      <img
                                        className="resume-icon-2"
                                        src="https://webcdn.imarticus.org/Placement/mingcute_video-fill.svg"
                                      />
                                    </div>
                                    <div className="resume-flex-wrap">
                                      <div className="upload-div-profile-content">
                                        <div className="upload-div-txt">
                                          <h5 className="resume-heading">
                                            Resume {index + 1}
                                          </h5>
                                        </div>
                                        <div className="upload-div-txt-2">
                                          <p>
                                            {momentjs(file.createdAt).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                      <div
                                        className="upload-div-profile-content"
                                        style={{ display: "flex" }}
                                      >
                                        <div className="upload-div-txt">
                                          <a
                                            href={file.resume_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <img
                                              src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                              className="as-dl-logo-job"
                                              alt="View Resume"
                                            />
                                          </a>
                                        </div>
                                        <div className="upload-div-txt">
                                          <img
                                            src="https://webcdn.imarticus.org/Placement/material-symbols_delete-outline.svg"
                                            className="as-dl-logo-job"
                                            onClick={() =>
                                              deleteResume(file._id)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                </div>
                              ))}
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid dashboard-content-2">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-2 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 profile-next-btn">
                  <div className="addcrit-footer">
                    <Row className="adques-footer">
                      <Col md={2}>
                        <Link
                          to={`/placement-module/professional_details/?crs_pg_id=${crs_pg_id}&redirect=${redirectVal}`}
                        >
                          <Button className="jobs-table-btn-5">Previous</Button>
                        </Link>
                      </Col>
                      <Col md={3}></Col>
                      <Col md={1}>4/4</Col>
                      <Col md={2}></Col>
                      <Col md={2}>
                        {(learnerAccess == false ||
                          redirectVal != "portal") && (
                          // <Link
                          //   to={`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`}
                          // >
                          <Button
                            className="labs-table-btn-2"
                            onClick={() => handleSkipSubmit()}
                          >
                            Skip & Submit
                          </Button>
                          // </Link>
                        )}
                      </Col>
                      <Col md={2}>
                        <Button
                          className="jobs-table-btn-5"
                          onClick={() => {
                            handleSaveSubmit();
                          }}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            isOpen={togglePDFDoc}
            size={"md"}
            style={{ minwidth: "400px" }}
            centered={true}
          >
            <ModalHeader
              className="resume-prev"
              style={{ paddingRight: "7px", width: "100%" }}
            >
              <div className="row" style={{ justifyContent: "space-between" }}>
                <div>Resume Preview </div>
                <div>
                  <img
                    src="https://webcdn.imarticus.org/Placement/Group926.svg"
                    style={{ paddingRight: "7px" }}
                    onClick={() => setTogglePDFDoc(false)}
                  />
                </div>
              </div>
            </ModalHeader>
            <ModalBody
              style={{
                maxHeight: "500px",
                // overflowY: "scroll",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {/* <PDFReader url={resumeURL}></PDFReader> */}
              <iframe
                src={resumeURL}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  minHeight: "500px",
                }}
                frameBorder="0"
              />
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
          </Modal>

          <Modal
            isOpen={openResumeConfirm}
            size={"xl"}
            className="apply-sucess-modal"
            centered
          >
            <ModalBody style={{ maxHeight: "800px", overflowY: "scroll" }}>
              <div>
                {/* <div className="prof-dtl-img ">
              <img
                src="https://webcdn.imarticus.org/Placement/lets-icons_done-ring-round-fill.svg"
                alt="cross"
                // onClick={closeRefer}
              />
            </div> */}
                <p className="aply-success-txt ">
                  Resume has been successfully uploaded!
                </p>
                {/* <p className="prof-dtl-txt">
              The status will be changed as per updated by the employer
            </p> */}
              </div>
              <div className="apply-success-div">
                <Button
                  // color="success"
                  onClick={() => {
                    setOpenResumeConfirm(false);
                    setSelectedFile(null);
                  }}
                  className="apply-success-btn-1"
                >
                  Done
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={openSuccessModal}
            size={"xl"}
            className="apply-sucess-modal"
            centered
          >
            <ModalBody style={{ maxHeight: "800px" }}>
              <div>
                <p className="aply-success-txt ">Thank you for submitting!</p>
                <p className="prof-dtl-txt">
                  Visit My Profile section to edit or update your profile.
                </p>
              </div>
              <div className="apply-success-div">
                <Button
                  // color="success"
                  onClick={() => {
                    setOpenSuccessModal(false);
                    redirectToCourse();
                  }}
                  className="apply-success-btn-1"
                >
                  Go to My Course
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={openZeroModal}
            size={"xl"}
            className="apply-sucess-modal"
            centered
          >
            <ModalBody style={{ maxHeight: "800px", overflowY: "scroll" }}>
              <div>
                <p className="aply-success-txt ">
                  You have skipped all Sections.
                </p>
                <p className="prof-dtl-txt">
                  Dont Worry ! Visit My Profile section to edit or update your
                  profile later.
                </p>
              </div>
              <div className="apply-success-div">
                <Button
                  // color="success"
                  onClick={() => {
                    setOpenZeroModal(false);
                    redirectToCourse();
                  }}
                  className="apply-success-btn-1"
                >
                  Go to My Course
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={openResumeUpload}
            //   toggle={toggleShowSettingsHandler}
            size={"xl"}
            className="prof-dtl-modal"
            centered
            style={{ maxWidth: "680px" }}
          >
            <ModalHeader>
              <div>
                <h5 className="job-aply-header">Upload Resume</h5>
              </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: "400px", overflowY: "scroll" }}>
              <div>
                <div
                  className="resume-upload-img"
                  style={{ textAlign: "center" }}
                >
                  <input
                    type="file"
                    id="fileInput"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  {!selectedFile ? (
                    <>
                      <Button
                        className="upload-resume-btn"
                        onClick={handleUploadClick}
                      >
                        <img
                          src="https://webcdn.imarticus.org/Placement/material-symbols_upload2.svg"
                          alt="Upload"
                          style={{ marginRight: "12px" }}
                        />
                        Upload
                      </Button>
                      <div className="resume-upload-txt">
                        Resume should be in pdf format only. File size should be
                        500 KB max
                      </div>
                    </>
                  ) : (
                    <iframe
                      src={URL.createObjectURL(selectedFile)}
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                        minHeight: "500px",
                      }}
                      frameBorder="0"
                    />
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter style={{ justifyContent: "right" }}>
              <Button className="apply-danger-btn" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="apply-success-btn" onClick={handleSubmit}>
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  profile: state.profile,
  course: state.course.courses[0],
});

const mapDispatchToProps = { getProfileInfo, setCurrentTab };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResumeDetails)
);
