import { connect } from "react-redux";
import React from "react";
import { withRouter } from "react-router";
import { Modal } from "reactstrap";
import { deleteGroupBackend } from "../../actions/groupAction";
import { dispatchIndividualBrand } from "../../actions/brandAction";
import {
  deleteProfileBackend,
  switchUserProfile,
} from "../../actions/profileAction";
import { KEY_CODES, URLS, PIC_TYPE } from "../../constants/constants";

class DeleteProfileModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {}

  cancel = () => {
    this.props.toggleDeletePGModal();
  };

  componentDidMount() {
    document.addEventListener("keydown", this.keydownHandler);
  }

  keydownHandler = (e) => {
    if (e.keyCode == KEY_CODES.ESC_KEY && this.props.is_dpgm_open) {
      this.props.toggleDeletePGModal();
    }
  };
  delete = () => {
    if (this.props.pic_type == PIC_TYPE.GROUP_PIC) {
      this.props.toggleDeletePGModal();
      this.props.deleteGroupBackend(this.props.groups, this.props.gid);
    } else if (this.props.pic_type == PIC_TYPE.PROFILE_PIC) {
      this.props.toggleDeletePGModal();
      this.props.deleteProfileBackend(
        this.props.profiles,
        this.props.profile_map,
        this.props.all_ex_gids,
        this.props.switchUserProfile,
        this.props.dispatchIndividualBrand
      );
    }
  };

  render() {
    // alert(this.props.ppic == URLS.DEFAULT_PPIC_URL);
    return (
      <div>
        <Modal
          isOpen={this.props.is_dpgm_open}
          className="dpr-modal-container"
          centered={true}
        >
          <div className="dpr-modal">
            <div className="top-section">
              <div className="heading">
                {"Delete " +
                  (this.props.pic_type == PIC_TYPE.GROUP_PIC
                    ? "Group"
                    : "Profile")}
              </div>
              <p className="msg">
                {"Are you sure you want to delete this " +
                  (this.props.pic_type == PIC_TYPE.GROUP_PIC
                    ? "group"
                    : "profile") +
                  "?"}
              </p>
            </div>
            <div className="bottom-section">
              <div className="btn-y" onClick={this.delete}>
                Yes
              </div>
              <div className="btn-n" onClick={this.props.toggleDeletePGModal}>
                No
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  gid: state.j_grp.gd_id,
  ppic: state.profile.ppic,
  profiles: state.profile.profiles,
  groups: state.j_grp.groups,
  profile_map: state.profile.profile_map,
  all_ex_gids: state.explore.all_ex_gids,
});

const mapDispatchToProps = {
  deleteProfileBackend,
  deleteGroupBackend,
  switchUserProfile,
  dispatchIndividualBrand,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DeleteProfileModal)
);
