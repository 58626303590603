import React, { Component, Fragment } from "react";
import logo from "../../images/logo.svg";
import logo_mb from "../../images/logo-mb.svg";
import { Link } from "react-router-dom";
import { CONFIGS } from "../../constants/constants";

class ErrorHeader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const hg = {
      height: "40px",
    };
    return (
      <Fragment>
        <div className="home-dashboard-header">
          <div className="nav-bar fixed-header full-header">
            {/* <div className="toggle-icon-container-mb">
                        </div> */}
            <div className="ecko-logo-container-mb">
              <Link to={CONFIGS.DASHBOARD_URL}>
                <img
                  className="logo-mb"
                  style={hg}
                  src="https://cdn.pegasus.imarticus.org/images/imarticusLogo.svg"
                ></img>
              </Link>
            </div>
            <div
              className="ecko-logo-container "
              style={{ width: "0px!important" }}
            >
              <Link to={CONFIGS.DASHBOARD_URL}>
                <img
                  className="logo"
                  style={hg}
                  src="https://cdn.pegasus.imarticus.org/images/imarticusLogo.svg"
                ></img>
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ErrorHeader;
