import React from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { connect } from "react-redux";
import {
  openPrimaryEmailModal,
  confirmPrimaryEmail,
  addNewSecondaryEmail,
  openAddNewEmailModal,
  isPrimaryEmailVerified,
  getSecondaryEmails,
} from "../../actions/primaryEmailActions";
import EmailImg from "../../images/email-1.png";
import {
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";
import LoadingModal from "./LoadingModal";
import EmailOtpVerificationModal from "./components/EmailVerifyModal";
import InlineLoader from "../utils/InlineLoader";

class PrimaryEmailModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      confirm_email: false,
      open_add_email: false,
      new_email: "",
      value: "select primary email",
      emails: [],
      emailSelected: true,
    };
    // this.props.isPrimaryEmailVerified();
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.dropdownUpdate = this.dropdownUpdate.bind(this);
    this.openAddNewEmail = this.openAddNewEmail.bind(this);
  }

  componentDidMount() {
    this.setState({
      confirm_email: this.props.confirm_email,
      open_add_email: this.props.add_new_email,
    });
    if (this.props.open_modal == true) {
      this.props.getSecondaryEmails();
    }
  }
  componentDidUpdate(prev_props) {
    if (
      prev_props.open_modal !== this.props.open_modal &&
      this.props.open_modal == true
    ) {
      this.props.getSecondaryEmails();
    }
    if (prev_props.emails != this.props.emails) {
      this.setState({ emails: this.props.emails });
    }
    if (prev_props.add_new_email != this.props.add_new_email) {
      this.setState({ open_add_email: this.props.add_new_email });
    }
    if (prev_props.confirm_email != this.props.confirm_email) {
      this.setState({ confirm_email: this.props.confirm_email });
    }
    if (this.props.verify_link != "") {
      this.props.openPrimaryEmailModal(false);
    }
  }

  toggle() {
    this.props.openPrimaryEmailModal(false);
  }

  dropdownToggle() {
    let value = this.state.isOpen;
    this.setState({ isOpen: !value });
  }
  dropdownUpdate(val) {
    this.setState({ value: val, emailSelected: true });
  }
  confirmEmailClicked() {
    console.log("cnf mail:", this.state.value);
    let mail = this.state.value;
    if (mail === "select primary email") {
      this.displayPopover(
        "OOPS!",
        "please select an email first",
        POPOVER_TYPE.ERROR
      );
    } else {
      this.props.confirmPrimaryEmail(mail);
    }
  }
  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };

  openAddNewEmail() {
    this.props.openAddNewEmailModal(true);
  }
  closeAddNewEmail = () => {
    this.props.openAddNewEmailModal(false);
  };
  emailValueChanged = (value) => {
    if (value === "") {
      this.displayPopover(
        POPOVER_HEADINGS.INVALID_EMAIL,
        POPOVER_CONTENT.INVALID_EMAIL,
        POPOVER_TYPE.ERROR
      );
    } else {
      this.setState({ new_email: value });
    }
  };
  addnewEmailBtnClicked = () => {
    let email = this.state.new_email;
    if (email === "") {
      this.displayPopover(
        "OOPS!",
        "please add an email first",
        POPOVER_TYPE.ERROR
      );
    } else {
      email = email.trim();
      var email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!email_regex.test(email)) {
        this.displayPopover(
          POPOVER_HEADINGS.INVALID_EMAIL,
          POPOVER_CONTENT.INVALID_EMAIL,
          POPOVER_TYPE.ERROR
        );
      } else {
        this.props.addNewSecondaryEmail(email);
      }
    }
  };

  render() {
    return (
      <>
        {!this.props.username ? (
          this.state.open_add_email ? (
            <Modal isOpen={this.props.open_modal} className="pe-adnew">
              <ModalBody>
                <div className="pe-adnew-head">
                  <i
                    class="fa fa-arrow-left"
                    aria-hidden="true"
                    onClick={this.closeAddNewEmail}
                  ></i>
                  Add Email ID
                </div>
                <Input
                  type="email"
                  onChange={(e) => this.emailValueChanged(e.target.value)}
                  className="pe-adnew-inp"
                  placeholder="Email ID"
                />

                <div className="pe-adnew-btn">
                  <Button
                    outline
                    color="primary"
                    className="pe-adnew-btn-1"
                    onClick={this.closeAddNewEmail}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    className="pe-adnew-btn-2"
                    onClick={this.addnewEmailBtnClicked}
                  >
                    Add
                  </Button>
                </div>
              </ModalBody>
            </Modal>
          ) : this.state.confirm_email ? (
            <Modal isOpen={this.props.open_modal} className="pe">
              <ModalBody>
                <EmailOtpVerificationModal />
              </ModalBody>
            </Modal>
          ) : (
            <Modal isOpen={this.props.open_modal} className="pe">
              <ModalBody>
                <img src={EmailImg} className="pe-img" />
                <div className="pe-head">Choose Email ID to Verify</div>
                <Dropdown
                  isOpen={this.state.isOpen}
                  toggle={this.dropdownToggle}
                >
                  <DropdownToggle className="pe-drpdwn">
                    {this.state.value}
                    {this.state.isOpen ? (
                      <i className="fa fa-angle-up"></i>
                    ) : (
                      <i className="fa fa-angle-down"></i>
                    )}
                  </DropdownToggle>
                  <DropdownMenu
                    modifiers={{
                      setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                          return {
                            ...data,
                            styles: {
                              ...data.styles,
                              overflow: "auto",
                              fontFamily: "Source Sans Pro",
                              maxHeight: "100px",
                              minWidth: "300px",
                            },
                          };
                        },
                      },
                    }}
                  >
                    {this.state.emails.map((vl, key) => (
                      <DropdownItem
                        key={key}
                        onClick={() => this.dropdownUpdate(vl)}
                      >
                        {vl}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <div className="pe-ad-ml">
                  <i
                    className="fa fa-plus"
                    aria-hidden="true"
                    onClick={this.openAddNewEmail}
                  ></i>
                  <span onClick={this.openAddNewEmail}>Add a new Email ID</span>
                </div>

                <Button
                  color="primary"
                  disabled={!this.state.emailSelected}
                  onClick={this.confirmEmailClicked.bind(this)}
                  className="pe-cnf-btn"
                >
                  {this.props.show_loading_modal ? (
                    <InlineLoader />
                  ) : (
                    "Confirm Email"
                  )}
                </Button>
                <div className="pe-info">
                  <em>
                    *You must confirm your email so that we can provide you with
                    the best learning experience
                  </em>
                </div>
              </ModalBody>
            </Modal>
          )
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  emails: state.primaryEmail.secondary_emails,
  open_modal: state.primaryEmail.show_ep_mdl,
  show_loading_modal: state.utils.is_loading_md,
  confirm_email: state.primaryEmail.cnf_email_modal,
  add_new_email: state.primaryEmail.add_new_email,
  verify_link: state.primaryEmail.verify_link,
  username: state.primaryEmail.username,
});

const mapDispatchToProps = {
  openPrimaryEmailModal,
  isPrimaryEmailVerified,
  getSecondaryEmails,
  showPopover,
  openAddNewEmailModal,
  addNewSecondaryEmail,
  confirmPrimaryEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryEmailModal);
