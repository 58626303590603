import axios from "axios";
import _ from "lodash";
import { ROUTES } from "../constants/routes";
import { POPOVER_TYPE } from "../constants/constants";
import { utils } from "../utility/utils";
import store from "../store";
import Cookies from "universal-cookie";
import { CONFIGS } from "../constants/constants";
import { BACKEND_ERROR_CODES } from "../constants/errors";

var cookies = new Cookies();

export const openPrimaryEmailModal = (val) => (dispatch) => {
  dispatch({
    type: "OPEN_EMAIL_VERIFY",
    val: val,
  });
};
export const openVerifyEmailModal = (val) => (dispatch) => {
  dispatch({
    type: "OPEN_EMAIL_STATUS_MODAL",
    val: val,
  });
};

export const openMobileModal = (val) => (dispatch) => {
  dispatch({
    type: "OPEN_MOBILE_VERIFY",
    val: val,
  });
};
export const openVerifyMobileModal = (val) => (dispatch) => {
  dispatch({
    type: "OPEN_MOBILE_STATUS_MODAL",
    val: val,
  });
};

export const changeEmailVerifyLink = (val) => (dispatch) => {
  console.log("change lik:", val);
  dispatch({
    type: "CHANGE_EMAIL_VERIFY_LINK",
    val: val,
  });
};

export const resetPrimaryEmailActions = () => (dispatch) => {
  dispatch({
    type: "RESET_EMAIL_ACTIONS",
  });
};

// check if mobile is verified
export const isMobileVerified = () => (dispatch) => {
  var link = store.getState().mobile.verify_link;
  if (link == "") {
    var aid = utils.getAid();
    var tokn = utils.getAt();
    dispatch({
      type: "OPEN_MOBILE_VERIFY",
      val: false,
    });

    // if (!(aid == undefined || tokn == undefined)) {
    // } else {
    dispatch({
      type: "SET_LOADING_MODAL",
      value: true,
      lm_msg: "Mobile verification check",
    });

    axios
      .get(`${ROUTES.IS_MOBILE_VERIFIED}?aid=${aid}&tokn=${tokn}`, {})
      .then((m_res) => {
        dispatch({
          type: "SET_LOADING_MODAL",
          value: false,
        });

        if (m_res && m_res.data && m_res.data.data) {
          var value = m_res.data.data.vrfy;

          dispatch({
            type: "OPEN_MOBILE_VERIFY",
            val: !value,
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: "SET_LOADING_MODAL",
          value: false,
        });
        if (err.response && err.response.data) {
          var error = err.response.data;
          utils.showPopover(
            "MOBILE VERIFICATION CHECK FAILED",
            error.message,
            POPOVER_TYPE.ERROR,
            dispatch
          );
          utils.handleErrors(err, dispatch);
        } else {
          utils.handleErrors(err, dispatch);
        }
      });
    // }
  } else {
    dispatch({
      type: "OPEN_MOBILE_VERIFY",
      val: false,
    });
    dispatch({
      type: "UPDATE_MOBILE_STATUS",
      val: true,
    });
  }
};

// check if primary email is verified
export const isPrimaryEmailVerified = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    var link = store.getState().primaryEmail.verify_link;
    if (link == "") {
      var aid = utils.getAid();
      var tokn = utils.getAt();
      dispatch({
        type: "OPEN_EMAIL_VERIFY",
        val: false,
      });

      // if (!(aid == undefined || tokn == undefined)) {
      // } else {
      dispatch({
        type: "SET_LOADING_MODAL",
        value: true,
        lm_msg: "Primary email id verification check",
      });
      console.log("------------PE check---------------------");
      axios
        .get(`${ROUTES.IS_EMAIL_VERIFIED}?aid=${aid}&tokn=${tokn}`, {})
        .then((email_res) => {
          console.log("------------PE check2---------------------", email_res);

          dispatch({
            type: "SET_LOADING_MODAL",
            value: false,
          });

          if (email_res && email_res.data && email_res.data.data) {
            var value = email_res.data.data.vrfy;
            dispatch({
              type: "OPEN_EMAIL_VERIFY",
              val: !value,
              username: email_res.data.data.username,
            });
            return resolve("resolved!");
          }
        })
        .catch((err) => {
          console.log("------------PE check3---------------------", err);

          dispatch({
            type: "SET_LOADING_MODAL",
            value: false,
          });
          if (err.response && err.response.data) {
            var error = err.response.data;
            utils.showPopover(
              "EMAIL VERIFICATION CHECK FAILED",
              error.message,
              POPOVER_TYPE.ERROR,
              dispatch
            );
            utils.handleErrors(err, dispatch);
            return reject("rejected!");
          } else {
            utils.handleErrors(err, dispatch);
            return reject("rejected!");
          }
        });
      // }
    } else {
      dispatch({
        type: "OPEN_EMAIL_VERIFY",
        val: false,
      });
      dispatch({
        type: "UPDATE_EMAIL_STATUS",
        val: true,
      });
      return resolve("resolved!");
    }
  });
};

// get all secondary emails linked to account
export const getSecondaryEmails = () => (dispatch) => {
  var aid = utils.getAid();
  var tokn = utils.getAt();

  // if (!(aid == undefined || tokn == undefined)) {
  // } else {
  dispatch({
    type: "SET_LOADING_MODAL",
    value: true,
    lm_msg: "loading secondary emails",
  });
  axios
    .get(`${ROUTES.GET_SC_EMAILS}?aid=${aid}&tokn=${tokn}`, {})
    .then((email_res) => {
      dispatch({
        type: "SET_LOADING_MODAL",
        value: false,
      });

      if (email_res && email_res.data && email_res.data.data) {
        var value = email_res.data.data.emails;

        dispatch({
          type: "SECONDARY_EMAIL_UPDATE",
          emails: value,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_LOADING_MODAL",
        value: false,
      });
      if (err.response && err.response.data) {
        var error = err.response.data;
        utils.showPopover(
          "SECONDARY EMAILS GET ERROR",
          error.message,
          POPOVER_TYPE.ERROR,
          dispatch
        );
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};
// };

export const openAddNewEmailModal = (value) => (dispatch) => {
  dispatch({
    type: "ADD_NEW_EMAIL",
    value: value,
  });
};

export const openAddNewMobileModal = (value) => (dispatch) => {
  dispatch({
    type: "ADD_NEW_MOBILE",
    value: value,
  });
};

// for adding new secondary email
export const addNewSecondaryEmail = (email) => (dispatch) => {
  var aid = utils.getAid();
  var tokn = utils.getAt();

  var email_ob = {
    aid: aid,
    tokn: tokn,
    email: email,
  };
  dispatch({
    type: "SET_LOADING_MODAL",
    value: true,
    lm_msg: "saving new email",
  });
  axios
    .post(ROUTES.ADD_NEW_SC_EMAIL, email_ob)
    .then((email_res) => {
      dispatch({
        type: "SET_LOADING_MODAL",
        value: false,
      });
      if (email_res && email_res.data && email_res.data.data) {
        var value = email_res.data.data.emails;

        dispatch({
          type: "SECONDARY_EMAIL_UPDATE",
          emails: value,
        });
        dispatch({
          type: "ADD_NEW_EMAIL",
          value: false,
        });

        utils.showPopover(
          "NEW EMAIL ADDED",
          "successfully added new secondary email",
          POPOVER_TYPE.SUCCESS,
          dispatch
        );
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_LOADING_MODAL",
        value: false,
      });
      if (err.response && err.response.data) {
        var error = err.response.data;
        utils.showPopover(
          "ADD NEW EMAIL FAILED",
          error.message,
          POPOVER_TYPE.ERROR,
          dispatch
        );
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};
// runs when user click on verify email after selecting email from dropdown list shown
export const confirmMobile = (m, ccod, errFunction) => (dispatch) => {
  var aid = utils.getAid();
  var tokn = utils.getAt();
  var pid = utils.getPid();
  var sid = utils.getSid();

  var m_ob = {
    aid: aid,
    tokn: tokn,
    mob: m,
    pid: pid,
    ccod: ccod,
  };
  dispatch({
    type: "SET_LOADING_MODAL",
    value: true,
    lm_msg: "Confirming Mobile",
  });
  axios
    .post(ROUTES.CONFIRM_MOBILE, m_ob)
    .then((m_res) => {
      dispatch({
        type: "SET_LOADING_MODAL",
        value: false,
      });
      console.log("save new mob res:", m_res);
      if (m_res && m_res.data && m_res.data.data) {
        var value = m_res.data.data.m;

        dispatch({
          type: "OPEN_CONFIRM_MOBILE_MODAL",
          value: !value,
        });
        dispatch({
          type: "MOBILE_UPDATE",
          mobile: m,
          ccod: ccod,
        });
        utils.showPopover(
          "SMS Sent!",
          "Please check your messages",
          POPOVER_TYPE.SUCCESS,
          dispatch
        );
        dispatch({
          type: "ADD_NEW_MOBILE",
          value: false,
        });
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_LOADING_MODAL",
        value: false,
      });
      if (err.response && err.response.data) {
        var error = err.response.data;
        // utils.showPopover(
        //   "EMAIL VERIFICATION FAILED",
        //   error.message,
        //   POPOVER_TYPE.ERROR,
        //   dispatch
        // );
        errFunction();
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};
// runs when user click on verify email after selecting email from dropdown list shown
export const confirmPrimaryEmail = (email) => (dispatch) => {
  var aid = utils.getAid();
  var tokn = utils.getAt();
  var pid = utils.getPid();
  var sid = utils.getSid();

  var email_ob = {
    aid: aid,
    tokn: tokn,
    email: email,
    pid: pid,
    sid: sid,
  };
  dispatch({
    type: "SET_LOADING_MODAL",
    value: true,
    lm_msg: "Confirming Primary Email",
  });
  axios
    .post(ROUTES.CONFIRM_PRIMARY_EMAIL, email_ob)
    .then((email_res) => {
      dispatch({
        type: "SET_LOADING_MODAL",
        value: false,
      });
      console.log("save new mail res:", email_res);
      if (email_res && email_res.data && email_res.data.data) {
        var value = email_res.data.data.emails;

        dispatch({
          type: "OPEN_CONFIRM_EMAIL_MODAL",
          value: !value,
        });
        dispatch({
          type: "PRIMARY_EMAIL_UPDATE",
          primary_email: email,
        });
        utils.showPopover(
          "Email Sent!",
          "Please check your email",
          POPOVER_TYPE.SUCCESS,
          dispatch
        );
      }
    })
    .catch((err) => {
      dispatch({
        type: "SET_LOADING_MODAL",
        value: false,
      });
      if (err.response && err.response.data) {
        var error = err.response.data;
        utils.showPopover(
          "EMAIL VERIFICATION FAILED",
          error.message,
          POPOVER_TYPE.ERROR,
          dispatch
        );
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

// runs when email link clicked and redirected to dashboard.
export const confirmPrimaryEmailToken = (tk) => (dispatch) => {
  dispatch({
    type: "WAITING_EMAIL_VERIFICATION",
    value: true,
  });
  dispatch({
    type: "OPEN_EMAIL_VERIFY",
    val: false,
  });
  axios
    .post(ROUTES.VERIFY_PRIMARY_EMAIL + tk)
    .then((res) => {
      dispatch({
        type: "WAITING_EMAIL_VERIFICATION",
        value: false,
      });
      console.log("verify email response", res);
      // if (res && res.data && res.data.data) {
      //   let data = res.data.data;
      //   var expirydate = new Date();
      //   expirydate.setDate(expirydate.getDate() + 180);
      //   cookies.set("at", data.at, {
      //     expires: expirydate,
      //     domain: CONFIGS.DOMAIN,
      //     path: "/",
      //   });
      //   cookies.set("aid", data.aid, {
      //     expires: expirydate,
      //     domain: CONFIGS.DOMAIN,
      //     path: "/",
      //   });
      //   cookies.set("secret", data.secret, {
      //     expires: expirydate,
      //     domain: CONFIGS.DOMAIN,
      //     path: "/",
      //   });
      //   cookies.set("rt", data.rt, {
      //     expires: expirydate,
      //     domain: CONFIGS.DOMAIN,
      //     path: "/",
      //   });
      //   cookies.set("isLoggedIn", true, {
      //     expires: expirydate,
      //     domain: CONFIGS.DOMAIN,
      //     path: "/",
      //   });
      //   cookies.set("pid", data.pid, {
      //     expires: expirydate,
      //     domain: CONFIGS.DOMAIN,
      //     path: "/",
      //   });
      //   cookies.set("s_id", data.sid, {
      //     expires: expirydate,
      //     domain: CONFIGS.DOMAIN,
      //     path: "/",
      //   });
      //   cookies.set("user", data.user, {
      //     expires: expirydate,
      //     domain: CONFIGS.DOMAIN,
      //     path: "/",
      //   });

      //   var value = res.data.data.vrfy;
      //   dispatch({
      //     type: "UPDATE_EMAIL_STATUS",
      //     value: value,
      //   });
      //   utils.showPopover(
      //     "Email Verified!",
      //     "email has been successfully verified",
      //     POPOVER_TYPE.SUCCESS,
      //     dispatch
      //   );
      // }
    })
    .catch((err) => {
      dispatch({
        type: "WAITING_EMAIL_VERIFICATION",
        value: false,
      });
      dispatch({
        type: "UPDATE_EMAIL_STATUS",
        value: false,
      });
      if (err.response && err.response.data) {
        var error = err.response.data;
        utils.showPopover(
          "EMAIL VERIFICATION FAILED",
          error.message,
          POPOVER_TYPE.ERROR,
          dispatch
        );
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

export const removeEmailfromBackend = (email_ob) => (dispatch) => {
  dispatch({
    type: "EMAIL_LOADER",
    value: true,
  });
  email_ob.a_id = utils.getAid();
  email_ob.tokn = utils.getAt();
  axios
    .post(ROUTES.EMAIL_REQ_OTP, email_ob)
    .then((otp_res) => {
      utils.showPopover(
        "OTP SENT!",
        "PLEASE CHECK YOUR DEVICE",
        POPOVER_TYPE.SUCCESS,
        dispatch
      );

      dispatch({
        type: "OTP_RECEIVED",
        otp_received: true,
      });

      dispatch({
        type: "EMAIL_LOADER",
        value: false,
      });
    })
    .catch((err) => {
      dispatch({
        type: "EMAIL_LOADER",
        value: false,
      });
      if (err.response && err.response.data) {
        var error = err.response.data;
        utils.showPopover(
          "GET OTP FAILED",
          error.message,
          POPOVER_TYPE.ERROR,
          dispatch
        );
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

export const verifyOtpFromBackend = (otp_obj) => (dispatch) => {
  dispatch({
    type: "EMAIL_LOADER",
    value: true,
  });

  var update_otp_action = {};
  otp_obj.aid = utils.getAid();
  otp_obj.tokn = utils.getAt();
  update_otp_action.type = "VERIFY_OTP";

  axios
    .post(ROUTES.EMAIL_VERIFY_OTP, otp_obj)
    .then((email_res) => {
      dispatch({
        type: "EMAIL_LOADER",
        value: false,
      });
      update_otp_action.otp_verified = true;
      dispatch(update_otp_action);
      if (email_res && email_res.data && email_res.data.data) {
        var value = email_res.data.data.emails;
        dispatch({
          type: "SECONDARY_EMAIL_UPDATE",
          emails: value,
        });
        utils.showPopover(
          "EMAIL DELETED!",
          "successfully deleted secondary email",
          POPOVER_TYPE.SUCCESS,
          dispatch
        );
      }
    })
    .catch((err) => {
      dispatch({
        type: "EMAIL_LOADER",
        value: false,
      });

      if (err.response && err.response.data) {
        var error = err.response.data;
        utils.showPopover(
          "VERIFY OTP FAILED",
          error.message,
          POPOVER_TYPE.ERROR,
          dispatch
        );
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

export const getOtpCall = (get_call_obj) => (dispatch) => {
  dispatch({
    type: "EMAIL_LOADER",
    value: true,
  });

  get_call_obj.a_id = utils.getAid();
  get_call_obj.d_id = utils.getSid();
  axios
    .post(ROUTES.GET_OTP_CALL, get_call_obj)
    .then((otp_res) => {
      dispatch({
        type: "EMAIL_LOADER",
        value: false,
      });

      utils.showPopover(
        "CALL INITIATED!",
        "You will get OTP verification call shortly!",
        POPOVER_TYPE.SUCCESS,
        dispatch
      );
    })
    .catch((err) => {
      dispatch({
        type: "EMAIL_LOADER",
        value: false,
      });

      if (err.response && err.response.data) {
        var error = err.response.data;
        utils.showPopover(
          "GET ON CALL FAILED!",
          error.message,
          POPOVER_TYPE.ERROR,
          dispatch
        );
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

export const resendOtpFromBackend = () => (dispatch) => {
  dispatch({
    type: "EMAIL_LOADER",
    value: true,
  });

  var resend_otp_ob = {
    aid: utils.getAid(),
    tokn: utils.getAt(),
  };
  axios
    .post(ROUTES.RESEND_OTP, resend_otp_ob)
    .then((otp_res) => {
      dispatch({
        type: "EMAIL_LOADER",
        value: false,
      });

      utils.showPopover(
        "RESEND OTP!",
        "YOU WILL GET OTP SHORTLY!",
        POPOVER_TYPE.SUCCESS,
        dispatch
      );
    })
    .catch((err) => {
      dispatch({
        type: "EMAIL_LOADER",
        value: false,
      });

      if (err.response && err.response.data) {
        var error = err.response.data;
        utils.showPopover(
          "RESEND OTP FAILED!",
          error.message,
          POPOVER_TYPE.ERROR,
          dispatch
        );
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

// change email to backend
export const updateSecondaryEmailtoBackend =
  (email, old_email) => (dispatch) => {
    var aid = utils.getAid();
    var tokn = utils.getAt();

    var email_ob = {
      aid: aid,
      tokn: tokn,
      email: email,
      old_email: old_email,
    };
    dispatch({
      type: "EMAIL_LOADER",
      value: true,
    });
    axios
      .post(ROUTES.CHANGE_SECONDARY_EMAIL, email_ob)
      .then((email_res) => {
        dispatch({
          type: "EMAIL_LOADER",
          value: false,
        });
        if (email_res && email_res.data && email_res.data.data) {
          var value = email_res.data.data.emails;
          dispatch({
            type: "SECONDARY_EMAIL_UPDATE",
            emails: value,
          });
          dispatch({
            type: "SEDONDARY_EMAIL_CHANGED",
            value: true,
          });

          utils.showPopover(
            "EMAIL CHANGED!",
            "successfully changed secondary email",
            POPOVER_TYPE.SUCCESS,
            dispatch
          );
        }
      })
      .catch((err) => {
        dispatch({
          type: "EMAIL_LOADER",
          value: false,
        });
        if (err.response && err.response.data) {
          var error = err.response.data;
          utils.showPopover(
            "EMAIL VERIFICATION CHECK FAILED",
            error.message,
            POPOVER_TYPE.ERROR,
            dispatch
          );
        } else {
          utils.handleErrors(err, dispatch);
        }
      });
  };
export const getPrimaryEmailsWithoutModal = () => {
  return (dispatch) => {
    var aid = utils.getAid();
    var tokn = utils.getAt();
    // if (!(aid == undefined || tokn == undefined)) {
    // } else {
    return axios
      .get(`${ROUTES.GET_SC_EMAILS}?aid=${aid}&tokn=${tokn}`, {})
      .then((email_res) => {
        if (email_res && email_res.data && email_res.data.data) {
          var value = email_res.data.data.emails;
          var primaryEmail = email_res.data.data.primaryemail;
          console.log(primaryEmail);
          dispatch({
            type: "PRIMARY_EMAIL_UPDATE",
            primary_email: primaryEmail,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // };
};
export const otpConfirmAction = ({ otp, email }) => {
  const aid = cookies.get("aid");
  const tokn = cookies.get("at");
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${CONFIGS.API_API_ENDPOINT}account/pe_otp_vrfy/`,
        { aid, tokn, otp, email }
      );
      console.log(response.data);
      dispatch({
        type: "EMAIL_OTP_VERIFIED",
        value: true,
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject("Something went wrong");
    }
  };
};

export const otpConfirmActionMobile = ({ otp, ccod, mobile }) => {
  const aid = cookies.get("aid");
  const tokn = cookies.get("at");
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${CONFIGS.API_API_ENDPOINT}account/mob_otp_vrfy/`,
        { aid, tokn, otp, mobile, ccod }
      );
      console.log(response.data);
      dispatch({
        type: "MOBILE_OTP_VERIFIED",
        value: true,
      });
    } catch (error) {
      console.log(error);
      if (error.response) {
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject("Something went wrong");
    }
  };
};

export const unSetEmail = () => {
  return {
    type: "UNSET_PRIMARY_EMAIL",
  };
};

export const getAllNotifications = () => (dispatch) => {
  const aid = cookies.get("aid");
  axios
    .post(
      `${CONFIGS.API_API_ENDPOINT}notifications/gt_al_usr_nots`,
      {
        aid,
        // tokn: utils.getAt(),
      },
      {}
    )
    .then((res) => {
      console.log(res.data.data);
      let notifications = {
        listOfNotifications: res.data.data.listOfNotifications,
        countOfNotifications: res.data.data.countOfNotifications,
      };
      dispatch({
        type: "USER_NOTIFICATIONS",
        notifications,
      });
      console.log(res.data.data.notifications);

      if (
        res.data.data.notifications &&
        Object.keys(res.data.data.notifications).length > 0
      ) {
        console.log("\n\n --- Sending In app notifications --- \n\n");
        let objtoDispatch = {
          type: "IN_APP",
          in_app_notification: {
            title:
              res.data.data.notifications.InAppMessage.Content[0].HeaderConfig
                .Header,
            body: res.data.data.notifications.InAppMessage.Content[0].BodyConfig
              .Body,
            img: res.data.data.notifications.InAppMessage.Content[0].ImageUrl,
            campId: res.data.data.notifications.CampaignId,
            cid: res.data.data.notifications.cid,
            keyObj:
              res.data.data.notifications.InAppMessage.CustomConfig.keyObj,
          },
        };
        dispatch(objtoDispatch);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateEndpoints = (token) => (dispatch) => {
  console.log("\n\n ====== In updateEndpoints ======");
  axios
    .post(CONFIGS.API_API_ENDPOINT + "notifications/up_ep", {
      aid: cookies.get("aid"),
      client: "w",
      deviceToken: token,
      inapp: true,
      tokn: utils.getAt(),
      rt: cookies.get("rt"),
    })
    .then((res) => {
      console.log("\n\n DeviceToken registered successfully! \n\n");
      // dispatch({type: "UPDATE_ENDPOINT", token})
      console.log("\n\nDispatching an event!\n\n\n");
      console.log(
        "\nres.data.data.ids.push_endpointId\n",
        res.data.data.ids.push_endpointId
      );
      dispatch({
        type: "TOKEN_SENT",
        ifTokenSent: true,
        endpointId: res.data.data.ids.push_endpointId,
      });
      cookies.set("ifTokenSent", true);
      cookies.set("endpointId", res.data.data.ids.push_endpointId);
    })
    .catch((err) => {
      console.log("We are in error :: ", err, "\n\n\n");
      // utils.handleErrors(err,dispatch);
    });
};
