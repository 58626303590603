import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { showSidebarMB } from "../../actions/utilAction";
import white_cross from "../../images/white-cross.svg";
import { withRouter } from "react-router";
import eck_logo from "../../images/imarticus-new-logo.svg";
import {
  setCurrentTab,
  setSidebar,
  //  resetLoadingData,
} from "../../actions/utilAction";

class SidebarSS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_crs: false,
      is_grp: false,
      loading: true,
    };
  }
  closeSidebar = () => {
    this.props.showSidebarMB(false);
  };

  render() {
    return (
      <Fragment>
        <div className="home-left-sidebar">
          <div className="menu-list left-sidebar-menu">
            <Link to="/dashboard">
              <div
                className="logo-container center-all"
                style={{
                  backgroundColor: `${
                    this.props.brand && this.props.brand.whiteBackground
                      ? "white"
                      : ""
                  }`,
                }}
              >
                <img
                  src={
                    this.props.brand
                      ? this.props.brand.pic
                      : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
                  }
                  alt="logo"
                />
              </div>
            </Link>
            <div className="dum-btn"></div>
            <div className="dum-btn-2"></div>
            <div className="dum-btn-3"></div>
            <div className="dum-btn-3"></div>
            <div className="dum-btn-2"></div>
          </div>
        </div>
        <div
          onClick={this.closeSidebar}
          className={this.props.show_sidebar_mb ? "demo" : "hide"}
        ></div>
        <div className={"home-left-sidebar-mb"}>
          <button
            className={this.props.show_sidebar_mb ? "close-btn" : "hide"}
            onClick={this.closeSidebar}
          >
            <img src={white_cross}></img>
          </button>
          <div
            className={
              "left-sidebar-menu-mb " +
              (this.props.show_sidebar_mb ? "out" : "")
            }
          >
            <Link to="/dashboard">
              <div className="logo-container">
                {!this.state.loading && (
                  <img
                    className="logo-sidebar center-all"
                    src={
                      this.props.brand
                        ? this.props.brand.pic
                        : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
                    }
                  />
                )}
              </div>
            </Link>
            <div className="dum-btn"></div>
            <div className="dum-btn-2"></div>
            <div className="dum-btn-3"></div>
            <div className="dum-btn-3"></div>
            <div className="dum-btn-2"></div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  show_sidebar_mb: state.utils.show_sidebar_mb,
  current_tab: state.utils.current_tab,
  is_data_loading: state.utils.is_data_loading,
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = {
  showSidebarMB,
  setCurrentTab,
  setSidebar,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarSS)
);
