import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
const cookies = new Cookies();

export const updateQuizStatus = (pid, cid, chid, fid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.UPDATE_QUIZ_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          cp_id: chid,
          qz_id: fid,
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const pluginIdentifier = (quiz, gid) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.PLUGIN_IDNT, {
        p_id: cookies.get("pid"),
        g_id: gid ? gid : quiz.gid,
        pl_id: quiz.plid,
        quizId: quiz._id,
        tokn: cookies.get("at"),
        type: 1,
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};
