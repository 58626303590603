import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router";
import Footer from "../footers/Footer";
import CardHeading from "../utils/CardHeading";
import { Link } from "react-router-dom";
import queryString from "query-string";
import {
  fetchGroups,
  disableMyGroupRedirection,
} from "../../actions/groupAction";
import { getProfileInfo } from "../../actions/profileAction";
import { utils } from "../../utility/utils";
import group_default from "../../images/group-default-colored-01.png";
import { PAGE_HEADINGS } from "../../constants/constants";
import { setPageHeading } from "../../actions/utilAction";
import Loader from "../utils/Loader";
import MyGroupSS from "../skeletonscreens/MyGroupSS";
import PoweredByImarticusFooter from "../footers/PoweredByImarticusFooter";

class MyGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      to_dashboard: false,
      res_card_width: 2000,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // document.documentElement.style.setProperty('--sidebar-width', SIDEBAR_WIDTH.HOME_SIDEBAR);
    this.props.setPageHeading(PAGE_HEADINGS.MY_GROUP);
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // this.props.fetchGroups(utils.getPid());
    var res_e = document.getElementsByClassName("grp-card")[0];
    if (res_e) {
      this.setState({
        res_card_width: res_e.offsetWidth,
      });
    }
  }
  resize = () => {
    var res_e = document.getElementsByClassName("grp-card")[0];
    if (res_e) {
      this.setState({
        res_card_width: res_e.offsetWidth,
      });
    }
  };

  componentDidUpdate(prev_props) {
    if (this.props.user_j_groups != prev_props.user_j_groups) {
      if (!this.props.user_j_groups || this.props.user_j_groups.length == 0) {
        this.setState({
          to_dashboard: true,
        });
      } else if (
        this.props.user_j_groups &&
        this.props.user_j_groups.length > 0
      ) {
        this.props.setPageHeading(PAGE_HEADINGS.MY_GROUP);
        this.resize();
      }
    }
    // else if(this.props.user_j_groups == prev_props.user_j_groups){
    //     if(this.props.user_j_groups && this.props.user_j_groups.length == 0){
    //         alert("---0--");
    //         this.setState({
    //             to_dashboard : true
    //         })
    //     }
    // }
  }

  redirectToGrpDetailPage = (gid) => {
    this.props.history.push({
      pathname: "/dashboard/group-detail",
      search: queryString.stringify(
        Object.assign({}, queryString.parse(this.props.location.search), {
          gid,
        })
      ),
    });
  };

  render() {
    this.props.disableMyGroupRedirection();
    if (this.state.to_dashboard) {
      return <Redirect to="/dashboard" />;
    } else if (this.props.is_pr_sw) {
      return <Redirect to="/dashboard" />;
    } else if (
      this.props.user_j_groups &&
      this.props.user_j_groups.length > 0
    ) {
      return (
        <div className="dashboard-content-wrapper">
          <div className="container-fluid dashboard-content">
            <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <p className="content-header">My Groups</p>
            </div>
            {/* </div> */}

            <div
              className="container-fluid my-grp-container "
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="row my-grp-row">
                {this.props.user_j_groups.map(({ name, gpic, _id }, index) => {
                  return (
                    <div className="col-xs-12 col-lg-4 col-md-4 col-sm-6">
                      <div
                        className="grp-card"
                        style={{
                          "--height":
                            this.state.res_card_width.toString() + "px",
                        }}
                      >
                        <div
                          className="card-cover"
                          onClick={() => this.redirectToGrpDetailPage(_id)}
                        ></div>
                        <div className="grp-img-container">
                          <img
                            src={
                              gpic
                                ? gpic
                                : "https://cdn.pegasus.imarticus.org/index/group_default.svg"
                            }
                            className="grp-img"
                          ></img>
                        </div>
                        <div className="grp-name-container">
                          <p className="grp-name">{name}</p>
                          <p className="view-btn">View Group</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {this.props.brand &&
                this.props.brand.poweredByEnabled == true &&
                this.props.brand.hyperlinkEnabled == true && (
                  <div
                    className="powered-by-container"
                    style={{ position: "relative", bottom: "24px" }}
                  >
                    <a
                      className="powered-by"
                      href={"https://www.imarticus.org"}
                    >
                      Powered By Imarticus Learning
                    </a>
                  </div>
                )}
              {this.props.brand &&
                this.props.brand.poweredByEnabled == true &&
                this.props.brand.hyperlinkEnabled == false && (
                  <div
                    className="powered-by-container"
                    style={{ pbottom: "24px" }}
                  >
                    <div className="powered-by">
                      Powered By Imarticus Learning
                    </div>
                  </div>
                )}
            </div>
          </div>

          <Footer />
        </div>
      );
    } else {
      return <Loader />;
    }
  }
}

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  is_pr_sw: state.profile.is_pr_sw,
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = {
  getProfileInfo,
  fetchGroups,
  disableMyGroupRedirection,
  setPageHeading,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyGroup)
);

// export default MyGroup;
