import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";

const TooltipItem = (props) => {
  const { batch, id } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <span>
      <span id={"Tooltip-" + id} style={{ background: "#f2f6f9" }}>
        <img
          src="https://cdn.pegasus.imarticus.org/certificates/info.svg"
          alt="info"
        />
        <Tooltip isOpen={tooltipOpen} target={"Tooltip-" + id} toggle={toggle}>
          {batch}
        </Tooltip>
      </span>
    </span>
  );
};

export default TooltipItem;
