import React, { useEffect, useState } from "react";
import InlineLoader from "../../utils/InlineLoader";
import { Button, Input } from "reactstrap";
import { OTP_EMAIL, POPOVER_TYPE } from "../../../constants/constants";
import Timer from "../../utils/timer/Timer";
import EmailImg2 from "../../../images/email-alert-01.png";
import {
  editPrimaryMailSendOtp,
  mailOtpConfirmApi,
} from "../../../apis/editapis";
import { useDispatch } from "react-redux";
//import { confirmPrimaryEmail } from "../../../actions/primaryEmailActions";
import { utils } from "../../../utility/utils";
import { connect } from "react-redux";
import {
  WebEngageDataLayer,
  WebEngageRegisteredEvents,
} from "../../../services/helper";
const EditEmailVerify = ({ email, step, setStep, contact, tz, name }) => {
  const dispatch = useDispatch();
  const [countResend, setCountResend] = useState(0);
  const time = OTP_EMAIL.RESEND_TIMEOUT;
  let timerId;
  const [timer, setTimer] = useState(0);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  //const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (timer === 0) {
      return;
    }
    timerId = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [timer]);
  useEffect(() => {
    setTimer(countResend * time);
  }, [countResend]);
  const handleResendCode = () => {
    editPrimaryMailSendOtp({ email })
      .then(() => {
        utils.showPopover(
          "Email Sent!",
          "Please check your email",
          POPOVER_TYPE.SUCCESS,
          dispatch
        );
      })
      .catch((err) => {
        utils.showPopover(
          "INVALID OTP",
          "The OTP entered is not valid.",
          POPOVER_TYPE.ERROR,
          dispatch
        );
      });
    setCountResend((prev) => prev + 1);
  };
  const submitOtpHandler = async () => {
    try {
      const data = await mailOtpConfirmApi({ otp, email });
      dispatch({ type: "PRIMARY_EMAIL_UPDATE", primary_email: email });
      let webEngageData = {
        name: name,
        email: email,
        mobile: contact,
        timeZone: tz,
      };

      WebEngageDataLayer(
        webEngageData,
        WebEngageRegisteredEvents.PROFILEUPDATED
      );
      setStep(step + 1);
    } catch (err) {
      console.log(err);
      utils.showPopover(err, err, POPOVER_TYPE.ERROR, dispatch);
    }
  };
  const handleOtp = (e) => {
    if (e.target.value.length > OTP_EMAIL.LENGTH) return;
    setOtp(e.target.value);
  };
  return (
    <>
      <>
        <img src={EmailImg2} className="pe-img" />
        <div className="pe-head">Enter 6 Digit Verification Code</div>
        <Input
          type="text"
          className={`pe-adnew-inp ${error && "pe-adnew-input-error"}`}
          placeholder="1-2-3-4-5-6"
          onChange={handleOtp}
          value={otp}
        />
        <div className="EmailVerificationModal__resendContainer">
          <button
            className={`EmailVerificationModal__resendButton`}
            onClick={handleResendCode}
            disabled={!(timer === 0)}
          >
            {console.log(typeof timer)}
            Resend{" "}
            {!!timer && (
              <>
                (<Timer times={timer} />)
              </>
            )}
          </button>
        </div>
        <p className="EmailVerificationModal__error">{error}</p>

        <Button
          color="primary"
          disabled={false}
          onClick={submitOtpHandler}
          className="pe-cnf-btn__otp"
        >
          {!false ? "Verify OTP and Edit Email" : <InlineLoader />}
        </Button>
        {/* {error ? <p>{error}</p> : ""} */}
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  tz: state.profile.tz,
  contact: state.profile.contact,
  name: state.profile.p_name,
});

export default connect(mapStateToProps, {})(EditEmailVerify);
