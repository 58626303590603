import React from "react";
import "./BrandSpinner.css";

export default function BrandSpinner({ color }) {
  console.log(color);
  return (
    <div className="spinner-container">
      <div
        className="loading-spinner"
        style={{ borderTop: `5px solid ${color}` }}
      ></div>
    </div>
  );
}
