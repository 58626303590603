import Axios from "axios";
import React, { useState } from "react";
import Button from "reactstrap/lib/Button";
import { editMobileSendOtp } from "../../../apis/editapis";
import CountryCodes from "../../../constants/countryCodes";
import { ROUTES } from "../../../constants/routes";
import { POPOVER_TYPE } from "../../../constants/constants";
import { utils } from "../../../utility/utils";
import InlineLoader from "../../utils/InlineLoader";
import { useDispatch, useSelector } from "react-redux";

const EditMobileInput = ({
  mobile,
  setMobile,
  step,
  setStep,
  ccode,
  setCcode,
}) => {
  const dispatch = useDispatch();
  const { contact } = useSelector((state) => state.profile);
  const [initNum, setInitNum] = useState(mobile);
  const [loading, setLoading] = useState(false);
  let isDisabled = contact != undefined;
  const [error, setError] = useState(null);
  window.onload = () => {
    setMobile(contact);
  };
  const submitData = async () => {
    try {
      setLoading(true);
      editMobileSendOtp({ mobile, ccode })
        .then(() => {
          utils.showPopover(
            "Code Sent On Mobile!",
            "Please check your email",
            POPOVER_TYPE.SUCCESS,
            dispatch
          );
          setLoading(false);
          setStep(step + 1);
        })
        .catch((err) => {
          utils.showPopover(
            "MOBILE VERIFICATION FAILED",
            err,
            POPOVER_TYPE.ERROR,
            dispatch
          );
          setLoading(false);
          setError(error);
        });
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  let delinkMobile = async () => {
    let dataObj = {
      aid: utils.getAid(),
      tokn: utils.getAt(),
    };
    Axios.post(ROUTES.REMOVE_MOBILE, dataObj).then((res) => {
      setMobile("");
      setCcode("");
      window.location.reload();
    });
  };

  let ccodChange = (e) => {
    setCcode(e.target.value);
    console.log(ccode, e.target.value);
  };

  let mobileChange = (e) => {
    let m = e.target.value;
    let re = /^[0-9\-]+$/;
    if (m != "" && !re.test(m)) {
      return;
    }
    setMobile(m);
  };

  let validateMobile = () => {
    if (!mobile) return false;
    return mobile.length < 16 && mobile.length > 0;
    return mobile.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/);
  };

  return (
    <div className="edit-main">
      <div className="edit-title">Edit Mobile</div>
      <div className="auth-box">
        <div className="auth-ttl">Enter Mobile Number</div>
        <div className="auth-container">
          <div style={{ display: "flex" }}>
            <select
              style={{ width: "30%", borderTopRightRadius: "0" }}
              className="auth-inp dropdown"
              onChange={ccodChange}
              value={ccode}
            >
              {CountryCodes.map((cc, i) => {
                return (
                  <option
                    key={cc.code + String(i)}
                    disabled={cc.value == "_"}
                    value={cc.value}
                  >
                    {cc.display}
                  </option>
                );
              })}
            </select>
            <input
              type="tel"
              pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
              style={{ width: "70%", borderTopLeftRadius: "0" }}
              className="auth-inp mobile"
              onChange={mobileChange}
              value={mobile}
            ></input>
          </div>
          <div className={`line ${validateMobile() ? "lgreen" : "lred"}`}></div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          color="danger"
          outline
          disabled={loading}
          onClick={isDisabled ? delinkMobile : undefined}
          style={{ width: "48%" }}
          className={`${isDisabled ? "" : "disable-red"} pe-cnf-btn__otp-3`}
        >
          {!loading ? "De-link Mobile Number" : <InlineLoader />}
        </Button>
        <button
          color="primary"
          disabled={loading}
          onClick={validateMobile() ? submitData : undefined}
          style={{ width: "48%", alignSelf: "self-end" }}
          className={`auth-btn ${validateMobile() ? "" : "disable"}`}
        >
          {!loading ? "Edit Mobile Number" : <InlineLoader />}
        </button>
        {error && <p align="center">{error}</p>}
      </div>
    </div>
  );
};

export default EditMobileInput;
