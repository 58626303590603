import { TABS, SIDEBARS } from "../constants/constants.js";

const initialState = {
  pg_heading: "",
  is_loading: false,
  is_search: false,
  show_sidebar_mb: false,
  current_tab: TABS.HOME,
  role: 0,
  is_not_found: false,
  is_err: false,
  err_msg: "",
  show_my_crs: false,
  show_my_grp: false,
  show_home: false,
  is_pover: false,
  pover_msg: "",
  pover_heading: "",
  pover_type: 0,
  is_loading_md: false, //is loading modal
  lm_msg: "", //loading modal msg
  sidebar: SIDEBARS.HOME_SIDEBAR,
  is_back: false,
  back_url: "",
  is_pf_chngd: false,
  is_data_loading: true,
  ss_msg: "",
  show_ss: false,
};

const utilReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, is_loading: action.payload.value };
    case "SET_SEARCH":
      return { ...state, is_search: action.payload.value };
    case "SHOW_SIDEBAR_MB":
      return { ...state, show_sidebar_mb: action.payload.value };
    case "SET_CURRENT_TAB":
      return { ...state, current_tab: action.payload.current_tab };
    case "SET_ROLE":
      return { ...state, role: action.payload.role };
    case "SET_NOT_FOUND":
      return { ...state, is_not_found: action.val };
    case "ERROR_OCCURED":
      return { ...state, is_err: action.val, err_msg: action.err_msg };
    case "SET_SHOW_MY_COURSE":
      return { ...state, show_my_crs: action.value };
    case "SET_SHOW_MY_GROUP":
      return { ...state, show_my_grp: action.value };
    case "SET_SHOW_HOME":
      return { ...state, show_home: action.value };
    case "SHOW_POPOVER":
      return {
        ...state,
        is_pover: true,
        pover_msg: action.msg,
        pover_heading: action.heading,
        pover_type: action.pover_type,
      };
    case "CLOSE_POPOVER":
      return {
        ...state,
        is_pover: false,
        epover_msg: "",
        epover_heading: "",
        pover_type: 0,
      };
    case "SET_LOADING_MODAL":
      return { ...state, is_loading_md: action.value, lm_msg: action.lm_msg };
    case "SET_PAGE_HEADING":
      return { ...state, pg_heading: action.pg_heading };
    case "SET_SIDEBAR":
      return { ...state, sidebar: action.sidebar };
    case "SET_BACK_BTN":
      return { ...state, is_back: true, back_url: action.back_url };
    case "RESET_BACK_BTN":
      return { ...state, is_back: false, back_url: "" };
    case "REDIRECT_TO_HOME":
      return { ...state, is_pf_chngd: true };
    case "RESET_PROFILE_CHANGED":
      return { ...state, is_pf_chngd: false };
    case "RESET_DATA_LOADING":
      return { ...state, is_data_loading: false };
    case "SET_DATA_LOADING":
      return { ...state, is_data_loading: true };
    case "SET_SLIDING_STRIP":
      return { ...state, show_ss: true, ss_msg: action.ss_msg };
    case "RESET_SLIDING_STRIP":
      return { ...state, show_ss: false, ss_msg: "" };
    default:
      return state;
  }
};

export default utilReducer;
