import React, { useEffect, useState } from "react";
import InlineLoader from "../../utils/InlineLoader";
import { Button, Input } from "reactstrap";
import { OTP_MOBILE, POPOVER_TYPE } from "../../../constants/constants";
import Timer from "../../utils/timer/Timer";
import MobImg2 from "../../../images/MobileCode.png";
import { editMobileSendOtp, mobileOtpConfirmApi } from "../../../apis/editapis";
import { useDispatch } from "react-redux";
import { confirmPrimaryEmail } from "../../../actions/primaryEmailActions";
import { utils } from "../../../utility/utils";
import { connect } from "react-redux";
import {
  WebEngageDataLayer,
  WebEngageRegisteredEvents,
} from "../../../services/helper";
const EditMobileVerify = ({
  mobile,
  step,
  setStep,
  ccode,
  email,
  tz,
  name,
}) => {
  console.log("In EditMobileVerify");
  const dispatch = useDispatch();
  const [countResend, setCountResend] = useState(0);
  const time = OTP_MOBILE.RESEND_TIMEOUT;
  let timerId;
  const [timer, setTimer] = useState(0);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (timer == 0) {
      return;
    }
    timerId = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [timer]);
  useEffect(() => {
    setTimer(countResend * time);
  }, [countResend]);
  const handleResendCode = () => {
    editMobileSendOtp({ mobile, ccode })
      .then(() => {
        utils.showPopover(
          "Code Sent On Mobile!",
          "Please check your email",
          POPOVER_TYPE.SUCCESS,
          dispatch
        );
      })
      .catch((err) => {
        utils.showPopover(
          "MOBILE VERIFICATION FAILED",
          err,
          POPOVER_TYPE.ERROR,
          dispatch
        );
      });
    setCountResend((prev) => prev + 1);
  };
  const submitOtpHandler = async () => {
    try {
      const data = await mobileOtpConfirmApi({ otp, mobile, ccod: ccode });
      dispatch({ type: "MOBILE_UPDATE", mobile });
      let webEngageData = {
        name: name,
        email: email,
        mobile: mobile,
        timeZone: tz,
      };

      WebEngageDataLayer(
        webEngageData,
        WebEngageRegisteredEvents.PROFILEUPDATED
      );

      setStep(step + 1);
    } catch (err) {
      console.log(err);
      utils.showPopover(
        "INVALID OTP",
        "The OTP entered is not valid.",
        POPOVER_TYPE.ERROR,
        dispatch
      );
    }
  };
  const handleOtp = (e) => {
    if (e.target.value.length > OTP_MOBILE.LENGTH) return;
    setOtp(e.target.value);
  };
  return (
    <>
      <>
        <img src={MobImg2} className="pe-img" />
        <div className="pe-head">Enter 6 Digit Verification Code</div>
        <Input
          type="text"
          className={`pe-adnew-inp ${error && "pe-adnew-input-error"}`}
          placeholder="1-2-3-4-5-6"
          onChange={handleOtp}
          value={otp}
        />
        <div className="EmailVerificationModal__resendContainer">
          <button
            className={`EmailVerificationModal__resendButton`}
            onClick={handleResendCode}
            disabled={!(timer == 0)}
          >
            {console.log(typeof timer)}
            Resend{" "}
            {!!timer && (
              <>
                (<Timer times={timer} />)
              </>
            )}
          </button>
        </div>
        <p className="EmailVerificationModal__error">{error}</p>

        <Button
          color="primary"
          disabled={loading}
          onClick={submitOtpHandler}
          className="pe-cnf-btn__otp"
        >
          {!loading ? "Verify OTP and Edit Mobile Number" : <InlineLoader />}
        </Button>
      </>
    </>
  );
};

const mapStateToProps = (state) => ({
  tz: state.profile.tz,
  email: state.profile.email,
  name: state.profile.p_name,
});

export default connect(mapStateToProps, {})(EditMobileVerify);
