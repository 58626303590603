import { connect } from "react-redux";
import React from "react";
import { withRouter } from "react-router";
import { Modal } from "reactstrap";
import Spinner from "../utils/Spinner";
import Loader from "../utils/Loader";

class LoadingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {}

  cancel = () => {
    this.props.cancelJoining();
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.is_loading_md}
          className={this.props.className}
          centered={true}
        >
          <div className="loading-modal">
            <Loader brand={this.state.brand} />
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_loading_md: state.utils.is_loading_md,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoadingModal)
);
