import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { CONFIGS } from "../../constants/constants";
import { utils } from "../../utility/utils";
import { Redirect } from "react-router";
import MD_WEB from "../../images/ManageDevices_Web.png";
import MD_Mobile from "../../images/ManageDevices_Mobile.png";
import MD_IPAD from "../../images/ManageDevices_iPad.png";
import { setLoading } from "../../actions/utilAction";
import Loader from "../utils/Loader";
import Cookies from "universal-cookie";

const cookies = new Cookies();
const findTypeOfDevice = (device) => {
  if (device === "W") {
    return MD_WEB;
  } else if (device === "I" || device === "A") {
    return MD_Mobile;
  }
};

const findNameOfDevice = (device, client) => {
  console.log("heyy", { device });
  if (device.includes("Macintosh") && client == "W") {
    return "MacBook";
  } else if (
    (device.includes("Windows") || device.includes("Linux")) &&
    client == "W"
  ) {
    console.log("12345  ");
    return "Web";
  } else if (device.includes("Android") && client == "A") {
    return device.split("Model")[1].replaceAll(":", "");
  } else if (device.includes("iPhone") && client == "I") {
    return "iPhone";
  }
};

function timeSince(date) {
  date = new Date(date);
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return (
      Math.floor(interval) + " year" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return (
      Math.floor(interval) + " month" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return (
      Math.floor(interval) + " day" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return (
      Math.floor(interval) + " hour" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  interval = seconds / 60;
  if (interval > 1) {
    return (
      Math.floor(interval) + " minute" + (Math.floor(interval) > 1 ? "s" : "")
    );
  }
  return (
    Math.floor(seconds) + " second" + (Math.floor(interval) > 1 ? "s" : "")
  );
}
const dummyList = [
  {
    clnt: "W",
    clv: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36",
    endpointId: 588505,
    _id: "63e8ad928c157572693fb3a7",
  },
  {
    clnt: "I",
    clv: "Imarticus Learning/1.1.6 (iPhone; iOS 16.1; Scale/3.00)",
    endpointId: 588505,
    _id: "63e8ad928c157572693fb3a7",
  },
  {
    clnt: "A",
    clv: "Android SDK: 30 (11) + Model:Redmi Note 8",
    endpointId: 588505,
    _id: "63e8ad928c157572693fb3a7",
  },
  {
    clnt: "W",
    clv: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36",
    endpointId: 588505,
    _id: "63e8ad928c157572693fb3a7",
  },
  {
    clnt: "I",
    clv: "Imarticus Learning/1.1.6 (iPhone; iOS 16.1; Scale/3.00)",
    endpointId: 588505,
    _id: "63e8ad928c157572693fb3a7",
  },
  {
    clnt: "A",
    clv: "Android SDK: 30 (11) + Model:Redmi Note 8",
    endpointId: 588505,
    _id: "63e8ad928c157572693fb3a7",
  },
  {
    clnt: "W",
    clv: "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36",
    endpointId: 588505,
    _id: "63e8ad928c157572693fb3a7",
  },
  {
    clnt: "I",
    clv: "Imarticus Learning/1.1.6 (iPhone; iOS 16.1; Scale/3.00)",
    endpointId: 588505,
    _id: "63e8ad928c157572693fb3a7",
  },
  {
    clnt: "A",
    clv: "Android SDK: 30 (11) + Model:Redmi Note 8",
    endpointId: 588505,
    _id: "63e8ad928c157572693fb3a7",
  },
];

const ManageDevices = () => {
  const [list, setList] = useState([]);
  const [current, setCurrent] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    callListOfDevices();
  }, []);
  const callListOfDevices = () => {
    axios
      .post(`${CONFIGS.API_API_ENDPOINT}notifications/mng_dvcs`, {
        aid: utils.getAid(),
        device_id: window.fingerprint.md5hash,
        tokn: utils.getAt(),
        rt: cookies.get("rt", { domain: CONFIGS.DOMAIN, path: "/" }),
      })
      .then((res) => {
        console.log(res.data.data);
        console.log(res.data.data.activeDevices);
        console.log(res.data.data.currentDevice?.[0] || {});
        setList(res.data.data.activeDevices || []);
        setCurrent(res.data.data.currentDevice || {});
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeDevice = (i) => {
    console.log("\n ========== Remove my device ============");
    // if (list.length === 0) return false;
    setLoading(true);
    axios
      .post(`${CONFIGS.API_API_ENDPOINT}notifications/removeDevice`, {
        aid: utils.getAid(),
        device_id: window.fingerprint.md5hash,
        endpointId: (i && i.endpointId) || "",
        removeAll: i.removaAll || false,
        tokn: utils.getAt(),

        rt: cookies.get("rt", { domain: CONFIGS.DOMAIN, path: "/" }),
      })
      .then((res) => {
        console.log("Res from removeDevice ::: ", res.data.data);
        alert("Removed device successfully!");
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log("Err from removeDevice ::: ", err);
        setLoading(false);
      });
  };
  if (loading) return <Loader />;
  return (
    <div
      className="group-db-content-wrapper"
      style={{ fontFamily: "Source Sans Pro" }}
    >
      <div className="container-fluid dashboard-content">
        <div className=" profile-setting-container-row">
          <div
            className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
            style={{ padding: "20px 20px" }}
          >
            <br />
            <h4 align="center">Manage Devices</h4>
            <br />
            <br />
            <div align="center">
              {current && (
                <div
                  className="card"
                  style={{
                    display: "inline-block",
                    width: "200px",
                    marginRight: "10px",
                    marginTop: "10px",
                  }}
                >
                  <div className="card-body">
                    <img
                      src={
                        "clnt" in current ? findTypeOfDevice(current.clnt) : ""
                      }
                      style={{ width: "50px", height: "55px" }}
                    />
                    <h5 style={{ marginTop: "10px" }}>
                      {"clv" in current
                        ? findNameOfDevice(current.clv, current.clnt)
                        : ""}
                    </h5>
                    <p>
                      {"createdAt" in current &&
                        timeSince(current.createdAt) + " ago"}
                    </p>
                    <p>
                      <span
                        style={{
                          padding: "5px 10px",
                          borderRadius: "40px",
                          background: "rgba(3, 86, 66, 0.15)",
                          color: "var(--themeColor)",
                        }}
                      >
                        Active Session
                      </span>
                    </p>
                    <p
                      onClick={() => removeDevice(current)}
                      style={{ cursor: "pointer", color: "red" }}
                    >
                      Remove
                    </p>
                  </div>
                </div>
              )}
              {list.length > 0 &&
                list.map((l, indx) => (
                  <div
                    key={indx}
                    className="card"
                    style={{
                      display: "inline-block",
                      width: "200px",
                      marginRight: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div className="card-body">
                      <img
                        src={findTypeOfDevice(l.clnt)}
                        style={{ width: "50px", height: "55px" }}
                      />
                      <h5 style={{ marginTop: "10px" }}>
                        {findNameOfDevice(l.clv, l.clnt)}
                      </h5>
                      <p>{timeSince(l.createdAt) + " ago"}</p>
                    </div>
                    <p>
                      <span
                        style={{
                          padding: "5px 10px",
                          borderRadius: "40px",
                          background: "rgba(3, 86, 66, 0.15)",
                          color: "var(--themeColor)",
                        }}
                      >
                        Other Device Session
                      </span>
                    </p>
                    <p style={{ cursor: "pointer", color: "white" }}>""</p>
                  </div>
                ))}
            </div>
            <br />
            <br />
            <div align="center">
              <span
                onClick={() => removeDevice({ removaAll: true })}
                style={{
                  fontFamily: "Source Sans Pro",
                  cursor: list.length > 0 ? "pointer" : "not-allowed",
                  color: list.length > 0 ? "red" : "#ff000047",
                  fontSize: "26px",
                }}
              >
                Log me out of all devices
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageDevices;
