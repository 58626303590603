import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
const cookies = new Cookies();

export const updateDocumentAsLectureStatus = (pid, cid, chid, fid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.UPDATE_DOC_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          cp_id: chid,
          doc_lec_id: fid,
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const getSignedUrl = (pid, gid, url) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-token"] = cookies.get("at");
    return axios
      .get(ROUTES.GET_SIGNED_URL, {
        configs,
        params: { m_url: url, g_id: gid, p_id: pid, tokn: cookies.get("at") },
      })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};
