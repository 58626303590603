import React, { Component } from "react";
import { withRouter } from "react-router";
import white_cross from "../../images/white-cross.svg";
import { connect } from "react-redux";
//import { getGroupPlugins, redirectToLearn, openPlugin, openDiscussionPlugin, disableMyGroupRedirection } from '../../actions/groupAction';
//import { setSelectedPluginData } from '../../actions/pluginAction';
import { setSlidingStrip, resetSlidingStrip } from "../../actions/utilAction";

class SlidingStrip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className={
          this.props.show_ss ? "gcs-container" : "gcs-container gcs-hide"
        }
      >
        <div className="gc-strip">
          <img
            className="cross"
            src={white_cross}
            onClick={this.props.resetSlidingStrip}
            alt=""
          />
          <p className={"gcs-msg"}>{this.props.ss_msg}</p>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  show_ss: state.utils.show_ss,
  ss_msg: state.utils.ss_msg,
});

const mapDispatchToProps = {
  setSlidingStrip,
  resetSlidingStrip,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SlidingStrip)
);
