import React from "react";
import loader_fix from "../../images/IMARTICUSICON-Green-Large-L 11.png";
import loader_rotate from "../../images/loader-rotate.svg";
import { connect } from "react-redux";
import BrandSpinner from "./BrandSpinner";
import Cookies from "universal-cookie";
const cookies = new Cookies();

class Spinner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  render() {
    return cookies.get("loaderColor") ? (
      <BrandSpinner color={cookies.get("loaderColor")} />
    ) : (
      <div className="loader-custom">
        <div className="loader-container">
          <img className="loader-fix" src={loader_fix} alt="rotater"></img>
          {/* {this.props.showLoader && ( */}
          <img
            className="loader-rotate"
            src="https://cdn.pegasus.imarticus.org/images/Imarticus_Loader_Circle-02-1.svg"
            height={80}
            alt="logo"
          ></img>
          {/* )} */}
        </div>
        <p className="loading-msg">{this.props.lm_msg}</p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lm_msg: state.utils.lm_msg,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Spinner);
