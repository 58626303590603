import React from "react";
import { useHistory } from "react-router";
import Button from "reactstrap/lib/Button";
import EmailImg4 from "../../../images/email-success-01.png";
const EditMobileSuccessFull = () => {
  const history = useHistory();
  return (
    <>
      <img src={EmailImg4} className="pe-img" />
      <div className="pe-head">Congratulations!</div>
      <div className="pe-vrfy-info">
        You have successfully verified your mobile.
      </div>
      <Button
        color="primary"
        className="pe-vrfy-btn"
        onClick={() => {
          window.open("/dashboard/edit", "_self");
        }}
      >
        Finish
      </Button>
    </>
  );
};
export default EditMobileSuccessFull;
