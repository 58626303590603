import React, { Fragment } from "react";
import styles from "./CourseHeader.scss";
//import logo from "../../images/logo.svg";
//import logo_mb from "../../images/imarticusLogo.svg";
import burger_icon from "../../images/burger-icon.svg";
//import search_mb from "../../images/search-mb.svg";
import AccountSettingDropdown from "../dropdowns/AccountSettingDropdown.js";
import NotificationDropdown from "../notifications/NotificationDropdown.js";
//import NotificationDropdown from "../dropdowns/NotificationDropdown.js";
//import SearchBox from "../searchboxs/SearchBox";
import { showFreshDesk } from "../../actions/showFreshDesk.js";
import { connect } from "react-redux";
import {
  URLS,
  SIDEBARS,
  //SIDEBAR_WIDTH,
  CONFIGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
} from "../../constants/constants.js";
import {
  setSearch,
  showSidebarMB,
  resetBackBtn,
  setPageHeading,
  showPopover,
} from "../../actions/utilAction.js";
import CustomPopover from "../utils/CustomPopover.js";
//import { fetchMyCourses, authenticateCourse } from "../../actions/courseAction";
//import left_blue_arrow from "../../images/left-arrow-blue.svg";
import { Link } from "react-router-dom";
import { getPrimaryEmailsWithoutModal } from "../../actions/primaryEmailActions.js";
import SupportIcon from "../../images/Support.svg";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { setLoadingData } from "../../actions/utilAction.js";
//import Breadcrumbs from "@material-ui/core/Breadcrumbs";
//import Typography from "@material-ui/core/Typography";
//import NavigateNextIcon from "@material-ui/icons/NavigateNext";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
const urlParams = new URLSearchParams(window.location.search);
let JobHeader = (props) => {
  console.log(props);
  const classes = useStyles();
  const [show_sidebar, setShow_sidebar] = useState(false);
  const [is_search, setIs_search] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [message, setMessage] = useState({
    heading: "",
    msg: "",
    type: POPOVER_TYPE.SUCCESS,
  });

  useEffect(() => {
    // console.log({ cid: urlParams.get("cid") });
  }, []);
  let handleClick = (event) => {
    // event.preventDefault();
    console.info("You clicked a breadcrumb.");
  };
  let showPopover = (heading, msg, type) => {
    setMessage({ heading, msg, type });
    togglePopover();
    setTimeout(function () {
      setPopoverOpen(false);
    }, POPOVER_HIDETIME);
  };
  let togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  return (
    <Fragment>
      <CustomPopover
        open={popoverOpen}
        heading={message.heading}
        msg={message.msg}
        type={message.type}
        toggleOpen={togglePopover}
      />
      <div className="home-dashboard-header courseHeader">
        <div className="nav-bar fixed-header-2" style={{ zIndex: 999 }}>
          <div className="header-content">
            <div className="toggle-icon-container-mb">
              <img
                onClick={() => props.showSidebarMB(!props.show_sidebar_mb)}
                src={burger_icon}
                width="70%"
              ></img>
            </div>
            <div className="ecko-logo-container-mb-2">
              <Link to={CONFIGS.DASHBOARD_URL}>
                {!props.is_data_loading && (
                  <img
                    onClick={() => {
                      props.setLoadingData();
                    }}
                    className="logo-mb"
                    // src={
                    //   props.individualBrand
                    //     ? props.individualBrand.pic
                    //     : props.brand
                    //     ? props.brand.pic
                    //     : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
                    // }
                    src={
                      "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
                    }
                  ></img>
                )}
              </Link>
            </div>
            <div className="profile-search-container-mb">
              <div className="profile-search-box">
                <AccountSettingDropdown />
              </div>
            </div>
            {/* <div className={!props.is_back ? "header-heading" : "hide"}>
              <span className="heading-text">
                {!props.is_data_loading ? props.pg_heading : ""}
              </span>
            </div> */}
            {/* {props.breadCrumbs && props.breadCrumbs[0] && (
              <div className="header-heading2" style={{ marginLeft: "4%" }}>
                <a href="/">
                  <span
                    className="heading-text2 rvm-v1-header-brdsc-ctnr"
                    style={{
                      overflow: "unset",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      fontSize: "16px",
                    }}
                  >
                    {props.breadCrumbs[0].name}
                  </span>
                </a>
                <span>
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: "small" }}
                  ></i>
                </span>
                <a href={props.breadCrumbs[1].link}>
                  <span
                    className="heading-text2 rvm-v1-header-brdsc-ctnr"
                    style={{
                      overflow: "unset",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      fontSize: "16px",
                    }}
                  >
                    {props.breadCrumbs[1].name}
                  </span>
                </a>
                <span>
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: "small" }}
                  ></i>
                </span>
                <span
                  className="heading-text2"
                  style={{
                    overflow: "unset",
                    paddingLeft: "8px",
                    paddingRight: "8px",
                    fontSize: "16px",
                  }}
                >
                  Career Services
                </span>
              </div>
            )} */}

            {/* <Link to={props.back_url} onClick={props.resetBackBtn}>
              <div
                className={props.is_back ? "header-heading back-btn" : "hide"}
              >
                <i className="fa fa-angle-down bold-angle-left"></i>
                <span>{!props.is_data_loading ? "Back" : ""}</span>
              </div>
            </Link> */}
            <div className="header-center"></div>

            <div className="profile-btns" style={{ height: "60px" }}>
              <ul className="navbar-right-top">
                {!props.is_data_loading &&
                  (props.brand == undefined ||
                    props.brand.getHelpEnabled == true) && (
                    <li className="nav-item header-get-help-container">
                      <button
                        className="header-get-help"
                        onClick={() => {
                          showFreshDesk(
                            [props.selected_course],
                            props.contact,
                            props.p_name,
                            props.email
                          );
                        }}
                      >
                        <img
                          src={
                            "https://cdn.pegasus.imarticus.org/index/help_neutral.svg"
                          }
                        ></img>
                        <span>Get help</span>
                      </button>
                    </li>
                  )}
                <li className="nav-item dropdown ">
                  <button
                    className="profile-pic"
                    href="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        props.ppic && props.ppic != ""
                          ? props.ppic
                          : URLS.DEFAULT_PPIC_URL
                      }
                      alt="user"
                      className="profile-img"
                    />
                  </button>
                </li>
                <li className="nav-item dropdown ">
                  <AccountSettingDropdown />
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className={props.show_menu ? "header-cover" : "hide"}></div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  is_search: state.utils.is_search,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  show_menu: state.ac_dd.show_menu,
  ppic: state.profile.ppic,
  p_name: state.profile.p_name,
  email: state.primaryEmail.primary_email,
  pg_heading: state.utils.pg_heading,
  sidebar: state.utils.sidebar,
  back_url: state.utils.back_url,
  is_back: state.utils.is_back,
  is_data_loading: state.utils.is_data_loading,
  brand: state.course.individualBrand,
  contact: state.profile.contact,
  profile: state.profile,
  courses: state.course.courses,
  selected_course: state.course.selected_course,
});

const mapDispatchToProps = {
  setSearch,
  resetBackBtn,
  showSidebarMB,
  getPrimaryEmailsWithoutModal,
  setPageHeading,
  showPopover,
  setLoadingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(JobHeader);
