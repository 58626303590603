import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router";
import close_img from "../../images/close-btn.svg";
import success from "../../images/success.svg";
import fail from "../../images/fail.svg";
import { closePopover } from "../../actions/utilAction";
import { POPOVER_TYPE } from "../../constants/constants";

class ErrorPopover extends Component {
  componentDidUpdate(prevProps) {}

  componentDidMount() {}

  onRouteChanged() {
    // this.props.setCurrentTab(this.getCurrentTab());
  }

  render() {
    return (
      <div
        className={
          this.props.is_pover
            ? this.props.pover_type === POPOVER_TYPE.ERROR
              ? "error-popover error"
              : "error-popover"
            : "error-popover hide-error-popover"
        }
      >
        <div className="ep-icon-container">
          <img
            className={"icon"}
            src={
              this.props.pover_type == POPOVER_TYPE.SUCCESS
                ? success
                : this.props.pover_type == POPOVER_TYPE.ERROR
                ? fail
                : ""
            }
          />
        </div>
        <div className="ep-content">
          <p className="ep-heading">{this.props.pover_heading}</p>
          <p className="ep-msg">{this.props.pover_msg}</p>
        </div>
        <div className="close">
          <img
            onClick={this.props.closePopover}
            className="close-img"
            src={close_img}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  is_pover: state.utils.is_pover,
  pover_msg: state.utils.pover_msg,
  pover_heading: state.utils.pover_heading,
  pover_type: state.utils.pover_type,
});

const mapDispatchToProps = {
  closePopover,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ErrorPopover)
);
