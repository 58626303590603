import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router";
import { connect } from "react-redux";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import "./quiz.scss";
import Loader from "../utils/Loader.js";
import {
  updateQuizStatus,
  pluginIdentifier,
} from "../../actions/quizAction.js";
import { getSignedUrl } from "../../actions/docLectureActions.js";
import { fetchSkillContentData } from "../../actions/assignmentAction.js";
import { CONFIGS } from "../../constants/constants.js";

const Quiz = ({
  course,
  getIndex,
  prevFid,
  setContentLoading,
  status,
  setNextButtonLoading,
}) => {
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  const cid = query[6];
  const levelId = query[7] != undefined && query[7] ? query[7] : null;
  const type = query[8];
  const chid = query[9];
  const fid = query[10];
  console.log({ query });
  const history = useHistory();

  const [quiz, setQuiz] = useState();
  const [isLockedDeadline, setIsLockedDeadline] = useState(false);
  const [deadline, setDeadline] = useState();
  const [resourceMap, setResourceMap] = useState();
  const [aboutResources, setAboutResources] = useState("About");
  const [showPopUp, setShowPopUp] = useState(false);
  const [skill_tag_ids, setSkillTagIds] = useState([]);
  const [skillTagContent, setSkillTagContent] = useState({});
  const [chapterMap, setChapterMap] = useState({});
  const [showSkillDetail, setShowSkillDetail] = useState(false);
  const [curr_skill_tag_id, setCurrSkillTagId] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setQuiz();
      setContentLoading(true);
      setLoading(true);
      getIndex();
      if (course) {
        await activateQuiz();
      }
      setLoading(false);
      setContentLoading(false);
    })();
  }, [course, prevFid]);
  useEffect(() => {
    console.log({ skill_tag_ids });
  }, [skill_tag_ids]);
  const activateQuiz = async () => {
    var tempQuizData = course.quizzes[fid];
    if (tempQuizData.skill_tag_ids && tempQuizData.skill_tag_ids.length > 0) {
      setSkillTagIds([...tempQuizData.skill_tag_ids]);
    } else {
      setSkillTagIds([]);
    }
    if (tempQuizData.etim && tempQuizData.etim != "") {
      var tempIsLockedDeadline =
        parseInt(tempQuizData.etim) < new Date().getTime() ? true : false;
      var tempFormattedDate = new Date(tempQuizData.etim).toLocaleString(
        "en-US",
        {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );
      setIsLockedDeadline(tempIsLockedDeadline);
      setDeadline(tempFormattedDate);
    }
    tempQuizData["enable"] = false;
    const pluginIdentifierData = await pluginIdentifier(tempQuizData, gid);
    if (pluginIdentifierData.data.success) {
      tempQuizData["u_idnt"] = pluginIdentifierData.data.data.u_idnt;
      tempQuizData.enable = true;
      tempQuizData.idnt_id = pluginIdentifierData.data.data.idnt_id;
    } else {
      await activateQuiz();
    }
    await updateQuizStatus(pid, cid, chid, fid);
    setNextButtonLoading(true);
    setQuiz(tempQuizData);
  };

  const handleMouseEnter = () => {
    setShowPopUp(true);
  };

  const handleMouseLeave = () => {
    setShowPopUp(false);
  };

  const fetchSkillContent = async (ev) => {
    var skillListDiv = document.getElementById("skillList");
    // Clear all existing elements in skillListDiv
    // while (skillListDiv.firstChild) {
    //   skillListDiv.removeChild(skillListDiv.firstChild);
    // }
    var curr_skill_tag_id = ev.target.value;
    setCurrSkillTagId(curr_skill_tag_id);
    var skill_name = ev.target.innerText;
    const skillListData = await fetchSkillContentData(
      pid,
      cid,
      curr_skill_tag_id
    );
    if (skillListData.success) {
      setSkillTagContent(skillListData.data.skillById);
      setChapterMap(skillListData.data.chapterLevelMap);
      setShowSkillDetail(true);
    }
  };

  const renderSkillContent = () => {
    let counter = 1;
    const skillList = [];

    for (const key in skillTagContent) {
      if (skillTagContent.hasOwnProperty(key)) {
        const content = skillTagContent[key];
        if (String(key) === String(curr_skill_tag_id)) {
          for (let i = 0; i < content.length; i++) {
            let levelId;
            if (chapterMap.hasOwnProperty(content[i].chapter_id)) {
              const levelDetail = chapterMap[content[i].chapter_id];
              levelId = levelDetail[0].levelId;
            }
            const contentId =
              content[i].type === "quiz"
                ? content[i].quiz_id
                : content[i].asgn_id;

            if (String(quiz.nm) !== String(content[i].name)) {
              skillList.push(
                <div
                  key={counter}
                  className="individualSkillContainer"
                  onClick={() => {
                    if (
                      !(
                        status.chapterLocking &&
                        status.chapterLocking[content[i].chapter_id] &&
                        (status.chapterLocking[content[i].chapter_id]
                          .isLocked ||
                          status.chapterLocking[content[i].chapter_id]
                            .isLockedDeadline)
                      )
                    ) {
                      var contentLock = false;
                      if (
                        status.contentLocking &&
                        status.contentLocking[content[i].chapter_id]
                      ) {
                        if (
                          status.contentLocking[content[i].chapter_id].length >
                          0
                        ) {
                          for (
                            var j = 0;
                            j <
                            status.contentLocking[content[i].chapter_id]
                              .length >
                            0;
                            j++
                          ) {
                            if (
                              status.contentLocking[content[i].chapter_id][j]
                                .fid == contentId &&
                              (status.contentLocking[content[i].chapter_id][j]
                                .isLocked ||
                                status.contentLocking[content[i].chapter_id][j]
                                  .isLockedDeadline)
                            ) {
                              contentLock = true;
                            }
                          }
                        }
                      }
                      if (!contentLock) {
                        window.open(
                          `${CONFIGS.WEB_DASHBOARD_BASE_URL}chapter/${pid}/${gid}/${cid}/${levelId}/${content[i].type}/${content[i].chapter_id}/${contentId}`,
                          "_self"
                        );
                      } else {
                        window.alert("The content is Locked");
                      }
                    }
                  }}
                >
                  <span className="individualSkillSpan">{counter}. </span>
                  <a className="individualSkill">{content[i].name}</a>
                  <img
                    src="https://webcdn.imarticus.org/aib/majesticons_open.svg"
                    alt="icon"
                    style={{ width: "20px", height: "20px", marginLeft: "5px" }}
                    onClick={() =>
                      history.push(
                        `/chapter/${pid}/${gid}/${cid}/${levelId}/${content[i].type}/${content[i].chapter_id}/${contentId}`
                      )
                    }
                  />
                </div>
              );
              counter++;
            }
          }
          break;
        }
      }
    }
    return skillList;
  };

  return !loading ? (
    isLockedDeadline ? (
      <div className="quizScss">
        <div className="as-dl-msg">The Content deadline is exceeded!</div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "200px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              top: "-80px",
              position: "relative",
              justifyContent: "center",
            }}
          >
            <img
              src="https://cdn.pegasus.imarticus.org/chapter-controller/deadlineExpired.svg"
              alt="Deadline Expired"
            />
          </div>
        </div>
        <div
          style={{
            color: "#e94b35",
            fontSize: "40px",
            fontWeight: "800",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: "-20px",
          }}
        >
          Deadline Expired at {deadline}
        </div>
      </div>
    ) : (
      <div className="quizScss">
        <div>
          {skill_tag_ids.length > 0 && (
            <div
              className="as-dl-skill"
              id="assignment-block-2"
              style={{ marginLeft: "56px", height: "40px" }}
            >
              {skill_tag_ids.map((skill, index) => (
                <div key={index}>
                  <button
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    id="skill-btn"
                    onClick={(event) => fetchSkillContent(event)}
                    value={skill.value}
                    label={skill.label}
                    style={{
                      display: "block",
                      float: "left",
                      padding: "4px 16px",
                      background: "rgba(1, 70, 68, 0.15)",
                      borderRadius: "100px",
                      color: "#212a39",
                      marginRight: "8px",
                      border: "none",
                      fontWeight: "500",
                    }}
                  >
                    {skill.label}
                  </button>
                  {showPopUp && (
                    <div className="showPopupSkill">
                      Click on skills to view linked contents.
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        {
          <div
            id="skillDetail"
            className="skillDetail"
            style={{ display: !showSkillDetail ? "none" : "block" }}
          >
            <div className="skillHeading">
              <p id="skillHeading">Explore all content of this skill</p>
              <div>
                <img
                  src="https://cdn.pegasus.imarticus.org/chapter-controller/closemodal.png"
                  style={{ paddingRight: "17px" }}
                  onClick={() => {
                    setShowSkillDetail(false);
                  }}
                  alt="Close"
                />
              </div>
            </div>
            <div id="skillList">
              <br />
              {renderSkillContent()}
            </div>
          </div>
        }
        <div className="quiz-area">
          <p id="desc">{quiz.nm}</p>
          <button
            id="q-btn"
            onClick={() => {
              console.log({ "process.env.NODE_ENV": process.env.NODE_ENV });
              let link =
                CONFIGS.QUIZ_USER_ENDPOINT +
                "#/list/test/" +
                quiz.tid +
                "?uid=" +
                quiz.u_idnt +
                "&plid=" +
                quiz.plid +
                "&idnt_id=" +
                quiz.idnt_id;
              console.log({ quizLink: link });
              // if (process.env.NODE_ENV == "development") {
              console.log(`same tab`);
              window.location.href = link;
              // } else {
              //   console.log(`new tab`)
              //   window.open(
              //     link,
              //     "_blank"
              //   );
              // }
            }}
            disabled={!quiz.enable}
            className="btn btn-primary blue-block"
          >
            Start Quiz
          </button>
        </div>
        {/* <div className="video-desc">
          <div
            className="nav-tabs"
            style={{ display: "flex", columnGap: "30px" }}
          >
            <div
              className="eck-tabss"
              onClick={() => {
                setAboutResources("About");
              }}
              style={{
                cursor: "pointer",
                marginLeft: "15px",
                color: "#055646",
                padding: "8px 16px 13px 16px",
                borderBottom:
                  aboutResources == "About" ? "3px solid #055646" : "",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              About
            </div>
            <div
              className="eck-tabss"
              onClick={() => {
                setAboutResources("Resources");
              }}
              style={{
                cursor: "pointer",
                color: "#055646",
                padding: "8px 16px 13px 16px",
                borderBottom:
                  aboutResources == "Resources" ? "3px solid #055646" : "",
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Resources
            </div>
          </div>
          <div>
            {aboutResources == "About" && (
              <div
                style={{
                  color: "#494949",
                  fontSize: "16px",
                  padding: "30px 0px",
                }}
              >
                <p dangerouslySetInnerHTML={{ __html: quiz.dsc }}></p>
              </div>
            )}
            {aboutResources == "Resources" && (
              <div id="resources" className="tab-pane">
                resources
              </div>
            )}
          </div>
        </div> */}
      </div>
    )
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = { dispatchIndividualBrand, getProfileInfoOnly };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Quiz));
