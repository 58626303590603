import React, { Component, Fragment } from "react";
import { Tooltip } from "reactstrap";
import join_grp_help from "../../images/join-grp-help.svg";

class GroupCodeTooltip extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      tooltipOpen: false,
    };
  }

  toggle() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  }

  render() {
    return (
      <Fragment>
        <img href="#" id="GroupCodeTooltip" src={join_grp_help} alt=""></img>
        {/* <p>Sometimes you need to allow users to select text within a <span style={{textDecoration: "underline", color:"blue"}} href="#" id="DisabledAutoHideExample">tooltip</span>.</p> */}
        <Tooltip
          placement="top"
          isOpen={this.state.tooltipOpen}
          autohide={false}
          target="GroupCodeTooltip"
          toggle={this.toggle}
        >
          One can enter a group via Group Code
        </Tooltip>
      </Fragment>
    );
  }
}

export default GroupCodeTooltip;
