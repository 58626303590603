import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers";
import { createLogger } from "redux-logger";

var middlewares = [];
middlewares.push(thunk);

// const loggerMiddleware = createLogger();
// middlewares.push(loggerMiddleware);

/* 
if (process.env.NODE_ENV === "debug") {
	const { createLogger } = require("redux-logger");

	const loggerMiddleware = createLogger();
	middlewares.push(loggerMiddleware);
}
*/
const middlewareThunk = applyMiddleware(thunk);

export default createStore(
  reducer,
  compose(
    middlewareThunk,
    typeof window === "object" &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== "undefined" &&
      process.env.REACT_APP_BUILD_ENV != "production"
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);
