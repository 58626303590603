import React from "react";
import ReactDOM from "react-dom";
import Cookies from "universal-cookie";
import App from "./App";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import "./lib/fingerprint.js";
import axios from "axios";
// import { errorHandler } from "./utility/utils";
import { authenticateCourse } from "./actions/courseAction";
import { renewAccessToken } from "./actions/utilAction";
import cookie from "react-cookies";
import { BACKEND_ERROR_CODES } from "./constants/errors";
import { expireOlderCookies, updateURLParameter } from "./utility/utils";
import { logoutUser } from "./actions/accountDropdownAction";
import { COOKIE_EXPIRY_PATHS } from "./constants/constants";
// import { Amplify, Analytics, Auth } from 'aws-amplify';
// import awsconfig from './aws-exports';

// Auth.configure({ mandatorySignIn: false});

// Amplify.configure({
//   ...awsconfig,
//   Analytics: {
//     disabled: true,
//   },
// });

const cookies = new Cookies();

axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log("---------- Error ::: ", error, " --------------");
    console.log({ errorResConfig: error.response?.config });

    const originalRequest = error.config;
    console.log({ error }, { retry: originalRequest._retry });
    if (error.response.status === 498 && !originalRequest._retry) {
      originalRequest._retry = true; // to avoid loops

      if (error.response.config.url.includes("/courselib")) {
        expireOlderCookies("crs_tkn", COOKIE_EXPIRY_PATHS);
        let cid = cookies.get("cid");

        if (cid) {
          console.log(
            "\n\n ================= Calling /at_Crs for token renewal ============== \n\n "
          );
          try {
            await authenticateCourse(cid);
            console.log(
              "\n\t successful call, new token should be set in crs_tkn cookie now! "
            );
            let crs_tkn_from_cookie = cookies.get("crs_tkn");

            error.response.config.headers["x-access-crs-token"] =
              crs_tkn_from_cookie;
            return await axios(error.response.config);
          } catch (err) {
            console.log("\n \tError in fetching the crs token! \n", err);
            logoutUser(); // will be called if there is an issue while generating at token.
            return Promise.reject(error);
          }
        } else {
          logoutUser(); // will be called if cid is not in the cookies.
          return Promise.reject(error);
        }
      } else if (!error.response.config.url.includes("/g_at_w")) {
        console.log(
          " ================= Calling /g_at_w for token renewal ============== "
        );
        try {
          // expireOlderCookies("at", ["/", "/dashboard"]);
          let at_updated_tkn = await renewAccessToken();

          console.log(
            " --- Response from /g_at_w ::: ",
            { newAtToUse: at_updated_tkn },
            " ---- "
          );

          if (!at_updated_tkn) {
            logoutUser(); // will be called when the at token is not refreshed.
            return Promise.reject(error);
          }

          // three ways to use the new at token,
          // first, in req.body.tokn (for post calls)
          // second, in req.query.tokn (for get calls)
          // third, in req.headers.x-access-token

          if (
            error.response.config.method === "POST" ||
            error.response.config.method === "post"
          ) {
            let dataToAdd = JSON.parse(error.response.config.data);
            error.response.config.data = {
              ...dataToAdd,
              tokn: at_updated_tkn,
            };
          } else {
            let newURlwithQueryParams = updateURLParameter(
              error.response.config.url,
              "tokn",
              at_updated_tkn
            );
            console.log({ newURlwithQueryParams });
            error.response.config.url = newURlwithQueryParams;
          }

          error.response.config.headers["x-access-token"] = at_updated_tkn;

          console.log(
            "\n ---- Final API call ::: ",
            error.response.config,
            " ---- \n"
          );

          // call the api which initially gave 498.
          return await axios(error.response.config);
        } catch (err) {
          logoutUser(); // will be called if there is an error in /g_at_w
          return Promise.reject(error);
        }
      } else {
        logoutUser(); // will be called if there is 498 issue but the route is NOT - /courselib and /g_at_w
        return Promise.reject(error);
      }
    } else if (
      error.response.status === 403 &&
      error.response.config.url.includes("/courselib/gen_zm_sgn") &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true; // to avoid loops

      expireOlderCookies("crs_tkn", COOKIE_EXPIRY_PATHS);
      let cid = cookies.get("cid");
      if (cid) {
        console.log(
          "\n\n ================= Calling /at_Crs for token renewal ============== \n\n "
        );
        try {
          await authenticateCourse(cid);
          console.log(
            "\n\t successful call, new token should be set in crs_tkn cookie now! "
          );
          let crs_tkn_from_cookie = cookies.get("crs_tkn");

          error.response.config.headers["x-access-crs-token"] =
            crs_tkn_from_cookie;
          return await axios(error.response.config);
        } catch (err) {
          console.log("\n \tError in fetching the crs token! \n", err);
          logoutUser(); // will be called if there is an issue while generating at token.
          return Promise.reject(error);
        }
      } else {
        logoutUser(); // will be called if cid is not in the cookies.
        return Promise.reject(error);
      }
    } else {
      // logoutUser(); // No need to logout user since it is not a 498 error
      return Promise.reject(error);
    }
  }
);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
// serviceWorker.register({ scope: '/dashboard' });
