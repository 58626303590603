import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getProfileInfoOnly } from "../../actions/profileAction.js";
import { dispatchIndividualBrand } from "../../actions/brandAction.js";
import "./docLecture.scss";
import Loader from "../utils/Loader.js";
import PDFReader from "../PDFReader/PDFReader";
import {
  getSignedUrl,
  updateDocumentAsLectureStatus,
} from "../../actions/docLectureActions.js";
import { downloadBase64File } from "../../utility/utils.js";

const DocLecture = ({
  course,
  getIndex,
  prevFid,
  setContentLoading,
  setNextButtonLoading,
}) => {
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const gid = query[5];
  const cid = query[6];
  const levelId = query[7] != undefined && query[7] ? query[7] : null;
  const type = query[8];
  const chid = query[9];
  const fid = query[10];
  console.log({ query, fid });

  const [documentLecture, setDocumentLecture] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [resourceMap, setResourceMap] = useState();
  const [isLockedDeadline, setIsLockedDeadline] = useState(false);
  const [deadline, setDeadline] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setContentLoading(true);
      setLoading(true);
      getIndex();
      console.log({ course });
      if (course) {
        console.log({ course });
        await activateDocument();
      }
      setLoading(false);
      setContentLoading(false);
    })();
  }, [prevFid]);

  const activateDocument = async () => {
    var tempDocLec = course.documentAsLectures[fid];
    if (tempDocLec.etim && tempDocLec.etim != "") {
      var tempIsLockedDeadline =
        parseInt(tempDocLec.etim) < new Date().getTime() ? true : false;
      var tempFormattedDate = new Date(tempDocLec.etim).toLocaleString(
        "en-US",
        {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }
      );
      setIsLockedDeadline(tempIsLockedDeadline);
      setDeadline(tempFormattedDate);
    }
    const signedUrl = await getSignedUrl(pid, gid, tempDocLec.file);
    if (signedUrl.success) {
      setFileUrl(signedUrl.data.signed_url);
      await updateDocumentAsLectureStatus(pid, cid, chid, fid);
      setNextButtonLoading(true);
    }
    if (tempDocLec.extras && tempDocLec.extras.length > 0) {
      var tempResourceMap = {};
      for (var i = 0; i < tempDocLec.extras.length; i++) {
        var tempResource = await getSignedUrl(
          pid,
          gid,
          course.others[tempDocLec.extras[i]].url
        );
        if (tempResource.success) {
          tempResourceMap[tempDocLec.extras[i]] =
            course.others[tempDocLec.extras[i]];
          tempResourceMap[tempDocLec.extras[i]]["signed_url"] =
            tempResource.data.signed_url;
        }
      }
      setResourceMap(tempResourceMap);
    }
    setDocumentLecture(tempDocLec);
    setLoading(false);
  };

  const downLoadResources = function (url, name) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        // Convert blob to Base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64Data = reader.result;
          console.log(base64Data); // Base64 representation of the image
          downloadBase64File(base64Data, name);
          setLoading(false);
        };
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
        setLoading(false);
      });
  };

  return !loading ? (
    <div className="assignment docLectureScss">
      {isLockedDeadline ? (
        <div>
          <div className="as-dl-msg">The Content deadline is exceeded!</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                position: "relative",
                top: "-80px",
              }}
            >
              <img
                src="https://cdn.pegasus.imarticus.org/chapter-controller/deadlineExpired.svg"
                alt="Deadline Expired"
              />
            </div>
          </div>
          <div
            style={{
              color: "#e94b35",
              fontSize: "40px",
              fontWeight: "800",
              display: "flex",
              justifyContent: "center",
              position: "relative",
              top: "-20px",
            }}
          >
            Deadline Expired at {deadline}
          </div>
        </div>
      ) : (
        <div>
          <div
            id="adobe-dc-view"
            className="pdf-view"
            style={{ height: "100%" }}
          >
            <PDFReader url={fileUrl}></PDFReader>
          </div>
          <div className="a-card">
            <h2 className="assignment-heading">Instructor Notes</h2>
            <p
              style={{ whiteSpace: "pre-wrap" }}
              dangerouslySetInnerHTML={{ __html: documentLecture.dsc }}
            ></p>
            <br />
            {documentLecture.extras.length > 0 && (
              <div>
                <h2 className="assignment-heading">Resources</h2>
                <ul className="docLectureResouceList">
                  {documentLecture.extras.map((extra, index) => (
                    <li key={index} className="docLectureResouceListItem">
                      {course.others[extra].url && (
                        <div
                          className="docLectureResouceListItemLink"
                          onClick={() => {
                            setLoading(true);
                            downLoadResources(
                              resourceMap[extra].signed_url,
                              course.others[extra].nm
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Download{" "}
                          {documentLecture.nm == course.others[extra].nm
                            ? `Resource ${documentLecture.nm}`
                            : course.others[extra].nm}
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  ) : (
    <Loader />
  );
};

const mapStateToProps = (state) => ({
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = { dispatchIndividualBrand, getProfileInfoOnly };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DocLecture)
);
