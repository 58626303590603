import { ROUTES } from "../constants/routes";
import Axios from "axios";
import { utils } from "../utility/utils";
import Cookies from "universal-cookie";
import { CONFIGS } from "../constants/constants";
var cookies = new Cookies();
export const editMobileSendOtp = async ({ mobile, ccode }) => {
  try {
    var aid = utils.getAid();
    var tokn = utils.getAt();
    var pid = utils.getPid();
    var email_ob = {
      aid: aid,
      tokn: tokn,
      pid: pid,
      mob: mobile,
      ccod: ccode,
    };
    const response = await Axios.post(ROUTES.CONFIRM_MOBILE, email_ob);
    return response.data.data;
  } catch (err) {
    if (err.response && err.response.data) {
      return Promise.reject(err.response.data.message);
    } else Promise.reject("Something went wrong");
  }
};
export const mobileOtpConfirmApi = async ({ otp, mobile, ccod }) => {
  const aid = cookies.get("aid");
  const tokn = cookies.get("at");
  try {
    const response = await Axios.post(
      `${CONFIGS.API_API_ENDPOINT}account/mob_otp_vrfy/`,
      { aid, tokn, otp, mobile, ccod }
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      return Promise.reject(err.response.data.message);
    }
    return Promise.reject("Something went wrong");
  }
};
export const editPrimaryMailSendOtp = async ({ email }) => {
  try {
    var aid = utils.getAid();
    var tokn = utils.getAt();
    var pid = utils.getPid();
    var email_ob = {
      aid: aid,
      tokn: tokn,
      email: email,
      pid: pid,
    };
    const response = await Axios.post(ROUTES.CONFIRM_PRIMARY_EMAIL, email_ob);
    return response.data.data;
  } catch (err) {
    if (err.response && err.response.data) {
      return Promise.reject(err.response.data.message);
    } else Promise.reject("Something went wrong");
  }
};
export const mailOtpConfirmApi = async ({ otp, email }) => {
  const aid = cookies.get("aid");
  const tokn = cookies.get("at");
  try {
    const response = await Axios.post(
      `${CONFIGS.API_API_ENDPOINT}account/pe_otp_vrfy/`,
      { aid, tokn, otp, email }
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      return Promise.reject(err.response.data.message);
    }
    return Promise.reject("Something went wrong");
  }
};
export const editTimeZone = async function (tz) {
  const aid = cookies.get("aid");
  const tokn = utils.getAt();
  try {
    const response = await Axios.put(
      `${CONFIGS.API_API_ENDPOINT}account/e_tz`,
      {
        aid,
        tz,
        tokn: tokn,
      }
    );
    return response.data;
  } catch (err) {
    if (err.response) {
      return Promise.reject(err.response.data.message);
    }
    return Promise.reject("Something went wrong");
  }
};
