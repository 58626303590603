const initialState = {
	number	        : '',
	pwd		        : '',
	confirm_pwd		: '',
	otp				: '',
	otp_received    : false,
	otp_verified	: false,
	is_loading		: false,
}

const passwordReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'UPDATE_PASSWORD' : 
			return {
				...state,
                number		    :   action.number,
                pwd             :   action.pwd,
                confirm_pwd     :   action.confirm_pwd, 
				otp             :   action.otp,   
				otp_received	:   action.otp_received
			}
		case 'VERIFY_OTP':
			return {
				...state,
				otp_verified : action.otp_verified ,
			}
		case 'SET_PASSWORD_LOADER':
			return {
				...state,
				is_loading		:   action.is_loading
			}
		default:
			return state
	}
}

export default passwordReducer