export const WebEngageRegisteredEvents = {
  PROFILEUPDATED: "Profile Updated",
  PROFILECOMPLETED: "Profile Completed",
};

export const WebEngageDataLayer = (data, event) => {
  try {
    window.dataLayer = window.dataLayer || [];
    const dataLayerData = {
      event: event,
      send_to: "AW-985758930/C52lCL621gYQ0vmF1gM",
      event: event,
      name: data.name || "",
      email: data.email || "",
      mobile: data.mobile || "",
      preferredTimeZone: data.timeZone || "",
    };

    console.debug({ dataLayerData });
    window.dataLayer.push(dataLayerData);
  } catch (err) {
    console.debug("Error in processing event", err);
  }
};
