import React, { Component } from "react";
import not_found_img from "../../images/notfound.svg";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setNotFound } from "../../actions/utilAction";

class NotFoundContent extends Component {
  render() {
    return (
      <div className="nf-wrapper">
        <div className="nf-content">
          <img className="nf-img" src={not_found_img} alt=""></img>
          <p className="nf-msg">
            Page Not Found
            <br /> We can't find the page you are looking for.
          </p>
          <Link
            className="back-btn-container"
            to={"/dashboard"}
            onClick={() => this.props.setNotFound()}
          >
            <button className="back-btn">Back to Home</button>
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  setNotFound,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotFoundContent)
);
