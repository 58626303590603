import axios from "axios";
import { ROUTES } from "../constants/routes";
import { EXPLORE_SECTIONS, CONFIGS } from "../constants/constants";
import { utils } from "../utility/utils";
import {
  ERROR_MESSAGES,
  FRONTEND_ERRORS,
  BACKEND_ERROR_CODES,
} from "../constants/errors";
import { renewAccessToken } from "./utilAction";

export const fetchExploreData = (pid) => (dispatch) => {
  // let exp_route = ROUTES.FETCH_EXPLORE_DATA + '?p_id=' + pid + '&tokn=' + utils.getAt();
  let exp_route = ROUTES.FETCH_EXPLORE_DATA;
  let config = {
    headers: {
      "Content-Type": "application/json",
      "x-access-token": utils.getAt(),
    },
  };
  axios
    .get(exp_route, config)
    .then((exp_data) => {
      var sec_data = exp_data.data.data.final.sections;
      var gids = [];
      var ex_obj = {};
      for (var i = 0; i < sec_data.length; i++) {
        if (sec_data[i].sec_num == EXPLORE_SECTIONS.TRENDING_COURSES) {
          ex_obj.type = "SET_TRENDING_COURSES";
          ex_obj.data = sec_data[i].data;
          dispatch(ex_obj);
        } else if (sec_data[i].sec_num == EXPLORE_SECTIONS.FREE_COURSES) {
          ex_obj.type = "SET_FREE_COURSES";
          ex_obj.data = sec_data[i].data;
          dispatch(ex_obj);
        } else if (sec_data[i].sec_num == EXPLORE_SECTIONS.TRENDING_GROUPS) {
          if (sec_data[i].data) {
            for (var k = 0; k < sec_data[i].data.length; k++) {
              if (
                sec_data[i].data &&
                sec_data[i].data[k] &&
                sec_data[i].data[k]._id
              ) {
                gids.push(sec_data[i].data[k]._id);
              }
            }
          }
          ex_obj.type = "SET_TRENDING_GROUPS";
          ex_obj.data = sec_data[i].data;
          dispatch(ex_obj);
        } else if (sec_data[i].sec_num == EXPLORE_SECTIONS.FEATURED_GROUPS) {
          if (sec_data[i].data) {
            for (var k = 0; k < sec_data[i].data.length; k++) {
              if (
                sec_data[i].data &&
                sec_data[i].data[k] &&
                sec_data[i].data[k]._id
              ) {
                gids.push(sec_data[i].data[k]._id);
              }
            }
          }
          ex_obj.type = "SET_FEATURED_GROUPS";
          ex_obj.data = sec_data[i].data;
          dispatch(ex_obj);
        } else if (sec_data[i].sec_num == EXPLORE_SECTIONS.RUNNING_COURSES) {
          ex_obj.type = "SET_RUNNING_COURSES";
          ex_obj.data = sec_data[i].data;
          dispatch(ex_obj);
        } else if (sec_data[i].sec_num == EXPLORE_SECTIONS.FINANCE_COURSES) {
          ex_obj.type = "SET_FINANCE_COURSES";
          ex_obj.data = sec_data[i].data;
          dispatch(ex_obj);
        } else if (sec_data[i].sec_num == EXPLORE_SECTIONS.TECHNOLOGY_COURSES) {
          ex_obj.type = "SET_TECHNOLOGY_COURSES";
          ex_obj.data = sec_data[i].data;
          dispatch(ex_obj);
        } else if (sec_data[i].sec_num == EXPLORE_SECTIONS.ANALYTICS_COURSES) {
          ex_obj.type = "SET_ANALYTICS_COURSES";
          ex_obj.data = sec_data[i].data;
          dispatch(ex_obj);
        } else if (sec_data[i].sec_num == EXPLORE_SECTIONS.MARKETING_COURSES) {
          ex_obj.type = "SET_MARKETING_COURSES";
          ex_obj.data = sec_data[i].data;
          dispatch(ex_obj);
        }
      }
      var info_data = {};
      info_data.tokn = utils.getAt();
      info_data.g_ids = gids;
      info_data.p_id = utils.getPid();
      axios
        .post(ROUTES.EXPLORE_GROUP_INFO, info_data)
        .then((info_res) => {
          info_res = info_res.data.data;
          var info_action = {};
          info_action.type = "SET_EX_G_INFO";
          info_action.all_ex_gids = gids;
          info_action.joined_gids = info_res.gids;
          dispatch(info_action);
        })
        .catch((err) => {
          utils.handleErrors(err, dispatch);
        });
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch);
    });
};

export const setTrendingCoursesToRender = (tr_crs_to_render) => (dispatch) => {
  var action_obj = {};
  action_obj.type = "SET_TRENDING_COURSES_TO_RENDER";
  action_obj.data = tr_crs_to_render;
  dispatch(action_obj);
};

export const setRunningCoursesToRender = (rn_crs_to_render) => (dispatch) => {
  var action_obj = {};
  action_obj.type = "SET_RUNNING_COURSES_TO_RENDER";
  action_obj.data = rn_crs_to_render;
  dispatch(action_obj);
};

export const setFinanceCoursesToRender = (fn_crs_to_render) => (dispatch) => {
  var action_obj = {};
  action_obj.type = "SET_FINANCE_COURSES_TO_RENDER";
  action_obj.data = fn_crs_to_render;
  dispatch(action_obj);
};

export const setTechnologyCoursesToRender =
  (tn_crs_to_render) => (dispatch) => {
    var action_obj = {};
    action_obj.type = "SET_TECHNOLOGY_COURSES_TO_RENDER";
    action_obj.data = tn_crs_to_render;
    dispatch(action_obj);
  };

export const setAnalyticsCoursesToRender = (an_crs_to_render) => (dispatch) => {
  var action_obj = {};
  action_obj.type = "SET_ANALYTICS_COURSES_TO_RENDER";
  action_obj.data = an_crs_to_render;
  dispatch(action_obj);
};

export const setMarketingCoursesToRender = (mn_crs_to_render) => (dispatch) => {
  var action_obj = {};
  action_obj.type = "SET_MARKETING_COURSES_TO_RENDER";
  action_obj.data = mn_crs_to_render;
  dispatch(action_obj);
};

export const setFreeCoursesToRender = (fr_crs_to_render) => (dispatch) => {
  var action_obj = {};
  action_obj.type = "SET_FREE_COURSES_TO_RENDER";
  action_obj.data = fr_crs_to_render;
  dispatch(action_obj);
};

export const setTrendingGroupsToRender = (tr_grp_to_render) => (dispatch) => {
  var action_obj = {};
  action_obj.type = "SET_TRENDING_GROUPS_TO_RENDER";
  action_obj.data = tr_grp_to_render;
  dispatch(action_obj);
};

export const setFeaturedGroupsToRender = (ft_grp_to_render) => (dispatch) => {
  var action_obj = {};
  action_obj.type = "SET_FEATURED_GROUPS_TO_RENDER";
  action_obj.data = ft_grp_to_render;
  dispatch(action_obj);
};
