import { connect } from "react-redux";
import React from "react";
import { Redirect, withRouter } from "react-router";
import { Modal } from "reactstrap";
import {
  toggleJoinGroupModal,
  cancelJoining,
  redirectToGroupPageWithoutJoining,
  redirectToGroupPage,
  redirectToCourseDashboard,
} from "../../actions/joinGroupAction.js";
import { disableGroupDetailRedirection } from "../../actions/groupAction.js";
import group_default from "../../images/groups-01-01.svg";
import { KEY_CODES, URLS, TABS, CONFIGS } from "../../constants/constants";
import queryString from "query-string";

class JoinGroupModal extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.addEventListener("keydown", this.keydownHandler);
  }
  componentDidUpdate() {}

  cancel = () => {
    this.props.cancelJoining();
  };
  keydownHandler = (e) => {
    if (e.keyCode == KEY_CODES.ESC_KEY && this.props.toggle_j_grp_modal) {
      this.props.cancelJoining();
    }
  };
  joinOrViewGroup = () => {
    this.props.cancelJoining();
    console.log("this.props.current_tab", this.props.current_tab);
    if (this.props.current_tab == TABS.MY_COURSES) {
      console.log(
        "\n --- Calling redirectToCourseDashboard from JoinGroupModal --- \n"
      );
      this.props.redirectToCourseDashboard(
        this.props.g_code,
        this.props.user_groups,
        this.props.g_id,
        this.props.selected_crs_id,
        this.props.selected_crs_is_adpt
      );
      // var win = window.open(url, '_blank');
      // win.focus();
    }
    // {
    // 	alert(this.props.selected_crs_id);
    // 	alert(this.props.g_id);
    // }
    else {
      if (this.props.is_member) {
        this.props.redirectToGroupPageWithoutJoining(
          this.props.g_id,
          this.props.user_groups
        );
      } else {
        this.props.redirectToGroupPage(
          this.props.g_code,
          this.props.user_groups
        );
      }
    }
  };

  redirectToGrpDetailPage = () => {
    if (this.props.gd_url && this.props.gd_url.gid && this.props.gd_url.path) {
      this.props.history.push({
        pathname: this.props.gd_url.path,
        search: queryString.stringify(
          Object.assign({}, queryString.parse(this.props.location.search), {
            gid: this.props.gd_url.gid,
          })
        ),
      });
    }
  };

  render() {
    if (this.props.gd_url) {
      this.props.disableGroupDetailRedirection();
      this.redirectToGrpDetailPage();
      // return <Redirect to={ this.props.join_grp_url } />
    }
    return (
      <div>
        <Modal
          isOpen={this.props.toggle_j_grp_modal}
          className={this.props.className}
          centered={true}
        >
          <div className="jg-modal">
            <p className="jg-modal-header">
              {this.props.is_member
                ? "You are already joined in this group!"
                : "Are you sure you want to join this group?"}
            </p>
            <div className="info-row">
              <div className="grp-img-container">
                <img
                  className="grp-img"
                  src={
                    this.props.g_pic_url
                      ? this.props.g_pic_url
                      : "https://cdn.pegasus.imarticus.org/index/group_default.svg"
                  }
                ></img>
              </div>
              <div className="grp-info-container">
                <div className="grp-name">{this.props.g_name}</div>
                <div className="grp-member">{this.props.g_members} Members</div>
              </div>
            </div>
            <hr></hr>
            <div className="btn-row">
              <button className="cancel-btn" onClick={this.cancel}>
                Cancel
              </button>
              <button className="join-btn" onClick={this.joinOrViewGroup}>
                {this.props.is_member ? "View Group" : "Join Group"}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  g_name: state.j_grp.g_name,
  g_members: state.j_grp.g_members,
  g_pic_url: state.j_grp.g_pic_url,
  toggle_j_grp_modal: state.j_grp.toggle_j_grp_modal,
  is_member: state.j_grp.is_member,
  g_id: state.j_grp.g_id,
  g_code: state.j_grp.g_code,
  user_groups: state.j_grp.groups,
  gd_url: state.j_grp.gd_url,
  current_tab: state.utils.current_tab,
  selected_crs_id: state.j_grp.selected_crs_id,
  selected_crs_is_adpt: state.j_grp.selected_crs_is_adpt,
});

const mapDispatchToProps = {
  toggleJoinGroupModal,
  cancelJoining,
  redirectToGroupPage,
  redirectToCourseDashboard,
  disableGroupDetailRedirection,
  redirectToGroupPageWithoutJoining,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JoinGroupModal)
);
