export const FRONTEND_ERRORS = {
  F0001: "Please choose a profile",
  F0002: "Please enter a profile name",
  F0003: "Please enter a valid group code",
  F0004: "Please enter a valid group name",
  F0005: "There is issue in your network",
  F0006: "Group name can't be empty",
  F0007: "Please enter child name",
};

export const BACKEND_ERRORS = {
  404: "Page not Found",
  900: "Group Code is incorrect",
};

export const BACKEND_ERROR_CODES = {
  INVALID_PARAMS: 100,
  BAD_REQUEST: 400,
  PAGE_NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  DUPLICATE_PROFILE: 600,
  GROUP_NOT_FOUND: 900,
  AUTHENTICATION_ERROR: 1200,
  PROFILE_GROUPS_EXIST: 2500,
  ONLY_ONE_PROFILE: 2700,
  ACCESS_TOKEN_EXPIRED: 4100,
  COURSE_ACCESS_TOKEN_EXPIRED: 4101,
  NO_ACCESS_TOKEN: 4200,
  ACCOUNT_NOT_FOUND: 5700,
  GROUP_MEMBERS_ALIVE: 6700,
  NOT_A_GROUP_MEMBER: 9800,
  USER_ALREADY_SUBSCRIBED: 11000,
  COUPON_CODE_EXPIRED: 13000,
  PAYMENT_TRNX_SKIP: 14000,
  NO_GROUP_PLUGINS: 26000,
  TOO_MANY_REQUEST: 6100,
};

export const AWS_ERROR_CODES = {
  ENTITY_TOO_LARGE: "EntityTooLarge",
  ENTITY_TOO_SMALL: "EntityTooSmall",
};

export const ERROR_MESSAGES = {
  NETWORK_ERROR: "Network Error",
};

export const ERROR_BLOCK = {
  PROFILE_PIC_UPLOAD: 100,
  DELETE_PPIC: 101,
  DELETE_PROFILE: 102,
  DELETE_GPIC: 103,
  DELETE_GROUP: 104,
  GROUP_PIC_UPLOAD: 105,
  INITIALIZE_TRAN: 106,
  APPLY_COUPON: 107,
  RAZORPAY_TRAN: 108,
  DUPLICATE_PROFILE: 600,
};

//the frontend errors are those that are defined explicity for frontend only they always starts from F for clarity
//the backend errors comes from the apis.
