import axios from "axios";
import { ROUTES } from "../constants/routes";
import { utils } from "../utility/utils";
import { CONFIGS } from "../constants/constants";
import Cookies from "universal-cookie";
let cookies = new Cookies();
export const showMenu = (somevar) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: "SHOW_MENU",
      show_menu: somevar,
    });
    resolve();
  });
};

export const logoutUser = (utag) => (dispatch) => {
  var data = {
    headers: {
      "Content-Type": "application/json",
    },
    a_id: utils.getAid(),
    d_id: window.fingerprint.md5hash,
    at: utils.getAt(),
    cl: "W",
    rt: utils.getRefreshToken(),
    endpointId: cookies.get("endpointId"),
    // k_ey	: utils.getSecret()
  };
  axios
    .post(ROUTES.LOGOUT, data)
    .then((res) => {
      // utils.removeAuthCookies();

      var home_page_url =
        CONFIGS.WEB_DASHBOARD_BASE_URL + (utag ? `${utag}/login` : "login");
      window.location.href = home_page_url;
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch);
    });
};
