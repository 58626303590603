export function locationOptionValue(location) {
  if (location == undefined) {
    return "";
  }
  console.log({ location: location.toLocaleLowerCase() });

  //returns option values from freshdesk
  switch (location.toLocaleLowerCase()) {
    case "ahmedabad":
      return 88000062924;
    case "bangalore":
      return 88000053467;
    case "chennai":
      return 88000053466;
    case "coimbatore":
      return 33000064351;
    case "fsb":
      return 33000213003;
    case "isa":
      return 33000213004;
    case "jaipur":
      return 88000053463;
    case "lucknow":
      return 88000053462;
    case "mumbai":
      return 88000053459;
    case "ncr":
      return 33000208740;
    case "online":
      return 33000064309;
    case "pune":
      return 88000053460;
    case "thane":
      return 33000207449;
    case "hyderabad":
      return 88000053465;
    case "certificate preparation":
      return 88000111718;
    case "b2b":
      return 88000053469;
    case "b2b":
      return 88000112481;
    case "delhi":
      return 88000053464;
    case "reg-onl":
      return 88000062922;
    case "thane":
      return 88000053461;
    case "dummy":
      return 88000150367;
    default:
      return 0;
  }
}

export const showFreshDesk = (courses, contact, name, email) => {
  console.log("courses in freashdesk", courses);
  var coursename = "";
  var batchname = "";
  var locationname = "";
  if (courses && courses.length > 0) {
    coursename = courses.map((crs) => crs.name).join(", ");
    batchname = courses.map((crs) => crs.btch_name).join(", ");
    locationname = courses.map((crs) => crs.loc_name).join(", ");
  }
  let mobileno = contact ? contact : "N/A";
  let description =
    "[Please Enter Your Query Here]\n" +
    "-".repeat(30) +
    "\nCourse Name -\n" +
    coursename +
    "\nBatch Name -\n" +
    batchname +
    "\nMobile No -\n" +
    mobileno +
    "\n\t" +
    "-".repeat(30) +
    "\n";

  var courseLocationId = "";

  window.FreshworksWidget?.("open");
  let custom_fields = {
    cf_registered_mobile_number: contact ? contact : "",
  };
  console.log("locationname", locationname);
  if (courses && courses.length == 1) {
    custom_fields["cf_course"] = coursename;
    custom_fields["cf_batch_number"] = batchname;

    courseLocationId = locationOptionValue(locationname);
  }
  console.log("courseLocationId", courseLocationId);
  window.FreshworksWidget?.("identify", "ticketForm", {
    name: name,
    email: email ? email : undefined,
    group_id: courseLocationId,
    description: description,
    custom_fields: custom_fields,
  });
};
