import { combineReducers } from "redux";

import utils from "./utilReducer";
import j_grp from "./joinGroupReducer";
import ac_dd from "./accountDropdownReducer";
import profile from "./profileReducer";
import password from "./passwordReducers";
import primaryEmail from "./primaryEmailReducers";
import plugin from "./pluginReducer";
import course from "./courseReducer";
import explore from "./exploreReducer";
import mobile from "./mobileReducers";
import brand from "./brandReducer";

export default combineReducers({
  utils,
  j_grp,
  ac_dd,
  profile,
  password,
  primaryEmail,
  course,
  explore,
  brand,
  plugin,
  mobile,
});
