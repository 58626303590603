import React, { Component } from "react";
import Footer from "../footers/Footer";
import AdminCreateGroupSwitch from "../switches/AdminCreateGroupSwitch";
import PublicVisibilitySwitch from "../switches/PublicVisiblitySwitch";

class CreateGroupForm extends Component {
  render() {
    return (
      <div className="dashboard-content-wrapper">
        <div className="container-fluid dashboard-content">
          {/* <div className="row tab-row"> */}
          <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <p className="content-header">Create Group</p>
          </div>
          {/* </div> */}
          <form>
            <div className=" profile-setting-container-row">
              <div className="cgrp-form-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                  <div className="cgf-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <p className="cgf-heading">Create Group</p>
                    <div className="img-container">
                      <img
                        className="profile-img"
                        alt=""
                        src="https://sample-videos.com/img/Sample-jpg-image-500kb.jpg"
                      ></img>
                      <div className="edit-img-container">
                        <div className="edit-img">
                          <i
                            className="pencil-icon fa fa-pencil"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="pf-content-container">
                      <p className="pf-name">Add Group Description</p>
                      <textarea className="ps-inp-ta" type="text"></textarea>
                      <div className="switch-container">
                        <p className="switch-label">Only Admins can post</p>
                        <span className="admin-switch">
                          <AdminCreateGroupSwitch />
                        </span>
                      </div>
                      <div className="des-container">
                        <p className="switch-description">
                          Only admins have the authority to post on group.
                        </p>
                      </div>

                      <div className="switch-container">
                        <p className="switch-label">Public Visibililty</p>
                        <span className="admin-switch">
                          <PublicVisibilitySwitch />
                        </span>
                      </div>
                      <div className="des-container">
                        <p className="switch-description">
                          Only admins have the authority to post on group.
                        </p>
                      </div>

                      <input
                        className="ps-update-btn"
                        type="submit"
                        value="Update"
                      ></input>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                </div>
              </div>
            </div>
            <input type="file" className="hide" id="edit-file"></input>
          </form>
        </div>

        <Footer />
      </div>
    );
  }
}

export default CreateGroupForm;
