import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
const cookies = new Cookies();

export const getCourses = (pid, gid) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.GET_COURSES, {
        p_id: pid,
        g_id: gid,
        tokn: cookies.get("at"),
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};
