import { connect } from 'react-redux';
import React from 'react';
import { withRouter } from "react-router";
import { Modal } from 'reactstrap';
import  group_default  from '../../images/groups-01-01.svg';
import { leaveGroupBackend } from '../../actions/groupAction';
import {KEY_CODES} from '../../constants/constants';

class LeaveGroupModal extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate() {
		
	}
	componentDidMount(){
		document.addEventListener('keydown',this.keydownHandler);
    }

	cancel = () => {
		this.props.toggleLeaveGroupModal();
	}

    toggleLeaveGroupModal = () => {
        this.setState({
          is_open : !this.state.is_open
        })
      }
    leaveGroup = () => {
        this.props.toggleLeaveGroupModal();
        this.props.leaveGroupBackend(this.props.gid,this.props.groups);
	}
	keydownHandler = (e) => {
		if(e.keyCode == KEY_CODES.ESC_KEY && this.props.is_open){
			this.props.toggleLeaveGroupModal();
		}
	}
    

	render() {
		return (
			<div>
				<Modal isOpen={this.props.is_open} className="lg-modal-container" centered={true}>
					<div className="lg-modal">
                        <div className="top-section">
                            <div className="heading">
                                Leave Group
                            </div>
                            <p className="msg">Are you sure you want to leave the group?</p>
                        </div>
                        <div className="bottom-section">
                            <div className="btn-y" onClick={this.leaveGroup}>Yes</div>
                            <div className="btn-n" onClick={this.props.toggleLeaveGroupModal}>No</div>
                        </div>
					</div>

				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => (
	{
		gid : state.j_grp.gd_id,
		groups : state.j_grp.groups,
	})


const mapDispatchToProps = {
    leaveGroupBackend,
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(LeaveGroupModal));
