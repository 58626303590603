import { connect } from "react-redux";
import React, { Component } from "react";
import parent from "../../images/parent.svg";
import student from "../../images/student.svg";
import teacher from "../../images/teacher.svg";
import parent_a from "../../images/parent-active.svg";
import student_a from "../../images/student-active.svg";
import teacher_a from "../../images/teacher-active.svg";
import { ROLES, PAGE_HEADINGS } from "../../constants/constants";
import { FRONTEND_ERRORS as f_err } from "../../constants/errors";
import Footer from "../footers/Footer";
import { setRole, setPageHeading } from "../../actions/utilAction";
import err_chat from "../../images/err-chat.svg";
import { getProfileInfo, createUserProfile } from "../../actions/profileAction";
import {
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";
import success from "../../images/success.svg";

class AddProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_incomplete: false,
      error_msg: "",
      error_code: "",
      p_name: "",
      ch_name: "",
    };
  }

  componentDidMount() {
    this.props.setPageHeading(PAGE_HEADINGS.PROFILE_SETTINGS);
    this.props.setRole(0);
  }

  handleEnterKey = (e) => {
    var username = document.getElementById("username").value.trim();
    var childname = document.getElementById("childname").value.trim();
    if (username && username.length) {
      this.setState({
        form_incomplete: false,
        error_code: "",
        error_msg: "",
      });
    }
    if (childname && childname.length) {
      this.setState({
        form_incomplete_child: false,
        error_code_child: "",
        error_msg_child: "",
      });
    }
    if (e.key === "Enter") {
      this.addProfile();
    }
  };

  addProfile = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (this.validateForm()) {
      //code to add the profile
      var username = this.state.p_name.trim();
      if (this.props.role == ROLES.PARENT) {
        var childname = this.state.ch_name;
        this.props.createUserProfile(
          username,
          this.props.role,
          childname,
          this.props.getProfileInfo
        );
      } else {
        this.props.createUserProfile(
          username,
          this.props.role,
          "",
          this.props.getProfileInfo
        );
      }
    }
  };

  validateProfileName = (name) => {
    var special_char_format = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]/;
    if (name && !isNaN(name)) {
      this.displayPopover(
        POPOVER_HEADINGS.NAME_START_WITH_NUM,
        POPOVER_CONTENT.NAME_START_WITH_NUM,
        POPOVER_TYPE.ERROR
      );
      return false;
    } else if (name.includes(" .") || name.includes("..") || name == ".") {
      this.displayPopover(
        POPOVER_HEADINGS.INVALID_NAME,
        POPOVER_CONTENT.INVALID_NAME,
        POPOVER_TYPE.ERROR
      );
      return false;
    } else if (name.length > 25) {
      this.displayPopover(
        POPOVER_HEADINGS.P_NAME_LENGTH_EXCEEDED,
        POPOVER_CONTENT.P_NAME_LENGTH_EXCEEDED,
        POPOVER_TYPE.ERROR
      );
      return false;
    } else if (special_char_format.test(name)) {
      // alert("special character")
      this.displayPopover(
        POPOVER_HEADINGS.SPECIAL_CHARACTER,
        POPOVER_CONTENT.SPECIAL_CHARACTER,
        POPOVER_TYPE.ERROR
      );
      return false;
    }
    return true;
  };

  validateChildName = (name) => {
    var special_char_format = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]/;
    if (name && !isNaN(name)) {
      this.displayPopover(
        POPOVER_HEADINGS.NAME_START_WITH_NUM,
        POPOVER_CONTENT.NAME_START_WITH_NUM,
        POPOVER_TYPE.ERROR
      );
      return false;
    } else if (name.includes(" .") || name.includes("..") || name == ".") {
      this.displayPopover(
        POPOVER_HEADINGS.INVALID_NAME,
        POPOVER_CONTENT.INVALID_NAME,
        POPOVER_TYPE.ERROR
      );
      return false;
    } else if (name.length > 25) {
      this.displayPopover(
        POPOVER_HEADINGS.CH_NAME_LENGTH_EXCEEDED,
        POPOVER_CONTENT.CH_NAME_LENGTH_EXCEEDED,
        POPOVER_TYPE.ERROR
      );
      return false;
    } else if (special_char_format.test(name)) {
      // alert("special character")
      this.displayPopover(
        POPOVER_HEADINGS.SPECIAL_CHARACTER,
        POPOVER_CONTENT.SPECIAL_CHARACTER,
        POPOVER_TYPE.ERROR
      );
      return false;
    }
    return true;
  };

  validateForm = () => {
    var username = document.getElementById("username").value.trim();
    this.setState({ username: username ? username : "" });
    if (!this.props.role) {
      this.setState({
        form_incomplete: true,
        error_code: "F0001",
        error_msg: f_err["F0001"],
      });
      return false;
    } else if (this.props.role == ROLES.PARENT) {
      var childname = document.getElementById("childname").value.trim();
      if (
        (!childname || !childname.length) &&
        (!username || !username.length)
      ) {
        this.setState({
          form_incomplete_child: true,
          error_code_child: "F0007",
          error_msg_child: f_err["F0007"],
          form_incomplete: true,
          error_code: "F0002",
          error_msg: f_err["F0002"],
        });
        return false;
      } else if (!username || !username.length) {
        this.setState({
          form_incomplete: true,
          error_code: "F0002",
          error_msg: f_err["F0002"],
        });
      } else if (!childname || !childname.length) {
        this.setState({
          form_incomplete_child: true,
          error_code_child: "F0007",
          error_msg_child: f_err["F0007"],
        });
      } else {
        this.setState({ childname: childname ? childname : "" });
        this.setState({ username: username ? username : "" });
      }
    } else if (!username || !username.length) {
      this.setState({
        form_incomplete: true,
        error_code: "F0002",
        error_msg: f_err["F0002"],
      });
      return false;
    } else {
      this.setState({
        form_incomplete: false,
        error_code: "",
        error_msg: "",
      });
    }
    return true;
  };

  setParent = (role) => {
    // alert('hey')
    this.setState({
      form_incomplete_child: false,
      error_code_child: "",
      error_msg_child: "",
      form_incomplete: false,
      error_code: "",
      error_msg: "",
    });
    if (role == ROLES.PARENT) {
      document.getElementById("childname").style.display = "block";
      document.getElementById("cname").style.display = "block";
    } else {
      document.getElementById("childname").style.display = "none";
      document.getElementById("cname").style.display = "none";
    }
    this.props.setRole(role);
  };

  setProfileName = (e) => {
    if (this.validateProfileName(e.target.value)) {
      this.setState({
        p_name: e.target.value,
      });
    }
  };

  setChildName = (e) => {
    if (this.validateChildName(e.target.value)) {
      this.setState({
        ch_name: e.target.value,
      });
    }
  };

  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };

  render() {
    return (
      <div className="group-db-content-wrapper">
        <div className="container-fluid dashboard-content">
          {/* <div className="row"> */}
          {/* <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <p className="content-header">Add Profile</p>
                        </div> */}
          {/* </div> */}
          <form>
            <div className=" profile-setting-container-row">
              <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                  <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <p className="edit-profile-heading">Add Profile</p>
                    <div className="row role-img-container">
                      <div
                        className="col-4 role-img-col"
                        onClick={() => this.setParent(ROLES.STUDENT)}
                      >
                        <div className="imgs-wrapper">
                          <div className="imgs-container">
                            <img
                              className={
                                this.props.role == ROLES.STUDENT
                                  ? "img-active"
                                  : "hide"
                              }
                              src={success}
                            ></img>
                            <img
                              className={
                                "role-img " +
                                (this.props.role == ROLES.STUDENT
                                  ? "active"
                                  : "")
                              }
                              src={
                                this.props.role == ROLES.STUDENT
                                  ? student_a
                                  : student
                              }
                            />
                          </div>
                        </div>
                        <p className="role-name">Student </p>
                      </div>
                      <div
                        className="col-4 role-img-col"
                        onClick={() => this.setParent(ROLES.TEACHER)}
                      >
                        <div className="imgs-wrapper">
                          <div className="imgs-container">
                            <img
                              className={
                                this.props.role == ROLES.TEACHER
                                  ? "img-active"
                                  : "hide"
                              }
                              src={success}
                            ></img>
                            <img
                              className={
                                "role-img " +
                                (this.props.role == ROLES.TEACHER
                                  ? "active"
                                  : "")
                              }
                              src={
                                this.props.role == ROLES.TEACHER
                                  ? teacher_a
                                  : teacher
                              }
                            />
                          </div>
                        </div>
                        <p className="role-name">Teacher</p>
                      </div>
                      <div
                        className="col-4 role-img-col"
                        onClick={() => this.setParent(ROLES.PARENT)}
                      >
                        <div className="imgs-wrapper">
                          <div className="imgs-container">
                            <img
                              className={
                                this.props.role == ROLES.PARENT
                                  ? "img-active"
                                  : "hide"
                              }
                              src={success}
                            ></img>
                            <img
                              className={
                                "role-img " +
                                (this.props.role == ROLES.PARENT
                                  ? "active"
                                  : "")
                              }
                              src={
                                this.props.role == ROLES.PARENT
                                  ? parent_a
                                  : parent
                              }
                            />
                          </div>
                        </div>
                        <p className="role-name">Parent</p>
                      </div>
                    </div>
                    <div className="ap-content-container">
                      <p className="pf-name">Enter Name</p>
                      <div className="ps-inp-container">
                        <input
                          className={
                            "ps-inp " +
                            (this.state.error_code == "F0002" ? "err-inp" : "")
                          }
                          type="text"
                          id="username"
                          onKeyPress={this.handleEnterKey}
                          value={this.state.p_name}
                          onChange={(e) => this.setProfileName(e)}
                        ></input>
                        <p
                          className={
                            "add-profile-err " +
                            (this.state.form_incomplete ? "" : "hide")
                          }
                        >
                          <img className="err-icon" src={err_chat}></img>
                          <span className="add-profile-err-msg">
                            {this.state.error_msg}
                          </span>
                        </p>
                        <p
                          className="pf-name"
                          id="cname"
                          style={{ display: "none" }}
                        >
                          Enter Child Name
                        </p>
                        <input
                          className={
                            "ps-inp " +
                            (this.state.error_code_child == "F0007"
                              ? "err-inp"
                              : "")
                          }
                          type="text"
                          id="childname"
                          onKeyPress={this.handleEnterKey}
                          style={{ display: "none" }}
                          value={this.state.ch_name}
                          onChange={(e) => this.setChildName(e)}
                        ></input>
                        <p
                          className={
                            "add-profile-err " +
                            (this.state.form_incomplete_child ? "" : "hide")
                          }
                        >
                          <img className="err-icon" src={err_chat}></img>
                          <span className="add-profile-err-msg">
                            {this.state.error_msg_child}
                          </span>
                        </p>
                        <input
                          className="ps-update-btn"
                          type="submit"
                          onClick={this.addProfile}
                          onKeyPress={this.handleEnterKey}
                          value="Add Profile"
                        ></input>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                </div>
              </div>
            </div>
            {/* <input type="file" className="hide" id="edit-file"></input> */}
          </form>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  role: state.utils.role,
});

const mapDispatchToProps = {
  setRole,
  createUserProfile,
  getProfileInfo,
  setPageHeading,
  showPopover,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProfile);
