import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import blankProfile from "./img/blank_profile.png";
import uploadIcon from "./img/uploadIcon.svg";
import arrowLeft from "./img/left-arrow.png";
import arrowRight from "./img/arrow-right.png";

export default function UploadDoc({
  imagesState,
  togglePreviewImages,
  currentImageIndex,
  openImage,
  toggleImagePreview,
}) {
  const [currIndex, setCurrentIndex] = useState(currentImageIndex);
  const previousImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imagesState.length - 1 : prevIndex - 1
    );
  };
  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === imagesState.length - 1 ? 0 : prevIndex + 1
    );
  };
  console.log(
    "In preview image: ",
    imagesState,
    currentImageIndex,
    imagesState[currIndex]
  );
  return (
    <Modal
      isOpen={togglePreviewImages}
      toggle={toggleImagePreview}
      size="xl"
      className="custom-modal"
      style={{ maxWidth: "736px" }}
    >
      {/* <ModalHeader style={{borderBottom: "none"}} toggle={toggleImagePreview}></ModalHeader> */}
      <ModalBody
        className="custom-modal-body"
        style={{ maxHeight: "700px", maxWidth: "734px" }}
      >
        {/* <div
          className="row w-100 align-items-center text-center"
          style={{ margin: "0px" }}
        >
          <div className="col-1">
            <img
              src={arrowLeft}
              className={`arrowIcon leftArrow ${
                currIndex === 0 ? "disabled" : ""
              }`}
              onClick={previousImage}
              disabled={currIndex === 0}
            />
          </div>
          <div className="col-10 align-self-center">
            <img
              src={imagesState[currIndex].media_url}
              alt={`Image ${currIndex + 1}`}
              style={{ maxHeight: "700px" }}
            />
          </div>
          <div className="col-1">
            <img
              src={arrowRight}
              className={`arrowIcon rightArrow ${
                currIndex === imagesState.length - 1 ? "disabled" : ""
              }`}
              onClick={nextImage}
              disabled={currIndex === imagesState.length - 1}
            />
          </div>
        </div> */}
        <div class="image-preview" style={{}}>
          <img
            onClick={previousImage}
            disabled={currIndex === 0}
            class="scroll-button left"
            src={arrowLeft}
          />
          <div class="image-container">
            <img
              style={{ maxHeight: "650px", margin: "auto" }}
              src={imagesState[currIndex].media_url}
              alt="Image Preview"
            />
          </div>
          {/* <button class="scroll-button right">&gt;</button> */}
          <img
            onClick={nextImage}
            disabled={currIndex === imagesState.length - 1}
            class="scroll-button right"
            src={arrowRight}
          />
        </div>
      </ModalBody>
    </Modal>
  );
}
