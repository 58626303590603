import React, { useState } from "react";
import { Button, Tooltip } from "reactstrap";

const SkillTagTooltipItem = (props) => {
  const { batch, id, text } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  console.log("inside skill tag tooltip");
  return (
    <span>
      <span id={"Tooltip-" + id} style={{ background: "white" }}>
        <Tooltip isOpen={tooltipOpen} target={"Tooltip-" + id} toggle={toggle}>
          {batch}
        </Tooltip>
        {/* <img
          // src="..."
          // alt="info"
        /> */}
        {text + ".."}
      </span>
    </span>
  );
};

export default SkillTagTooltipItem;
