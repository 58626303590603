import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import Footer from "../footers/Footer";
import { Link } from "react-router-dom";
import explore from "../../images/explore.svg";
import create_grp from "../../images/home-create-grp.svg";
import join_grp from "../../images/home-join-grp.svg";
import home_background from "../../images/home.svg";
import CardHeading from "../utils/CardHeading";
import CourseAndGroup from "./CourseAndGroup";
import {
  setPageHeading,
  resetLoadingData,
  setLoadingData,
} from "../../actions/utilAction";
import { getProfileInfo } from "../../actions/profileAction";
import { fetchMyCourses } from "../../actions/courseAction";
import MyCourse from "../homecontents/MyCourse";
import { PAGE_HEADINGS, SIDEBAR_WIDTH } from "../../constants/constants";
import MyGroup from "../homecontents/MyGroup";
import LoadingModal from "../modals/LoadingModal";
import Loader from "../utils/Loader";
import EmptyState from "./EmptyState";
import cookie from "react-cookies";
import Cookies from "universal-cookie";
import { logoutUser } from "../../actions/accountDropdownAction";

const cookies = new Cookies();

let Home = (props) => {
  const [is_crs_grp, setIs_crs_grp] = useState(true);

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--sidebar-width",
      SIDEBAR_WIDTH.HOME_SIDEBAR
    );
  }, []);

  // componentDidUpdate(prev_props) {
  //   if (
  //     prev_props.courses != this.props.courses ||
  //     prev_props.groups != this.props.groups
  //   ) {
  //     if (
  //       ((this.props.courses && this.props.courses.length == 0) ||
  //         !this.props.courses) &&
  //       ((this.props.groups && !this.props.groups.length) || !this.props.groups)
  //     ) {
  //       this.props.setPageHeading(PAGE_HEADINGS.HOME);
  //     } else if (
  //       prev_props.groups != this.props.groups ||
  //       prev_props.courses != this.props.courses
  //     ) {
  //       // alert("pp");
  //       // this.setState({loading: true})
  //       // this.props.setLoadingData();
  //       // setTimeout(
  //       //     function() {
  //       //         // this.setState({loading: false});
  //       //         this.props.resetLoadingData();
  //       //     }
  //       //     .bind(this),
  //       //     1000
  //       // );
  //     }

  //     if (this.props.courses && this.props.courses.length > 0) {
  //       this.props.setPageHeading(PAGE_HEADINGS.MY_COURSE);
  //     } else if (
  //       this.props.courses &&
  //       this.props.courses.length == 0 &&
  //       this.props.user_j_groups &&
  //       this.props.user_j_groups.length > 0
  //     ) {
  //       this.props.setPageHeading(PAGE_HEADINGS.MY_GROUP);
  //     }
  //   }

  //   //     if(prev_props.courses != this.props.courses || prev_props.groups != this.props.groups){
  //   //         this.setState({
  //   //             loading: true
  //   //         })
  //   //         setTimeout(
  //   //             function() {
  //   //                 this.setState({loading: false});
  //   //             }
  //   //             .bind(this),
  //   //             1000
  //   //         );
  //   //     }
  // }
  console.log({ isDataLoading: props.is_data_loading, crs: props.courses });

  if (!cookies.get("isLoggedIn")) {
    logoutUser();
    window.location.href = "/login";
  }
  return (
    <React.Fragment>
      {/* {console.log(
        "====== ",
        !props.is_data_loading && props.courses && props.courses.length > 0
          ? "true"
          : "false",
        " ======",
        !props.is_data_loading &&
          props.courses &&
          props.courses.length == 0 &&
          props.user_j_groups &&
          props.user_j_groups.length > 0
          ? "true"
          : "false",
        !props.is_data_loading ? "true" : "false"
      )} */}
      {!props.is_data_loading &&
      ((props.courses && props.courses.length > 0) ||
        (props.pendingInstallments && props.pendingInstallments.length > 0)) ? (
        <MyCourse />
      ) : !props.is_data_loading &&
        props.courses &&
        props.courses.length == 0 &&
        props.user_j_groups &&
        props.user_j_groups.length > 0 ? (
        <MyGroup />
      ) : !props.is_data_loading ? (
        <EmptyState loading={props.is_data_loading} />
      ) : (
        <Loader />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  pendingInstallments: state.course.pendingInstallments,
});

const mapDispatchToProps = {
  getProfileInfo,
  fetchMyCourses,
  setPageHeading,
  resetLoadingData,
  setLoadingData,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
