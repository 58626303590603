import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import Cookies from "universal-cookie";
import PDFReader from "../PDFReader/PDFReader";
import "./verifycertificate.scss";
import { apllycert } from "../../actions/certificateAction";
import { verifyCertificate } from "../../actions/verifyCertificateAction";
import Loader from "../utils/Loader";

const cookies = new Cookies();

const VerifyCertificate = () => {
  let query = window.location.href;
  query = query.split("/");
  const certId = query[4];
  const monthsSmall = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [certyCode, setCertyCode] = useState(certId);
  const [invalidSerial, setInvalidSerial] = useState(null);
  const [certUsername, setCertUsername] = useState();
  const [certName, setCertName] = useState();
  const [certIssueDate, setCertIssueDate] = useState("");
  const [isVerifiedCerty, setIsVerifiedCerty] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      window.FreshworksWidget?.("hide", "launcher");
      if (certId && /^EK[A-Z0-9]{12}$/.test(certId)) {
        let verifyCertificateData = await verifyCertificate(certId);
        if (verifyCertificateData.success) {
          if (verifyCertificateData.data.certionfo) {
            let certiData = verifyCertificateData.data.certionfo;
            setCertUsername(certiData.username);
            setCertName(certiData.crtnm);
            var _date = null;
            if (
              certiData.issueDate &&
              typeof certiData.issueDate === "string" &&
              certiData.issueDate.length === 0
            ) {
              setCertIssueDate(certiData.createdAt);
            } else {
              if (certiData.createdAt) {
                _date = new Date(certiData.createdAt);
              }
              if (certiData.updatedAt) {
                _date = new Date(certiData.updatedAt);
              }
              if (_date) {
                var _y = _date.getFullYear();
                var _d = _date.getDate();
                var _m = _date.getMonth() + 1;
                setCertIssueDate(_d + " " + monthsSmall[_m - 1] + " " + _y);
              }
            }
            setIsVerifiedCerty(true);
          } else {
            setIsVerifiedCerty(false);
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } else {
        setCertyCode(certId);
        setIsLoading(false);
        setInvalidSerial(true);
      }
    })();
  }, []);

  return !isLoading ? (
    <div className="verifyCertiScss">
      <div
        className="courses-page"
        style={{ height: "fit-content", paddingBottom: "260px" }}
      >
        <div id="content">
          <nav
            className="header-nav navbar navbar-expand-lg fixed-top navbar-light bg-light"
            style={{ height: "80px" }}
          >
            <div
              className="container"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="navbar-header"></div>
              <a
                className="navbar-brand"
                target="_top"
                href="https://pegasus.imarticus.org"
              >
                <img
                  src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Imarticus_Logo_New_Green.svg"
                  alt="logo"
                />
              </a>
            </div>
          </nav>
          <div className={isLoading ? "loading" : "hidden"}></div>
          {!isLoading && (
            <div
              className="container"
              style={{
                marginTop: "170px",
                display: "flex ",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="container">
                <div className="row certificate-row" style={{ margin: "auto" }}>
                  <div
                    className="col-12"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      padding: "0 ",
                    }}
                  >
                    <div className="var-container">
                      <div
                        className="user-certy-main-header-container"
                        style={{ backgroundColor: "#035642 " }}
                      >
                        {certyCode}
                      </div>
                    </div>
                    <div className="content-container">
                      {isVerifiedCerty && (
                        <div className="display-images">
                          <div className="side-image">
                            <img src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Verified_Left.png" />
                          </div>
                          <div className="dashed-border-short"></div>
                          <img src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Verified_Middle.png" />
                          <div className="dashed-border-short"></div>
                          <div className="side-image">
                            <img src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Verified_Right.png" />
                          </div>
                        </div>
                      )}
                      {!isVerifiedCerty && (
                        <div className="display-images">
                          <div className="side-image">
                            <img src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Not_Verified_Left.png" />
                          </div>
                          <div className="dashed-border-short"></div>
                          <img src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Not_Verified_Middle.png" />
                          <div className="dashed-border-short"></div>
                          <div className="side-image">
                            <img src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Not_Verified_Right.png" />
                          </div>
                        </div>
                      )}
                      {isVerifiedCerty && (
                        <div className="display-images-mb">
                          <div className="side-image">
                            <img
                              src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Verified_Left.png"
                              className="img-mobile-side"
                            />
                          </div>
                          <div className="dashed-border-short-mb"></div>
                          <img
                            src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Verified_Middle.png"
                            className="img-mobile-middle"
                          />
                          <div className="dashed-border-short-mb"></div>
                          <div className="side-image">
                            <img
                              src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Verified_Right.png"
                              className="img-mobile-side"
                            />
                          </div>
                        </div>
                      )}
                      {!isVerifiedCerty && (
                        <div className="display-images-mb">
                          <div className="side-image">
                            <img
                              src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Not_Verified_Left.png"
                              className="img-mobile-side"
                            />
                          </div>
                          <div className="dashed-border-short-mb"></div>
                          <img
                            src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Not_Verified_Middle.png"
                            className="img-mobile-middle"
                          />
                          <div className="dashed-border-short-mb"></div>
                          <div className="side-image">
                            <img
                              src="https://cdn.pegasus.imarticus.org/verify-cert-controller/Not_Verified_Right.png"
                              className="img-mobile-side"
                            />
                          </div>
                        </div>
                      )}
                      {isVerifiedCerty && (
                        <div className="display-text">
                          This is a verified certificate!
                        </div>
                      )}
                      {!isVerifiedCerty && (
                        <div className="display-text">
                          This is an unverified certificate!
                        </div>
                      )}
                      {invalidSerial && (
                        <div className="display-text">
                          Invalid certificate serial!
                        </div>
                      )}
                      <div className="dashed-border-long"></div>
                      <div className="student-info">
                        <div className="username">{certUsername}</div>
                        <div className="certificate-name">{certName}</div>
                        <div className="issue-date">{certIssueDate}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default VerifyCertificate;
