import axios from 'axios';
import _ from 'lodash';
import { ROUTES } from '../constants/routes';
import { POPOVER_TYPE } from '../constants/constants';
import { utils, handleErrors } from '../utility/utils';



export const updatePasswordToBackend = (password_object) => dispatch =>  {

    var set_password_loader_action = {};
    set_password_loader_action.is_loading = true;
    set_password_loader_action.type = 'SET_PASSWORD_LOADER';
    dispatch(set_password_loader_action);

    var change_password_action = {};
    password_object.a_id = utils.getAid();
    password_object.tokn = utils.getAt();
    change_password_action.type =  'UPDATE_PASSWORD';
    axios.post(ROUTES.GET_OTP, password_object)
        .then(pwd_otp_res => {
            utils.showPopover("OTP SENT!", "PLEASE CHECK YOUR DEVICE", POPOVER_TYPE.SUCCESS,dispatch);

            change_password_action.otp_received = true;
            change_password_action.pwd = password_object.password;
            change_password_action.confirm_pwd = password_object.confirm_password;
            change_password_action.number = password_object.p_no;
            dispatch(change_password_action);

            var set_password_loader_action = {};
            set_password_loader_action.is_loading = false;
            set_password_loader_action.type = 'SET_PASSWORD_LOADER';
            dispatch(set_password_loader_action);

        })
        .catch(err => {
            var set_password_loader_action = {};
            set_password_loader_action.is_loading = false;
            set_password_loader_action.type = 'SET_PASSWORD_LOADER';
            dispatch(set_password_loader_action);
            if (err.response && err.response.data ){
                var error = err.response.data;
                utils.showPopover( 'GET OTP FAILED',error.message ,POPOVER_TYPE.ERROR,dispatch);
            }
            else{
                utils.handleErrors(err, dispatch);
            }
        })
}


export const verifyOtpFromBackend = (otp_obj) => dispatch => {
    var set_password_loader_action = {};
    set_password_loader_action.is_loading = true;
    set_password_loader_action.type = 'SET_PASSWORD_LOADER';
    dispatch(set_password_loader_action);

    var update_otp_action = {}
    otp_obj.aid = utils.getAid();
    update_otp_action.type = 'VERIFY_OTP';

    axios.post(ROUTES.VERIFY_OTP, otp_obj)
        .then(pwd_otp_res => {
            var set_password_loader_action = {};
            set_password_loader_action.is_loading = false;
            set_password_loader_action.type = 'SET_PASSWORD_LOADER';
            dispatch(set_password_loader_action);

            utils.showPopover("OTP VERIFIED!", "OTP VERIFIED SUCCESSFULLY!", POPOVER_TYPE.SUCCESS,dispatch);
            console.log('res:' , pwd_otp_res);
            update_otp_action.otp_verified = true;
            dispatch(update_otp_action);
        })
        .catch(err => {
            var set_password_loader_action = {};
            set_password_loader_action.is_loading = false;
            set_password_loader_action.type = 'SET_PASSWORD_LOADER';
            dispatch(set_password_loader_action);

            if (err.response && err.response.data ){
                var error = err.response.data;
                utils.showPopover( 'VERIFY OTP FAILED',error.message ,POPOVER_TYPE.ERROR,dispatch);
            }
            else{
                utils.handleErrors(err, dispatch);
            }
        })

}


export const getOtpCall = (get_call_obj) => dispatch => {
    var set_password_loader_action = {};
    set_password_loader_action.is_loading = true;
    set_password_loader_action.type = 'SET_PASSWORD_LOADER';
    dispatch(set_password_loader_action);

    get_call_obj.a_id = utils.getAid();
    get_call_obj.d_id = utils.getSid();
    axios.post(ROUTES.GET_OTP_CALL , get_call_obj)
        .then(pwd_otp_res => {
            var set_password_loader_action = {};
            set_password_loader_action.is_loading = false;
            set_password_loader_action.type = 'SET_PASSWORD_LOADER';
            dispatch(set_password_loader_action);
            
            utils.showPopover("CALL INITIATED!", "You will get OTP verification call shortly!", POPOVER_TYPE.SUCCESS,dispatch);
        })
        .catch(err => {
            var set_password_loader_action = {};
            set_password_loader_action.is_loading = false;
            set_password_loader_action.type = 'SET_PASSWORD_LOADER';
            dispatch(set_password_loader_action);

            if (err.response && err.response.data ){
                var error = err.response.data;
                utils.showPopover( 'GET ON CALL FAILED!',error.message ,POPOVER_TYPE.ERROR,dispatch);
            }
            else{
                utils.handleErrors(err, dispatch);
            }
        })
}


export const resendOtpFromBackend = () => dispatch => {
    var set_password_loader_action = {};
    set_password_loader_action.is_loading = true;
    set_password_loader_action.type = 'SET_PASSWORD_LOADER';
    dispatch(set_password_loader_action);

    var resend_otp_ob = {
        aid : utils.getAid(),
        tokn : utils.getAt(),
    }
    axios.post(ROUTES.RESEND_OTP ,resend_otp_ob)
        .then(pwd_otp_res => {
            var set_password_loader_action = {};
            set_password_loader_action.is_loading = false;
            set_password_loader_action.type = 'SET_PASSWORD_LOADER';
            dispatch(set_password_loader_action);

            utils.showPopover("RESEND OTP!", "YOU WILL GET OTP SHORTLY!", POPOVER_TYPE.SUCCESS,dispatch);
        })
        .catch(err => {
            var set_password_loader_action = {};
            set_password_loader_action.is_loading = false;
            set_password_loader_action.type = 'SET_PASSWORD_LOADER';
            dispatch(set_password_loader_action);

            if (err.response && err.response.data ){
                var error = err.response.data;
                utils.showPopover( 'RESEND OTP FAILED!',error.message ,POPOVER_TYPE.ERROR,dispatch);
            }
            else{
                utils.handleErrors(err, dispatch);
            }
        })
}
