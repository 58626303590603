import React from "react";
const Timer = ({ times }) => {
  console.log(times);
  const showTime = () => {
    const minutes = Math.floor(times / 60);
    const seconds = Math.floor(times % 60);
    return `${minutes >= 10 ? minutes : `0${minutes}`}:${
      seconds >= 10 ? seconds : `0${seconds}`
    }`;
  };
  return (
    <span className="EmailVerificationModal__resendTimer">
      {times && <span className="pwd-otp-tmr">{showTime(times)}</span>}
    </span>
  );
};
export default Timer;
