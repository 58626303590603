import { connect } from 'react-redux';
import React from 'react';
import { withRouter } from "react-router";
import { Modal } from 'reactstrap';
import  group_default  from '../../images/groups-01-01.svg';
import { deletePPicBackend } from '../../actions/profileAction';
import { deleteGPicBackend } from '../../actions/groupAction';
import queryString from "query-string";
import {KEY_CODES, URLS, PIC_TYPE} from '../../constants/constants';

class DeletePhotoModal extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate() {
		
	}

	cancel = () => {
		this.props.toggleDeletePhotoModal();
    }

    componentDidMount(){
		document.addEventListener('keydown',this.keydownHandler);
    }
    
    toggleLeaveGroupModal = () => {
        this.setState({
          is_open : !this.state.is_open
        })
      }
    leaveGroup = () => {
        this.props.toggleLeaveGroupModal();
        this.props.leaveGroupBackend(this.props.gid,this.props.groups);
    }
    keydownHandler = (e) => {
		if(e.keyCode == KEY_CODES.ESC_KEY && this.props.is_dpm_open){
			this.props.toggleDeletePhotoModal();
		}
    }
    deletePic = () => {
      if(this.props.pic_type == PIC_TYPE.PROFILE_PIC){
        this.props.toggleDeletePhotoModal();
        this.props.toggleEditModal();
        this.props.deletePPicBackend(this.props.profiles);
      }
      else if(this.props.pic_type == PIC_TYPE.GROUP_PIC){
        // var query_params = queryString.parse(this.props.location.search);
        // var gid = query_params.gid;
        this.props.toggleDeletePhotoModal();
        this.props.toggleEditModal();
        this.props.deleteGPicBackend(this.props.groups,this.props.gid);
      }
    }
    

	render() {
        // alert(this.props.ppic == URLS.DEFAULT_PPIC_URL);
		return (
			<div>
				<Modal isOpen={this.props.is_dpm_open} className="dp-modal-container" centered={true}>
					<div className="dp-modal">
                        <div className="top-section">
                            <div className="heading">
                                Delete Photo
                            </div>
                            <p className="msg">{"Are you sure to delete " + (this.props.pic_type == PIC_TYPE.GROUP_PIC ? "group" : "profile" ) + " photo?"  }</p>
                        </div>
                        <div className="bottom-section">
                            <div className="btn-y" onClick={this.deletePic}>Yes</div>
                            <div className="btn-n" onClick={this.props.toggleDeletePhotoModal}>No</div>
                        </div>
					</div>

				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => (
	{
        gid : state.j_grp.gd_id,
        ppic : state.profile.ppic,
        groups : state.j_grp.groups,
        profiles : state.profile.profiles,
	})


const mapDispatchToProps = {
    deletePPicBackend,
    deleteGPicBackend,
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(DeletePhotoModal));
