import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
import { CONFIGS } from "../constants/constants";
import { dispatchIndividualBrand } from "./brandAction";
const cookies = new Cookies();

export const atCrs = ({ pid, cid, dispatchIndividualBrand }) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.AUTHENTICATE_COURSE, {
        crs_id: cid,
        p_id: pid,
        tokn: cookies.get("at"),
        a_id: cookies.get("aid"),
        cl: "W",
        d_id: window.fingerprint.md5hash,
      })
      .then(async (response) => {
        console.log(response.data);
        let brand;
        if (!window?.location.href.includes("imarticus.org")) {
          let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);

          if (res.data.success) {
            let brandData = res.data?.data?.brand;
            console.log({ brandData });
            if (brandData) brand = brandData;
          }
        } else {
          brand = response.data?.data?.brand;
        }
        console.log({ brand });
        dispatchIndividualBrand(brand);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const brandDataCourse = (cid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .get(ROUTES.FETCH_BRAND_DATA_CRS + `?cid=${cid}`, configs)
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const userLogout = () => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.LOGOUT,
        {
          a_id: cookies.get("aid"),
          d_id: window.fingerprint.md5hash,
          at: cookies.get("at"),
          cl: "W",
          rt: cookies.get("rt"),
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const getValueWeb = () => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-token"] = cookies.get("at");
    configs.headers = headers;
    return axios
      .get(ROUTES.GET_WEBSITE_COURSE, configs)
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const uploadDocumentsToAws = (document) => {
  return new Promise((resolve, reject) => {
    let data = new FormData();
    if (document) {
      data.set("document", document);
    }
    return axios({
      method: "POST",
      url: ROUTES.UPLOAD_DOCUMENTS_TO_AWS,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "x-access-crs-token": cookies.get("crs_tkn"),
      },
      onUploadProgress: function (progressEvent) {
        const percentCompleted = progressEvent.loaded / progressEvent.total;
        return percentCompleted;
      },
      data,
    })
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const getGradDetails = (pid, cid, elec_id) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    let data = {
      crs_id: cid,
      p_id: pid,
      a_id: cookies.get("aid"),
    };
    if (elec_id && elec_id != "undefined") {
      data["elec_id"] = elec_id;
    }
    return axios
      .post(ROUTES.GET_GRADUATION_STATUS, data, configs)
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const apllycert = (cid, pid, elec_id) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    let data = {
      crs_id: cid,
      p_id: pid,
      a_id: cookies.get("aid"),
    };
    if (elec_id && elec_id != "undefined") {
      data["elec_id"] = elec_id;
    }
    return axios
      .post(ROUTES.APPLY_CERTIFICATE, data, configs)
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const genCerti = (cid, pid, elec_id) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    let data = {
      crs_id: cid,
      p_id: pid,
      a_id: cookies.get("aid"),
      cl: "w",
    };
    if (elec_id && elec_id != "undefined") {
      data["elec_id"] = elec_id;
    }
    return axios
      .post(ROUTES.GENERATE_CERTIFICATE, data, configs)
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};
