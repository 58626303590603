import React, { useState } from "react";
import { useSelector } from "react-redux";
import EditMobileInput from "./EditMobileInput";
import EditMobileSuccessFull from "./EditMobileSuccess";
import EditMobileVerify from "./EditMobileVerify";
const EditMobileWindow = () => {
  const [step, setStep] = useState(0);
  const { contact } = useSelector((state) => state.profile);
  const [mobile, setMobile] = useState(contact);
  const { ccode: ccod } = useSelector((state) => state.profile);
  const [ccode, setCcode] = useState(ccod ? ccod : "91");
  const elements = [
    <EditMobileInput
      mobile={mobile}
      setMobile={setMobile}
      step={step}
      setStep={setStep}
      ccode={ccode}
      setCcode={setCcode}
    />,
    <EditMobileVerify
      mobile={mobile}
      step={step}
      setStep={setStep}
      ccode={ccode}
    />,
    <EditMobileSuccessFull />,
  ];
  console.log(
    "In EditMobileWindow",
    window.location.pathname,
    window.location.pathname === "/dashboard/edit/mobile"
  );
  return (
    <>
      <div className="group-db-content-wrapper">
        <div className="container-fluid dashboard-content">
          <div className=" profile-setting-container-row">
            <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row justify-content-center">
                <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 ">
                  {elements[step]}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMobileWindow;
