import React, { Component, Fragment } from 'react';
import yellow_star from '../../images/yellow-star.png';
import grey_star from '../../images/grey-star.png';
import CardHeading from '../utils/CardHeading';
import PropTypes from 'prop-types';
import { DEFAULT_IMG_URL, CONFIGS } from '../../constants/constants.js';
import ScrollerSS from '../skeletonscreens/ScrollerSS';
import { EXPLORE_SECTIONS } from '../../constants/constants.js';
import { connect } from 'react-redux';
import { getGroupInfo } from '../../actions/joinGroupAction.js';

// In scroller pass in the two props heading and the data 

class CourseScroller extends Component {

    constructor(props){
        super(props);
    }

    onButtonAction = (u_tag,code) => {
       switch(this.props.section){
           case EXPLORE_SECTIONS.TRENDING_GROUPS:{
               this.openJoinGroupModal(code);
             break;
           }
           case EXPLORE_SECTIONS.FEATURED_GROUPS:{
            this.openJoinGroupModal(code);
            break;
           }
           case EXPLORE_SECTIONS.FREE_COURSES:{
            this.redirectToCoursePage(u_tag);
            break;
           }
           case EXPLORE_SECTIONS.TRENDING_COURSES:{
            this.redirectToCoursePage(u_tag);
            break;
           }
       }
    }
    redirectToCoursePage = (u_tag) => {
        if(u_tag){ 
            // var crs_url = CONFIGS.COURSE_PAGE_BASE_URL + u_tag; 
            var crs_url = u_tag;
            var win = window.open(crs_url);
            win.focus();
        }
    }
    openJoinGroupModal = (code) => {
        this.props.getGroupInfo(code);
    }

    render() {
       
        if(this.props.data && this.props.section){
            return (
                <Fragment>
                    {/* <h1>{this.props.data}</h1> */}
                <p className="scroller-heading">{this.props.heading}</p>
                    <div className="scroller">
                        <div className="card-container" style={{ width: "max-content" }}>
                            {
                                this.props.data.map(({gpic, name, size, code, rtg, rtng, u_tag}, index) => {
                                    return(
                                        <div className="card">
                                            <div className="card-cover" onClick={() => this.redirectToCoursePage(u_tag)}></div>
                                        <img className="card-img" src={gpic ? gpic : DEFAULT_IMG_URL}></img>
                                        <div className="heading-container"><CardHeading text={name} /></div>
                                        <p className="enroll">{size} Enrollments</p>
                                        {/* <div className="rating-container"> */}
                                        <div className="rating-container">
                                        <div class="star-rating srtng_p" data-rating={rtng}>
                                        </div>
                                        </div>
                                        {/* <div className="rating-text">{rtg} Ratings</div> */}

                                        {/* </div> */}
                                        {/* <button className="learn-btn" onClick={()=>this.onButtonAction(u_tag,code)}>{this.props.section == EXPLORE_SECTIONS.FEATURED_GROUPS || this.props.section == EXPLORE_SECTIONS.TRENDING_GROUPS ? "Join Group" : "Learn More"} </button> */}
                                    </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    </Fragment>
            );
        }
        else{
            return(
                <ScrollerSS heading={this.props.heading}/>
            )
        }
        
    }
}

// Scroller.propTypes = {
//     heading : PropTypes.string.isRequired,
//     data    : PropTypes.arrayOf(PropTypes.shape({

//         gpic       : PropTypes.string.isRequired,
//         name          : PropTypes.string.isRequired,
//         enrollments   : PropTypes.string.isRequired,

//     })).isRequired,
//   }


const mapStateToProps = (state) => (
    {
      tr_crs: state.explore.tr_crs,
      tr_crs_to_render: state.explore.tr_crs_to_render,
    }
  )
  
  const mapDispatchToProps = {
    // fetchExploreData,
    getGroupInfo,
    // setTrendingCoursesToRender,
  }
  
  export default (connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CourseScroller));
  



