import axios from "axios";
import _ from "lodash";
import Cookies from "universal-cookie";
import cookie from "react-cookies";
import { ROUTES } from "../constants/routes";
import {
  CONFIGS,
  TABS,
  LOADING_MODAL_MSG,
  AWS_BUCKET_URLS,
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
  URLS,
  ROLES,
} from "../constants/constants";
import {
  BACKEND_ERRORS,
  BACKEND_ERROR_CODES,
  FRONTEND_ERRORS,
  ERROR_MESSAGES,
  ERROR_BLOCK,
} from "../constants/errors";
import { utils, handleErrors } from "../utility/utils";
import { base64StringtoFile } from "../utility/utils";
// import { setHomeTab, setCourseTab, setGroupTab} from '../actions/utilAction';
// import { dispatchIndividualBrand } from "../actions/brandAction";
import { renewAccessToken } from "./utilAction";
import XMLParser from "../lib/xml-parser";
import { push } from "react-router-redux";

const cookies = new Cookies();

axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getProfileInfo = (dispatchIndividualBrand) => (dispatch) => {
  var loading_data_action = {};
  loading_data_action.type = "SET_DATA_LOADING";
  dispatch(loading_data_action);
  loading_data_action.type = "RESET_DATA_LOADING";

  let data = {
    aid: utils.getAid(),
    tokn: utils.getAt(),
    pid: utils.getPid(),
  };
  console.log(data.aid);
  axios
    .get(`${ROUTES.PROFILE_DETAILS}/${data.aid}`, {
      headers: {
        "x-access-token": data.tokn,
      },
    })
    .then((p_info) => {
      var res = p_info.data.data;
      console.log(res);
      /* 
			check if pid is set in the cookie, 
			>> if saved, then fetch gids w.r.t that cookie, or 
			>> else set new pid in cookie 
			*/
      var pid = res.profile._id,
        profile_map,
        p_nam = res.profile.nam,
        ppic = res.profile.ppic,
        contact = res.m,
        email = res.ep,
        ifSubscribed = res.ifSubscribed,
        tz = res.tz,
        ep_vrfy = res.ep_vrfy,
        vrfy = res.vrfy,
        ccode = res.ccode,
        hide_mob_vrfy_popup = res.hide_mob_vrfy_popup,
        user_version = res.user_version,
        uid = res.uid,
        showProfilePrompt = res.showProfilePrompt,
        popup_snooze = res.popup_snooze,
        nsdc_url = res.nsdc_url,
        nsdc_mobile = res.nsdc_mobile;

      // profile_map = getUserProfileMap(res.Profile);

      // pid = res.Profile[0]._id;
      // utils.setPid(pid);
      // pid = utils.getPid();
      // p_nam = profile_map[pid]["nam"];
      // ppic = profile_map[pid]["ppic"];

      var profiles_to_render = [];
      var current_profile = res.profile;

      var profileObj = {};
      profileObj.p_name = p_nam;
      profileObj.contact = contact;
      profileObj.email = email;
      profileObj.ifSubscribed = ifSubscribed;
      profileObj.tz = tz;
      profileObj.profiles = [res.profile];
      profileObj.profile_map = current_profile;
      profileObj.ppic = ppic;
      profileObj.ep_vrfy = ep_vrfy;
      profileObj.vrfy = vrfy;
      profileObj.ccode = ccode;
      profileObj.hide_mob_vrfy_popup = hide_mob_vrfy_popup;
      profileObj.type = "USER_PROFILES";
      profileObj.user_version = user_version;
      profileObj.uid = uid;
      profileObj.showProfilePrompt = showProfilePrompt;
      profileObj.popup_snooze = popup_snooze;
      profileObj.nsdc_url = nsdc_url;
      profileObj.nsdc_mobile = nsdc_mobile;
      console.log({ profileObj });
      dispatch(profileObj);
      dispatch({ type: "PRIMARY_EMAIL_UPDATE", primary_email: email });
      // fetch groups
      let g_info_route =
        ROUTES.FETCH_GROUP +
        "?_=" +
        new Date().getTime() +
        "&p_id=" +
        pid +
        "&tokn=" +
        utils.getAt();
      let config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      axios
        .get(g_info_route, config)
        .then((groups) => {
          var res = groups.data.data;
          var groupObj = {};
          var groups = res.groups;
          var data = {
            headers: {
              "Content-Type": "application/json",
            },

            params: {
              // g_id: gid,
              p_id: utils.getPid(),
              tokn: utils.getAt(),
            },
          };

          axios
            .get(ROUTES.GET_GRPIDS_SEQ, data)
            .then((grp_seq_res) => {
              var gid_seq =
                grp_seq_res &&
                grp_seq_res.data &&
                grp_seq_res.data.data &&
                grp_seq_res.data.data.gid_seq
                  ? grp_seq_res.data.data.gid_seq
                  : [];
              groups = _.sortBy(groups, function (obj) {
                return _.indexOf(gid_seq, obj._id);
              });
              groupObj.groups = groups;
              groupObj.groupmembers = res.groupmembers;
              groupObj.type = "USER_JOINED_GROUPS";
              dispatch(groupObj);
              // fetch my_courses
              let data = {
                headers: {
                  "Content-Type": "application/json",
                },

                pid: pid,
                tokn: utils.getAt(),
                d_id: utils.getDid(),
                cl: "W",
              };
              axios
                .post(ROUTES.MY_CRS, data)
                .then(async (my_courses) => {
                  var res = my_courses.data.data; //>>>>>>>> need to uncomment this & use api data
                  // var res = JSON.parse(JSON.stringify(myCrs.data)) // >>>>> comment out this line
                  var crs_pid = res.crs_pid != undefined ? res.crs_pid : null;
                  console.log("Log 1");
                  // console.log(crs_pid, " >>>> crs_pid");
                  var crsPidObj = {};
                  crsPidObj.crs_pid = crs_pid;
                  crsPidObj.type = "CRS_PID";
                  dispatch(crsPidObj);
                  console.log(res);
                  // console.log("Notification : ", {
                  //   notificationsInResponse: res.notifications,
                  // });
                  // if (
                  //   res.notifications &&
                  //   Object.keys(res.notifications).length > 0
                  // ) {
                  //   console.log(
                  //     "\n\n --- Sending In app notifications --- \n\n"
                  //   );
                  //   let objtoDispatch = {
                  //     type: "IN_APP",
                  //     notification: {
                  //       title:
                  //         res.notifications.InAppMessage.Content[0].HeaderConfig
                  //           .Header,
                  //       body: res.notifications.InAppMessage.Content[0]
                  //         .BodyConfig.Body,
                  //       img: res.notifications.InAppMessage.Content[0].ImageUrl,
                  //     },
                  //   };
                  //   dispatch(objtoDispatch);
                  // }
                  var courseObj = {};
                  courseObj.brand = res.brand;
                  let brand;
                  if (!window?.location.href.includes("imarticus.org")) {
                    let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);
                    if (res.data?.data?.brand) brand = res.data?.data?.brand;
                  } else {
                    brand = res.brand;
                  }
                  console.log({ brand });
                  dispatchIndividualBrand(brand);
                  courseObj.courses = res.courses ? res.courses : [];
                  courseObj.groups = res.groups ? res.groups : [];
                  courseObj.combo_courses = res.combo_courses
                    ? res.combo_courses
                    : [];
                  courseObj.resume_course = res.resume_course
                    ? res.resume_course
                    : [];
                  courseObj.live_lectures =
                    res.liveLectures != undefined ? res.liveLectures : [];
                  courseObj.type = "MY_COURSES";
                  var ic_map = {};
                  var ic = JSON.parse(JSON.stringify(courseObj.courses));
                  var ic_len = ic.length;
                  for (var i = 0; i < ic_len; i++) {
                    if (!ic_map[ic[i]._id]) {
                      ic_map[ic[i]._id] = "";
                    }
                    ic_map[ic[i]._id] = ic[i];
                  }
                  courseObj.courses_map = JSON.parse(JSON.stringify(ic_map));
                  dispatch(courseObj);

                  let data = {
                    tokn: utils.getAt(),
                  };

                  axios
                    .post(ROUTES.PENDING_INSTALLMENTS, data)
                    .then((response) => {
                      let installmentObj = {
                        type: "PENDING_INSTALLMENTS",
                        pendingInstallments:
                          response.data.data.pendingInstallments,
                      };
                      dispatch(installmentObj);
                      dispatch(loading_data_action);
                    })
                    .catch((error) => {
                      utils.handleErrors(error, dispatch);
                    });
                })
                .catch((err) => {
                  utils.handleErrors(err, dispatch);
                });
            })
            .catch((err) => {
              var err_obj = {};
              if (err.response && err.response.data.code == 900) {
                var groupObj = {};
                groupObj.groups = [];
                groupObj.groupmembers = [];
                groupObj.type = "USER_JOINED_GROUPS";
                dispatch(groupObj);

                // fetch my_courses
                let data = {
                  headers: {
                    "Content-Type": "application/json",
                  },

                  pid: utils.getPid(),
                  tokn: utils.getAt(),
                  d_id: utils.getDid(),
                  cl: "W",
                };
                axios
                  .post(ROUTES.MY_CRS, data)
                  .then(async (my_courses) => {
                    var res = my_courses.data.data; //>>>>>>>> need to uncomment this & use api data
                    // var res = JSON.parse(JSON.stringify(myCrs.data)) // >>>>> comment out this line
                    var crs_pid = res.crs_pid != undefined ? res.crs_pid : null;
                    console.log("Log 1");

                    // console.log(crs_pid, " >>>> crs_pid");
                    var crsPidObj = {};
                    crsPidObj.crs_pid = crs_pid;
                    crsPidObj.type = "CRS_PID";
                    dispatch(crsPidObj);
                    console.log(res);
                    // console.log("Notification : ", {
                    //   notificationsInResponse: res.notifications,
                    // });
                    // if (
                    //   res.notifications &&
                    //   Object.keys(res.notifications).length > 0
                    // ) {
                    //   console.log(
                    //     "\n\n --- Sending In app notifications --- \n\n"
                    //   );
                    //   let objtoDispatch = {
                    //     type: "IN_APP",
                    //     notification: {
                    //       title:
                    //         res.notifications.InAppMessage.Content[0]
                    //           .HeaderConfig.Header,
                    //       body: res.notifications.InAppMessage.Content[0]
                    //         .BodyConfig.Body,
                    //       img: res.notifications.InAppMessage.Content[0]
                    //         .ImageUrl,
                    //     },
                    //   };
                    //   dispatch(objtoDispatch);
                    // }
                    var courseObj = {};
                    courseObj.brand = res.brand;
                    let brand;
                    if (!window?.location.href.includes("imarticus.org")) {
                      let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);
                      if (res.data?.data?.brand) brand = res.data?.data?.brand;
                    } else {
                      brand = res.brand;
                    }
                    dispatchIndividualBrand(brand);
                    courseObj.courses = res.courses ? res.courses : [];
                    courseObj.groups = res.groups ? res.groups : [];
                    courseObj.combo_courses = res.combo_courses
                      ? res.combo_courses
                      : [];
                    courseObj.resume_course = res.resume_course
                      ? res.resume_course
                      : [];
                    courseObj.live_lectures =
                      res.liveLectures != undefined ? res.liveLectures : [];
                    courseObj.type = "MY_COURSES";
                    var ic_map = {};
                    var ic = JSON.parse(JSON.stringify(courseObj.courses));
                    var ic_len = ic.length;
                    for (var i = 0; i < ic_len; i++) {
                      if (!ic_map[ic[i]._id]) {
                        ic_map[ic[i]._id] = "";
                      }
                      ic_map[ic[i]._id] = ic[i];
                    }
                    courseObj.courses_map = JSON.parse(JSON.stringify(ic_map));
                    dispatch(courseObj);
                    let data = {
                      tokn: utils.getAt(),
                    };

                    axios
                      .post(ROUTES.PENDING_INSTALLMENTS, data)
                      .then((response) => {
                        let installmentObj = {
                          type: "PENDING_INSTALLMENTS",
                          pendingInstallments:
                            response.data.data.pendingInstallments,
                        };
                        dispatch(installmentObj);
                        dispatch(loading_data_action);
                      })
                      .catch((error) => {
                        utils.handleErrors(error, dispatch);
                      });
                  })
                  .catch((err) => {
                    utils.handleErrors(err, dispatch);
                  });

                // window.location.replace(CONFIGS.DASHBOARD_URL);
              } else if (
                !err.response &&
                err.message == ERROR_MESSAGES.NETWORK_ERROR
              ) {
                err_obj.type = "ERROR_OCCURED";
                err_obj.err_msg = FRONTEND_ERRORS.F0005;
                err_obj.val = true;
                dispatch(err_obj);
              }
            });
        })
        .catch((err) => {
          if (err.response && err.response.data.code == 900) {
            // fetch my_courses
            let data = {
              headers: {
                "Content-Type": "application/json",
              },

              pid: utils.getPid(),
              tokn: utils.getAt(),
              d_id: utils.getDid(),
              cl: "W",
            };
            axios
              .post(ROUTES.MY_CRS, data)
              .then(async (my_courses) => {
                var res = my_courses.data.data; //>>>>>>>> need to uncomment this & use api data
                // var res = JSON.parse(JSON.stringify(myCrs.data)) // >>>>> comment out this line
                var crs_pid = res.crs_pid != undefined ? res.crs_pid : null;
                console.log("Log 1");

                // console.log(crs_pid, " >>>> crs_pid");
                var crsPidObj = {};
                crsPidObj.crs_pid = crs_pid;
                crsPidObj.type = "CRS_PID";
                dispatch(crsPidObj);
                console.log(res);
                // console.log("Notification : ", {
                //   notificationsInResponse: res.notifications,
                // });
                // if (
                //   res.notifications &&
                //   Object.keys(res.notifications).length > 0
                // ) {
                //   console.log("\n\n --- Sending In app notifications --- \n\n");
                //   let objtoDispatch = {
                //     type: "IN_APP",
                //     notification: {
                //       title:
                //         res.notifications.InAppMessage.Content[0].HeaderConfig
                //           .Header,
                //       body: res.notifications.InAppMessage.Content[0].BodyConfig
                //         .Body,
                //       img: res.notifications.InAppMessage.Content[0].ImageUrl,
                //     },
                //   };
                //   dispatch(objtoDispatch);
                // }
                var courseObj = {};
                courseObj.brand = res.brand;
                let brand;
                if (!window?.location.href.includes("imarticus.org")) {
                  let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);
                  if (res.data?.data?.brand) brand = res.data?.data?.brand;
                } else {
                  brand = res.brand;
                }
                dispatchIndividualBrand(brand);
                courseObj.courses = res.courses ? res.courses : [];
                courseObj.groups = res.groups ? res.groups : [];
                courseObj.combo_courses = res.combo_courses
                  ? res.combo_courses
                  : [];
                courseObj.resume_course = res.resume_course
                  ? res.resume_course
                  : [];
                courseObj.live_lectures =
                  res.liveLectures != undefined ? res.liveLectures : [];
                courseObj.type = "MY_COURSES";
                var ic_map = {};
                var ic = JSON.parse(JSON.stringify(courseObj.courses));
                var ic_len = ic.length;
                for (var i = 0; i < ic_len; i++) {
                  if (!ic_map[ic[i]._id]) {
                    ic_map[ic[i]._id] = "";
                  }
                  ic_map[ic[i]._id] = ic[i];
                }
                courseObj.courses_map = JSON.parse(JSON.stringify(ic_map));
                dispatch(courseObj);
                let data = {
                  tokn: utils.getAt(),
                };

                axios
                  .post(ROUTES.PENDING_INSTALLMENTS, data)
                  .then((response) => {
                    let installmentObj = {
                      type: "PENDING_INSTALLMENTS",
                      pendingInstallments:
                        response.data.data.pendingInstallments,
                    };
                    dispatch(installmentObj);
                    dispatch(loading_data_action);
                  })
                  .catch((error) => {
                    utils.handleErrors(error, dispatch);
                  });
              })
              .catch((err) => {
                utils.handleErrors(err, dispatch);
              });
          } else {
            utils.handleErrors(err, dispatch);
          }
        });
    })
    .catch((err) => {
      /* CATCH BLOCK FOR GET_PROFILE_INFO */
      var err_obj = {};
      if (err.response && err.response.data.code == 900) {
        // fetch my_courses
        let data = {
          headers: {
            "Content-Type": "application/json",
          },

          pid: utils.getPid(),
          tokn: utils.getAt(),
          d_id: utils.getDid(),
          cl: "W",
        };
        axios
          .post(ROUTES.MY_CRS, data)
          .then(async (my_courses) => {
            var res = my_courses.data.data; //>>>>>>>> need to uncomment this & use api data
            // var res = JSON.parse(JSON.stringify(myCrs.data)) // >>>>> comment out this line
            var crs_pid = res.crs_pid != undefined ? res.crs_pid : null;
            // console.log(crs_pid, " >>>> crs_pid");
            console.log("Log 1");

            var crsPidObj = {};
            crsPidObj.crs_pid = crs_pid;
            crsPidObj.type = "CRS_PID";
            dispatch(crsPidObj);
            console.log(res);
            // console.log("Notification : ", {
            //   notificationsInResponse: res.notifications,
            // });
            // if (
            //   res.notifications &&
            //   Object.keys(res.notifications).length > 0
            // ) {
            //   console.log("\n\n --- Sending In app notifications --- \n\n");
            //   let objtoDispatch = {
            //     type: "IN_APP",
            //     notification: {
            //       title:
            //         res.notifications.InAppMessage.Content[0].HeaderConfig
            //           .Header,
            //       body: res.notifications.InAppMessage.Content[0].BodyConfig
            //         .Body,
            //       img: res.notifications.InAppMessage.Content[0].ImageUrl,
            //     },
            //   };
            //   dispatch(objtoDispatch);
            // }
            var courseObj = {};
            courseObj.brand = res.brand;
            let brand;
            if (!window?.location.href.includes("imarticus.org")) {
              let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);
              if (res.data?.data?.brand) brand = res.data?.data?.brand;
            } else {
              brand = res.brand;
            }
            dispatchIndividualBrand(brand);
            courseObj.courses = res.courses ? res.courses : [];
            courseObj.groups = res.groups ? res.groups : [];
            courseObj.combo_courses = res.combo_courses
              ? res.combo_courses
              : [];
            courseObj.resume_course = res.resume_course
              ? res.resume_course
              : [];
            courseObj.live_lectures =
              res.liveLectures != undefined ? res.liveLectures : [];
            courseObj.type = "MY_COURSES";
            var ic_map = {};
            var ic = JSON.parse(JSON.stringify(courseObj.courses));
            var ic_len = ic.length;
            for (var i = 0; i < ic_len; i++) {
              if (!ic_map[ic[i]._id]) {
                ic_map[ic[i]._id] = "";
              }
              ic_map[ic[i]._id] = ic[i];
            }
            courseObj.courses_map = JSON.parse(JSON.stringify(ic_map));
            dispatch(courseObj);
            let data = {
              tokn: utils.getAt(),
            };

            axios
              .post(ROUTES.PENDING_INSTALLMENTS, data)
              .then((response) => {
                let installmentObj = {
                  type: "PENDING_INSTALLMENTS",
                  pendingInstallments: response.data.data.pendingInstallments,
                };
                dispatch(installmentObj);
                dispatch(loading_data_action);
              })
              .catch((error) => {
                utils.handleErrors(error, dispatch);
              });
          })
          .catch((err) => {
            utils.handleErrors(err, dispatch);
          });
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

export const getProfileInfoOnly = () => (dispatch) => {
  var loading_data_action = {};
  loading_data_action.type = "SET_DATA_LOADING";
  dispatch(loading_data_action);
  loading_data_action.type = "RESET_DATA_LOADING";

  let data = {
    aid: utils.getAid(),
    tokn: utils.getAt(),
    pid: utils.getPid(),
  };
  console.log(data.pid);
  axios
    .get(`${ROUTES.PROFILE_DETAILS}/${data.aid}`, {
      headers: {
        "x-access-token": data.tokn,
      },
    })
    .then((p_info) => {
      var res = p_info.data.data;
      console.log(res);
      /* 
			check if pid is set in the cookie, 
			>> if saved, then fetch gids w.r.t that cookie, or 
			>> else set new pid in cookie 
			*/
      var pid = res.profile._id,
        profile_map,
        p_nam = res.profile.nam,
        ppic = res.profile.ppic,
        contact = res.m,
        email = res.ep,
        ifSubscribed = res.ifSubscribed,
        tz = res.tz,
        ep_vrfy = res.ep_vrfy,
        vrfy = res.vrfy,
        ccode = res.ccode,
        hide_mob_vrfy_popup = res.hide_mob_vrfy_popup,
        user_version = res.user_version,
        uid = res.uid,
        showProfilePrompt = res.showProfilePrompt,
        popup_snooze = res.popup_snooze,
        nsdc_url = res.nsdc_url,
        nsdc_mobile = res.nsdc_mobile;

      // profile_map = getUserProfileMap(res.Profile);

      // pid = res.Profile[0]._id;
      // utils.setPid(pid);
      // pid = utils.getPid();
      // p_nam = profile_map[pid]["nam"];
      // ppic = profile_map[pid]["ppic"];

      var profiles_to_render = [];
      var current_profile = res.profile;

      var profileObj = {};
      profileObj.p_name = p_nam;
      profileObj.contact = contact;
      profileObj.email = email;
      profileObj.ifSubscribed = ifSubscribed;
      profileObj.tz = tz;
      profileObj.profiles = [res.profile];
      profileObj.profile_map = current_profile;
      profileObj.ppic = ppic;
      profileObj.ep_vrfy = ep_vrfy;
      profileObj.vrfy = vrfy;
      profileObj.ccode = ccode;
      profileObj.hide_mob_vrfy_popup = hide_mob_vrfy_popup;
      profileObj.type = "USER_PROFILES";
      profileObj.user_version = user_version;
      profileObj.uid = uid;
      profileObj.showProfilePrompt = showProfilePrompt;
      profileObj.popup_snooze = popup_snooze;
      profileObj.nsdc_url = nsdc_url;
      profileObj.nsdc_mobile = nsdc_mobile;
      console.log({ profileObj });
      dispatch(profileObj);
      dispatch({ type: "PRIMARY_EMAIL_UPDATE", primary_email: email });
      dispatch(loading_data_action);
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch);
    });
};

export const switchUserProfile =
  ({
    p_id,
    profile_map,
    ex_gids,
    profiles,
    is_deleted,
    dispatchIndividualBrand,
  }) =>
  (dispatch) => {
    var loading_data_action = {};
    loading_data_action.type = "SET_DATA_LOADING";
    dispatch(loading_data_action);
    loading_data_action.type = "RESET_DATA_LOADING";

    var pid = p_id;
    utils.hidePopover(dispatch);
    // utils.setPid(pid);
    // set current profile name && ppic
    var p_map = JSON.parse(JSON.stringify(profile_map));
    var ppic = p_map[pid]["ppic"];
    var p_nam = p_map[pid]["nam"];
    var updated_profiles = [];
    var cp;
    for (var i = 0; i < profiles.length; i++) {
      if (profiles[i]._id != p_id) {
        updated_profiles.push(profiles[i]);
      } else {
        cp = profiles[i];
      }
    }
    updated_profiles.sort(function (a, b) {
      return a.nam.localeCompare(b.nam);
    });
    if (cp) {
      updated_profiles.unshift(cp);
    }
    var profileObj = {};
    profileObj.ppic = ppic;
    profileObj.p_name = p_nam;
    profileObj.type = "SET_PPIC_NAME";

    var pf_action = {};
    pf_action.profiles = updated_profiles;
    pf_action.type = "UPDATE_PROFILES";
    dispatch(profileObj);
    dispatch(pf_action);
    let g_info_route =
      ROUTES.FETCH_GROUP +
      "?_=" +
      new Date().getTime() +
      "&p_id=" +
      pid +
      "&tokn=" +
      utils.getAt();
    let config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .get(g_info_route, config)
      .then((groups) => {
        var res = groups.data.data;
        var groupObj = {};
        var groups = res.groups;
        groups.sort((a, b) => a.name.localeCompare(b.name));
        groupObj.groups = groups;
        groupObj.groupmembers = res.groupmembers;
        groupObj.type = "USER_JOINED_GROUPS";
        dispatch(groupObj);

        var redirect_action = {};
        redirect_action.type = "REDIRECT_TO_HOME";
        dispatch(redirect_action);
        if (!is_deleted) {
          utils.showPopover(
            POPOVER_HEADINGS.SUCCESS,
            POPOVER_CONTENT.PROFILE_CHANGE_SUCCESS,
            POPOVER_TYPE.SUCCESS,
            dispatch
          );
        } else {
          utils.showPopover(
            POPOVER_HEADINGS.DEL_PROFILE_SUCCESS,
            POPOVER_CONTENT.DEL_PROFILE_SUCCESS,
            POPOVER_TYPE.SUCCESS,
            dispatch
          );
        }
        var data = {
          headers: {
            "Content-Type": "application/json",
          },

          params: {
            p_id: utils.getPid(),
            tokn: utils.getAt(),
          },
        };
        axios
          .get(ROUTES.GET_GRPIDS_SEQ, data)
          .then((grp_seq_res) => {
            var gid_seq =
              grp_seq_res &&
              grp_seq_res.data &&
              grp_seq_res.data.data &&
              grp_seq_res.data.data.gid_seq
                ? grp_seq_res.data.data.gid_seq
                : [];
            groups = _.sortBy(groups, function (obj) {
              return _.indexOf(gid_seq, obj._id);
            });
            groupObj.groups = groups;
            groupObj.groupmembers = res.groupmembers;
            groupObj.type = "USER_JOINED_GROUPS";
            dispatch(groupObj);

            // fetch my_courses
            let data = {
              headers: {
                "Content-Type": "application/json",
              },
              pid: pid,
              tokn: utils.getAt(),
              d_id: utils.getDid(),
              cl: "W",
            };
            axios
              .post(ROUTES.MY_CRS, data, {})
              .then(async (my_courses) => {
                var res = my_courses.data.data; //>>>>>>>> need to uncomment this & use api data
                // var res = JSON.parse(JSON.stringify(myCrs.data)) // >>>>> comment out this line
                var crs_pid = res.crs_pid != undefined ? res.crs_pid : null;
                // console.log(crs_pid, " >>>> crs_pid");
                console.log("Log 1");

                var crsPidObj = {};
                crsPidObj.crs_pid = crs_pid;
                crsPidObj.type = "CRS_PID";
                dispatch(crsPidObj);
                console.log(res);
                // console.log("Notification : ", {
                //   notificationsInResponse: res.notifications,
                // });
                // if (
                //   res.notifications &&
                //   Object.keys(res.notifications).length > 0
                // ) {
                //   console.log("\n\n --- Sending In app notifications --- \n\n");
                //   let objtoDispatch = {
                //     type: "IN_APP",
                //     notification: {
                //       title:
                //         res.notifications.InAppMessage.Content[0].HeaderConfig
                //           .Header,
                //       body: res.notifications.InAppMessage.Content[0].BodyConfig
                //         .Body,
                //       img: res.notifications.InAppMessage.Content[0].ImageUrl,
                //     },
                //   };
                //   dispatch(objtoDispatch);
                // }
                var courseObj = {};
                courseObj.brand = res.brand;
                let brand;
                if (!window?.location.href.includes("imarticus.org")) {
                  let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);
                  if (res.data?.data?.brand) brand = res.data?.data?.brand;
                } else {
                  brand = res.brand;
                }
                dispatchIndividualBrand(brand);
                courseObj.courses = res.courses ? res.courses : [];
                courseObj.groups = res.groups ? res.groups : [];
                courseObj.combo_courses = res.combo_courses
                  ? res.combo_courses
                  : [];
                courseObj.resume_course = res.resume_course
                  ? res.resume_course
                  : [];
                courseObj.live_lectures =
                  res.liveLectures != undefined ? res.liveLectures : [];
                courseObj.type = "MY_COURSES";
                var ic_map = {};
                var ic = JSON.parse(JSON.stringify(courseObj.courses));
                var ic_len = ic.length;
                for (var i = 0; i < ic_len; i++) {
                  if (!ic_map[ic[i]._id]) {
                    ic_map[ic[i]._id] = "";
                  }
                  ic_map[ic[i]._id] = ic[i];
                }
                courseObj.courses_map = JSON.parse(JSON.stringify(ic_map));
                dispatch(courseObj);
                var redirect_action = {};
                redirect_action.type = "REDIRECT_TO_HOME";
                dispatch(redirect_action);
                dispatch(loading_data_action);
                if (!is_deleted) {
                  utils.showPopover(
                    POPOVER_HEADINGS.SUCCESS,
                    POPOVER_CONTENT.PROFILE_CHANGE_SUCCESS,
                    POPOVER_TYPE.SUCCESS,
                    dispatch
                  );
                } else {
                  utils.showPopover(
                    POPOVER_HEADINGS.DEL_PROFILE_SUCCESS,
                    POPOVER_CONTENT.DEL_PROFILE_SUCCESS,
                    POPOVER_TYPE.SUCCESS,
                    dispatch
                  );
                }
              })
              .catch((err) => {
                if (err.response && err.response.data.code == 900) {
                  var groupObj = {};
                  groupObj.groups = [];
                  groupObj.groupmembers = [];
                  groupObj.courses = [];
                  groupObj.type = "NULL_GRP_CRS";
                  dispatch(groupObj);
                  // dispatch(push(CONFIGS.DASHBOARD_URL));
                  let data = {
                    headers: {
                      "Content-Type": "application/json",
                    },
                    pid: pid,
                    tokn: utils.getAt(),
                    d_id: utils.getDid(),
                    cl: "W",
                  };
                  axios
                    .post(ROUTES.MY_CRS, data, {})
                    .then(async (my_courses) => {
                      var res = my_courses.data.data; //>>>>>>>> need to uncomment this & use api data
                      // var res = JSON.parse(JSON.stringify(myCrs.data)) // >>>>> comment out this line
                      var crs_pid =
                        res.crs_pid != undefined ? res.crs_pid : null;
                      console.log("Log 1");

                      // console.log(crs_pid, " >>>> crs_pid");
                      var crsPidObj = {};
                      crsPidObj.crs_pid = crs_pid;
                      crsPidObj.type = "CRS_PID";
                      dispatch(crsPidObj);
                      console.log(res);
                      // console.log("Notification : ", {
                      //   notificationsInResponse: res.notifications,
                      // });
                      // if (
                      //   res.notifications &&
                      //   Object.keys(res.notifications).length > 0
                      // ) {
                      //   console.log(
                      //     "\n\n --- Sending In app notifications --- \n\n"
                      //   );
                      //   let objtoDispatch = {
                      //     type: "IN_APP",
                      //     notification: {
                      //       title:
                      //         res.notifications.InAppMessage.Content[0]
                      //           .HeaderConfig.Header,
                      //       body: res.notifications.InAppMessage.Content[0]
                      //         .BodyConfig.Body,
                      //       img: res.notifications.InAppMessage.Content[0]
                      //         .ImageUrl,
                      //     },
                      //   };
                      //   dispatch(objtoDispatch);
                      // }
                      var courseObj = {};
                      courseObj.brand = res.brand;
                      let brand;
                      if (!window?.location.href.includes("imarticus.org")) {
                        let res = await axios.get(
                          ROUTES.FETCH_BRAND_DATA_BY_URL
                        );
                        if (res.data?.data?.brand)
                          brand = res.data?.data?.brand;
                      } else {
                        brand = res.brand;
                      }
                      dispatchIndividualBrand(brand);
                      courseObj.courses = res.courses ? res.courses : [];
                      courseObj.groups = res.groups ? res.groups : [];
                      courseObj.combo_courses = res.combo_courses
                        ? res.combo_courses
                        : [];
                      courseObj.resume_course = res.resume_course
                        ? res.resume_course
                        : [];
                      courseObj.live_lectures =
                        res.liveLectures != undefined ? res.liveLectures : [];
                      courseObj.type = "MY_COURSES";
                      var ic_map = {};
                      var ic = JSON.parse(JSON.stringify(courseObj.courses));
                      var ic_len = ic.length;
                      for (var i = 0; i < ic_len; i++) {
                        if (!ic_map[ic[i]._id]) {
                          ic_map[ic[i]._id] = "";
                        }
                        ic_map[ic[i]._id] = ic[i];
                      }
                      courseObj.courses_map = JSON.parse(
                        JSON.stringify(ic_map)
                      );
                      dispatch(courseObj);

                      var redirect_action = {};
                      redirect_action.type = "REDIRECT_TO_HOME";
                      dispatch(redirect_action);
                      dispatch(loading_data_action);
                      if (!is_deleted) {
                        utils.showPopover(
                          POPOVER_HEADINGS.SUCCESS,
                          POPOVER_CONTENT.PROFILE_CHANGE_SUCCESS,
                          POPOVER_TYPE.SUCCESS,
                          dispatch
                        );
                      } else {
                        utils.showPopover(
                          POPOVER_HEADINGS.DEL_PROFILE_SUCCESS,
                          POPOVER_CONTENT.DEL_PROFILE_SUCCESS,
                          POPOVER_TYPE.SUCCESS,
                          dispatch
                        );
                      }
                    })
                    .catch((err) => {
                      utils.handleErrors(err, dispatch);
                    });
                }
              });
          })
          .catch((err) => {
            utils.handleErrors(err, dispatch);
          });
      })
      .catch((err) => {
        var err_obj = {};
        if (err.response && err.response.data.code == 900) {
          var groupObj = {};
          groupObj.groups = [];
          groupObj.groupmembers = [];
          groupObj.courses = [];
          groupObj.type = "NULL_GRP_CRS";
          dispatch(groupObj);

          let data = {
            headers: {
              "Content-Type": "application/json",
            },
            pid: pid,
            tokn: utils.getAt(),
            d_id: utils.getDid(),
            cl: "W",
          };
          axios
            .post(ROUTES.MY_CRS, data, {})
            .then(async (my_courses) => {
              var res = my_courses.data.data; //>>>>>>>> need to uncomment this & use api data
              // var res = JSON.parse(JSON.stringify(myCrs.data)) // >>>>> comment out this line
              var crs_pid = res.crs_pid != undefined ? res.crs_pid : null;
              console.log("Log 1");

              // console.log(crs_pid, " >>>> crs_pid");
              var crsPidObj = {};
              crsPidObj.crs_pid = crs_pid;
              crsPidObj.type = "CRS_PID";
              dispatch(crsPidObj);
              console.log(res);
              // console.log("Notification : ", {
              //   notificationsInResponse: res.notifications,
              // });
              // if (
              //   res.notifications &&
              //   Object.keys(res.notifications).length > 0
              // ) {
              //   console.log("\n\n --- Sending In app notifications --- \n\n");
              //   let objtoDispatch = {
              //     type: "IN_APP",
              //     notification: {
              //       title:
              //         res.notifications.InAppMessage.Content[0].HeaderConfig
              //           .Header,
              //       body: res.notifications.InAppMessage.Content[0].BodyConfig
              //         .Body,
              //       img: res.notifications.InAppMessage.Content[0].ImageUrl,
              //     },
              //   };
              //   dispatch(objtoDispatch);
              // }
              var courseObj = {};
              courseObj.brand = res.brand;
              let brand;
              if (!window?.location.href.includes("imarticus.org")) {
                let res = await axios.get(ROUTES.FETCH_BRAND_DATA_BY_URL);
                if (res.data?.data?.brand) brand = res.data?.data?.brand;
              } else {
                brand = res.brand;
              }
              dispatchIndividualBrand(brand);
              courseObj.courses = res.courses != undefined ? res.courses : [];
              courseObj.groups = res.groups != undefined ? res.groups : [];
              courseObj.combo_courses =
                res.combo_courses != undefined ? res.combo_courses : [];
              courseObj.resume_course =
                res.resume_course != undefined ? res.resume_course : [];
              courseObj.live_lectures =
                res.liveLectures != undefined ? res.liveLectures : [];
              courseObj.type = "MY_COURSES";
              var ic_map = {};
              var ic = JSON.parse(JSON.stringify(courseObj.courses));
              var ic_len = ic.length;
              for (var i = 0; i < ic_len; i++) {
                if (!ic_map[ic[i]._id]) {
                  ic_map[ic[i]._id] = "";
                }
                ic_map[ic[i]._id] = ic[i];
              }
              courseObj.courses_map = JSON.parse(JSON.stringify(ic_map));
              dispatch(courseObj);

              var redirect_action = {};
              redirect_action.type = "REDIRECT_TO_HOME";
              dispatch(redirect_action);
              dispatch(loading_data_action);
              if (!is_deleted) {
                utils.showPopover(
                  POPOVER_HEADINGS.SUCCESS,
                  POPOVER_CONTENT.PROFILE_CHANGE_SUCCESS,
                  POPOVER_TYPE.SUCCESS,
                  dispatch
                );
              } else {
                utils.showPopover(
                  POPOVER_HEADINGS.DEL_PROFILE_SUCCESS,
                  POPOVER_CONTENT.DEL_PROFILE_SUCCESS,
                  POPOVER_TYPE.SUCCESS,
                  dispatch
                );
              }
            })
            .catch((err) => {
              utils.handleErrors(err, dispatch);
            });
        } else if (
          !err.response &&
          err.message == ERROR_MESSAGES.NETWORK_ERROR
        ) {
          err_obj.type = "ERROR_OCCURED";
          err_obj.err_msg = FRONTEND_ERRORS.F0005;
          err_obj.val = true;
          dispatch(err_obj);
        }
      });
  };

export const createUserProfile =
  (name, role, childname, getProfileInfoCB) => (dispatch) => {
    let data = {};
    if (role == ROLES.PARENT) {
      data.childname = childname;
    }
    data.a_id = utils.getAid();
    data.role = role;
    data.name = name;
    data.tokn = utils.getAt();

    let add_p_route = ROUTES.ADD_PROFILE;
    axios
      .post(add_p_route, data)
      .then((p_info) => {
        var res = p_info.data.data;
        var pid = res.Profile._id;
        // utils.setPid(pid);
        // getProfileInfoCB();
        window.location.href = CONFIGS.DASHBOARD_BASE_URL;
      })
      .catch((err) => {
        utils.handleErrors(err, dispatch, ERROR_BLOCK.DUPLICATE_PROFILE);
      });
  };

export const getUserProfileMap = (profiles) => {
  var p_map = {};
  if (!profiles) {
    return p_map;
  }

  if (profiles.length > 0) {
    for (var i = 0; i < profiles.length; i++) {
      var prof = profiles[i];
      var p_id = prof._id + "";
      if (!p_map[p_id]) {
        p_map[p_id] = prof;
      }
    }
  }
  return p_map;
};

export const disableSwitchProfileCheck = () => (dispatch) => {
  var action = {};
  action.type = "SWITCH_PROFILE";
  action.val = false;
  dispatch(action);
};

export const deletePPicBackend = (profiles) => (dispatch) => {
  var loading_action = {};
  loading_action.type = "SET_LOADING_MODAL";
  loading_action.lm_msg = LOADING_MODAL_MSG.UPDATING;
  loading_action.value = true;
  dispatch(loading_action);
  loading_action.value = false;
  loading_action.lm_msg = "";

  var data = {};
  data.a_id = utils.getAid();
  data.p_id = utils.getPid();
  data.tokn = utils.getAt();
  axios
    .put(ROUTES.PROFILE_PIC_REMOVE, data)
    .then((del_res) => {
      if (!del_res || !del_res.data || !del_res.data.data) {
        return;
      }
      var rm_action = {};
      var pid = utils.getPid();
      rm_action.type = "REMOVE_PPIC";
      for (var i = 0; i < profiles.length; i++) {
        if (profiles[i]._id == pid) {
          profiles[i].ppic = URLS.DEFAULT_PPIC_URL;
          break;
        }
      }
      rm_action.profiles = profiles;
      rm_action.edit_ppic = URLS.DEFAULT_PPIC_URL;
      rm_action.ppic = URLS.DEFAULT_PPIC_URL;
      del_res = del_res.data.data;
      dispatch(rm_action);
      dispatch(loading_action);
      utils.showPopover(
        POPOVER_HEADINGS.DEL_PPIC_SUCCESS,
        POPOVER_CONTENT.DEL_PPIC_SUCCESS,
        POPOVER_TYPE.SUCCESS,
        dispatch
      );
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch, ERROR_BLOCK.DELETE_PPIC);
    });
};

export const deleteProfileBackend =
  (
    profiles,
    profile_map,
    all_ex_gids,
    switchUserProfileCB,
    dispatchIndividualBrand
  ) =>
  (dispatch) => {
    var loading_action = {};
    loading_action.type = "SET_LOADING_MODAL";
    loading_action.lm_msg = LOADING_MODAL_MSG.UPDATING;
    loading_action.value = true;
    dispatch(loading_action);
    loading_action.value = false;
    loading_action.lm_msg = "";

    var data = {};
    data.a_id = utils.getAid();
    data.p_id = utils.getPid();
    data.tokn = utils.getAt();
    axios
      .post(ROUTES.PROFILE_REMOVE, data)
      .then((del_res) => {
        if (!del_res || !del_res.data || !del_res.data.data) {
          return;
        }

        var updated_profiles = [];
        var rm_action = {};
        var pid = utils.getPid();
        rm_action.type = "REMOVE_PROFILE";
        for (var i = 0; i < profiles.length; i++) {
          if (profiles[i]._id != pid) {
            updated_profiles.push(profiles[i]);
          }
        }
        var next_pid = updated_profiles[0] ? updated_profiles[0]._id : "";
        // switchUserProfileCB(next_pid,profile_map,all_ex_gids,profiles);
        rm_action.profiles = updated_profiles;
        // rm_action.edit_ppic = URLS.DEFAULT_PPIC_URL;
        // rm_action.ppic = URLS.DEFAULT_PPIC_URL;
        del_res = del_res.data.data;
        dispatch(rm_action);
        dispatch(loading_action);
        switchUserProfileCB({
          p_id: next_pid,
          profile_map,
          ex_gids: all_ex_gids,
          profiles: updated_profiles,
          is_deleted: true,
          dispatchIndividualBrand,
          // next_pid,
          // profile_map,
          // all_ex_gids,
          // updated_profiles,
          // true,
        });
      })
      .catch((err) => {
        dispatch(loading_action);
        utils.handleErrors(err, dispatch, ERROR_BLOCK.DELETE_PROFILE);
      });
  };

export const getFirstProfileName = (profiles) => {
  if (!profiles) {
    return "";
  }
  if (profiles.length > 0) {
    return profiles[0]["nam"].split(" ")[0] &&
      profiles[0]["nam"].split(" ")[0] != undefined
      ? profiles[0]["nam"].split(" ")[0]
      : "User";
  }
};

export const getFirstProfilePic = (profiles) => {
  if (!profiles) {
    return "";
  }
  if (profiles.length > 0) {
    return profiles[0]["ppic"] && profiles[0]["ppic"] != undefined
      ? profiles[0]["ppic"]
      : "";
  }
};

export const updatePicURL = (img) => (dispatch) => {
  var action = {};
  action.type = "UPDATE_PPIC";
  action.edit_ppic = img;
  dispatch(action);
  // var p_map = {}
};

export const updateCroppedPPic = (cropped_img) => (dispatch) => {
  var action = {};
  action.type = "UPDATE_CROPPED_PPIC";
  action.cropped_ppic = cropped_img;
  dispatch(action);
};

const getS3Credentials = (file_ext, cb) => {
  var data = {
    params: {},
  };
  var d = new Date();
  data.params.f_nm =
    utils.getPid() + d.getTime() + "." + (file_ext && file_ext.split("/")[1]);
  data.params.f_tp = file_ext ? file_ext.split("/")[1] : "";
  data.params.m_od = "image";
  data.params.p_id = utils.getPid();
  data.params.tokn = utils.getAt();
  axios
    .get(ROUTES.PROFILE_PIC_WEB, data)
    .then((res) => {
      cb(null, res.data.data);
      //code to upload to aws
    })
    .catch((err) => {
      cb(err, null);
      // utils.handleErrors(err,dispatch);
    });
};

export const updateProfileDetailsToBackend =
  (update_obj, profiles) => (dispatch) => {
    /* the function included three section handled individually
	   1. updating both name name and pic 
	   2. updating only name 
	   3. updating only pic  */
    var data = {
      params: {},
    };
    var loading_action = {};
    loading_action.type = "SET_LOADING_MODAL";
    loading_action.lm_msg = LOADING_MODAL_MSG.UPDATING;
    loading_action.value = true;
    dispatch(loading_action);
    loading_action.value = false;
    loading_action.lm_msg = "";

    //updating name and pic ===================================================================================
    if (update_obj.name && update_obj.ppic) {
      var data = {
        tokn: utils.getAt(),
        p_id: utils.getPid(),
        name: update_obj.name,
        a_id: utils.getAid(),
      };
      axios
        .put(ROUTES.PROFILE_NAME_EDIT, data)
        .then((edit_res) => {
          var pid = utils.getPid();
          for (var i = 0; i < profiles.length; i++) {
            if (profiles[i]._id == pid) {
              profiles[i].nam = update_obj.name;
              break;
            }
          }
          var action = {};
          action.type = "UPDATE_PROFILE_NAME";
          action.p_name = update_obj.name;
          action.profiles = profiles;
          dispatch(action);
          getS3Credentials(update_obj.ppic_ext, function (err, s3_params) {
            if (err) {
              try {
                throw err;
              } catch (err) {
                dispatch(loading_action);
                utils.handleErrors(err, dispatch, ERROR_BLOCK.GROUP_PIC_UPLOAD);
                return;
              }
            }
            let data = new FormData();
            var d = new Date();
            var f_nm =
              utils.getPid() +
              d.getTime() +
              "." +
              (update_obj && update_obj.ppic_ext
                ? update_obj.ppic_ext.split("/")[1]
                : "");
            var cropped_file = base64StringtoFile(update_obj.ppic, f_nm);

            data.set("key", f_nm);
            data.set("AWSAccessKeyId", s3_params.AWSAccessKeyId);
            data.set("acl", "public-read");
            data.set("Policy", s3_params.s3Policy);
            data.set("Content-Type", update_obj.ppic_ext);
            data.set("Signature", s3_params.s3Signature);
            data.set("success_action_status", "201");
            data.set("file", cropped_file);

            var s3UploadConfig = {
              method: "POST",
              url: AWS_BUCKET_URLS.PPIC,
              // headers: { "Content-Type": undefined },
              onUploadProgress: function (progress) {},
              body: data,
            };

            fetch(AWS_BUCKET_URLS.PPIC, s3UploadConfig)
              // axios(s3UploadConfig)
              .then(async (response) => {
                console.log({ response });
                response = await response.text();
                console.log({ response });
                // var xml = new XMLParser().parseFromString(response.data);
                // var location = xml.getElementsByTagName("Location");
                let pic_url = response
                  .split("<Location>")[1]
                  .split("</Location>")[0];
                // var pic_url = location[0] ? location[0].value : null;
                var ppic = pic_url;
                var pid = utils.getPid();
                for (var i = 0; i < profiles.length; i++) {
                  if (profiles[i]._id == pid) {
                    profiles[i].ppic = ppic;
                    break;
                  }
                }
                var edit_data = {};
                edit_data.a_id = utils.getAid();
                edit_data.p_id = utils.getPid();
                edit_data.ppic = ppic;
                edit_data.tokn = utils.getAt();
                axios
                  .put(ROUTES.PROFILE_PIC_EDIT, edit_data)
                  .then((pic_edit_res) => {
                    var action = {};
                    action.type = "UPDATE_PROFILE_PIC";
                    action.ppic = ppic;
                    action.profiles = profiles;
                    dispatch(action);
                    dispatch(loading_action);
                    utils.showPopover(
                      POPOVER_HEADINGS.PPIC_UPLOAD_SUCCESS,
                      POPOVER_CONTENT.PPIC_UPLOAD_SUCCESS,
                      POPOVER_TYPE.SUCCESS,
                      dispatch
                    );
                  })
                  .catch((err) => {
                    dispatch(loading_action);
                    utils.handleErrors(err, dispatch);
                  });
              })
              .catch((err) => {
                dispatch(loading_action);
                utils.handleErrors(
                  err,
                  dispatch,
                  ERROR_BLOCK.PROFILE_PIC_UPLOAD
                );
              });
          });
        })
        .catch((err) => {
          dispatch(loading_action);
          utils.handleErrors(err, dispatch);
        });
    }
    //updating only name=======================================================================================
    else if (update_obj.name) {
      var data = {
        tokn: utils.getAt(),
        p_id: utils.getPid(),
        name: update_obj.name,
        a_id: utils.getAid(),
      };
      axios
        .put(ROUTES.PROFILE_NAME_EDIT, data)
        .then((edit_res) => {
          var pid = utils.getPid();
          for (var i = 0; i < profiles.length; i++) {
            if (profiles[i]._id == pid) {
              profiles[i].nam = update_obj.name;
              break;
            }
          }
          var action = {};
          action.type = "UPDATE_PROFILE_NAME";
          action.p_name = update_obj.name;
          action.profiles = profiles;
          dispatch(action);
          dispatch(loading_action);
          utils.showPopover(
            POPOVER_HEADINGS.PPIC_UPLOAD_SUCCESS,
            POPOVER_CONTENT.PPIC_UPLOAD_SUCCESS,
            POPOVER_TYPE.SUCCESS,
            dispatch
          );
        })
        .catch((err) => {
          dispatch(loading_action);
          utils.handleErrors(err, dispatch);
        });
    }
    //updating only pic =========================================================================================
    else if (update_obj.ppic) {
      getS3Credentials(update_obj.ppic_ext, function (err, s3_params) {
        if (err) {
          try {
            throw err;
          } catch (err) {
            dispatch(loading_action);
            utils.handleErrors(err, dispatch, ERROR_BLOCK.GROUP_PIC_UPLOAD);
            return;
          }
        }
        let data = new FormData();
        var d = new Date();
        var f_nm =
          utils.getPid() +
          d.getTime() +
          "." +
          (update_obj && update_obj.ppic_ext
            ? update_obj.ppic_ext.split("/")[1]
            : "");

        var cropped_file = base64StringtoFile(update_obj.ppic, f_nm);

        data.set("key", f_nm);
        data.set("AWSAccessKeyId", s3_params.AWSAccessKeyId);
        data.set("acl", "public-read");
        data.set("Policy", s3_params.s3Policy);
        data.set("Content-Type", update_obj.ppic_ext);
        data.set("Signature", s3_params.s3Signature);
        data.set("success_action_status", "201");
        data.set("file", cropped_file);

        var s3UploadConfig = {
          method: "POST",
          url: AWS_BUCKET_URLS.PPIC,
          // headers: { "Content-Type": undefined },
          onUploadProgress: function (progress) {},
          body: data,
        };
        fetch(AWS_BUCKET_URLS.PPIC, s3UploadConfig)
          // axios(s3UploadConfig)
          .then(async (response) => {
            console.log({ response });
            response = await response.text();
            console.log({ response });
            // var xml = new XMLParser().parseFromString(response.data);
            // var location = xml.getElementsByTagName("Location");
            let pic_url = response
              .split("<Location>")[1]
              .split("</Location>")[0];
            // var pic_url = location[0] ? location[0].value : null;
            var ppic = pic_url;
            var pid = utils.getPid();
            for (var i = 0; i < profiles.length; i++) {
              if (profiles[i]._id == pid) {
                profiles[i].ppic = ppic;
                break;
              }
            }
            var edit_data = {};
            edit_data.a_id = utils.getAid();
            edit_data.p_id = utils.getPid();
            edit_data.ppic = ppic;
            edit_data.tokn = utils.getAt();
            axios
              .put(ROUTES.PROFILE_PIC_EDIT, edit_data)
              .then((pic_edit_res) => {
                var action = {};
                action.type = "UPDATE_PROFILE_PIC";
                action.ppic = ppic;
                action.profiles = profiles;
                dispatch(action);
                dispatch(loading_action);

                utils.showPopover(
                  POPOVER_HEADINGS.PPIC_UPLOAD_SUCCESS,
                  POPOVER_CONTENT.PPIC_UPLOAD_SUCCESS,
                  POPOVER_TYPE.SUCCESS,
                  dispatch
                );
              })
              .catch((err) => {
                dispatch(loading_action);
                utils.handleErrors(err, dispatch);
              });
          })
          .catch((err) => {
            dispatch(loading_action);
            utils.handleErrors(err, dispatch, ERROR_BLOCK.PROFILE_PIC_UPLOAD);
          });
      });
    }
  };

export const setProfilePicExtension = (ext) => (dispatch) => {
  var action = {};
  action.type = "SET_PPIC_EXT";
  action.ppic_ext = ext;
  dispatch(action);
};

// export const updatePicFileObject = (file) => dispatch => {
// 	var action = {};
// 	action.type = "UPDATE_PPIC_FILE";
// 	action.edit_ppic_file = file;
// 	dispatch(action);
// }

var myCrs = {
  success: true,
  data: {
    courses: [
      {
        _id: "5c4e8ea5dc3fc94704f8c952",
        name: "CertificatePaid1",
        tag: "CFP1",
        desc: "\u003cp\u003eCFP1 description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url:
          "https://images.pexels.com/photos/67636/rose-blue-flower-rose-blooms-67636.jpeg?auto\u003dcompress\u0026cs\u003dtinysrgb\u0026dpr\u003d2\u0026h\u003d650\u0026w\u003d940",
        crsimg:
          "https://images.pexels.com/photos/67636/rose-blue-flower-rose-blooms-67636.jpeg?auto\u003dcompress\u0026cs\u003dtinysrgb\u0026dpr\u003d2\u0026h\u003d650\u0026w\u003d940",
        v_p_url: "https://youtu.be/3CTrU8-12a0",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1548652189554,
        l_date: "2019-01-28T05:09:57.000Z",
        act: true,
        u_tag: "certificatepaid1-2197526",
        __v: 0,
        intrstr: "Sarthak1",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651aab6457121b59024f",
        btch_name: "Default_Batch_1560503579590_CertificatePaid1",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5c4eaa75dc3fc94704f8c965", "5c4eaa75dc3fc94704f8c968"],
        gids: ["57a9a9789c3a923c01c5c3c1"],
        gcodes: ["261754"],
        course_completion: 0,
      },
      {
        _id: "5c63e409dff4c84341d4f94f",
        name: "NonAdaptivePaid",
        tag: "NAP",
        desc: "\u003cp\u003eNAP description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url:
          "https://www.gettyimages.com/gi-resources/images/CreativeLandingPage/HP_Sept_24_2018/CR3_GettyImages-159018836.jpg",
        crsimg:
          "https://www.gettyimages.com/gi-resources/images/CreativeLandingPage/HP_Sept_24_2018/CR3_GettyImages-159018836.jpg",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1550050296091,
        l_date: "2019-02-13T09:31:53.000Z",
        act: true,
        u_tag: "nonadaptivepaid-0313973",
        __v: 0,
        intrstr: "Saptorshi",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651bab6457121b5902ce",
        btch_name: "Default_Batch_1560503579975_NonAdaptivePaid",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5c63ed18dff4c84341d4f985", "5c63ed18dff4c84341d4f988"],
        gids: ["5c63e365dff4c84341d4f94a"],
        gcodes: ["391247"],
        course_completion: 40,
      },
      {
        _id: "5c63f82d44df328746919094",
        name: "CertificatePanelPaid",
        tag: "CP2",
        desc: "\u003cp\u003eCP2 description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url:
          "https://www.gettyimages.com/gi-resources/images/CreativeLandingPage/HP_Sept_24_2018/CR3_GettyImages-159018836.jpg",
        crsimg:
          "https://www.gettyimages.com/gi-resources/images/CreativeLandingPage/HP_Sept_24_2018/CR3_GettyImages-159018836.jpg",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1550055451343,
        l_date: "2019-02-13T10:57:49.000Z",
        act: true,
        u_tag: "certificatepanel2-5469319",
        __v: 0,
        intrstr: "Sarthak",
        edby: "5bf3e05eeed93d024419ccc4",
        is_open: false,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651aab6457121b590254",
        btch_name: "Default_Batch_1560503579602_CertificatePanelPaid",
        crtf_enable: false,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5c63f99644df32874691909c", "5c63f99644df32874691909f"],
        gids: ["5c63e365dff4c84341d4f94a"],
        gcodes: ["391247"],
        course_completion: 100,
      },
      {
        _id: "5c6fc8d0d6b34a222303c8ff",
        name: "ComboChild2",
        tag: "CP",
        desc: "\u003cp\u003eCP description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url: "https://timesofindia.indiatimes.com/photo/55858929.cms",
        crsimg: "https://timesofindia.indiatimes.com/photo/55858929.cms",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1550829773642,
        l_date: "2019-02-22T10:02:56.000Z",
        act: true,
        u_tag: "comboparent-9776679",
        __v: 0,
        intrstr: "Sarthak",
        edby: "5bf3e05eeed93d024419ccc4",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651aab6457121b590269",
        btch_name: "Default_Batch_1560503579667_ComboChild2",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5c9b3af2e00e4bf07ed59322", "5c9b3af3e00e4bf07ed59325"],
        gids: ["5b39d029a5f6815a74d85bc9"],
        gcodes: ["718263"],
        course_completion: 0,
      },
      {
        _id: "5c6fc916d6b34a222303c902",
        name: "ComboChild1",
        tag: "CC",
        desc: "\u003cp\u003eCC` description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url: "https://timesofindia.indiatimes.com/photo/55858929.cms",
        crsimg: "https://timesofindia.indiatimes.com/photo/55858929.cms",
        v_p_url: "https://youtu.be/jFGKJBPFdUA",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1550829843065,
        l_date: "2019-02-22T10:04:06.000Z",
        act: true,
        u_tag: "combochild-9846051",
        __v: 0,
        intrstr: "Sarthak",
        edby: "5bf3e05eeed93d024419ccc4",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651aab6457121b590268",
        btch_name: "Default_Batch_1560503579665_ComboChild1",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5c9b3ae8e00e4bf07ed59319", "5c9b3ae8e00e4bf07ed5931c"],
        gids: ["5c63e365dff4c84341d4f94a"],
        gcodes: ["391247"],
        course_completion: 0,
      },
      {
        _id: "5c7e0b8480fd000d20633749",
        name: "PaytmCourse5",
        tag: "PC5",
        desc: "\u003cp\u003ecoursje description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url:
          "https://www.ypad4change.org/wp-content/uploads/2017/09/636120872757539984-1330483037_family.jpg",
        crsimg:
          "https://www.ypad4change.org/wp-content/uploads/2017/09/636120872757539984-1330483037_family.jpg",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1551764345501,
        l_date: "2019-03-05T05:39:16.000Z",
        act: true,
        u_tag: "paytmcourse5-4356875",
        __v: 0,
        intrstr: "Sarthak",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651bab6457121b5902ef",
        btch_name: "Default_Batch_1560503580068_PaytmCourse5",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5c7e1a2180fd000d206337f1", "5c7e1a2280fd000d206337f4"],
        gids: ["5c63e365dff4c84341d4f94a"],
        gcodes: ["391247"],
        course_completion: 0,
      },
      {
        _id: "5c7e11c080fd000d20633774",
        name: "PaytmCourse7",
        tag: "PC7",
        desc: "\u003cp\u003ekjcourse description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url:
          "https://images-na.ssl-images-amazon.com/images/I/81W5nfYYxoL._SX425_.jpg",
        crsimg:
          "https://images-na.ssl-images-amazon.com/images/I/81W5nfYYxoL._SX425_.jpg",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1551765941106,
        l_date: "2019-03-05T06:05:52.000Z",
        act: true,
        u_tag: "paytmcourse7-5952478",
        __v: 0,
        intrstr: "Sarthak and Lakshay",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651bab6457121b5902f3",
        btch_name: "Default_Batch_1560503580083_PaytmCourse7",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5c98892cf2a7200e158bad04", "5c98892cf2a7200e158bad07"],
        gids: ["5c63e365dff4c84341d4f94a"],
        gcodes: ["391247"],
        course_completion: 0,
      },
      {
        _id: "5c8652575ecbc14777972fd4",
        name: "ComboParent",
        tag: "AGB1",
        desc: "\u003cp\u003ehcourse description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url: "https://timesofindia.indiatimes.com/photo/55858929.cms",
        crsimg: "https://timesofindia.indiatimes.com/photo/55858929.cms",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1552306762055,
        l_date: "2019-03-11T12:19:35.000Z",
        act: true,
        u_tag: "androidgraduationbug1-6775055",
        __v: 0,
        intrstr: "Sarthak",
        edby: "5bf3e05eeed93d024419ccc4",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651aab6457121b590236",
        btch_name: "Default_Batch_1560503579507_ComboParent",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: [],
        gids: [],
        gcodes: [],
        course_completion: 0,
      },
      {
        _id: "5cfdfe9a726fa02358951a60",
        name: "OldBatchParent",
        tag: "OBP",
        desc: "\u003cp\u003ecoudsrse description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        v_p_url: "",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1560149593658,
        l_date: "2019-06-10T06:54:18.000Z",
        act: true,
        u_tag: "oldbatchparent-9658643",
        __v: 0,
        intrstr: "Sarthak",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651bab6457121b5902d9",
        btch_name: "Default_Batch_1560503580004_OldBatchParent",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: [],
        gids: [],
        gcodes: [],
        course_completion: 0,
      },
      {
        _id: "5cfdfee8726fa02358951a63",
        name: "OldBatchChild1",
        tag: "OBC1",
        desc: "\u003cp\u003edcourse description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        v_p_url: "",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1560149671975,
        l_date: "2019-06-10T06:55:36.000Z",
        act: true,
        u_tag: "oldbatchchild1-9736920",
        __v: 0,
        intrstr: "Sarthak Singh",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651bab6457121b5902d6",
        btch_name: "Default_Batch_1560503579995_OldBatchChild1",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5cfe0867220ae1855a40ab88", "5cfe0867220ae1855a40ab8b"],
        gids: ["5cfe0857220ae1855a40ab81"],
        gcodes: ["792458"],
        course_completion: 0,
      },
      {
        _id: "5cfdff37726fa02358951a66",
        name: "OldBatchChild2",
        tag: "OBC2",
        desc: "\u003cp\u003ejcourse description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        v_p_url: "",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1560149750856,
        l_date: "2019-06-10T06:56:55.000Z",
        act: true,
        u_tag: "oldbatchchild2-9815720",
        __v: 0,
        intrstr: "Sarthak Singh Negi",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651bab6457121b5902d8",
        btch_name: "Default_Batch_1560503580002_OldBatchChild2",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5cfe0873220ae1855a40ab8f", "5cfe0873220ae1855a40ab92"],
        gids: ["5cfe085d220ae1855a40ab83"],
        gcodes: ["481539"],
        course_completion: 0,
      },
      {
        _id: "5cfe3f70c12d15767346ca90",
        crs_pg_id: "5d03651bab6457121b5902f1",
        btch_name: "Default_Batch_1560503580074_PaytmCourse5",
        desc: ",mn",
        is_open: true,
        act: true,
        name: "PaytmCourse5",
        u_tag: "paytmcourse5-4356875",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "PC5",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-03-05T05:39:16.000Z",
        l_date_n: 1551764345501,
        crtnm: "",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://www.ypad4change.org/wp-content/uploads/2017/09/636120872757539984-1330483037_family.jpg",
        crs_thumb_url:
          "https://www.ypad4change.org/wp-content/uploads/2017/09/636120872757539984-1330483037_family.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5cfe3f71c12d15767346ca94", "5cfe3f71c12d15767346ca97"],
        gids: ["5cfe3e5bc12d15767346ca6a"],
        gcodes: ["794638"],
        course_completion: 0,
      },
      {
        _id: "5cff83a8686ea9013aeb3233",
        crs_pg_id: "5d03651aab6457121b590256",
        btch_name: "Default_Batch_1560503579607_CertificatePanelPaid",
        desc: "kjdnf",
        is_open: true,
        act: true,
        name: "CertificatePanelPaid",
        u_tag: "certificatepanel2-5469319",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "CP2",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-02-13T10:57:49.000Z",
        l_date_n: 1550055451343,
        crtnm: "",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://www.gettyimages.com/gi-resources/images/CreativeLandingPage/HP_Sept_24_2018/CR3_GettyImages-159018836.jpg",
        edby: "5bf3e05eeed93d024419ccc4",
        crs_thumb_url:
          "https://www.gettyimages.com/gi-resources/images/CreativeLandingPage/HP_Sept_24_2018/CR3_GettyImages-159018836.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5cff83a9686ea9013aeb3237", "5cff83a9686ea9013aeb323a"],
        gids: ["5cf0a420b64cd06668e4743c"],
        gcodes: ["215794"],
        course_completion: 0,
      },
      {
        _id: "5cff8cbf686ea9013aeb3269",
        crs_pg_id: "5d03651aab6457121b5902a8",
        btch_name: "Default_Batch_1560503579856_LastBatchPaid",
        desc: "\u003cp\u003eafecourse description\u003c/p\u003e\n",
        is_open: true,
        act: true,
        name: "LastBatchPaid",
        u_tag: "lastbatchpaid-1583434",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "fv",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-11T11:13:03.000Z",
        l_date_n: 1560251516572,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5cff8dab686ea9013aeb327a", "5cff8dab686ea9013aeb327d"],
        gids: ["5cff8d8b686ea9013aeb3275"],
        gcodes: ["175436"],
        course_completion: 100,
      },
      {
        _id: "5cff90cd686ea9013aeb32d9",
        crs_pg_id: "5d03651bab6457121b5902aa",
        btch_name: "Default_Batch_1560503579861_LastBatchPaid",
        desc: "nk",
        is_open: true,
        act: true,
        name: "LastBatchPaid",
        u_tag: "lastbatchpaid-1583434",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "fv",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-11T11:13:03.000Z",
        l_date_n: 1560251516572,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak Sapto",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5cff90cd686ea9013aeb32dd", "5cff90cd686ea9013aeb32e0"],
        gids: ["5cff8efa686ea9013aeb32b0"],
        gcodes: ["546791"],
        course_completion: 0,
      },
      {
        _id: "5cff90dc686ea9013aeb32e5",
        crs_pg_id: "5d03651bab6457121b5902ab",
        btch_name: "Default_Batch_1560503579863_LastBatchPaid",
        desc: "nj",
        is_open: true,
        act: true,
        name: "LastBatchPaid",
        u_tag: "lastbatchpaid-1583434",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "fv",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-11T11:13:03.000Z",
        l_date_n: 1560251516572,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak Sapto",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5cff90dd686ea9013aeb32e9", "5cff90dd686ea9013aeb32ec"],
        gids: ["5cff8efa686ea9013aeb32b0"],
        gcodes: ["546791"],
        course_completion: 50,
      },
      {
        _id: "5d00ae44ecef1899783717ba",
        crs_pg_id: "5d03651bab6457121b5902af",
        btch_name: "Default_Batch_1560503579876_LastBatchPaid",
        desc: "jh",
        is_open: true,
        act: true,
        name: "LastBatchPaid",
        u_tag: "lastbatchpaid-1583434",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "fv",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-11T11:13:03.000Z",
        l_date_n: 1560251516572,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak Sapto",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d00ae45ecef1899783717be", "5d00ae45ecef1899783717c1"],
        gids: ["5cff8d8b686ea9013aeb3275"],
        gcodes: ["175436"],
        course_completion: 0,
      },
      {
        _id: "5d00b318ecef1899783717e8",
        crs_pg_id: "5d03651aab6457121b59025c",
        btch_name: "Default_Batch_1560503579623_ChapterDelete",
        desc: "\u003cp\u003esgafcourse description\u003c/p\u003e\n",
        is_open: true,
        act: true,
        name: "ChapterDelete",
        u_tag: "chapterdelete-6936482",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "dsgsg",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-12T08:08:56.000Z",
        l_date_n: 1560326874333,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d00b3c1ecef1899783717ef", "5d00b3c2ecef1899783717f2"],
        gids: ["5cff8efa686ea9013aeb32b0"],
        gcodes: ["546791"],
        course_completion: 0,
      },
      {
        _id: "5d00b571ecef18997837180e",
        crs_pg_id: "5d03651aab6457121b59025d",
        btch_name: "Default_Batch_1560503579625_ChapterDelete",
        desc: "one",
        is_open: true,
        act: true,
        name: "ChapterDelete",
        u_tag: "chapterdelete-6936482",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "dsgsg",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-12T08:08:56.000Z",
        l_date_n: 1560326874333,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d00b571ecef189978371812", "5d00b572ecef189978371815"],
        gids: ["5cff8d8b686ea9013aeb3275"],
        gcodes: ["175436"],
        course_completion: 0,
      },
      {
        _id: "5d00b873ecef189978371836",
        crs_pg_id: "5d03651aab6457121b59025e",
        btch_name: "Default_Batch_1560503579628_ChapterDelete",
        desc: "jbjk",
        is_open: true,
        act: true,
        name: "ChapterDelete",
        u_tag: "chapterdelete-6936482",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "dsgsg",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-12T08:08:56.000Z",
        l_date_n: 1560326874333,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d00b873ecef18997837183a", "5d00b873ecef18997837183d"],
        gids: ["5cff8d8b686ea9013aeb3275"],
        gcodes: ["175436"],
        course_completion: 0,
      },
      {
        _id: "5d00bd72679ae3b97c6221d9",
        crs_pg_id: "5d03651aab6457121b59025f",
        btch_name: "Default_Batch_1560503579630_ChapterDelete",
        desc: "lmklm",
        is_open: true,
        act: true,
        name: "ChapterDelete",
        u_tag: "chapterdelete-6936482",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "dsgsg",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-12T08:08:56.000Z",
        l_date_n: 1560326874333,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d00bd72679ae3b97c6221dd", "5d00bd73679ae3b97c6221e0"],
        gids: ["5cff8d8b686ea9013aeb3275"],
        gcodes: ["175436"],
        course_completion: 0,
      },
      {
        _id: "5d00bf90679ae3b97c622229",
        crs_pg_id: "5d03651aab6457121b590261",
        btch_name: "Default_Batch_1560503579638_ChapterDelete",
        desc: "kn",
        is_open: true,
        act: true,
        name: "ChapterDelete",
        u_tag: "chapterdelete-6936482",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "dsgsg",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-12T08:08:56.000Z",
        l_date_n: 1560326874333,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d00bf91679ae3b97c62222d", "5d00bf91679ae3b97c622230"],
        gids: ["5cff8d8b686ea9013aeb3275"],
        gcodes: ["175436"],
        course_completion: 0,
      },
      {
        _id: "5d00c07d679ae3b97c622257",
        crs_pg_id: "5d03651aab6457121b590262",
        btch_name: "Default_Batch_1560503579641_ChapterDelete",
        desc: "j",
        is_open: true,
        act: true,
        name: "ChapterDelete",
        u_tag: "chapterdelete-6936482",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "dsgsg",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-12T08:08:56.000Z",
        l_date_n: 1560326874333,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d00c07d679ae3b97c62225b", "5d00c07d679ae3b97c62225e"],
        gids: ["5cff8d8b686ea9013aeb3275"],
        gcodes: ["175436"],
        course_completion: 100,
      },
      {
        _id: "5d00c0d3679ae3b97c622284",
        crs_pg_id: "5d03651aab6457121b590263",
        btch_name: "Default_Batch_1560503579644_ChapterDelete",
        desc: "m",
        is_open: true,
        act: true,
        name: "ChapterDelete",
        u_tag: "chapterdelete-6936482",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "dsgsg",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-12T08:08:56.000Z",
        l_date_n: 1560326874333,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        repl_id: "REPL684106",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d00c0d3679ae3b97c622288", "5d00c0d3679ae3b97c62228b"],
        gids: ["5cff8d8b686ea9013aeb3275"],
        gcodes: ["175436"],
        course_completion: 100,
      },
      {
        _id: "5d03867596bf7103263ce556",
        crs_pg_id: "5d03867596bf7103263ce555",
        btch_name: "Default_Batch_1560512117564_Coupon Test",
        desc: "\u003cp\u003encourse description\u003c/p\u003e\n",
        is_open: false,
        act: true,
        name: "Coupon Test",
        u_tag: "coupon-test",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "jb",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-14T11:35:17.000Z",
        l_date_n: 1560512053027,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d038ef396bf7103263ce577", "5d038ef396bf7103263ce57a"],
        gids: ["5c63e365dff4c84341d4f94a"],
        gcodes: ["391247"],
        course_completion: 0,
      },
      {
        _id: "5d0390a696bf7103263ce5a0",
        crs_pg_id: "5d03867596bf7103263ce555",
        btch_name: "Batch 1",
        desc: "kjn",
        is_open: true,
        act: true,
        name: "Coupon Test",
        u_tag: "coupon-test",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "jb",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-14T11:35:17.000Z",
        l_date_n: 1560512053027,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d0390a696bf7103263ce5a4", "5d0390a696bf7103263ce5a7"],
        gids: ["5cf0a420b64cd06668e4743c"],
        gcodes: ["215794"],
        course_completion: 0,
      },
      {
        _id: "5d09c72cb391e2cb543d29c2",
        crs_pg_id: "5d09c72cb391e2cb543d29c1",
        btch_name: "Default_Batch_1560921900400_TestForPaid",
        desc: "\u003cp\u003ejcourse description\u003c/p\u003e\n",
        is_open: true,
        act: true,
        name: "TestForPaid",
        u_tag: "testforpaid-1900377",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "TP",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-19T05:25:00.000Z",
        l_date_n: 1560921828444,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d09dd40308e3fdc70c7eb00", "5d09dd40308e3fdc70c7eb03"],
        gids: ["5c63e365dff4c84341d4f94a"],
        gcodes: ["391247"],
        course_completion: 78,
      },
      {
        _id: "5d09cccf308e3fdc70c7eaa9",
        crs_pg_id: "5d03651aab6457121b590254",
        btch_name: "batch 1",
        desc: "jbkjk",
        is_open: false,
        act: true,
        name: "CertificatePanelPaid",
        u_tag: "certificatepanel2-5469319",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "CP2",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-02-13T10:57:49.000Z",
        l_date_n: 1550055451343,
        crtnm: "",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://www.gettyimages.com/gi-resources/images/CreativeLandingPage/HP_Sept_24_2018/CR3_GettyImages-159018836.jpg",
        edby: "5bf3e05eeed93d024419ccc4",
        crs_thumb_url:
          "https://www.gettyimages.com/gi-resources/images/CreativeLandingPage/HP_Sept_24_2018/CR3_GettyImages-159018836.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        crtf_enable: false,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d09ccd0308e3fdc70c7eaad", "5d09ccd0308e3fdc70c7eab0"],
        gids: ["5cf0a420b64cd06668e4743c"],
        gcodes: ["215794"],
        course_completion: 100,
      },
      {
        _id: "5d0a1d1e44b0a57d01a121ec",
        crs_pg_id: "5d09c72cb391e2cb543d29c1",
        btch_name: "Batch 1",
        desc: "jn",
        is_open: false,
        act: true,
        name: "TestForPaid",
        u_tag: "testforpaid-1900377",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "TP",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-19T05:25:00.000Z",
        l_date_n: 1560921828444,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d0a1d1f44b0a57d01a121f0", "5d0a1d1f44b0a57d01a121f3"],
        gids: ["5cf0a420b64cd06668e4743c"],
        gcodes: ["215794"],
        course_completion: 0,
      },
      {
        _id: "5d0a2a720eab27c00a5119f3",
        crs_pg_id: "5d09c72cb391e2cb543d29c1",
        btch_name: "Batch 3",
        desc: "kn",
        is_open: false,
        act: true,
        name: "TestForPaid",
        u_tag: "testforpaid-1900377",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        tag: "TP",
        mref: false,
        duration: "1 Days",
        d_unit: "1",
        d_base: "d",
        l_date: "2019-06-19T05:25:00.000Z",
        l_date_n: 1560921828444,
        crtnm: "",
        v_p_url: "",
        rtg: "",
        intrstr: "Sarthak",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        seo_title: "",
        seo_meta_desc: "",
        enbl_rev: false,
        is_adpt: false,
        __v: 0,
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: ["5d0a2a730eab27c00a5119f7", "5d0a2a730eab27c00a5119fa"],
        gids: ["5cf0a64fb64cd06668e47461"],
        gcodes: ["365429"],
        course_completion: 50,
      },
    ],
    groups: [
      {
        _id: "57a9a9789c3a923c01c5c3c1",
        cat: "2016-08-09T09:59:20.556Z",
        code: "261754",
        name: "demo-tuts",
        gpic: "https://eckgpic.s3.amazonaws.com/57a9a9789c3a923c01c5c3c11510290981943.jpg",
        act: true,
        __v: 0,
        desc: "Some new descriptive description",
      },
      {
        _id: "5b39d029a5f6815a74d85bc9",
        cat: "2018-07-02T07:11:37.842Z",
        code: "718263",
        name: "Testing Paid CF",
        act: true,
        cpid: "57e224308ec11d0464a59e5c",
        caid: "57e224308ec11d0464a59e5b",
        crole: "1",
        desc: "",
        __v: 0,
      },
      {
        _id: "5c63e365dff4c84341d4f94a",
        cat: "2019-02-13T09:29:09.046Z",
        code: "391247",
        name: "NonAdaptiveBatch",
        act: true,
        cpid: "5bf3e05eeed93d024419ccc4",
        caid: "5bf3e05eeed93d024419ccc3",
        crole: "3",
        __v: 0,
      },
      {
        _id: "5cf0a420b64cd06668e4743c",
        cat: "2019-05-31T03:48:48.449Z",
        code: "215794",
        name: "NonAdaptiveBatch1",
        act: true,
        cpid: "5bf3e05eeed93d024419ccc4",
        caid: "5bf3e05eeed93d024419ccc3",
        crole: "3",
        __v: 0,
      },
      {
        _id: "5cf0a64fb64cd06668e47461",
        cat: "2019-05-31T03:58:07.988Z",
        code: "365429",
        name: "NonAdaptiveBatch3",
        act: true,
        cpid: "5bf3e05eeed93d024419ccc4",
        caid: "5bf3e05eeed93d024419ccc3",
        crole: "3",
        __v: 0,
      },
      {
        _id: "5cfe0857220ae1855a40ab81",
        cat: "2019-06-10T07:35:51.275Z",
        code: "792458",
        name: "OldChild1",
        act: true,
        cpid: "5bf3e05eeed93d024419ccc4",
        caid: "5bf3e05eeed93d024419ccc3",
        crole: "3",
        __v: 0,
      },
      {
        _id: "5cfe085d220ae1855a40ab83",
        cat: "2019-06-10T07:35:57.076Z",
        code: "481539",
        name: "OldChild2",
        act: true,
        cpid: "5bf3e05eeed93d024419ccc4",
        caid: "5bf3e05eeed93d024419ccc3",
        crole: "3",
        __v: 0,
      },
      {
        _id: "5cfe3e5bc12d15767346ca6a",
        cat: "2019-06-10T11:26:19.234Z",
        code: "794638",
        name: "Paytm Batch 2",
        act: true,
        cpid: "5bf3e05eeed93d024419ccc4",
        caid: "5bf3e05eeed93d024419ccc3",
        crole: "3",
        __v: 0,
      },
      {
        _id: "5cff8d8b686ea9013aeb3275",
        cat: "2019-06-11T11:16:27.511Z",
        code: "175436",
        name: "LastBatchPaidFreeAdaptive",
        act: true,
        cpid: "5bf3e05eeed93d024419ccc4",
        caid: "5bf3e05eeed93d024419ccc3",
        crole: "3",
        __v: 0,
      },
      {
        _id: "5cff8efa686ea9013aeb32b0",
        cat: "2019-06-11T11:22:34.061Z",
        code: "546791",
        name: "LastBatchP/F/A1",
        act: true,
        cpid: "5bf3e05eeed93d024419ccc4",
        caid: "5bf3e05eeed93d024419ccc3",
        crole: "3",
        __v: 0,
      },
    ],
    resume_course: {
      _id: "5d1c441fc06af7680b36ca85",
      updatedAt: "2019-07-03T06:21:59.643Z",
      createdAt: "2019-07-03T05:58:55.000Z",
      cid: "5d09c72cb391e2cb543d29c2",
      cpid: "5d1c40c1c06af7680b36ca7a",
      did: "5d1c40c1c06af7680b36ca7a",
      aid: "5cff7343686ea9013aeb30dc",
      pid: "5cff7343686ea9013aeb30dd",
      typ: 1,
      stts: 4,
      act: true,
      __v: 0,
      tim: 1562134919642,
      ncl: 1,
      course: [
        {
          _id: "5d09c72cb391e2cb543d29c2",
          crs_pg_id: "5d09c72cb391e2cb543d29c1",
          btch_name: "Default_Batch_1560921900400_TestForPaid",
          desc: "\u003cp\u003ejcourse description\u003c/p\u003e\n",
          is_open: true,
          act: true,
          name: "TestForPaid",
          u_tag: "testforpaid-1900377",
          t_url:
            "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
          s_url:
            "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
          tag: "TP",
          mref: false,
          duration: "1 Days",
          d_unit: "1",
          d_base: "d",
          l_date: "2019-06-19T05:25:00.000Z",
          l_date_n: 1560921828444,
          crtnm: "",
          v_p_url: "",
          rtg: "",
          intrstr: "",
          crsimg:
            "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
          crs_thumb_url:
            "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
          seo_title: "",
          seo_meta_desc: "",
          enbl_rev: false,
          is_adpt: false,
          __v: 0,
          crtf_enable: true,
          u_tag_alias: [],
          i_seats_l: false,
          i_paid: true,
          grp_pl: ["5d09dd40308e3fdc70c7eb00", "5d09dd40308e3fdc70c7eb03"],
          gids: ["5c63e365dff4c84341d4f94a"],
          gcodes: ["391247"],
          course_completion: 78,
        },
      ],
    },
    combo_courses: [
      {
        _id: "5c8652575ecbc14777972fd4",
        name: "ComboParent",
        tag: "AGB1",
        desc: "\u003cp\u003ehcourse description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url: "https://timesofindia.indiatimes.com/photo/55858929.cms",
        crsimg: "https://timesofindia.indiatimes.com/photo/55858929.cms",
        v_p_url: "https://www.youtube.com/watch?v\u003dqdkDicI1Vp8",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1552306762055,
        l_date: "2019-03-11T12:19:35.000Z",
        act: true,
        u_tag: "androidgraduationbug1-6775055",
        __v: 0,
        intrstr: "Sarthak",
        edby: "5bf3e05eeed93d024419ccc4",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651aab6457121b590236",
        btch_name: "Default_Batch_1560503579507_ComboParent",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: [],
        gids: [],
        child_courses: ["5c6fc8d0d6b34a222303c8ff", "5c6fc916d6b34a222303c902"],
      },
      {
        _id: "5cfdfe9a726fa02358951a60",
        name: "OldBatchParent",
        tag: "OBP",
        desc: "\u003cp\u003ecoudsrse description\u003c/p\u003e\n",
        s_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo.jpg",
        t_url:
          "https://s3-ap-southeast-1.amazonaws.com/web-public-res/publicgroups/plugins/quiz_promo_thumb.jpg",
        crs_thumb_url:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        crsimg:
          "https://helpx.adobe.com/content/dam/help/en/stock/how-to/visual-reverse-image-search/jcr_content/main-pars/image/visual-reverse-image-search-v2_intro.jpg",
        v_p_url: "",
        d_unit: "1",
        d_base: "d",
        duration: "1 Days",
        l_date_n: 1560149593658,
        l_date: "2019-06-10T06:54:18.000Z",
        act: true,
        u_tag: "oldbatchparent-9658643",
        __v: 0,
        intrstr: "Sarthak",
        is_open: true,
        repl_id: "REPL684106",
        crs_pg_id: "5d03651bab6457121b5902d9",
        btch_name: "Default_Batch_1560503580004_OldBatchParent",
        crtf_enable: true,
        u_tag_alias: [],
        i_seats_l: false,
        i_paid: true,
        grp_pl: [],
        gids: [],
        child_courses: ["5cfdfee8726fa02358951a63", "5cfdff37726fa02358951a66"],
      },
    ],
  },
};
export const updateTimeZone = (tz) => {
  return {
    type: "UPDATE_PROFILE_TIMEZONE",
    payload: {
      tz,
    },
  };
};

export const placementProfileData = (cid) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.PLACEMENT_PROFILE_DETAILS,
        {
          cid: cid,
          aid: cookies.get("aid"),
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const placementJobData = (cid) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.PLACEMENT_JOB_DETAILS,
        {
          cid: cid,
          aid: cookies.get("aid"),
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const singleJobData = (cid, job_id) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.PLACEMENT_SINGLE_JOB_DETAILS,
        {
          cid: cid,
          aid: cookies.get("aid"),
          job_id: job_id,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const applyToJob = (
  crs_pg_id,
  job_id,
  resume_id,
  additionalQuestions
) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.PLACEMENT_APPLY_JOB,
        {
          crs_pg_id: crs_pg_id,
          aid: cookies.get("aid"),
          job_id: job_id,
          resume_id: resume_id,
          additionalQuestions: additionalQuestions,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const addLearnerRemarks = (crs_pg_id, job_id, aid, learnerRemarks) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.PLACEMENT_ADD_LEARNER_REMARKS,
        {
          crs_pg_id: crs_pg_id,
          job_id: job_id,
          aid: aid,
          learnerRemarks: learnerRemarks,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const snoozeProfilePopup = () => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(ROUTES.PLACEMENT_SNOOZE_POPUP, {}, configs)
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const changeSaveStatus = (job_app_id, save_status) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.PLACEMENT_SAVE_JOB,
        {
          job_app_id: job_app_id,
          aid: cookies.get("aid"),
          save_status: save_status,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export var getPlacementProfile = (slag, crs_pg_id) => {
  console.log({ slag });
  let aid = cookies.get("aid");
  console.log({ crs_pg_id });
  // headers["x-access-crs-token"] = cookies.get("crs_tkn");
  return new Promise(async (resolve, reject) => {
    let statusCode;
    let URL = `${ROUTES.PLACEMENT_PROFILE_SCHEMA}`;
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at") || "",
      "x-access-crs-token": cookies.get("crs_tkn"),
    };
    if (cookies.at || cookies.aid) headers["cookies"] = cookies;
    axios({
      method: "post",
      url: URL,
      headers,
      data: {
        crs_pg_id,
        slag: slag || "",
        aid,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res);
        }
        console.log("getPlacementProfile::");
        console.log({ data: res.data });
        statusCode = res.status;
        if (res.data.success) {
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export var getPlacementProfilePreview = (crs_pg_id) => {
  let aid = cookies.get("aid");
  console.log({ crs_pg_id });
  return new Promise(async (resolve, reject) => {
    let statusCode;
    let URL = `${ROUTES.PLACEMENT_PROFILE_PREVIEW}`;
    let headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-access-token": cookies.get("at") || "",
      "x-access-crs-token": cookies.get("crs_tkn"),
    };
    if (cookies.at || cookies.aid) headers["cookies"] = cookies;
    axios({
      method: "post",
      url: URL,
      headers,
      data: {
        crs_pg_id,
        aid,
      },
    })
      .then((res) => {
        if (!res) {
          return reject(res);
        }
        console.log("getPlacementProfile::");
        console.log({ data: res.data });
        statusCode = res.status;
        if (res.data.success) {
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const saveProfileDetails = (crs_pg_id, slug, profileData) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    // configs.data = formData;
    return axios
      .post(
        ROUTES.SAVE_PROFILE_DATA,
        {
          crs_pg_id: crs_pg_id,
          slug: slug,
          aid: cookies.get("aid"),
          profileData: profileData,
        },
        configs
      )
      .then((response) => {
        console.log("Le Response:", response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const saveResumeDetails = (docresume, vidresume) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    // configs.data = formData;
    return axios
      .post(
        ROUTES.SAVE_USER_RESUME,
        {
          docresume: docresume,
          vidresume: vidresume,
          aid: cookies.get("aid"),
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const deleteUserResume = (resume_id, aid) => {
  return new Promise((resolve, reject) => {
    let configs = {};
    let headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    // configs.data = formData;
    return axios
      .post(
        ROUTES.DELETE_USER_RESUME,
        {
          resume_id: resume_id,
          aid: aid,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        reject(err);
      });
  });
};

export const uploadUserResume = (file) => {
  return new Promise((resolve, reject) => {
    let aid = cookies.get("aid");
    let fd = new FormData();
    if (file != undefined) fd.set("file", file);
    if (aid != undefined) fd.set("aid", aid);
    let getData = {
      url: `${ROUTES.UPLOAD_USER_RESUME}`,
      method: "POST",
      headers: {
        "x-access-token": cookies.get("at"),
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "x-access-crs-token": cookies.get("crs_tkn"),
      },
      data: fd,
    };
    axios(getData)
      .then((res) => {
        console.log({ data: res.data });
        let statusCode = res.status;
        if (res.data.success) {
          console.log("uploadUserResume data!!", res.data);
          return resolve({ ...res.data, status: statusCode });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const uploadUserProfilePic = (file) => {
  return new Promise((resolve, reject) => {
    let aid = cookies.get("aid");
    let fd = new FormData();
    if (file != undefined) fd.set("file", file);
    if (aid != undefined) fd.set("aid", aid);
    let getData = {
      url: `${ROUTES.UPLOAD_USER_RESUME}`,
      method: "POST",
      headers: {
        "x-access-token": cookies.get("at"),
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "x-access-crs-token": cookies.get("crs_tkn"),
      },
      data: fd,
    };
    axios(getData)
      .then((res) => {
        console.log({ data: res.data });
        let statusCode = res.status;
        if (res.data.success) {
          console.log("uploadUserProfilePic data!!", res.data);
          return resolve({ ...res.data, status: statusCode });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};

export const uploadUserAgreement = (file) => {
  return new Promise((resolve, reject) => {
    let aid = cookies.get("aid");
    let fd = new FormData();
    if (file != undefined) fd.set("file", file);
    if (aid != undefined) fd.set("aid", aid);
    let getData = {
      url: `${ROUTES.UPLOAD_USER_RESUME}`,
      method: "POST",
      headers: {
        "x-access-token": cookies.get("at"),
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        "x-access-crs-token": cookies.get("crs_tkn"),
      },
      data: fd,
    };
    axios(getData)
      .then((res) => {
        console.log({ data: res.data });
        let statusCode = res.status;
        if (res.data.success) {
          console.log("uploadUserProfilePic data!!", res.data);
          return resolve({ ...res.data, status: statusCode });
        } else {
          console.log("API call failed!", res.data);
          return resolve({ ...res.data, status: statusCode });
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err);
      });
  });
};
