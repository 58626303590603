import { URLS } from "../constants/constants";

const initialState = {
  packages: [],
  pl_packages: [],
  pl: null,
  cpn_code: "",
  updated_price: -1,
  is_cp_applied: false,
  is_rp_res: false,
  rp_status: null,
};

const pluginReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_PPIC_NAME":
      return {
        ...state,
        p_name: action.p_name,
        ppic: action.ppic ? action.ppic : URLS.DEFAULT_PPIC_URL,
      };
    case "SET_GROUP_DETAILS":
      return {
        ...state,
        packages: action.packages,
      };
    case "SET_PLUGIN_DETAILS":
      return {
        ...state,
        pl_packages: action.pl_packages,
        pl: action.pl,
      };
    case "COUPON_APPLIED":
      return {
        ...state,
        is_cp_applied: true,
        updated_price: action.updated_price,
        cpn_code: action.cpn_code,
      };
    case "RESET_COUPON":
      return {
        ...state,
        is_cp_applied: false,
        updated_price: -1,
        cpn_code: "",
      };
    case "SET_RAZORPAY_RES":
      return {
        ...state,
        is_rp_res: true,
        rp_status: action.rp_status,
      };
    case "RESET_RAZORPAY_RES":
      return {
        ...state,
        is_rp_res: false,
        rp_status: null,
      };
    default:
      return state;
  }
};

export default pluginReducer;
