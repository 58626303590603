import ReactCrop from "../utils/ReactCropDemo";
// import ReactCrop from 'react-image-crop';
import React, { Component, Fragment } from "react";
import "react-image-crop/dist/ReactCrop.css";
import {
  image64toCanvasRef,
  base64StringtoFile,
  downloadBase64File,
  extractImageFileExtensionFromBase64,
} from "../../utility/utils";
import {
  updatePicURL,
  setProfilePicExtension,
} from "../../actions/profileAction";
import { connect } from "react-redux";
import {
  URLS,
  VALID_PROFILE_PIC_TYPES,
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";

class ImageCrop extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      url: "",
      img_src: null,
      is_cropped: false,
      crop: {
        aspect: 1 / 1,
      },
      orignal_img: null,
    };
  }
  componentDidMount() {
    this.setState({
      img_src: this.props.img_src,
      orignal_img: this.props.edit_ppic,
    });

    var save_btn = document.getElementById("edit-modal-save-btn");
  }
  componentDidUpdate(prevProps) {
    if (prevProps.img_src != this.props.img_src) {
      this.setState({
        img_src: this.props.img_src,
      });
    }
  }

  handleImageLoaded = (image) => {};
  handleOnCropChange = (crop) => {
    this.setState({
      crop: crop,
    });
    this.props.setOriginalPicAndCrop(this.state.orignal_img, crop);
  };
  handleOnCropComplete = (pixel_crop, crop) => {
    const canvasRef = this.imagePreviewCanvasRef.current;
    // const {img_src} = this.state;
    const img_src = this.props.edit_ppic;

    image64toCanvasRef(canvasRef, img_src, pixel_crop);
  };
  getBase64Image = (img) => {
    // img.setAttribute('crossOrigin', 'anonymous');
    // var canvas = document.createElement("canvas");
    // canvas.width = img.width;
    // canvas.height = img.height;
    // var ctx = canvas.getContext("2d");
    // ctx.drawImage(img, 0, 0);
    // var dataURL = canvas.toDataURL("image/jpg");
    // return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    // axios.get("https://eckppic.s3.amazonaws.com/5c25d7b79c349daa59dc83181547794360360.jpg")
    // .then(res => {
    // })
    // .catch(err=>{
    // })
  };

  // getBase64FromImageUrl = (url) => {
  //     var img = document.getElementById('edit-img-id');

  //     img.setAttribute('crossOrigin', 'anonymous');

  //     img.onload = function () {
  //         var canvas = document.createElement("canvas");
  //         canvas.width =this.width;
  //         canvas.height =this.height;

  //         var ctx = canvas.getContext("2d");
  //         ctx.drawImage(this, 0, 0);

  //         var dataURL = canvas.toDataURL("image/png");

  //         alert(dataURL.replace(/^data:image\/(png|jpg);base64,/, ""));
  //     };

  //     img.src = url;
  // }

  // convertToBase64 = () =>{
  //     const myFileItemReader = new FileReader()
  //     myFileItemReader.addEventListener("load",()=>{
  //         this.setState({
  //             img_src : myFileItemReader.result
  //         } ,()=> { })
  //     },false)
  // }

  handleDownloadClick = (event) => {
    // if(!this.isImageCropped()){
    //     this.displayPopover(POPOVER_HEADINGS.NO_IMAGE_SELECTION,POPOVER_CONTENT.NO_IMAGE_SELECTION,POPOVER_TYPE.ERROR);
    //     return;
    // }
    // return;
    if (document.getElementById("canvas-container-id")) {
    }
    // alert(this.state.is_cropped);
    // if(!this.state.is_cropped){
    //     // alert("not cropped");
    //     return;
    // }
    event.preventDefault();
    const canvasRef = this.imagePreviewCanvasRef.current;
    // const {img_src} = this.state;
    const img_src = this.props.edit_ppic;
    const file_extension = extractImageFileExtensionFromBase64(img_src);

    const imageData64 = canvasRef.toDataURL("/image" + file_extension);
    const myfilename = "preview." + file_extension;
    const my_new_cropped_file = base64StringtoFile(imageData64, myfilename);
    this.props.updatePicURL(imageData64);
    //download file
    // downloadBase64File(imageData64, myfilename);

    //convert to base 64
    // var reader = new FileReader();
    // reader.readAsDataURL(my_new_cropped_file);
    // reader.onload = function () {
    //   this.props.updatePicURL(reader.result);
    // }.bind(this);
  };

  isImageCropped = () => {
    var crop = this.state.crop;
    if (crop.width == 0 || crop.height == 0) {
      return false;
    } else {
      return true;
    }
  };

  picSelected = (e) => {
    if (!e.target || !e.target.files[0]) {
      return;
    }
    var current_file = e.target.files[0];
    var is_type_valid = false;
    for (var i = 0; i < VALID_PROFILE_PIC_TYPES.length; i++) {
      if (current_file.type == VALID_PROFILE_PIC_TYPES[i]) {
        is_type_valid = true;
        break;
      }
    }
    if (is_type_valid) {
      this.props.setProfilePicExtension(current_file.type);
      // alert("valid");
    } else {
      this.displayPopover(
        POPOVER_HEADINGS.INVALID_PP_EXT,
        POPOVER_CONTENT.INVALID_PP_EXT,
        POPOVER_TYPE.ERROR
      );
      e.target = null;
      return;
    }
    var updatePicURL = this.props.updatePicURL;
    var updatePicFileObject = this.props.updatePicFileObject;
    const myFileItemReader = new FileReader();
    myFileItemReader.addEventListener("load", () => {
      var img = new Image();
      img.src = myFileItemReader.result;
      img.onload = function () {
        var w = this.naturalWidth;
        var h = this.height;
        // alert(w);
        // alert(h);
        updatePicURL(myFileItemReader.result);
        updatePicFileObject(current_file);
        // this.width = 120;
        // this.height = 120;
      };
      // this.props.updatePicURL(myFileItemReader.result);
      // this.setState({
      //     img_src : myFileItemReader.result
      // })
    });

    var url = URL.createObjectURL(e.target.files[0]);
    // this.props.updatePicURL(url);
    // this.setState({
    //     img_src : url
    // })
    // this.setState({
    //     url : url
    // })
  };
  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };

  render() {
    // if(this.state.img_src && document.getElementById("edit-img-id")){

    //         var base64 = this.getBase64Image(document.getElementById("edit-img-id"));

    // }
    const { img_src } = this.state;
    if (this.props.edit_ppic) {
      return (
        <Fragment>
          <div className="img-crop-container">
            <ReactCrop
              crop={this.state.crop}
              onChange={this.handleOnCropChange}
              src={this.props.edit_ppic}
              onImageLoaded={this.handleImageLoaded}
              // imageStyle = {{backgroundColor: 'red', borderRadius: '50%'}}
              // style={{borderRadius:"50%"}}
              onComplete={this.handleOnCropComplete}
            />
          </div>
          <div id={"canvas-container-id"} className="img-canvas-container">
            <canvas
              className="img-crop-canvas"
              id
              ref={this.imagePreviewCanvasRef}
            ></canvas>
          </div>
          <input
            type="file"
            className="hide"
            onChange={(e) => this.picSelected(e)}
            id="edit-file"
          ></input>
          <button onClick={(e) => this.handleDownloadClick(e)}>Download</button>
        </Fragment>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }
}

// export default ImageCrop;
const mapStateToProps = (state) => ({
  edit_ppic: state.profile.edit_ppic,
});

const mapDispatchToProps = {
  updatePicURL,
  showPopover,
  setProfilePicExtension,
  // updatePicFileObject
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageCrop);
