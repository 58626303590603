import axios from "axios";
import _ from "lodash";
import cookie from "react-cookies";
import { ROUTES } from "../constants/routes";
import {
  POPOVER_CONTENT,
  LOADING_MODAL_MSG,
  AWS_BUCKET_URLS,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  URLS,
  POPOVER_HIDETIME,
  CONFIGS,
  PLUGIN_CATEGORY,
  GROUP_MEMBER_TYPE,
  PLUGIN_ROUTE_VALUES,
} from "../constants/constants";
import {
  BACKEND_ERRORS,
  BACKEND_ERROR_CODES,
  FRONTEND_ERRORS,
  ERROR_MESSAGES,
  ERROR_BLOCK,
} from "../constants/errors";
import { utils } from "../utility/utils";
import { renewAccessToken } from "./utilAction";
import { base64StringtoFile } from "../utility/utils";
import XMLParser from "../lib/xml-parser";
import queryString from "query-string";

export const fetchGroups = (pid) => (dispatch) => {
  let g_info_route =
    ROUTES.FETCH_GROUP +
    "?_=" +
    new Date().getTime() +
    "&p_id=" +
    pid +
    "&tokn=" +
    utils.getAt();
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios
    .get(g_info_route, config)
    .then((groups) => {
      var res = groups.data.data;
      var action = {};
      var groups = res.groups;
      groups.sort((a, b) => a.name.localeCompare(b.name));
      action.groups = groups;
      action.groupmembers = res.groupmembers;
      action.type = "USER_JOINED_GROUPS";
      dispatch(action);
    })
    .catch((err) => {
      if (
        err.response &&
        err.response.data.code == BACKEND_ERROR_CODES.NO_GROUP_PLUGINS
      ) {
        var action = {};
        action.groups = [];
        action.groupmembers = [];
        action.courses = [];
        action.type = "NULL_GRP_CRS";
        dispatch(action);
      } else if (
        err.response &&
        err.response.data.code == BACKEND_ERROR_CODES.GROUP_NOT_FOUND
      ) {
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

export const updateGroupSequence = (g_id, groups) => (dispatch) => {
  var data = {
    g_id: g_id,
    p_id: utils.getPid(),
    tokn: utils.getAt(),
  };
  axios
    .post(ROUTES.UPDATE_GRPIDS_SEQ, data)
    .then((updated_res) => {
      var gid_seq =
        updated_res &&
        updated_res.data &&
        updated_res.data.data &&
        updated_res.data.data.gid_seq
          ? updated_res.data.data.gid_seq
          : [];
      groups = _.sortBy(groups, function (obj) {
        return _.indexOf(gid_seq, obj._id);
      });
      var action = {};
      action.groups = groups;
      action.type = "UPDATE_JOINED_GROUPS";
      dispatch(action);
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch);
    });
};

export const getGroupPlugins =
  (gid, query_pid, from_edit_page) => (dispatch) => {
    var data = {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        g_id: gid,
        p_id: utils.getPid() ? utils.getPid() : query_pid,
        tokn: utils.getAt(),
      },
    };
    axios
      .get(ROUTES.MEMBER_CHECK, data)
      .then((mem_check) => {
        var check_data = mem_check.data.data;
        var grp_plugin_route = ROUTES.GROUP_PLUGIN;
        var data = {
          headers: {
            "Content-Type": "application/json",
          },
          g_id: gid,
          p_id: utils.getPid() ? utils.getPid() : query_pid,
          tokn: utils.getAt(),
        };
        var action = {};
        action.type = "SET_MEMBER_TYPE";
        if (check_data.member.type == GROUP_MEMBER_TYPE.ADMIN) {
          action.is_admin = true;
        } else if (check_data.member.type == GROUP_MEMBER_TYPE.MEMBER) {
          action.is_admin = false;
        }
        dispatch(action);
        axios
          .post(grp_plugin_route, data)
          .then((grp_plugin) => {
            var gp_data = grp_plugin.data.data;
            var grp_obj = {};
            var all_plugins = [],
              paid_plugins_arr,
              is_paid,
              paid_plugins = {},
              sub_plugins = {},
              all_courses = [];
            var quiz_plugins = [];
            var feed_plugins = [];
            var video_plugins = [];
            var sub_plugins_arr = gp_data.subscriptions;
            all_plugins = gp_data.plugins;
            all_courses = gp_data.groupcourse;
            paid_plugins_arr = gp_data.paid_plugins;

            for (var i = 0; i < paid_plugins_arr.length; i++) {
              paid_plugins[paid_plugins_arr[i]] = 1;
            }
            for (var i = 0; i < sub_plugins_arr.length; i++) {
              sub_plugins[sub_plugins_arr[i]] = 1;
            }

            for (var i = 0; i < all_plugins.length; i++) {
              if (all_plugins[i].catg == PLUGIN_CATEGORY.VIDEO) {
                if (paid_plugins[all_plugins[i]._id]) {
                  all_plugins[i].is_paid = true;
                } else {
                  all_plugins[i].is_paid = false;
                }
                if (sub_plugins[all_plugins[i]._id]) {
                  all_plugins[i].is_sub = true;
                } else {
                  all_plugins[i].is_sub = false;
                }
                video_plugins.push(all_plugins[i]);
              } else if (all_plugins[i].catg == PLUGIN_CATEGORY.FEED) {
                if (paid_plugins[all_plugins[i]._id]) {
                  all_plugins[i].is_paid = true;
                } else {
                  all_plugins[i].is_paid = false;
                }
                if (sub_plugins[all_plugins[i]._id]) {
                  all_plugins[i].is_sub = true;
                } else {
                  all_plugins[i].is_sub = false;
                }
                feed_plugins.push(all_plugins[i]);
              } else if (all_plugins[i].catg == PLUGIN_CATEGORY.QUIZ) {
                if (paid_plugins[all_plugins[i]._id]) {
                  all_plugins[i].is_paid = true;
                } else {
                  all_plugins[i].is_paid = false;
                }
                if (sub_plugins[all_plugins[i]._id]) {
                  all_plugins[i].is_sub = true;
                } else {
                  all_plugins[i].is_sub = false;
                }
                quiz_plugins.push(all_plugins[i]);
              }
            }
            grp_obj.quiz = quiz_plugins;
            grp_obj.video = video_plugins;
            grp_obj.feed = feed_plugins;
            grp_obj.course = all_courses;
            grp_obj.packages = gp_data.packages;
            grp_obj.type = "SET_GROUP_DETAILS";
            dispatch(grp_obj);
          })
          .catch((err) => {
            var err_obj = {};
            if (
              err.response &&
              err.response.data.code == BACKEND_ERROR_CODES.NO_GROUP_PLUGINS
            ) {
              err_obj.type = "NULLIFY_PLUGINS";
              dispatch(err_obj);
            } else if (
              err.response &&
              err.response.data.code == BACKEND_ERROR_CODES.INVALID_PARAMS
            ) {
              err_obj.type = "SET_NOT_FOUND";
              err_obj.val = true;
              dispatch(err_obj);
            } else {
              utils.handleErrors(err, dispatch);
            }
          });
      })
      .catch((err) => {
        var err_obj = {};

        if (
          err.response &&
          err.response.data &&
          err.response.data.code == BACKEND_ERROR_CODES.NO_GROUP_PLUGINS
        ) {
          err_obj.type = "NULLIFY_PLUGINS";
          dispatch(err_obj);
        } else if (
          err.response &&
          err.response.data.code == BACKEND_ERROR_CODES.INVALID_PARAMS
        ) {
          err_obj.type = "SET_NOT_FOUND";
          err_obj.val = true;
          dispatch(err_obj);
        } else if (
          from_edit_page &&
          err.response &&
          err.response.data.code == BACKEND_ERROR_CODES.GROUP_NOT_FOUND
        ) {
          err_obj.type = "REDIRECT_TO_MGP";
          err_obj.val = true;
          dispatch(err_obj);
        } else {
          utils.handleErrors(err, dispatch);
        }
      });
  };

export const setSelectedGroupDetails = (grp_dtl, index) => (dispatch) => {
  var grp_obj = {};
  grp_obj.name = grp_dtl.name;
  grp_obj._id = grp_dtl._id;
  grp_obj.index = index;
  grp_obj.gpic = grp_dtl.gpic;
  grp_obj.code = grp_dtl.code;
  grp_obj.grp_type = grp_dtl.gtyp;
  grp_obj.type = "SET_GROUP_DETAILS_FROM_SIDEBAR";
  dispatch(grp_obj);
};

export const setGroupDetailsInSettings = (gid, groups) => (dispatch) => {
  var group = {};
  for (var i = 0; i < groups.length; i++) {
    if (groups[i]._id == gid) {
      group = groups[i];
      break;
    }
  }
  if (!group._id) {
    return;
  }
  var grp_obj = {};
  grp_obj.name = group.name;
  grp_obj._id = group._id;
  // grp_obj.index = index;
  grp_obj.gpic = group.gpic;
  grp_obj.code = group.code;
  grp_obj.grp_type = group.gtyp;
  grp_obj.gd_desc = group.desc;
  grp_obj.type = "SET_GROUP_DETAILS_FROM_SIDEBAR";
  dispatch(grp_obj);
};

export const createGroupBackend = (g_name) => (dispatch) => {
  /*
  earlier routes hit after group/index  (POST)
  /profile/find    --> aid && tokn
  /group/index   (GET)
  /groupmember/check   (GET)
  /message/previous_v14   (GET)
  /plugin/g_pl_cf   (POST)
  */
  var data = {};
  data.name = g_name;
  data.p_id = utils.getPid();
  data.tokn = utils.getAt();
  axios
    .post(ROUTES.CREATE_GROUP, data)
    .then((cg_res) => {
      if (!cg_res || !cg_res.data || !cg_res.data.data) {
        return;
      }
      cg_res = cg_res.data.data;
      var gid = cg_res.Group._id;
      var discuss_url =
        CONFIGS.ECKOVATION_WEB_ENDPOINT +
        "group/" +
        gid +
        "?pid=" +
        utils.getPid();
      window.location.href = discuss_url;
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch);
    });
};

export const updateGPicURL = (img) => (dispatch) => {
  var action = {};
  action.type = "UPDATE_GPIC";
  action.edit_gpic = img;
  dispatch(action);
  // var p_map = {}
};

export const deleteGPicBackend = (groups, gid) => (dispatch) => {
  var loading_action = {};
  loading_action.type = "SET_LOADING_MODAL";
  loading_action.lm_msg = LOADING_MODAL_MSG.UPDATING;
  loading_action.value = true;
  dispatch(loading_action);
  loading_action.value = false;
  loading_action.lm_msg = "";

  var data = {};
  data.g_id = gid;
  data.p_id = utils.getPid();
  data.tokn = utils.getAt();
  axios
    .put(ROUTES.GROUP_PIC_REMOVE, data)
    .then((del_res) => {
      if (!del_res || !del_res.data || !del_res.data.data) {
        return;
      }
      var rm_action = {};
      rm_action.type = "REMOVE_GPIC";
      for (var i = 0; i < groups.length; i++) {
        if (groups[i]._id == gid) {
          groups[i].gpic = URLS.DEFAULT_GPIC_URL;
          break;
        }
      }
      rm_action.groups = groups;
      rm_action.edit_gpic = URLS.DEFAULT_GPIC_URL;
      rm_action.gd_gpic = URLS.DEFAULT_GPIC_URL;
      del_res = del_res.data.data;
      dispatch(rm_action);
      dispatch(loading_action);
      utils.showPopover(
        POPOVER_HEADINGS.DEL_GPIC_SUCCESS,
        POPOVER_CONTENT.DEL_GPIC_SUCCESS,
        POPOVER_TYPE.SUCCESS,
        dispatch
      );
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch, ERROR_BLOCK.DELETE_GPIC);
    });
};

export const redirectToLearn = (gid) => (dispatch) => {
  var learn_url =
    CONFIGS.WEB_DASHBOARD_BASE_URL +
    "course_home/" +
    utils.getPid() +
    "/" +
    gid;
  var win = window.open(learn_url);
  win.focus();
};
export const setGDBack = () => (dispatch) => {
  dispatch({
    type: "SET_GD_BACK",
  });
};
export const resetGDBack = () => (dispatch) => {
  dispatch({
    type: "RESET_GD_BACK",
  });
};
export const setGSettingBack = () => (dispatch) => {
  dispatch({
    type: "SET_GSETTING_BACK",
  });
};
export const resetGSettingBack = () => (dispatch) => {
  dispatch({
    type: "RESET_GSETTING_BACK",
  });
};

export const updateGroupDescriptionBackend =
  (gid, desc, groups) => (dispatch) => {
    var data = {};
    data.p_id = utils.getPid();
    data.g_id = gid;
    data.desc = desc;
    data.tokn = utils.getAt();
    axios
      .post(ROUTES.GROUP_SETTINGS, data)
      .then((gs_res) => {
        var updated_groups = [];
        for (var i = 0; i < groups.length; i++) {
          var temp = {};
          for (var k in groups[i]) {
            temp[k] = groups[i][k];
          }
          if (groups[i]._id == gid) {
            temp.desc = desc;
          }
          updated_groups.push(temp);
        }
        var action = {};
        action.type = "UPDATE_GROUP_DESC";
        action.groups = updated_groups;
        action.gd_desc = desc;
        dispatch(action);
        utils.showPopover(
          POPOVER_HEADINGS.SUCCESS,
          POPOVER_CONTENT.GDESC_UPDATE_SUCCESS,
          POPOVER_TYPE.SUCCESS,
          dispatch
        );
      })
      .catch((err) => {
        utils.handleErrors(err, dispatch);
      });
  };

export const deleteGroupBackend = (groups, gid) => (dispatch) => {
  var loading_action = {};
  loading_action.type = "SET_LOADING_MODAL";
  loading_action.lm_msg = LOADING_MODAL_MSG.UPDATING;
  loading_action.value = true;
  dispatch(loading_action);
  loading_action.value = false;
  loading_action.lm_msg = "";

  var data = {};
  data.g_id = gid;
  data.p_id = utils.getPid();
  data.tokn = utils.getAt();
  axios
    .post(ROUTES.GROUP_REMOVE, data)
    .then((del_res) => {
      var updated_groups = [];
      var rm_action = {};
      rm_action.type = "REMOVE_GROUP";
      for (var i = 0; i < groups.length; i++) {
        if (groups[i]._id != gid) {
          updated_groups.push(groups[i]);
        }
      }
      rm_action.groups = updated_groups;
      dispatch(rm_action);
      dispatch(loading_action);
      if (updated_groups.length == 0) {
        var db_action = {};
        db_action.type = "REDIRECT_TO_HOME";
        dispatch(db_action);
      }
      utils.showPopover(
        POPOVER_HEADINGS.DEL_GROUP_SUCCESS,
        POPOVER_CONTENT.DEL_GROUP_SUCCESS,
        POPOVER_TYPE.SUCCESS,
        dispatch
      );
    })
    .catch((err) => {
      dispatch(loading_action);
      utils.handleErrors(err, dispatch, ERROR_BLOCK.DELETE_GROUP);
    });
};

const getS3Credentials = (file_ext, gid, cb) => {
  var data = {
    params: {},
  };
  var d = new Date();
  data.params.f_nm =
    utils.getPid() +
    d.getTime() +
    "." +
    (file_ext ? file_ext.split("/")[1] : "");
  data.params.f_tp = file_ext ? file_ext.split("/")[1] : "";
  data.params.m_od = "image";
  data.params.g_id = gid;
  data.params.p_id = utils.getPid();
  data.params.tokn = utils.getAt();
  axios
    .get(ROUTES.GROUP_PIC_WEB, data)
    .then((res) => {
      cb(null, res.data.data);
      //code to upload to aws
    })
    .catch((err) => {
      cb(err, null);
      // utils.handleErrors(err,dispatch);
    });
};

export const setGroupPicExtension = (ext) => (dispatch) => {
  var action = {};
  action.type = "SET_GPIC_EXT";
  action.gpic_ext = ext;
  dispatch(action);
};

export const updateGroupDetailsToBackend =
  (update_obj, groups, gid, profiles) => (dispatch) => {
    /* the function included three section handled individually
	   1. updating both name name and pic 
	   2. updating only name 
	   3. updating only pic  */
    var data = {
      params: {},
    };
    var loading_action = {};
    loading_action.type = "SET_LOADING_MODAL";
    loading_action.lm_msg = LOADING_MODAL_MSG.UPDATING;
    loading_action.value = true;
    dispatch(loading_action);
    loading_action.value = false;
    loading_action.lm_msg = "";
    //updating name and pic ===================================================================================
    if (update_obj.name && update_obj.gpic) {
      var data = {
        tokn: utils.getAt(),
        p_id: utils.getPid(),
        name: update_obj.name,
        g_id: gid,
      };
      axios
        .put(ROUTES.GROUP_NAME_EDIT, data)
        .then((edit_res) => {
          var new_groups = [];
          for (var i = 0; i < groups.length; i++) {
            var temp = {};
            for (var k in groups[i]) {
              temp[k] = groups[i][k];
            }
            if (groups[i]._id == gid) {
              temp.name = update_obj.name;
            }
            new_groups.push(temp);
          }
          var action = {};
          action.type = "UPDATE_GROUP_NAME";
          action.gd_gname = update_obj.name;
          action.groups = new_groups;
          dispatch(action);
          getS3Credentials(update_obj.gpic_ext, gid, function (err, s3_params) {
            if (err) {
              try {
                throw err;
              } catch (err) {
                dispatch(loading_action);
                utils.handleErrors(err, dispatch, ERROR_BLOCK.GROUP_PIC_UPLOAD);
                return;
              }
            }
            let data = new FormData();
            var d = new Date();
            var f_nm =
              utils.getPid() +
              d.getTime() +
              "." +
              (update_obj && update_obj.gpic_ext
                ? update_obj.gpic_ext.split("/")[1]
                : "");
            var cropped_file = base64StringtoFile(update_obj.gpic, f_nm);

            data.set("key", f_nm);
            data.set("AWSAccessKeyId", s3_params.AWSAccessKeyId);
            data.set("acl", "public-read");
            data.set("Policy", s3_params.s3Policy);
            data.set("Content-Type", update_obj.gpic_ext);
            data.set("Signature", s3_params.s3Signature);
            data.set("success_action_status", "201");
            data.set("file", cropped_file);

            var s3UploadConfig = {
              method: "POST",
              url: AWS_BUCKET_URLS.GPIC,
              // headers: { "Content-Type": undefined },
              onUploadProgress: function (progress) {},
              body: data,
            };

            fetch(AWS_BUCKET_URLS.PPIC, s3UploadConfig)
              // axios(s3UploadConfig)
              .then(async (response) => {
                response = await response.text();
                // var xml = new XMLParser().parseFromString(response.data);
                // var location = xml.getElementsByTagName("Location");
                // var pic_url = location[0] ? location[0].value : null;
                let pic_url = response
                  .split("<Location>")[1]
                  .split("</Location>")[0];
                var gpic = pic_url;
                var pid = utils.getPid();
                var new_groups = [];
                for (var i = 0; i < groups.length; i++) {
                  var temp = {};
                  for (var k in groups[i]) {
                    temp[k] = groups[i][k];
                  }
                  if (groups[i]._id == gid) {
                    temp.gpic = gpic;
                    temp.name = update_obj.name;
                  }
                  new_groups.push(temp);
                }
                // for (var i = 0; i < profiles.length; i++) {
                // 	if (profiles[i]._id == pid) {
                // 		profiles[i].ppic = ppic;
                // 		break;
                // 	}
                // }
                var edit_data = {};
                edit_data.g_id = gid;
                edit_data.p_id = utils.getPid();
                edit_data.gpic = gpic;
                edit_data.tokn = utils.getAt();
                axios
                  .put(ROUTES.GROUP_PIC_EDIT, edit_data)
                  .then((pic_edit_res) => {
                    var action = {};
                    action.type = "UPDATE_GROUP_PIC";
                    action.gd_gpic = gpic;
                    action.groups = new_groups;
                    dispatch(action);
                    dispatch(loading_action);
                    utils.showPopover(
                      POPOVER_HEADINGS.GPIC_UPLOAD_SUCCESS,
                      POPOVER_CONTENT.GPIC_UPLOAD_SUCCESS,
                      POPOVER_TYPE.SUCCESS,
                      dispatch
                    );
                  })
                  .catch((err) => {
                    dispatch(loading_action);
                    utils.handleErrors(err, dispatch);
                  });
              })
              .catch((err) => {
                dispatch(loading_action);
                utils.handleErrors(
                  err,
                  dispatch,
                  ERROR_BLOCK.PROFILE_PIC_UPLOAD
                );
              });
          });
        })
        .catch((err) => {
          dispatch(loading_action);
          utils.handleErrors(err, dispatch);
        });
    }
    //updating only name=======================================================================================
    else if (update_obj.name) {
      var data = {
        tokn: utils.getAt(),
        p_id: utils.getPid(),
        name: update_obj.name,
        g_id: gid,
      };
      axios
        .put(ROUTES.GROUP_NAME_EDIT, data)
        .then((edit_res) => {
          var new_groups = [];

          for (var i = 0; i < groups.length; i++) {
            var temp = {};
            for (var k in groups[i]) {
              temp[k] = groups[i][k];
            }
            if (groups[i]._id == gid) {
              temp.name = update_obj.name;
            }
            new_groups.push(temp);
          }
          var action = {};
          action.type = "UPDATE_GROUP_NAME";
          action.gd_gname = update_obj.name;
          action.groups = new_groups;
          dispatch(action);
          dispatch(loading_action);
          utils.showPopover(
            POPOVER_HEADINGS.GPIC_UPLOAD_SUCCESS,
            POPOVER_CONTENT.GPIC_UPLOAD_SUCCESS,
            POPOVER_TYPE.SUCCESS,
            dispatch
          );
        })
        .catch((err) => {
          dispatch(loading_action);
          utils.handleErrors(err, dispatch);
        });
    }
    //updating only pic =========================================================================================
    else if (update_obj.gpic) {
      getS3Credentials(update_obj.gpic_ext, gid, function (err, s3_params) {
        if (err) {
          try {
            throw err;
          } catch (err) {
            dispatch(loading_action);
            utils.handleErrors(err, dispatch, ERROR_BLOCK.GROUP_PIC_UPLOAD);
            return;
          }
        }
        let data = new FormData();
        var d = new Date();
        var f_nm =
          utils.getPid() +
          d.getTime() +
          "." +
          (update_obj && update_obj.gpic_ext
            ? update_obj.gpic_ext.split("/")[1]
            : "");
        var cropped_file = base64StringtoFile(update_obj.gpic, f_nm);

        data.set("key", f_nm);
        data.set("AWSAccessKeyId", s3_params.AWSAccessKeyId);
        data.set("acl", "public-read");
        data.set("Policy", s3_params.s3Policy);
        data.set("Content-Type", update_obj.gpic_ext);
        data.set("Signature", s3_params.s3Signature);
        data.set("success_action_status", "201");
        data.set("file", cropped_file);

        var s3UploadConfig = {
          method: "POST",
          url: AWS_BUCKET_URLS.GPIC,
          // headers: { "Content-Type": undefined },
          onUploadProgress: function (progress) {},
          body: data,
        };

        fetch(AWS_BUCKET_URLS.PPIC, s3UploadConfig)
          // axios(s3UploadConfig)
          .then(async (response) => {
            response = await response.text();
            // var xml = new XMLParser().parseFromString(response.data);
            // var location = xml.getElementsByTagName("Location");
            // var pic_url = location[0] ? location[0].value : null;
            let pic_url = response
              .split("<Location>")[1]
              .split("</Location>")[0];
            var gpic = pic_url;
            var pid = utils.getPid();
            var new_groups = [];
            for (var i = 0; i < groups.length; i++) {
              var temp = {};
              for (var k in groups[i]) {
                temp[k] = groups[i][k];
              }
              if (groups[i]._id == gid) {
                temp.gpic = gpic;
              }
              new_groups.push(temp);
            }
            var edit_data = {};
            edit_data.g_id = gid;
            edit_data.p_id = utils.getPid();
            edit_data.gpic = gpic;
            edit_data.tokn = utils.getAt();
            axios
              .put(ROUTES.GROUP_PIC_EDIT, edit_data)
              .then((pic_edit_res) => {
                var action = {};
                action.type = "UPDATE_GROUP_PIC";
                action.gd_gpic = gpic;
                action.groups = new_groups;
                dispatch(action);
                dispatch(loading_action);

                utils.showPopover(
                  POPOVER_HEADINGS.GPIC_UPLOAD_SUCCESS,
                  POPOVER_CONTENT.GPIC_UPLOAD_SUCCESS,
                  POPOVER_TYPE.SUCCESS,
                  dispatch
                );
              })
              .catch((err) => {
                dispatch(loading_action);
                utils.handleErrors(err, dispatch, ERROR_BLOCK.GROUP_PIC_UPLOAD);
              });
          })
          .catch((err) => {
            dispatch(loading_action);
            utils.handleErrors(err, dispatch, ERROR_BLOCK.GROUP_PIC_UPLOAD);
          });
      });
    }
  };

export const openPlugin = (plugin, gid, is_admin) => (dispatch) => {
  var pid = utils.getPid();
  // var usr_idnt_route = ROUTES.USER_PLUGIN_IDNT;
  var data = {
    headers: {
      "Content-Type": "application/json",
    },
    g_id: gid,
    p_id: utils.getPid(),
    tokn: utils.getAt(),
    pl_id: plugin._id,
  };
  axios
    .post(ROUTES.USER_PLUGIN_IDNT, data)
    .then((usr_res) => {
      usr_res = usr_res.data.data;
      plugin.uidnt = usr_res.u_idnt;
      plugin.is_admin = is_admin;
      plugin.idnt_id = usr_res.idnt_id;
      var win = window.open(generatePluginUrl(plugin), "_blank");
      win.focus();
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch);
    });

  // axios.post()
};

function generatePluginUrl(plugin) {
  var value = plugin.is_admin
    ? PLUGIN_ROUTE_VALUES.ADMIN
    : PLUGIN_ROUTE_VALUES.NOT_ADMIN;
  var url;
  if (window.NODE_ENV == "local") {
    var protocol = "http:";
    if (window.location.protocol) {
      protocol = window.location.protocol;
    }
    url =
      CONFIGS.QUIZ_USER_ENDPOINT +
      "#/?uid=" +
      plugin.uidnt +
      "&plid=" +
      plugin._id +
      "&ifa=" +
      value +
      "&idnt_id=" +
      plugin.idnt_id;
  } else {
    var protocol = "https:";
    url =
      CONFIGS.QUIZ_USER_ENDPOINT +
      "#/?uid=" +
      plugin.uidnt +
      "&plid=" +
      plugin._id +
      "&ifa=" +
      value +
      "&idnt_id=" +
      plugin.idnt_id;
  }
  return url;
}

export const openDiscussionPlugin = (gid) => (dispatch) => {
  var discuss_url =
    CONFIGS.MY_COURSE_DISCUSSIONURL + gid + "?pid=" + utils.getPid();
  window.location.href = discuss_url;
};

export const changeGroupType = (gid, gtyp, all_groups) => (dispatch) => {
  var gtyp_bool = gtyp;
  var data = {
    tokn: utils.getAt(),
    p_id: utils.getPid(),
    g_id: gid,
    gtyp: gtyp.toString(),
  };
  axios
    .put(ROUTES.CHANGE_GROUP_TYPE, data)
    .then((change_res) => {
      for (var i = 0; i < all_groups.length; i++) {
        if (all_groups[i]._id == gid) {
          all_groups[i].gtyp = gtyp;
        }
      }
      var action = {};
      action.type = "CHANGE_GROUP_TYPE";
      action.grp_type = gtyp_bool;
      action.groups = all_groups;
      dispatch(action);
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch);
    });
};

export const updateCroppedGPic = (cropped_img) => (dispatch) => {
  var action = {};
  action.type = "UPDATE_CROPPED_GPIC";
  action.cropped_gpic = cropped_img;
  dispatch(action);
};

export const leaveGroupBackend = (gid, groups) => (dispatch) => {
  var data = {};
  data.g_id = gid;
  data.p_id = utils.getPid();
  data.tokn = utils.getAt();
  axios
    .put(ROUTES.LEAVE_GROUP, data)
    .then((lg_res) => {
      if (!lg_res || !lg_res.data || !lg_res.data.data) {
        return;
      }
      var updated_groups = [];
      for (var i = 0; i < groups.length; i++) {
        if (groups[i]._id != gid) {
          updated_groups.push(groups[i]);
        }
      }
      // updated_groups.sort((a, b) => a.name.localeCompare(b.name));
      if (updated_groups.length > 0) {
        var action = {};
        action.type = "REDIRECT_TO_GRP_DTL";
        action.val = true;
        action.groups = updated_groups;
        dispatch(action);
      } else {
        var action = {};
        action.type = "UPDATE_USER_GROUPS";
        action.groups = updated_groups;
        dispatch(action);
        action = {};
        action.type = "REDIRECT_TO_HOME";
        dispatch(action);
      }
      utils.showPopover(
        POPOVER_HEADINGS.GRP_LEAVE_SUCCESS,
        POPOVER_CONTENT.GRP_LEAVE_SUCCESS,
        POPOVER_TYPE.SUCCESS,
        dispatch
      );
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch);
      utils.showPopover(
        POPOVER_HEADINGS.GRP_LEAVE_FAILURE,
        POPOVER_CONTENT.GRP_LEAVE_FAILURE,
        POPOVER_TYPE.ERROR,
        dispatch
      );
    });
};

export const disableMyGroupRedirection = () => (dispatch) => {
  var action = {};
  action.val = false;
  action.type = "DISABLE_TO_GRP_DTL";
  dispatch(action);
};

export const disableGroupDetailRedirection = () => (dispatch) => {
  var action = {};
  action.type = "DISABLE_GRP_DTL_REDIRECT";
  dispatch(action);
};
