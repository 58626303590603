import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import { Button, Input } from "reactstrap";
import EmailImg2 from "../../../images/email-alert-01.png";
import mobileOtpImg from "../../../images/mobile-otp-01.png";
import closeBtn from "../../../images/close-btn.svg";
import { useState } from "react";
import {
  otpConfirmActionMobile,
  unSetEmail,
  confirmPrimaryEmail,
  openMobileModal,
  confirmMobile,
} from "../../../actions/primaryEmailActions";
import Timer from "../../utils/timer/Timer";
import { OTP_EMAIL } from "../../../constants/constants";
import InlineLoader from "../../utils/InlineLoader";
import { connect } from "react-redux";
import {
  WebEngageDataLayer,
  WebEngageRegisteredEvents,
} from "../../../services/helper";

const MobileOtpVerificationModal = (props) => {
  const dispatch = useDispatch();
  const [countResend, setCountResend] = useState(0);
  const time = OTP_EMAIL.RESEND_TIMEOUT;
  let timerId;
  const [timer, setTimer] = useState(0);
  const { m: mobile, ccod } = useSelector((state) => state.mobile);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const x = useSelector((state) => state.mobile);

  const submitOtpHandler = () => {
    dispatch(otpConfirmActionMobile({ otp, mobile, ccod }))
      .then(() => {
        let webEngageData = {
          name: props.name,
          email: props.email,
          mobile: mobile,
          timeZone: props.tz,
        };

        WebEngageDataLayer(
          webEngageData,
          WebEngageRegisteredEvents.PROFILECOMPLETED
        );

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };
  // useEffect(()=>{
  // if(timer<0)
  // {
  //     // setTimer(()=>0)s
  //      console.log("here")
  //      console.log(timerId)
  //  clearInterval(timerId)
  // }
  // },[timer])
  useEffect(() => {
    if (timer == 0) {
      return;
    }
    timerId = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);
    return () => {
      clearInterval(timerId);
    };
  }, [timer]);
  useEffect(() => {
    setTimer(countResend * time);
  }, [countResend]);
  const handleResendCode = () => {
    setCountResend((prev) => prev + 1);
    dispatch(confirmMobile(mobile, ccod));
  };
  const handleOtp = (e) => {
    if (e.target.value.length > OTP_EMAIL.LENGTH) return;
    setOtp(e.target.value);
  };
  const closeAddNewMobile = () => {
    props.closeFunc(false);
  };
  return (
    <>
      <img
        src={closeBtn}
        style={{ float: "right", padding: "8px" }}
        onClick={closeAddNewMobile}
      ></img>
      <img src={mobileOtpImg} className="pe-img" />
      <div className="pe-head">Enter 6 Digit Verification Code</div>
      <Input
        type="text"
        className={`pe-adnew-inp ${error && "pe-adnew-input-error"}`}
        placeholder="1-2-3-4-5-6"
        onChange={handleOtp}
        value={otp}
      />
      <div className="EmailVerificationModal__resendContainer">
        <button
          onClick={handleResendCode}
          className={`EmailVerificationModal__resendButton`}
          disabled={!(timer == 0)}
        >
          {console.log(typeof timer)}
          Resend{" "}
          {!!timer && (
            <>
              (<Timer times={timer} />)
            </>
          )}
        </button>
      </div>
      <p className="EmailVerificationModal__error">{error}</p>

      <Button
        color="primary"
        disabled={loading}
        onClick={submitOtpHandler}
        className="pe-cnf-btn__otp"
      >
        {!loading ? "Verify" : <InlineLoader />}
      </Button>
      <div className="pe-info2">
        <em>Note: Verification code is only valid for 15 minutes</em>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  tz: state.profile.tz,
  email: state.profile.email,
  name: state.profile.p_name,
});

export default connect(mapStateToProps, {})(MobileOtpVerificationModal);
