import { URLS } from "../constants/constants";

const initialState = {
  profiles: [],
  default_pid: null,
  p_name: "",
  contact: "",
  email: "",
  tz: "",
  ppic: URLS.DEFAULT_PPIC_URL,
  profile_map: {},
  edit_ppic: URLS.DEFAULT_PPIC_URL,
  is_pr_sw: false, //is_profile_sw
  ifSubscribed: "",
  hide_mob_vrfy_popup: false,
  uid: "",
  showProfilePrompt: null,
  popup_snooze: Date.now(),
  nsdc_url: "",
  nsdc_mobile: "",
  // crs_pid		: null,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_PROFILES":
      console.log("ACTION USER PROFILE:: ", action.ifSubscribed);
      return {
        ...state,
        profiles: action.profiles,
        default_pid: action.profiles[0]._id,
        p_name: action.p_name,
        ppic: action.ppic ? action.ppic : URLS.DEFAULT_PPIC_URL,
        profile_map: action.profile_map,
        contact: action.contact,
        email: action.email,
        tz: action.tz,
        vrfy: action.vrfy,
        ep_vrfy: action.ep_vrfy,
        ccode: action.ccode,
        ifSubscribed: action.ifSubscribed,
        hide_mob_vrfy_popup: action.hide_mob_vrfy_popup,
        uid: action.uid,
        showProfilePrompt: action.showProfilePrompt,
        popup_snooze: action.popup_snooze,
        nsdc_mobile: action.nsdc_mobile,
        nsdc_url: action.nsdc_url,
      };
    case "SET_PPIC_NAME":
      return {
        ...state,
        p_name: action.p_name,
        ppic: action.ppic ? action.ppic : URLS.DEFAULT_PPIC_URL,
      };
    case "SET_PPIC_EXT":
      return {
        ...state,
        ppic_ext: action.ppic_ext,
      };
    case "SWITCH_PROFILE":
      return {
        ...state,
        is_pr_sw: action.val,
      };
    case "UPDATE_PROFILE_NAME":
      return {
        ...state,
        p_name: action.p_name,
        profiles: action.profiles,
      };
    case "UPDATE_PROFILE_PIC":
      return {
        ...state,
        ppic: action.ppic,
        profiles: action.profiles,
      };
    case "UPDATE_PROFILES":
      return {
        ...state,
        profiles: action.profiles,
      };
    case "UPDATE_CROPPED_PPIC":
      return {
        ...state,
        cropped_ppic: action.cropped_ppic,
      };

    case "UPDATE_PPIC":
      return {
        ...state,
        edit_ppic: action.edit_ppic,
      };
    case "REMOVE_PPIC":
      return {
        ...state,
        edit_ppic: action.edit_ppic,
        profiles: action.profiles,
        ppic: action.ppic,
      };
    case "REMOVE_PROFILE":
      return {
        ...state,
        profiles: action.profiles,
      };
    case "UPDATE_PROFILE_TIMEZONE":
      return {
        ...state,
        tz: action.payload.tz,
      };
    case "CHANGE_SUBSCRIPTION":
      return {
        ...state,
        ifSubscribed: action.ifSubscribed,
      };
    // case 'CRS_PID':
    // 	return {
    // 		...state,
    // 		crs_pid				: action.crs_pid,
    // 	}
    default:
      return state;
  }
};

export default profileReducer;
