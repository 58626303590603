import { connect } from "react-redux";
import React from "react";
import { withRouter } from "react-router";
import { Modal } from "reactstrap";
import Spinner from "../utils/Spinner";
import close_img from "../../images/close-btn.svg";
import {
  KEY_CODES,
  PLUGIN_MODAL_VIEWS,
  PAYMENT_MODES,
  POPOVER_HEADINGS,
  POPOVER_CONTENT,
  POPOVER_TYPE,
  PLUGIN_CATEGORY,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";
import {
  fetchRazorPayDetails,
  applyCouponCodeBackend,
  resetCoupon,
  resetRazorpayResponse,
} from "../../actions/pluginAction";
import { updatePicURL } from "../../actions/profileAction";
import ReactCropperr from "../utils/ProfilePicCropper";
import { URLS } from "../../constants/constants";
import { utils } from "../../utility/utils";
import success from "../../images/success.svg";

const PAYMENT_MODES_ARR = [
  { id: PAYMENT_MODES.NET_BANKING, name: "Net Banking" },
  { id: PAYMENT_MODES.DEBIT_CARD, name: "Debit Card" },
  { id: PAYMENT_MODES.CREDIT_CARD, name: "Credit Card" },
  { id: PAYMENT_MODES.WALLET, name: "Wallet" },
];

class PluginPaymentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: PLUGIN_MODAL_VIEWS.PACKAGE,
      py_mode: PAYMENT_MODES.NET_BANKING,
      sel_pkg_id: null,
      c_code: "",
    };
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keydownHandler);
    var e = document.getElementsByClassName("modal");
  }
  componentDidUpdate(prev_props) {
    if (this.props.open_ppm != prev_props.open_ppm && this.props.open_ppm) {
      // utils.centralizeModalForHomeSidebar();
    }
    if (
      (this.props.pl_packages != prev_props.pl_packages ||
        this.state.sel_pkg_id == null) &&
      this.props.pl_packages[0]
    ) {
      this.setPluginPackage(
        this.props.pl_packages[0]._id,
        parseInt(this.props.pl_packages[0].package_price)
      );
    }
    if (this.props.is_rp_res !== prev_props.is_rp_res) {
      if (this.props.is_rp_res) {
        this.closePPModal();
        this.props.resetRazorpayResponse();
      }
    }
  }

  closePPModal = () => {
    this.setState(
      {
        view: PLUGIN_MODAL_VIEWS.PACKAGE,
        py_mode: PAYMENT_MODES.NET_BANKING,
        sel_pkg_id: null,
        c_code: "",
      },
      this.setPluginPackage(
        this.props.pl_packages[0]._id,
        parseInt(this.props.pl_packages[0].package_price)
      )
    );

    this.props.resetCoupon();
    this.props.togglePluginPaymentModal();
  };
  keydownHandler = (e) => {
    if (e.keyCode == KEY_CODES.ESC_KEY && this.props.open_ppm) {
      this.closePPModal();
    }
  };
  back = () => {
    this.setState({
      view: PLUGIN_MODAL_VIEWS.PACKAGE,
      py_mode: PAYMENT_MODES.NET_BANKING,
    });
  };
  pay = () => {
    var sel_pkg = {};
    if (!this.state.py_mode) {
      this.props.showPopover(
        POPOVER_HEADINGS.ERROR,
        POPOVER_CONTENT.NO_PAYMENT_MODE,
        POPOVER_TYPE.ERROR
      );
    } else {
      for (var i = 0; i < this.props.pl_packages.length; i++) {
        if (this.state.sel_pkg_id == this.props.pl_packages[i]._id) {
          sel_pkg = this.props.pl_packages[i];
          break;
        }
      }
      if (this.props.pl && this.props.pl.catg == PLUGIN_CATEGORY.VIDEO) {
        this.props.fetchRazorPayDetails(
          this.props.pl,
          sel_pkg,
          this.props.gd_id,
          this.state.py_mode,
          this.props.updated_price,
          this.props.cpn_code,
          this.props.gd_video
        );
      } else if (this.props.pl && this.props.pl.catg == PLUGIN_CATEGORY.QUIZ) {
        this.props.fetchRazorPayDetails(
          this.props.pl,
          sel_pkg,
          this.props.gd_id,
          this.state.py_mode,
          this.props.updated_price,
          this.props.cpn_code,
          this.props.gd_quiz
        );
      }
    }
  };
  setPaymentMode = (py_mode) => {
    this.setState({
      py_mode: py_mode,
    });
  };
  setPluginPackage = (pkg_id, price_to_pay) => {
    if (pkg_id && pkg_id == this.state.sel_pkg_id) {
      return;
    }
    this.props.resetCoupon();
    this.setState({
      c_code: "",
    });
    this.setState({
      sel_pkg_id: pkg_id,
      price_to_pay: price_to_pay,
    });
  };
  setCouponCode = (e) => {
    if (e.target && e.target.value && e.target.value.length > 11) {
      return;
    }
    if (e.target) {
      this.setState({
        c_code: e.target.value,
      });
    }
  };
  cancelPayment = () => {
    // this.setState({
    //    view : PLUGIN_MODAL_VIEWS.PACKAGE,
    //    py_mode : null,
    //    sel_pkg_id : null,
    // })
    this.closePPModal();
  };
  continueToPayment = () => {
    this.setState({
      view: PLUGIN_MODAL_VIEWS.PAYMENT,
    });
  };
  applyCouponCode = () => {
    if (this.props.is_cp_applied) {
      return;
    }
    var sel_pkg = {};
    for (var i = 0; i < this.props.pl_packages.length; i++) {
      if (this.state.sel_pkg_id == this.props.pl_packages[i]._id) {
        sel_pkg = this.props.pl_packages[i];
        break;
      }
    }
    this.props.applyCouponCodeBackend(
      sel_pkg,
      this.state.c_code.toUpperCase(),
      this.props.gd_id
    );
  };

  submit = (e) => {
    if (e.key === "Enter") {
      this.applyCouponCode();
    }
  };

  render() {
    if (this.state.view == PLUGIN_MODAL_VIEWS.PAYMENT) {
      return (
        <Modal
          isOpen={this.props.open_ppm}
          className={"pp-modal-container"}
          centered={true}
        >
          <div className="pp-modal">
            <p className="modal-heading">
              {this.props.pl ? this.props.pl.name : ""}
              <img
                className="close-btn"
                onClick={this.closePPModal}
                src={close_img}
              ></img>
            </p>
            {PAYMENT_MODES_ARR.map(({ id, name }, index) => {
              return (
                <div
                  className={
                    this.state.py_mode == id
                      ? "payment-card active-border"
                      : "payment-card"
                  }
                >
                  <div
                    className="btn-cover"
                    onClick={() => this.setPaymentMode(id)}
                  ></div>
                  <div className="btn-section">
                    <div
                      className={
                        this.state.py_mode == id
                          ? "outer-circle-a"
                          : "outer-circle"
                      }
                    >
                      <div
                        className={
                          this.state.py_mode == id ? "inner-circle" : "hide"
                        }
                      ></div>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.py_mode == id
                        ? "content-section active-color"
                        : "content-section"
                    }
                  >
                    {name}
                  </div>
                </div>
              );
            })}

            <div className="footer">
              <button className="back-btn" onClick={this.back}>
                Back
              </button>
              <button className="pay-btn" onClick={this.pay}>
                Pay
              </button>
            </div>
          </div>
        </Modal>
      );
    } else if (this.state.view == PLUGIN_MODAL_VIEWS.PACKAGE) {
      return (
        <Modal
          isOpen={this.props.open_ppm}
          className={"pp-modal-container"}
          centered={true}
        >
          <div className="pp-modal">
            <p className="modal-heading">
              {this.props.pl ? this.props.pl.name : ""}
              <img
                className="close-btn"
                onClick={this.closePPModal}
                src={close_img}
              ></img>
            </p>
            {this.props.pl_packages.map(
              (
                {
                  package_name,
                  package_price,
                  _id,
                  plugin_id,
                  duration_base,
                  duration_unit,
                },
                index
              ) => {
                return (
                  <div
                    className={
                      this.state.sel_pkg_id == _id
                        ? "payment-card active-border"
                        : "payment-card"
                    }
                  >
                    <div
                      className="btn-cover"
                      onClick={() =>
                        this.setPluginPackage(_id, parseInt(package_price))
                      }
                    ></div>
                    <div className="btn-section">
                      <div
                        className={
                          this.state.sel_pkg_id == _id
                            ? "outer-circle-a"
                            : "outer-circle"
                        }
                      >
                        <div
                          className={
                            this.state.sel_pkg_id == _id
                              ? "inner-circle"
                              : "hide"
                          }
                        ></div>
                      </div>
                    </div>
                    <div className="content-section">
                      <span
                        className={
                          this.state.sel_pkg_id == _id
                            ? "pk-duration active-color"
                            : "pk-duration"
                        }
                      >
                        {duration_unit}
                      </span>
                      <span
                        className={
                          this.state.sel_pkg_id == _id
                            ? "pk-unit active-color"
                            : "pk-unit"
                        }
                      >
                        {" "}
                        &nbsp;&nbsp;{duration_base.toLowerCase()}
                      </span>
                    </div>
                    <div className="rupee-section">
                      <p
                        className={
                          this.state.sel_pkg_id == _id
                            ? "price active-color"
                            : "price"
                        }
                      >
                        <span
                          className={
                            this.state.sel_pkg_id == _id
                              ? "rs-sign active-color"
                              : "rs-sign"
                          }
                        >
                          &#x20B9;
                        </span>
                        <span>{parseInt(package_price)}</span>
                      </p>
                    </div>
                  </div>
                );
              }
            )}
            <div className="coupon-section">
              <input
                type="text"
                className="cc-input"
                readOnly={this.props.is_cp_applied ? true : false}
                placeholder={"Enter Coupon Code"}
                value={this.state.c_code}
                onKeyPress={(e) => this.submit(e)}
                onChange={(e) => this.setCouponCode(e)}
              ></input>
              <span
                className={!this.props.is_cp_applied ? "apply-btn" : "hide"}
                onClick={this.applyCouponCode}
              >
                Apply
              </span>
              <span
                className={this.props.is_cp_applied ? "applied-btn" : "hide"}
              >
                Applied <img className="success-img" src={success} />
              </span>
            </div>
            <p className="pay-msg">
              Your final payable amount is Rs{" "}
              {this.props.is_cp_applied
                ? this.props.updated_price
                : this.state.price_to_pay}{" "}
              only/-
            </p>
            <div className="footer">
              <button className="back-btn" onClick={this.cancelPayment}>
                Cancel
              </button>
              <button className="pay-btn" onClick={this.continueToPayment}>
                Go to Payment
              </button>
            </div>
          </div>
        </Modal>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  packages: state.plugin.packages,
  pl: state.plugin.pl,
  pl_packages: state.plugin.pl_packages,
  gd_id: state.j_grp.gd_id,
  is_cp_applied: state.plugin.is_cp_applied,
  updated_price: state.plugin.updated_price,
  cpn_code: state.plugin.cpn_code,
  rp_status: state.plugin.rp_status,
  is_rp_res: state.plugin.is_rp_res,
  gd_quiz: state.j_grp.gd_quiz,
  gd_video: state.j_grp.gd_video,
});

const mapDispatchToProps = {
  showPopover,
  fetchRazorPayDetails,
  applyCouponCodeBackend,
  resetCoupon,
  resetRazorpayResponse,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PluginPaymentModal)
);
