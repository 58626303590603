const initialState = {
  show_ep_mdl: false, // show primary email verify modal
  secondary_emails: [],
  cnf_email_modal: false,
  add_new_email: false,
  open_status_modal: true, // will open verify email status
  verify_link: "",
  email_verified: false,
  waiting_verification: true,
  otp_received: false,
  otp_verified: false,
  is_loading: false, // loader status for change email section
  secondary_email_changed: false, // return boolean for secondary email changed to db or not
  primary_email: undefined,
  notifications: [],
  ifTokenSent: false,
  token: "",
  in_app_notification: {},
  username: "",
};

const primaryEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_EMAIL_VERIFY":
      return { ...state, show_ep_mdl: action.val, username: action.username };
    case "RESET_EMAIL_ACTIONS":
      return {
        ...state,
        otp_received: false,
        otp_verified: false,
        is_loading: false,
        secondary_email_changed: false,
      };
    case "OPEN_EMAIL_STATUS_MODAL":
      return { ...state, open_status_modal: action.val };
    case "CHANGE_EMAIL_VERIFY_LINK":
      return { ...state, verify_link: action.val };
    case "SECONDARY_EMAIL_UPDATE":
      return { ...state, secondary_emails: action.emails };
    case "OPEN_CONFIRM_EMAIL_MODAL":
      return { ...state, cnf_email_modal: action.value };
    case "ADD_NEW_EMAIL":
      return { ...state, add_new_email: action.value };
    case "UPDATE_EMAIL_STATUS":
      return { ...state, email_verified: action.value };
    case "EMAIL_OTP_VERIFIED":
      return {
        ...state,
        email_verified: true,
        cnf_email_modal: true,
        show_ep_mdl: false,
        open_status_modal: true,
        waiting_verification: false,
      };
    case "WAITING_EMAIL_VERIFICATION":
      return { ...state, waiting_verification: action.value };
    case "EMAIL_LOADER":
      return { ...state, is_loading: action.value };
    case "SEDONDARY_EMAIL_CHANGED":
      return { ...state, secondary_email_changed: action.value };
    case "VERIFY_OTP":
      return { ...state, otp_verified: action.otp_verified };
    case "OTP_RECEIVED":
      return { ...state, otp_received: action.otp_received };
    case "PRIMARY_EMAIL_UPDATE":
      return { ...state, primary_email: action.primary_email };
    case "MOBILE_UPDATE":
      return { ...state, mobile: action.mobile };
    case "UNSET_PRIMARY_EMAIL":
      return { ...state, primary_email: undefined };
    case "USER_NOTIFICATIONS":
      return { ...state, notifications: action.notifications };
    case "SET_TOKEN":
      return { ...state, token: action.token };
    case "TOKEN_SENT":
      return { ...state, ifTokenSent: action.ifTokenSent };
    case "IN_APP":
      console.log({ action });
      return {
        ...state,
        in_app_notification: action.in_app_notification,
      };
    default:
      return state;
  }
};

export default primaryEmailReducer;
