import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CONFIGS } from "../../../constants/constants";
import { utils } from "../../../utility/utils";
import { useHistory } from "react-router";
import Loader from "../../utils/Loader.js";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  FormGroup,
} from "reactstrap";
import { ROUTES } from "../../../constants/routes.js";

const ViewNSDCDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const profile = useSelector((state) => state.profile);
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const { primary_email } = useSelector((state) => state.primaryEmail);
  console.log({ profile });
  useEffect(() => {
    setIsLoading(true);
    var courseList = [];
    let data = {
      headers: {
        "Content-Type": "application/json",
      },
      tokn: utils.getAt(),
      d_id: utils.getDid(),
      cl: "W",
    };
    Axios.post(ROUTES.MY_CRS, data).then(async (my_courses) => {
      var res = my_courses.data.data;
      courseList = res.courses;
      if (Array.isArray(courseList)) {
        const enabledCourses = [];
        const seenIds = new Set();
        courseList.forEach((course) => {
          if (course.isPlacementEnabled && !seenIds.has(course.crs_pg_id)) {
            enabledCourses.push(course);
            seenIds.add(course.crs_pg_id);
          }
        });

        console.log({ enabledCourses });
        if (courseList.length > 0) {
          if (enabledCourses.length === 1) {
            const crs_pg_id = enabledCourses[0].crs_pg_id;
            history.push(
              `/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`
            );
          } else if (enabledCourses.length > 1) {
            const courseObjects = enabledCourses.map((course) => ({
              crs_pg_id: course.crs_pg_id,
              name: course.name,
            }));
            setCourses(courseObjects);
            setIsLoading(true);
            setTogglePDFDoc(true);
          } else {
            // setIsLoading(false);
            console.log("in else condition but why ");
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          }
        } else {
          setIsLoading(false);
        }
      }
    });
  }, [history]);

  let creationDate = new Date(profile.profile_map.cat).toString();

  const previewResumeClick = () => {
    setTogglePDFDoc(true);
  };

  const handleCourseClick = (crs_pg_id) => {
    history.push(`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}`);
  };

  const changeEmailSubscription = () => {
    console.log(" --- Changed the subscription --- ");
    Axios.post(`${CONFIGS.API_API_ENDPOINT}notifications/sbscrb_email`, {
      aid: utils.getAid(),
      tokn: utils.getAt(),
      ifSubscribed: !profile.ifSubscribed,
    })
      .then((res) => {
        console.log("Response successful!");
        dispatch({
          type: "CHANGE_SUBSCRIPTION",
          ifSubscribed: !profile.ifSubscribed,
        });
        return;
      })
      .catch((err) => {
        console.log("Error: ", err);
        return;
      });
  };

  if (isLoading) {
    console.log("yesssssssssssssssssssssssssssssssssssssssssssssssssss");
    return (
      <>
        <Modal
          isOpen={togglePDFDoc}
          size={"md"}
          style={{ minwidth: "400px" }}
          centered={true}
        >
          <ModalHeader
            className="resume-prev"
            style={{ paddingRight: "7px", width: "100%" }}
          >
            <div className="row" style={{ justifyContent: "space-between" }}>
              <div>Choose Course Profile to Preview </div>
              <div>
                {/* <img
                  src="https://webcdn.imarticus.org/Placement/Group926.svg"
                  style={{ paddingRight: "7px" }}
                  onClick={() => setTogglePDFDoc(false)}
                /> */}
              </div>
            </div>
          </ModalHeader>
          <ModalBody
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            {courses.map((course) => (
              <button
                key={course.crs_pg_id}
                onClick={() => handleCourseClick(course.crs_pg_id)}
                style={{
                  margin: "10px",
                  padding: "10px 20px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                {course.name}
              </button>
            ))}
          </ModalBody>
          <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
        </Modal>
        <Loader />
      </>
    );
  }
  if (!isLoading) {
    return (
      <>
        <div className="group-db-content-wrapper-2">
          <div className="container-fluid dashboard-content">
            <div className=" profile-setting-container-row">
              <div className="profile-setting-container-77 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row justify-content-center">
                  <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 ">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "48px",
                      }}
                    >
                      <p className="edit-profile-heading">Your NSDC Details</p>
                    </div>

                    <div className="edit-window-profile-details">
                      <li className="row">
                        <div className="col-md-6">
                          <h5
                            class="modal-title profile-heading"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            Mobile No. Linked with NSDC Profile
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </h5>
                          <FormGroup>
                            <div className="row">
                              <div
                                style={{
                                  width: "100px",
                                  paddingRight: "12px",
                                  paddingLeft: "12px",
                                }}
                              >
                                <Input
                                  name="mobileCountryCode"
                                  placeholder="Country Code"
                                  type="string"
                                  value={"+91"}
                                  disabled={true}
                                ></Input>
                              </div>
                              <Input
                                type="text"
                                name="text"
                                id="companyHRMobile"
                                value={profile.nsdc_mobile}
                                style={{ width: "60%" }}
                                disabled={true}
                              />
                            </div>
                          </FormGroup>
                        </div>
                        <div className="col-md-6">
                          <h5
                            class="modal-title profile-heading"
                            htmlFor="thumbImg"
                            style={{ marginTop: "1rem" }}
                          >
                            NSDC Profile Link
                            <span style={{ color: "red", fontSize: "18px" }}>
                              *
                            </span>
                          </h5>
                          <FormGroup>
                            <Input
                              type="text"
                              name="text"
                              id="name"
                              value={profile.nsdc_url}
                              style={{ width: "98%" }}
                              className="profile-box"
                              disabled={true}
                            />
                          </FormGroup>
                        </div>
                      </li>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default ViewNSDCDetails;
