import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
import lecture from "../components/lecture/lecture";
import chapter from "../components/chapter/chapter";
const cookies = new Cookies();

export const updateLecStatus = (pid, cid, chid, fid, time, done) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.UPDATE_LECTURE_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          cp_id: chid,
          lc_id: fid,
          a_id: cookies.get("aid"),
          cl: "W",
          tim: time ? time : 0,
          done: done,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const updateLivLecStatus = (pid, cid, chid, fid, time, done) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.UPDATE_LIV_LEC_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          cp_id: chid,
          liv_lec_id: fid,
          a_id: cookies.get("aid"),
          cl: "W",
          tim: time ? time : 0,
          done: done,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const updateLectureBookmark = (lecture_id, millis, note) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .put(
        ROUTES.UPDATE_LECTURE_BOOKMARK,
        {
          lec_id: lecture_id,
          millis: millis,
          note: note,
          a_id: cookies.get("aid"),
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const getLectureBookmark = (lecture_id) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.GET_LECTURE_BOOKMARK,
        {
          lec_id: lecture_id,
          a_id: cookies.get("aid"),
          cl: "w",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const getChapterBookmark = (course_id) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.GET_CHAPTER_BOOKMARK,
        {
          c_id: course_id,
          a_id: cookies.get("aid"),
          cl: "w",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const submitIvq = (quesId, ans, fid, pid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    headers["Content-Type"] = "application/json";
    headers["Accept"] = "application/json";
    configs.headers = headers;
    return axios
      .post(
        ROUTES.SUBMIT_IVQ,
        {
          qus_id: quesId,
          p_id: pid,
          a_id: cookies.get("aid"),
          qus_res: ans,
          lec_id: fid,
          cl: "w",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const deleteLectureBookmark = (lecId, bkId) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .put(
        ROUTES.DELETE_BOOKMARK,
        {
          bkmrk_id: bkId,
          a_id: cookies.get("aid"),
          lec_id: lecId,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const getLectureTime = (lecture_id, liveData) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.GET_LECTURE_TIMING,
        {
          lec_id: lecture_id,
          isLive: liveData,
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};
