import React, { Component } from "react";
import facebookIcon from "../../images/facebook-icon.svg";
import googleIcon from "../../images/google-icon.svg";
import emailIcon from "../../images/email-icon.svg";
import mobileIcon from "../../images/mobile-icon.svg";
import usernameIcon from "../../images/username-icon.svg";
import { ROUTES } from "../../constants/routes";
import Axios from "axios";
import { Link, Redirect, withRouter } from "react-router-dom";
import { utils } from "../../utility/utils";
import Spinner from "../utils/Spinner";
import BrandSpinner from "../utils/BrandSpinner";
import { setBrandData } from "../../actions/brandAction";
import { connect } from "react-redux";
import Cookies from "universal-cookie";
import { CONFIGS, LABS_REDIRECT_LINK } from "../../constants/constants";
import cookie from "react-cookies";
import LoginWithEmail from "./LoginWithEmail";
import { Button, Input } from "reactstrap";
import CountryCodes from "../../constants/countryCodes";
const cookies = new Cookies();

let errorMessages = {
  incorrectPassword: "Incorrect Password",
  incorrectNumber:
    "This number is not linked with any account. Kindly try another method.",
  incorrectEmail:
    "No account is linked with this mail Id. Kindly try another method.",
  noPass:
    "Your account doesn’t have a password set up, please use Forgot Password option to set up your password",
};

class Auth extends Component {
  constructor(props) {
    super(props);

    var query = new URLSearchParams(this.props.location.search);
    this.redirectValue = query.get("redirect");

    var brand = props.match.params.brand;
    console.log({ query });
    if (brand) {
      this.state = {
        email: "",
        password: "",
        incorrectPassword: false,
        incorrectEmail: false,
        noPass: false,
        bruteforce: false,
        isLoading: true,
        invalidEmail: false,
        mobile: "",
        ccod: "91",
        method: "email",
        username: "",
      };
      Axios.get(ROUTES.FETCH_BRAND_DATA + `?brand_id=${brand}`)
        .then((res) => {
          this.setState({ isLoading: false });
          if (res.data.success) {
            let brandData = res.data.data.brand;
            if (
              !cookies.get("loaderColor") ||
              cookies.get("loaderColor") != brandData.loaderColorIndex
            ) {
              if (cookies.get("loaderColor") != brandData.loaderColorIndex) {
                cookies.remove("loaderColor", {
                  path: "/",
                  domain: CONFIGS.DOMAIN,
                });
              }
              let expirydate = new Date();
              expirydate.setDate(expirydate.getDate() + 180);
              cookies.set("loaderColor", brandData.loaderColorIndex, {
                expires: expirydate,
                domain: CONFIGS.DOMAIN,
                path: "/",
              });
            }
            this.setState({ brand: brandData });
            // this.props.setBrandData(brandData);
          } else {
            this.props.history.push("/login");
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log({ err });
          this.props.history.push("/login");
          window.location.reload();
        });
      // window.FreshworksWidget("hide");
      if (utils.getLoggedIn()) this.props.history.push("/dashboard");
    } else {
      if (cookies.get("loaderColor")) {
        cookies.remove("loaderColor", {
          path: "/",
          domain: CONFIGS.DOMAIN,
        });
      }
      this.state = {
        email: "",
        password: "",
        incorrectPassword: false,
        incorrectEmail: false,
        noPass: false,
        bruteforce: false,
        invalidEmail: false,
        isLoading: false,
        mobile: "",
        ccod: "91",
        method: "email",
        username: "",
      };
      let auth_cookies = query.get("auth_cookies");
      if (auth_cookies) {
        let {
          rt,
          secret,
          at: a_t,
          aid,
          pid,
          user,
          sid,
          Profile,
        } = JSON.parse(auth_cookies);
        utils.setLoginCookies(
          a_t,
          aid,
          secret,
          rt,
          true,
          pid,
          sid,
          user,
          Profile
        );
      }
      // window.FreshworksWidget("hide");

      if (utils.getLoggedIn()) this.props.history.push("/dashboard");
    }
  }

  validateEmail = (email) => {
    console.log({ email });
    return (
      email &&
      email.length <= 60 &&
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    );
  };

  validateMobile = (mobile) => {
    return mobile.length < 16 && mobile.length > 0;
  };

  validatePassword = (password) => {
    return password.length >= 8 && password.length <= 50;
  };
  validateUsername = (email) => {
    return email.length <= 60 && email.length > 0;
  };

  formValidation = () => {
    return (
      (this.validateEmail(this.state.email) ||
        this.validateMobile(this.state.mobile) ||
        this.validateUsername(this.state.username)) &&
      this.validatePassword(this.state.password)
    );
  };

  emailChange = (e) => {
    let email = e.target.value;
    if (email.length > 61) return;
    this.setState({
      email,
      incorrectEmail: false,
      invalidEmail: false,
    });
  };

  usernameChange = (e) => {
    let username = e.target.value;
    if (username.length > 50) return;
    this.setState({
      username,
      incorrectEmail: false,
      invalidEmail: false,
    });
  };

  passwordChange = (e) => {
    let password = e.target.value;
    if (password.length > 50) return;
    this.setState({
      password,
      incorrectPassword: false,
    });
  };

  loginResponseHandler = (res) => {
    let data = res.data.data;

    utils.setLoginCookies(
      data.at,
      data.aid,
      data.secret,
      data.rt,
      true,
      data.pid,
      data.sid,
      data.user,
      data.Profile[0]
    );

    this.setState({
      incorrectEmail: false,
      incorrectPassword: false,
      isLoading: false,
    });

    if (this.redirectValue === "labs")
      window.location.href = LABS_REDIRECT_LINK;

    if (this.redirectValue) {
      Axios.get(
        ROUTES.DEEP_LINK +
          "link" +
          this.redirectValue +
          `?aid=${cookies.get("aid")}&redirect=false`
      )
        .then((res) => {
          console.log(res);
          if (res.data.data.url) {
            window.location.href = res.data.data.url;
          }
        })
        .catch((err) => {
          console.log(err);
          this.props.history.push("/dashboard");
        });
    } else {
      this.props.history.push("/dashboard");
    }
  };

  loginWithEmail = () => {
    this.setState({ isLoading: true });
    Axios.post(ROUTES.LOGIN_ROUTE, {
      auth_method: "email",
      email: this.state.email,
      password: this.state.password,
      device_id: window.fingerprint.md5hash,
    })
      .then((res) => {
        this.loginResponseHandler(res);
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 310) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 100) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 315) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: true,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 207000) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: true,
          });
        } else if (ecode == 320) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: true,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 5400) {
          this.setState({
            incorrectEmail: true,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        }
      });
  };

  loginWithNumber = () => {
    this.setState({ isLoading: true });
    Axios.post(ROUTES.LOGIN_ROUTE, {
      auth_method: "phone",
      ccod: this.state.ccod,
      mobile: this.state.mobile,
      password: this.state.password,
      device_id: window.fingerprint.md5hash,
    })
      .then((res) => {
        this.loginResponseHandler(res);
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 310) {
          this.setState({
            incorrectNumber: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidNumber: false,
          });
        } else if (ecode == 100) {
          this.setState({
            incorrectNumber: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidNumber: false,
          });
        } else if (ecode == 315) {
          this.setState({
            incorrectNumber: false,
            incorrectPassword: false,
            noPass: true,
            bruteforce: false,
            isLoading: false,
            invalidNumber: false,
          });
        } else if (ecode == 206000) {
          this.setState({
            incorrectNumber: true,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidNumber: true,
          });
        } else if (ecode == 320) {
          this.setState({
            incorrectNumber: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: true,
            isLoading: false,
            invalidNumber: false,
          });
        } else if (ecode == 5400) {
          this.setState({
            incorrectNumber: true,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidNumber: false,
          });
        }
      });
  };

  loginWithUsername = () => {
    this.setState({ isLoading: true });
    Axios.post(ROUTES.LOGIN_ROUTE, {
      auth_method: "username",
      email: this.state.username,
      password: this.state.password,
      device_id: window.fingerprint.md5hash,
    })
      .then((res) => {
        this.loginResponseHandler(res);
      })
      .catch((err) => {
        let ecode = err.response.data.code;
        console.log(err.response.data);
        if (ecode == 310) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 100) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: true,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 315) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: true,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 207000) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: true,
          });
        } else if (ecode == 320) {
          this.setState({
            incorrectEmail: false,
            incorrectPassword: false,
            noPass: false,
            bruteforce: true,
            isLoading: false,
            invalidEmail: false,
          });
        } else if (ecode == 5400) {
          this.setState({
            incorrectEmail: true,
            incorrectPassword: false,
            noPass: false,
            bruteforce: false,
            isLoading: false,
            invalidEmail: false,
          });
        }
      });
  };

  loginWithFacebook = () => {
    this.setState({ isLoading: true });
    Axios.post(ROUTES.OAUTH_LOGIN + "?auth_method=facebook").then((res) => {
      if (this.redirectValue) {
        Axios.get(
          ROUTES.DEEP_LINK +
            "link" +
            this.redirectValue +
            `?aid=${cookies.get("aid")}&redirect=true`
        )
          .then((res) => {
            if (res.data.data.url) {
              window.location.href = res.data.data.url;
            }
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      } else {
        let url = res.data.data.url;
        window.location.href = url;
      }
      // let url = res.data.data.url;
      // window.location.href = url;
      //console.log(url);
    });
  };

  loginWithSaml = (utag) => {
    this.setState({ isLoading: true });
    Axios.get(ROUTES.SAML_LOGIN + "?utag=" + utag + "&redirect=false").then(
      (res) => {
        if (this.redirectValue) {
          Axios.get(
            ROUTES.DEEP_LINK +
              "link" +
              this.redirectValue +
              `?aid=${cookies.get("aid")}&redirect=true`
          )
            .then((res) => {
              if (res.data.data.url) {
                window.location.href = res.data.data.url;
              }
            })
            .catch((err) => {
              console.log(err.response.data);
            });
        } else {
          let url = res.data.data.url;
          window.location.href = url;
        }
        // let url = res.data.data.url;
        // window.location.href = url;
        //console.log(url);
      }
    );
  };

  loginWithGoogle = () => {
    this.setState({ isLoading: true });
    Axios.post(ROUTES.OAUTH_LOGIN + "?auth_method=google").then((res) => {
      if (this.redirectValue) {
        Axios.get(
          ROUTES.DEEP_LINK +
            "link" +
            this.redirectValue +
            `?aid=${cookies.get("aid")}&redirect=true`
        )
          .then((res) => {
            if (res.data.data.url) {
              window.location.href = res.data.data.url;
            }
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      } else {
        let url = res.data.data.url;
        window.location.href = url;
      }
      //console.log(url);
    });
  };

  ccodChange = (e) => {
    let ccod = e.target.value;
    this.setState({
      ccod,
      incorrectNumber: false,
    });
  };

  mobileChange = (e) => {
    let mobile = e.target.value;
    if (mobile.length > 15) return;
    let regExp = new RegExp("^[0-9]*$");
    if (mobile != "" && !regExp.test(mobile)) {
      return;
    }
    this.setState({
      mobile,
      incorrectNumber: false,
      invalidNumber: false,
    });
  };

  methodOfLogin = () => {
    if (this.state.method === "email") {
      this.loginWithEmail();
    }
    if (this.state.method === "mobile") {
      this.loginWithMobile();
    }
  };

  render() {
    return this.state.isLoading ? (
      <div className="loader loader-center">
        <div className="loader-msg-container">
          <Spinner />
        </div>
      </div>
    ) : (
      <div
        className="auth-main-2"
        style={
          this.state.brand
            ? {
                background: `url(${this.state.brand.bgimg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }
            : {}
        }
      >
        <div className="auth-block-2" style={{ padding: "40px" }}>
          <img
            className="auth-logo"
            alt="logo"
            width={170}
            height={34}
            src={
              this.state.brand
                ? this.state.brand.pic
                : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
            }
            style={{ marginBottom: "32px" }}
          ></img>
          <div
            className="auth-h"
            style={{ marginBottom: "24px", fontSize: "48px" }}
          >
            Welcome Back
          </div>
          <div style={{ marginBottom: "40px" }}>
            <b>Before moving forward, kindly login! </b>
          </div>

          {this.state.method === "email" && (
            <>
              {" "}
              <div className="auth-box" style={{ width: "100%" }}>
                <div>
                  <div className="auth-ttl">Enter Email Id</div>
                </div>
                <div className="auth-container">
                  <Input
                    type="email"
                    className="auth-inp"
                    name="email"
                    onChange={this.emailChange}
                    value={this.state.email}
                  ></Input>
                  <div
                    className={`line ${
                      this.validateEmail(this.state.email) &&
                      !this.state.incorrectEmail
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                </div>
                {this.state.incorrectEmail && (
                  <div className="auth-error">
                    {errorMessages["incorrectEmail"]}
                  </div>
                )}
                {this.state.invalidEmail && (
                  <div className="auth-error">
                    {errorMessages["invalidEmail"]}
                  </div>
                )}
              </div>
              <div className="auth-box" style={{ width: "100%" }}>
                <div className="auth-ttl">Enter Password</div>
                <div className="auth-container">
                  <Input
                    type="password"
                    className={`auth-inp ${
                      this.state.incorrectPassword ? "error-outline" : ""
                    }`}
                    name="password"
                    onChange={this.passwordChange}
                    value={this.state.password}
                  ></Input>
                  <div
                    className={`line ${
                      this.validatePassword(this.state.password) &&
                      !this.state.incorrectPassword
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                </div>
                {this.state.incorrectPassword && (
                  <div className="auth-error">
                    {errorMessages["incorrectPassword"]}
                  </div>
                )}
                {this.state.noPass && (
                  <div className="auth-error">{errorMessages["noPass"]}</div>
                )}
                {this.state.bruteforce && (
                  <div className="auth-error">
                    {errorMessages["bruteforce"]}
                  </div>
                )}
              </div>
              <Button
                type="submit"
                className={`${this.formValidation() ? "" : "disable"} ${
                  this.state.brand && this.state.brand.themeColorIndex
                    ? "auth-btn-brand"
                    : "auth-btn"
                }`}
                onClick={(event) => {
                  event.preventDefault();
                  if (this.formValidation()) this.loginWithEmail();
                }}
                style={{
                  marginTop: "32px",
                  background: `${
                    this.state.brand &&
                    this.state.brand.themeColorIndex &&
                    this.state.brand.themeColorIndex
                  }`,
                }}
              >
                Log in
              </Button>
            </>
          )}

          {this.state.method === "mobile" && (
            <>
              <div className="auth-box" style={{ width: "100%" }}>
                <div className="auth-ttl">Enter Mobile Number</div>
                <div className="auth-container">
                  <div style={{ display: "flex" }}>
                    <select
                      style={{ width: "30%" }}
                      className="auth-inp dropdown"
                      onChange={this.ccodChange}
                      value={this.state.ccod}
                    >
                      {CountryCodes.map((cc, i) => {
                        return (
                          <option
                            key={cc.code + String(i)}
                            disabled={cc.value == "_"}
                            value={cc.value}
                          >
                            {cc.display}
                          </option>
                        );
                      })}
                    </select>
                    <input
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      style={{ width: "70%" }}
                      name="tel"
                      className="auth-inp mobile"
                      onChange={this.mobileChange}
                      value={this.state.mobile}
                    ></input>
                  </div>
                  <div
                    className={`line ${
                      this.validateMobile(this.state.mobile) &&
                      !this.state.incorrectNumber
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                </div>
                {this.state.incorrectNumber && (
                  <div className="auth-error">
                    {errorMessages["incorrectNumber"]}
                  </div>
                )}
                {this.state.invalidNumber && (
                  <div className="auth-error">
                    {errorMessages["invalidNumber"]}
                  </div>
                )}
              </div>
              <div className="auth-box" style={{ width: "100%" }}>
                <div className="auth-ttl">Enter Password</div>
                <div className="auth-container">
                  <Input
                    type="password"
                    className={`auth-inp ${
                      this.state.incorrectPassword ? "error-outline" : ""
                    }`}
                    name="password"
                    onChange={this.passwordChange}
                    value={this.state.password}
                  ></Input>
                  <div
                    className={`line ${
                      this.validatePassword(this.state.password) &&
                      !this.state.incorrectPassword
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                </div>
                {this.state.incorrectPassword && (
                  <div className="auth-error">
                    {errorMessages["incorrectPassword"]}
                  </div>
                )}
                {this.state.noPass && (
                  <div className="auth-error">{errorMessages["noPass"]}</div>
                )}
                {this.state.bruteforce && (
                  <div className="auth-error">
                    {errorMessages["bruteforce"]}
                  </div>
                )}
              </div>
              <Button
                type="submit"
                className={`${this.formValidation() ? "" : "disable"} ${
                  this.state.brand && this.state.brand.themeColorIndex
                    ? "auth-btn-brand"
                    : "auth-btn"
                }`}
                onClick={(event) => {
                  event.preventDefault();
                  if (this.formValidation()) this.loginWithNumber();
                }}
                style={{
                  background: `${
                    this.state.brand &&
                    this.state.brand.themeColorIndex &&
                    this.state.brand.themeColorIndex
                  }`,
                }}
              >
                Log in
              </Button>
            </>
          )}

          {this.state.method === "username" && (
            <>
              {" "}
              <div className="auth-box" style={{ width: "100%" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="auth-ttl">Enter Username</div>
                </div>
                <div className="auth-container">
                  <Input
                    type="text"
                    className="auth-inp"
                    name="username"
                    onChange={this.usernameChange}
                    value={this.state.username}
                  ></Input>
                  <div
                    className={`line ${
                      this.validateUsername(this.state.username) &&
                      !this.state.incorrectEmail
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                </div>
                {this.state.incorrectEmail && (
                  <div className="auth-error">
                    {errorMessages["incorrectEmail"]}
                  </div>
                )}
                {this.state.invalidEmail && (
                  <div className="auth-error">
                    {errorMessages["invalidEmail"]}
                  </div>
                )}
              </div>
              <div className="auth-box" style={{ width: "100%" }}>
                <div className="auth-ttl">Enter Password</div>
                <div className="auth-container">
                  <Input
                    type="password"
                    className={`auth-inp ${
                      this.state.incorrectPassword ? "error-outline" : ""
                    }`}
                    name="password"
                    onChange={this.passwordChange}
                    value={this.state.password}
                  ></Input>
                  <div
                    className={`line ${
                      this.validatePassword(this.state.password) &&
                      !this.state.incorrectPassword
                        ? "lgreen"
                        : "lred"
                    }`}
                  ></div>
                </div>
                {this.state.incorrectPassword && (
                  <div className="auth-error">
                    {errorMessages["incorrectPassword"]}
                  </div>
                )}
                {this.state.noPass && (
                  <div className="auth-error">{errorMessages["noPass"]}</div>
                )}
                {this.state.bruteforce && (
                  <div className="auth-error">
                    {errorMessages["bruteforce"]}
                  </div>
                )}
              </div>
              <Button
                type="submit"
                className={`${this.formValidation() ? "" : "disable"} ${
                  this.state.brand && this.state.brand.themeColorIndex
                    ? "auth-btn-brand"
                    : "auth-btn"
                }`}
                onClick={(event) => {
                  event.preventDefault();
                  if (this.formValidation()) this.loginWithUsername();
                }}
                style={{
                  background: `${
                    this.state.brand &&
                    this.state.brand.themeColorIndex &&
                    this.state.brand.themeColorIndex
                  }`,
                }}
              >
                Log in
              </Button>
            </>
          )}

          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              minHeight: "85%",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <br />
            <div className="auth-name-mob-container">
              {(!this.state.brand ||
                this.state.brand.usernameEnabled == true) &&
                this.state.method !== "username" && (
                  <div
                    onClick={() => this.setState({ method: "username" })}
                    className="auth-login-btn"
                  >
                    <img src={usernameIcon} alt=""></img>
                    Login with Username
                  </div>
                )}
              {(!this.state.brand || this.state.brand.mobileAllowed == true) &&
                this.state.method !== "mobile" && (
                  <div
                    className="auth-login-btn"
                    onClick={() => this.setState({ method: "mobile" })}
                  >
                    <img src={mobileIcon}></img>
                    Login with Mobile Number
                  </div>
                )}
              {this.state.method !== "email" && (
                <div
                  onClick={() => this.setState({ method: "email" })}
                  className="auth-login-btn"
                >
                  <img src={usernameIcon} alt=""></img>
                  Login with Email
                </div>
              )}
            </div>
            <div className="auth-name-mob-container">
              {(!this.state.brand ||
                this.state.brand.googleAllowed == true) && (
                <div className="auth-login-btn" onClick={this.loginWithGoogle}>
                  <img src={googleIcon}></img>
                  Login with Google
                </div>
              )}
              {(!this.state.brand ||
                this.state.brand.facebookAllowed == true) && (
                <div
                  className="auth-login-btn"
                  onClick={this.loginWithFacebook}
                >
                  <img src={facebookIcon}></img>
                  Login with Facebook
                </div>
              )}
            </div>
            <div className="auth-name-mob-container">
              {this.state.brand &&
                this.state.brand.samlLoginEnabled == true && (
                  <div
                    className="auth-login-btn-full"
                    onClick={() => {
                      this.loginWithSaml(this.state.brand.utag);
                    }}
                  >
                    {this.state.brand && this.state.brand.pic && (
                      <img
                        src={this.state.brand.pic}
                        width={64}
                        height={32}
                      ></img>
                    )}
                    Login with {this.state.brand.name}
                  </div>
                )}
            </div>

            {this.state.brand &&
              this.state.brand.poweredByEnabled == true &&
              this.state.brand.hyperlinkEnabled == true && (
                <div
                  className="powered-by-container"
                  style={{ bottom: "24px" }}
                >
                  <a className="powered-by" href={"https://www.imarticus.org"}>
                    Powered By Imarticus Learning
                  </a>
                </div>
              )}
            {this.state.brand &&
              this.state.brand.poweredByEnabled == true &&
              this.state.brand.hyperlinkEnabled == false && (
                <div
                  className="powered-by-container"
                  style={{ bottom: "24px" }}
                >
                  <div className="powered-by">
                    Powered By Imarticus Learning
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default Auth;
