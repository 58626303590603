import React, { Component } from "react";
import Footer from "../footers/Footer";
import { withRouter } from "react-router";

class MyCourseSS extends Component {
  comboCourse = () => {
    this.props.history.push({
      pathname: "/dashboard/combo",
    });
  };
  showImg = (e) => {
    // e.target.style.display = "block";
  };
  render() {
    return (
      <div className="dashboard-content-wrapper">
        <div className="container-fluid dashboard-content">
          {/* <div className="row tab-row"> */}
          <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <p className="content-header">My Courses</p>
          </div>
          <div className="course-container">
            <div className="course-content">
              <div className="ic-container mb-25 br-hide">
                <div className="dum-container">
                  <div className="dum-mb-1"></div>
                  <div className="dum-mb-2"></div>
                  {/* <div className="dum-mb-3"></div> */}
                </div>
                <div className="img-section">
                  <div className="crs-img-container">
                    <img
                      className="crs-img"
                      onLoad={(e) => this.showImg(e)}
                      src={
                        "https://cdn.pegasus.imarticus.org/images/Full-Stack.png"
                      }
                    ></img>
                  </div>
                </div>

                <div className="progress-section-ss">
                  <div className="dum-ss-1"></div>
                  <div className="dum-ss-2"></div>
                </div>
                <div className="btn-section">
                  <div className="ss-btn"></div>
                </div>
              </div>
              <div className="ic-container mb-25 br-hide">
                <div className="dum-container">
                  <div className="dum-mb-1"></div>
                  <div className="dum-mb-2"></div>
                  {/* <div className="dum-mb-3"></div> */}
                </div>
                <div className="img-section">
                  <div className="crs-img-container">
                    <img
                      className="crs-img"
                      onLoad={(e) => this.showImg(e)}
                      src={
                        "https://cdn.pegasus.imarticus.org/images/Full-Stack.png"
                      }
                    ></img>
                  </div>
                </div>
                <div className="progress-section-ss">
                  <div className="dum-ss-1"></div>
                  <div className="dum-ss-2"></div>
                </div>
                <div className="btn-section">
                  <div className="ss-btn"></div>
                </div>
              </div>
              <div className="ic-container mb-25 br-hide">
                <div className="dum-container">
                  <div className="dum-mb-1"></div>
                  <div className="dum-mb-2"></div>
                  {/* <div className="dum-mb-3"></div> */}
                </div>
                <div className="img-section">
                  <div className="crs-img-container">
                    <img
                      className="crs-img"
                      onLoad={(e) => this.showImg(e)}
                      src={
                        "https://cdn.pegasus.imarticus.org/images/Full-Stack.png"
                      }
                    ></img>
                  </div>
                </div>

                <div className="progress-section-ss">
                  <div className="dum-ss-1"></div>
                  <div className="dum-ss-2"></div>
                </div>
                <div className="btn-section">
                  <div className="ss-btn"></div>
                </div>
              </div>
              <br />
            </div>
          </div>

          {/* </div> */}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(MyCourseSS);
