import React, { Component } from "react";
// import Slider from "react-slick";

const grp_dump_arr = [
  {
    img_url: "https://cdn.pegasus.imarticus.org/images/Machine-Learning.png",
    grp_name: "Introduction to Machine Learning Value Package",
  },
  {
    img_url: "https://cdn.pegasus.imarticus.org/images/Full-Stack.png",
    grp_name: "Full Stack Online Course",
  },
  {
    img_url: "https://cdn.pegasus.imarticus.org/images/CAT-super-50.png",
    grp_name: "CAT Super 50 Program",
  },
  {
    img_url:
      "https://cdn.pegasus.imarticus.org/images/Android-value-package.png",
    grp_name: "Android Development Online Certification",
  },
];

// const crs_dump_arr = [
//   {
//     img_url: "https://sample-videos.com/img/Sample-jpg-image-500kb.jpg",
//     crs_name: "Introduction to Machine Learning Value Package",
//     percent: 25,
//   },
//   {
//     img_url: "https://sample-videos.com/img/Sample-jpg-image-500kb.jpg",
//     crs_name: "Introduction to Machine",
//     percent: 13,
//   },
//   {
//     img_url: "https://sample-videos.com/img/Sample-jpg-image-500kb.jpg",
//     crs_name:
//       "Introduction to Machine Learning Value Package With Internship and much more",
//     percent: 0,
//   },
// ];

class ExploreCourseAndGroup extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //     grp_dump_arr : []
    // }
    //this.click = this.click.bind(this);
  }

  // componentDidMount() {
  //     this.setState({
  //         grp_dump_arr : [
  //             { img_url: 'https://cdn.pegasus.imarticus.org/images/Machine-Learning.png', grp_name: 'Introduction to Machine Learning Value Package' },
  //             { img_url: 'https://cdn.pegasus.imarticus.org/images/Full-Stack.png', grp_name: 'Full Stack Online Course' },
  //             { img_url: 'https://cdn.pegasus.imarticus.org/images/CAT-super-50.png', grp_name: 'CAT Super 50 Program' },
  //             { img_url: 'https://cdn.pegasus.imarticus.org/images/Android-value-package.png', grp_name: 'Android Development Online Certification' }
  //         ]
  //     })
  // }

  render() {
    var settings = {
      //dots: true,
      arrows: true,
      infinite: true,
      //speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
    };
    return (
      <div className="home-cg-container mr-0 ml-0 mb-0">
        <div>
          <p className="main-heading">Free Courses</p>
          <div className="row">
            {/* <Slider {...settings}/> */}
            {grp_dump_arr.map(({ img_url, grp_name }, index) => {
              return (
                //<div className="col-md-3 col-lg-3">
                // <Slider {...settings}>
                <div className="free-crs-card">
                  <div className="free-crs-container">
                    <div className="free-crs-img-container">
                      <img
                        className="free-crs-img"
                        src="https://marvel-live.freetls.fastly.net/canvas/2018/2/3bac41d56a5a4c659e06f1a48b45ee46?quality=95&fake=.png"
                      />
                    </div>
                    <div className="free-crs-dtl-container">
                      <div className="free-crs-dtl-info">
                        <div style={{ display: "block" }}>
                          <span className="lrn-nm">Learn Robotics</span>
                          <br />
                          <span
                            className="lrn-mem"
                            style={{ fontSize: "16px" }}
                          >
                            1124 Members
                          </span>
                        </div>
                      </div>
                      <div className="lrn-mr">
                        <button className="lrn-mr-btn">Learn More</button>
                      </div>
                    </div>
                  </div>
                </div>
                // </Slider>
                // <Slider {...settings}/>
              );
            })}
            {/* </Slider> */}
          </div>
        </div>
      </div>
    );
  }
}

export default ExploreCourseAndGroup;
