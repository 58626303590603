import Axios from "axios";
import { withRouter, useHistory } from "react-router";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import Loader from "../../utils/Loader.js";
import {
  CONFIGS,
  BOOL_DROPDOWN,
  JOB_LOCATIONS,
  BOARD_DROPDOWN,
  BACHELORS_DROPDOWN,
  MASTERS_DROPDOWN,
  STATIC_ROUNDS,
} from "../../../constants/constants";
import RightArrow from "../../../images/right_arrow.svg";
import { Link, useLocation } from "react-router-dom";
import small_left from "../../../images/left-arr-white.svg";
import ProfileDetailCard from "../../utils/ProfileDetailCard";
import { utils } from "../../../utility/utils";
import momentjs from "moment-timezone";
import "./placementprofile.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
  Input,
  ModalBody,
  Label,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { CCODS, TABS, PAGE_HEADINGS } from "../../../constants/constants";
import Select from "react-select";
import { setCurrentTab, setPageHeading } from "../../../actions/utilAction";
import PDFReader from "../../PDFReader/PDFReader";
import {
  getPlacementProfilePreview,
  getProfileInfo,
  getProfileInfoOnly,
  saveProfileDetails,
} from "../../../actions/profileAction";
const ProfilePreview = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  let fileInput = useRef(null);
  let profile = props.profile;
  let courses = props.courses;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // const [ifSubscribed, setIfSubscribed] = useState(profile.ifSubscribed);
  //states
  const [loading, setLoading] = useState(true);
  const [crs_pg_id, setCrsPgId] = useState("");
  const [initialLoading, setIntitalLoading] = useState(false);
  const [percCompleted, setPercCompleted] = useState(0);
  const [redirectVal, setRedirectVal] = useState("");
  const [account, setAccount] = useState({});
  const [togglePDFDoc, setTogglePDFDoc] = useState(false);
  const [resumeURL, setResumeURL] = useState("");
  const [lastFilledBlockIndex, setLastFilledBlockIndex] = useState(0);
  const [basicDetails, setBasicDetails] = useState({
    ccod: "",
    name: "",
    email: "",
    num: "",
    aboutme: "",
    gender: "",
    uid: "",
    ppic: "",
  });
  const [educationDetails, setEducationDetails] = useState({
    matric: {
      school: "",
      board: "",
      score_type: "CGPA",
      perc: 0,
      year: "",
    },
    inter: {
      school: "",
      board: "",
      score_type: "CGPA",
      perc: 0,
      year: "",
    },
    grad: {
      college: "",
      degree: "",
      score_type: "CGPA",
      perc: 0,
      year: "",
    },
    post_grad_completed: false,
    post_grad: {
      college: "",
      degree: "",
      score_type: "CGPA",
      perc: 0,
      year: "",
    },
  });
  const [professionalDetails, setProfessionalDetails] = useState({
    working: "false",
    notice_period: 0,
    last_salary: 0,
    default_jobexp: {
      curr_working: false,
      company_name: "",
      job_title: "",
      job_location: "",
      employment_type: "0",
      year: 0,
      month: 0,
    },
    additional_jobexp: [],
    total_experience: {
      year: 0,
      month: 0,
    },
    preferences: {
      relocate: "0",
      night_shift: "0",
      intern: "0",
      contract: "0",
      loc_pref: [],
    },
    links: {
      linkedin: "",
      github: "",
      kaggle: "",
      icr_agreement: "",
    },
  });
  const [resume, setResume] = useState([]);
  const [jobsData, setJobsData] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState(2);
  const [icrAgreeURL, setICRAgreeURl] = useState("");
  //end states
  const [isDefaultCollapsed, setIsDefaultCollapsed] = useState(true);
  const [expanded, setExpanded] = useState({});
  const [dragging, setDragging] = useState(false);
  console.log({ profile });
  let creationDate = new Date(profile.profile_map.cat).toString();
  useEffect(() => {
    props.setCurrentTab(TABS.PROFESSIONAL_DETAILS);
    props.setPageHeading(PAGE_HEADINGS.PROFILE_SETTINGS);
    if (props.uid == "") {
      props.getProfileInfo();
    }
    const crs_pgid = searchParams.get("crs_pg_id");
    setCrsPgId(crs_pgid);
    const redirect = searchParams.get("redirect");
    setRedirectVal(redirect);
    setIntitalLoading(true);
  }, []);
  useEffect(() => {
    console.log("useEffect ran");
    if (initialLoading) {
      getPlacementProfilePreview(crs_pg_id)
        .then(function (res) {
          setLoading(false);
          console.log(res, ":response data");
          setAccount(res.account);
          if (res && res.lastFilledBlockIndex) {
            setLastFilledBlockIndex(res.lastFilledBlockIndex);
          }
          if (res && res.learnerInfo) {
            let basicdetail = res.learnerInfo?.["basic-details"] ?? {};
            let educationaldetail =
              res.learnerInfo?.["educational-details"] ?? {};
            let professionaldetail =
              res.learnerInfo?.["professional-details"] ?? {};

            setBasicDetails({
              ccod: basicdetail?.ccod,
              name: basicdetail?.name,
              email: basicdetail?.email,
              num: basicdetail?.num,
              uid: basicdetail?.uid,
              ppic: basicdetail?.ppic,
              aboutme: basicdetail?.aboutme,
              gender: basicdetail?.gender,
            });
            setEducationDetails({
              matric: {
                school: educationaldetail?.matric?.school,
                board: educationaldetail?.matric?.board,
                score_type: educationaldetail?.matric?.score_type,
                perc: educationaldetail?.matric?.perc,
                year: educationaldetail?.matric?.year,
              },
              inter: {
                school: educationaldetail?.inter?.school,
                board: educationaldetail?.inter?.board,
                score_type: educationaldetail?.inter?.score_type,
                perc: educationaldetail?.inter?.perc,
                year: educationaldetail?.inter?.year,
              },
              grad: {
                college: educationaldetail?.grad?.college,
                degree: educationaldetail?.grad?.degree,
                score_type: educationaldetail?.grad?.score_type,
                perc: educationaldetail?.grad?.perc,
                year: educationaldetail?.grad?.year,
              },
              post_grad_completed: educationaldetail?.post_grad_completed,
              post_grad: {
                college: educationaldetail?.post_grad?.college,
                degree: educationaldetail?.post_grad?.degree,
                score_type: educationaldetail?.post_grad?.score_type,
                perc: educationaldetail?.post_grad?.perc,
                year: educationaldetail?.post_grad?.year,
              },
            });
            setProfessionalDetails({
              working: professionaldetail?.working,
              notice_period: professionaldetail?.notice_period,
              last_salary: professionaldetail?.last_salary,
              default_jobexp: {
                curr_working: professionaldetail?.default_jobexp?.curr_working,
                company_name: professionaldetail?.default_jobexp?.company_name,
                job_title: professionaldetail?.default_jobexp?.job_title,
                job_location: professionaldetail?.default_jobexp?.job_location,
                employment_type:
                  professionaldetail?.default_jobexp?.employment_type,
                year: professionaldetail?.default_jobexp?.year,
                month: professionaldetail?.default_jobexp?.month,
              },
              additional_jobexp: professionaldetail?.additional_jobexp,
              total_experience: {
                year: professionaldetail?.total_experience?.year,
                month: professionaldetail?.total_experience?.month,
              },
              preferences: {
                relocate: professionaldetail?.preferences?.relocate,
                night_shift: professionaldetail?.preferences?.night_shift,
                intern: professionaldetail?.preferences?.intern,
                contract: professionaldetail?.preferences?.contract,
                loc_pref: professionaldetail?.preferences?.loc_pref,
              },
              links: {
                linkedin: professionaldetail?.links?.linkedin,
                github: professionaldetail?.links?.github,
                kaggle: professionaldetail?.links?.kaggle,
                icr_agreement: professionaldetail?.links?.icr_agreement,
              },
            });
            setPercCompleted(res.perc_completed);
          }
          if (res && res.learnerResume) {
            setResume(res.learnerResume);
            console.log({ learnerResume: res.learnerResume });
          }
          if (res && res.jobsData) {
            setJobsData(res.jobsData);
          }
        })
        .catch(function (err) {
          setLoading(false);
          console.log(err);
        });
    }
  }, [initialLoading]);
  const changeJobType = (jobType) => {
    console.log({ jobType });
    setSelectedJobType(jobType);
  };

  const previewResumeClick = (url) => {
    setResumeURL(url);
    setTogglePDFDoc(true);
  };

  const changeEmailSubscription = () => {
    console.log(" --- Changed the subscription --- ");
    Axios.post(`${CONFIGS.API_ENDPOINT}notifications/sbscrb_email`, {
      aid: utils.getAid(),
      tokn: utils.getAt(),
      ifSubscribed: !profile.ifSubscribed,
    })
      .then((res) => {
        console.log("Response successful!");
        dispatch({
          type: "CHANGE_SUBSCRIPTION",
          ifSubscribed: !profile.ifSubscribed,
        });
        return;
      })
      .catch((err) => {
        console.log("Error: ", err);
        return;
      });
  };

  const extensions = [".pdf", ".docx", ".xlsx", ".txt"];

  const getFileName = (url) => {
    return extensions.find((ext) => url.endsWith(ext))
      ? url.substring(url.lastIndexOf("/") + 1)
      : null;
  };

  const redirectToCourse = () => {
    const course = courses.find((course) => course.crs_pg_id === crs_pg_id);
    if (course) {
      history.push(`/view/?cid=${course._id}`);
    }
  };

  const toggleCollapse = () => {
    setIsDefaultCollapsed(!isDefaultCollapsed);
  };

  const toggleExpand = (index) => {
    setExpanded((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      <div className="group-sidebar">
        <Link to="/dashboard">
          <div
            className="logo-container center-all "
            style={{
              backgroundColor: `${
                props.brand && props.brand.whiteBackground ? "white" : ""
              }`,
            }}
          >
            {!props.is_data_loading && (
              <img
                src={
                  props.brand
                    ? props.brand.pic
                    : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                }
                className="side-bar__logo-img"
                style={{
                  maxHeight: "50px",
                  margin: "unset",
                }}
              />
            )}
          </div>
        </Link>
        {/* <Link to="/dashboard"> */}
        <p className="back-btn" onClick={redirectToCourse}>
          <img className="back-img" src={small_left}></img>Back
        </p>
        {/* </Link> */}
        <div>
          <p className="grp-heading">My Profile</p>
          <Link
            to={`/placement-module/profile_preview/?crs_pg_id=${crs_pg_id}&redirect=${redirectVal}`}
          >
            <div className={"g-name-wrapper active"}>
              <p className={"g-name"}>Profile Preview</p>
            </div>
          </Link>
          <Link
            to={`/placement-module/basic_details/?crs_pg_id=${crs_pg_id}&redirect=${redirectVal}`}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Basic Details</p>
            </div>
          </Link>
          <Link
            to={"#"}
            // to={`/placement-module/education-details/?crs_pg_id=${crs_pg_id}`}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Education Details</p>
            </div>
          </Link>
          <Link
            to={"#"}
            // to={`/placement-module/professional-details/?crs_pg_id=${crs_pg_id}`}
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Professional Details</p>
            </div>
          </Link>
          <Link
            to={
              "#"
              // `/placement-module/resume-details/?crs_pg_id=${crs_pg_id}`
            }
            onClick={(e) => {
              e.preventDefault();
            }}
            style={{ opacity: 0.5 }}
          >
            <div className={"g-name-wrapper"}>
              <p className={"g-name"}>Resume</p>
            </div>
          </Link>
        </div>
      </div>
      {!loading ? (
        <>
          <div className="group-db-content-wrapper-2">
            <div className="container-fluid dashboard-content">
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container-77 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <br />
                  <div className="row profile-bar-2">
                    <div
                      className="col-12 text-center"
                      style={{ justifyContent: "center" }}
                    >
                      <img className="profile-img_pp" src={profile.ppic}></img>
                      <p className="UID_pp" style={{ marginTop: "20px" }}>
                        UID: {account.uid}
                      </p>
                    </div>
                    <br />
                    <div className="col-lg-6 col-sm-12">
                      <Link to="/dashboard/edit/profile">
                        <div
                          style={{
                            borderBottom: "0.5px solid rgba(60, 72, 82, 0.24)",
                          }}
                        >
                          <small>Name</small>
                          <h5 className="profile-preview-name">
                            {profile.p_name}
                            <img
                              src={RightArrow}
                              style={{
                                float: "right",
                              }}
                            />
                          </h5>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-sm-12 addMTonPhone">
                      <Link to="/dashboard/edit/email">
                        <div
                          style={{
                            borderBottom: "0.5px solid rgba(60, 72, 82, 0.24)",
                          }}
                        >
                          <small>
                            Primary Email{" "}
                            {account.ep_vrfy && (
                              <img
                                style={{ height: "16px", marginLeft: "10px" }}
                                src={
                                  "https://cdn.pegasus.imarticus.org/courses/images/checked.svg"
                                }
                                alt={"Verified"}
                              />
                            )}
                          </small>
                          <h5 className="profile-preview-name">
                            {account.ep}
                            <img
                              src={RightArrow}
                              style={{
                                float: "right",
                              }}
                            />
                          </h5>
                        </div>
                      </Link>
                    </div>

                    <div className="col-lg-6 col-sm-12 mt-4">
                      <Link to="/dashboard/edit/mobile">
                        <div
                          style={{
                            borderBottom: "0.5px solid rgba(60, 72, 82, 0.24)",
                          }}
                        >
                          <small>
                            Registered Mobile Number{" "}
                            {account.m && (
                              <img
                                style={{ height: "16px", marginLeft: "10px" }}
                                src={
                                  "https://cdn.pegasus.imarticus.org/courses/images/checked.svg"
                                }
                                alt={"Verified"}
                              />
                            )}
                          </small>
                          <h5 className="profile-preview-name">
                            {account.m}
                            <img
                              src={RightArrow}
                              style={{
                                float: "right",
                              }}
                            />
                          </h5>
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-sm-12 mt-4">
                      <Link to="/dashboard/edit/timezone">
                        <div
                          style={{
                            borderBottom: "0.5px solid rgba(60, 72, 82, 0.24)",
                          }}
                        >
                          <small>
                            Preferred Timezone{" "}
                            {account.m && (
                              <img
                                style={{ height: "16px", marginLeft: "10px" }}
                                src={
                                  "https://cdn.pegasus.imarticus.org/courses/images/checked.svg"
                                }
                                alt={"Verified"}
                              />
                            )}
                          </small>
                          <h5 className="profile-preview-name">
                            {account.tz}
                            <img
                              style={{ float: "right" }}
                              src={RightArrow}
                              alt={"Verified"}
                            />
                          </h5>
                        </div>
                      </Link>
                    </div>

                    {/* <div
                        style={{
                          marginLeft: "18px",
                          marginRight: "15px",
                          borderTop: "1px solid #71748d",
                          stroke: "rgba(60, 72, 82, 0.15)",
                        }}
                      ></div> */}
                    {/* <div
                        className="row profile-preview-fields "
                        style={{
                          marginLeft: "15px",
                          // borderTop: "1px solid #71748d",
                        }}
                      > */}
                    {/* <div className="col-md-6 ">
                          <p>
                            <img
                              style={{ height: "16px", marginRight: "5px" }}
                              src={
                                "https://webcdn.imarticus.org/Placement/teenyicons_id-solid.svg"
                              }
                            />
                            {account.uid}
                          </p>
                        </div> */}
                    {/* <div className="col-md-6">
                          <div className="row ignore-row-margin">
                            <p>
                              <img
                                style={{ height: "16px", marginRight: "5px" }}
                                src={
                                  "https://webcdn.imarticus.org/Placement/ic_baseline-email.svg"
                                }
                              />
                              {account.ep}
                            </p>
                            {account.ep_vrfy && (
                              <img
                                style={{ height: "16px", marginLeft: "10px" }}
                                src={
                                  "https://cdn.pegasus.imarticus.org/courses/images/checked.svg"
                                }
                                alt={"Verified"}
                              />
                            )}
                            <Link to="/dashboard/edit/email">
                              <img
                                style={{ height: "16px", marginLeft: "10px" }}
                                src={RightArrow}
                                alt={"Verified"}
                              />
                            </Link>
                          </div>
                        </div>
                      {/* </div> 
                      <div className="row" style={{ marginLeft: "15px" }}>
                        <div className="col-md-6">
                          <div className="row ignore-row-margin profile-preview-fields ">
                            <p>
                              <img
                                style={{ height: "16px", marginRight: "5px" }}
                                src={
                                  "https://webcdn.imarticus.org/Placement/material-symbols_call.svg"
                                }
                              />
                              {account.m}
                            </p>
                            {account.m && (
                              <img
                                style={{ height: "16px", marginLeft: "10px" }}
                                src={
                                  "https://cdn.pegasus.imarticus.org/courses/images/checked.svg"
                                }
                                alt={"Verified"}
                              />
                            )}
                            <Link to="/dashboard/edit/mobile">
                              <img
                                style={{ height: "16px", marginLeft: "10px" }}
                                src={RightArrow}
                                alt={"Verified"}
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-6 profile-preview-fields ">
                          <p>
                            <img
                              style={{ height: "16px", marginRight: "5px" }}
                              src={
                                "https://webcdn.imarticus.org/Placement/mdi_timezone.svg"
                              }
                            />
                            {account.tz}
                            <Link to="/dashboard/edit/timezone">
                              <img
                                style={{ height: "16px", marginLeft: "10px" }}
                                src={RightArrow}
                                alt={"Verified"}
                              />
                            </Link>
                          </p>
                        </div>
                      </div> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="group-db-content-wrapper">
            {lastFilledBlockIndex >= 1 && (
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-77 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="justify-content-center">
                      <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">
                              Education
                            </h4>
                          </li>
                          <li className="row">
                            <div className="col-md-12">
                              <h5
                                className="profile-preview-heading-2 modal-title "
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Class X
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Board Name:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {BOARD_DROPDOWN.find(
                                  (item) =>
                                    String(item.value) ===
                                    String(educationDetails.matric.board)
                                )?.label || "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Grade:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.matric.score_type === "CGPA"
                                  ? `${educationDetails.matric.perc} CGPA`
                                  : `${educationDetails.matric.perc} %`}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-12">
                              <h5
                                className="profile-preview-heading-2 modal-title "
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Class XII
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Board Name:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {BOARD_DROPDOWN.find(
                                  (item) =>
                                    String(item.value) ===
                                    String(educationDetails.inter.board)
                                )?.label || "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Grade:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.inter.score_type === "CGPA"
                                  ? `${educationDetails.inter.perc} CGPA`
                                  : `${educationDetails.inter.perc} %`}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-12">
                              <h5
                                className="profile-preview-heading-2 modal-title "
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Graduation/Diploma
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                College/University Name:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.grad.college}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Degree Name:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {BACHELORS_DROPDOWN.find(
                                  (item) =>
                                    String(item.value) ===
                                    String(educationDetails.grad.degree)
                                )?.label || "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Graduation Pass Out Year:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.grad.year}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Grade:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {educationDetails.grad.score_type === "CGPA"
                                  ? `${educationDetails.grad.perc} CGPA`
                                  : `${educationDetails.grad.perc} %`}
                              </h5>
                            </div>
                          </li>

                          {educationDetails.post_grad_completed === true && (
                            <>
                              <li className="row">
                                <div className="col-md-12">
                                  <h5
                                    className="profile-preview-heading-2 modal-title "
                                    htmlFor="thumbImg"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    Masters/Post-Graduation
                                  </h5>
                                </div>
                              </li>
                              <li className="row">
                                <div className="col-md-6">
                                  <h5
                                    className="modal-title profile-preview-heading-3"
                                    htmlFor="thumbImg"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    College/University Name:
                                  </h5>
                                </div>
                                <div className="col-md-6">
                                  <h5
                                    className="modal-title profile-preview-detail"
                                    htmlFor="thumbImg"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    {educationDetails.post_grad.college}
                                  </h5>
                                </div>
                              </li>
                              <li className="row">
                                <div className="col-md-6">
                                  <h5
                                    className="modal-title profile-preview-heading-3"
                                    htmlFor="thumbImg"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    Degree Name:
                                  </h5>
                                </div>
                                <div className="col-md-6">
                                  <h5
                                    className="modal-title profile-preview-detail"
                                    htmlFor="thumbImg"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    {MASTERS_DROPDOWN.find(
                                      (item) =>
                                        String(item.value) ===
                                        String(
                                          educationDetails.post_grad.degree
                                        )
                                    )?.label || "-"}
                                  </h5>
                                </div>
                              </li>
                              <li className="row">
                                <div className="col-md-6">
                                  <h5
                                    className="modal-title profile-preview-heading-3"
                                    htmlFor="thumbImg"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    Graduation Pass Out Year:
                                  </h5>
                                </div>
                                <div className="col-md-6">
                                  <h5
                                    className="modal-title profile-preview-detail"
                                    htmlFor="thumbImg"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    {educationDetails.post_grad.year}
                                  </h5>
                                </div>
                              </li>
                              <li className="row">
                                <div className="col-md-6">
                                  <h5
                                    className="modal-title profile-preview-heading-3"
                                    htmlFor="thumbImg"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    Grade:
                                  </h5>
                                </div>
                                <div className="col-md-6">
                                  <h5
                                    className="modal-title profile-preview-detail"
                                    htmlFor="thumbImg"
                                    style={{ marginTop: "1rem" }}
                                  >
                                    {educationDetails.post_grad.score_type ===
                                    "CGPA"
                                      ? `${educationDetails.post_grad.perc} CGPA`
                                      : `${educationDetails.post_grad.perc} %`}
                                  </h5>
                                </div>
                              </li>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {lastFilledBlockIndex === 2 && (
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-77 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="justify-content-center">
                      <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">
                              Job Details
                            </h4>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Do you have any work
                                experience?(Full-time+Internship):
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.working === true ||
                                professionalDetails.working === "true"
                                  ? "Yes"
                                  : professionalDetails.working === false ||
                                    professionalDetails.working === "false"
                                  ? "No"
                                  : "-"}
                              </h5>
                            </div>
                          </li>
                          {professionalDetails?.working &&
                            professionalDetails?.working === "true" && (
                              <>
                                <li className="row">
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-heading-3"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      Total Experience:
                                    </h5>
                                  </div>
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-detail"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      {professionalDetails.total_experience
                                        .year || 0}{" "}
                                      Years{" "}
                                      {professionalDetails.total_experience
                                        .month || 0}{" "}
                                      Months
                                    </h5>
                                  </div>
                                </li>
                                <li className="row">
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-heading-3"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      Notice Period:
                                    </h5>
                                  </div>
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-detail"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      {professionalDetails.notice_period} Months
                                    </h5>
                                  </div>
                                </li>
                                <li className="row">
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-heading-3"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      Last Drawn Salary:
                                    </h5>
                                  </div>
                                  <div className="col-md-6">
                                    <h5
                                      class="modal-title profile-preview-detail"
                                      htmlFor="thumbImg"
                                      style={{ marginTop: "1rem" }}
                                    >
                                      {professionalDetails.last_salary} LPA
                                    </h5>
                                  </div>
                                </li>
                              </>
                            )}
                          {professionalDetails?.working &&
                            professionalDetails?.working === "true" && (
                              <>
                                <li
                                  className="row"
                                  style={{
                                    marginTop: "16px",
                                    width: "100%",
                                    background: "#EFF4F5",
                                    borderRadius: "4px",
                                    marginLeft: "0px",
                                  }}
                                >
                                  <li
                                    className="row"
                                    style={{
                                      marginTop: "16px",
                                      marginLeft: "16px",
                                      width: "100%",
                                      display: "flex",
                                      justifyContent: "space-between",
                                      cursor: "pointer",
                                    }}
                                    onClick={toggleCollapse}
                                  >
                                    <h6 className="profile-prev-job-exp-heading ">
                                      Job Experience 1
                                    </h6>
                                    {isDefaultCollapsed ? (
                                      <i
                                        className="icon fa fa-chevron-down"
                                        style={{
                                          fontSize: "small",
                                          paddingRight: "32px",
                                          paddingTop: "8px",
                                        }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="icon fa fa-chevron-up"
                                        style={{
                                          fontSize: "small",
                                          paddingRight: "32px",
                                          paddingTop: "8px",
                                        }}
                                      ></i>
                                    )}
                                  </li>
                                  {!isDefaultCollapsed && (
                                    <>
                                      <li
                                        className="row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading-3"
                                            htmlFor="thumbImg"
                                          >
                                            Company Name:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.company_name
                                            }
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading-3"
                                            htmlFor="thumbImg"
                                          >
                                            Job Title:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.job_title
                                            }
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading-3"
                                            htmlFor="thumbImg"
                                          >
                                            Job Location:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.job_location
                                            }
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading-3"
                                            htmlFor="thumbImg"
                                          >
                                            Employment Type:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.employment_type
                                            }
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading-3"
                                            htmlFor="thumbImg"
                                          >
                                            Experience:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {
                                              professionalDetails.default_jobexp
                                                ?.year
                                            }{" "}
                                            Years{" "}
                                            {
                                              professionalDetails.default_jobexp
                                                ?.month
                                            }{" "}
                                            Months
                                          </h5>
                                        </div>
                                      </li>
                                      <li
                                        className="row"
                                        style={{
                                          marginTop: "16px",
                                          marginLeft: "16px",
                                          marginBottom: "16px",
                                          width: "100%",
                                        }}
                                      >
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-heading-3"
                                            htmlFor="thumbImg"
                                          >
                                            Currently working here:
                                          </h5>
                                        </div>
                                        <div className="col-md-6">
                                          <h5
                                            class="modal-title profile-preview-detail"
                                            htmlFor="thumbImg"
                                          >
                                            {professionalDetails.default_jobexp
                                              ?.curr_working
                                              ? "Yes"
                                              : "No"}
                                          </h5>
                                        </div>
                                      </li>
                                    </>
                                  )}
                                </li>

                                {professionalDetails.additional_jobexp &&
                                  professionalDetails.additional_jobexp.length >
                                    0 &&
                                  professionalDetails.additional_jobexp.map(
                                    (job, index) => (
                                      <li
                                        className="row"
                                        key={index}
                                        style={{
                                          marginTop: "16px",
                                          width: "100%",
                                          background: "#EFF4F5",
                                          borderRadius: "4px",
                                          marginLeft: "0px",
                                        }}
                                      >
                                        <li
                                          className="row"
                                          style={{
                                            marginTop: "16px",
                                            marginLeft: "16px",
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => toggleExpand(index)}
                                        >
                                          <h6 className="profile-prev-job-exp-heading">
                                            Job Experience {index + 2}
                                          </h6>
                                          {expanded[index] ? (
                                            <i
                                              className="icon fa fa-chevron-up"
                                              style={{
                                                fontSize: "small",
                                                paddingRight: "32px",
                                                paddingTop: "8px",
                                              }}
                                            ></i>
                                          ) : (
                                            <i
                                              className="icon fa fa-chevron-down"
                                              style={{
                                                fontSize: "small",
                                                paddingRight: "32px",
                                                paddingTop: "8px",
                                              }}
                                            ></i>
                                          )}
                                        </li>
                                        {expanded[index] && (
                                          <>
                                            <li
                                              className="row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading-3">
                                                  Company Name:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.company_name || "-"}
                                                </h5>
                                              </div>
                                            </li>
                                            <li
                                              className="row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading-3">
                                                  Job Title:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.job_title || "-"}
                                                </h5>
                                              </div>
                                            </li>
                                            <li
                                              className="row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading-3">
                                                  Job Location:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.job_location || "-"}
                                                </h5>
                                              </div>
                                            </li>
                                            <li
                                              className="row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading-3">
                                                  Employment Type:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.employment_type || "-"}
                                                </h5>
                                              </div>
                                            </li>
                                            <li
                                              className="row"
                                              style={{
                                                marginTop: "16px",
                                                marginLeft: "16px",
                                                marginBottom: "16px",
                                                width: "100%",
                                              }}
                                            >
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-heading-3">
                                                  Experience:
                                                </h5>
                                              </div>
                                              <div className="col-md-6">
                                                <h5 className="modal-title profile-preview-detail">
                                                  {job.year} Years {job.month}{" "}
                                                  Months
                                                </h5>
                                              </div>
                                            </li>
                                          </>
                                        )}
                                      </li>
                                    )
                                  )}
                              </>
                            )}
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Open to Relocate:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.preferences.relocate ===
                                  true ||
                                professionalDetails.preferences.relocate ===
                                  "true"
                                  ? "Yes"
                                  : professionalDetails.preferences.relocate ===
                                      false ||
                                    professionalDetails.preferences.relocate ===
                                      "false"
                                  ? "No"
                                  : "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Open for Night Shifts/Rotational:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.preferences.night_shift ===
                                  true ||
                                professionalDetails.preferences.night_shift ===
                                  "true"
                                  ? "Yes"
                                  : professionalDetails.preferences
                                      .night_shift === false ||
                                    professionalDetails.preferences
                                      .night_shift === "false"
                                  ? "No"
                                  : "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Open for Internships?:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.preferences.intern ===
                                  true ||
                                professionalDetails.preferences.intern ===
                                  "true"
                                  ? "Yes"
                                  : professionalDetails.preferences.intern ===
                                      false ||
                                    professionalDetails.preferences.intern ===
                                      "false"
                                  ? "No"
                                  : "-"}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Open for Contractual Roles:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.preferences.contract ===
                                  true ||
                                professionalDetails.preferences.contract ===
                                  "true"
                                  ? "Yes"
                                  : professionalDetails.preferences.contract ===
                                      false ||
                                    professionalDetails.preferences.contract ===
                                      "false"
                                  ? "No"
                                  : "-"}
                              </h5>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {lastFilledBlockIndex === 2 && (
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="justify-content-center">
                      <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">
                              Links
                            </h4>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Github Link:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {/* {professionalDetails.links.github || "-"} */}
                                {professionalDetails.links.github ? (
                                  <a
                                    href={professionalDetails.links.github}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {professionalDetails.links.github}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Kaggle Link:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {/* {professionalDetails.links.kaggle || "-"} */}
                                {professionalDetails.links.kaggle ? (
                                  <a
                                    href={professionalDetails.links.kaggle}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {professionalDetails.links.kaggle}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                LinkedIn Link:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {/* {professionalDetails.links.linkedin || "-"} */}
                                {professionalDetails.links.linkedin ? (
                                  <a
                                    href={professionalDetails.links.linkedin}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {professionalDetails.links.linkedin}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </h5>
                            </div>
                          </li>
                          <li className="row">
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-heading-3"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                Signed ICR Agreement:
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <h5
                                class="modal-title profile-preview-detail"
                                htmlFor="thumbImg"
                                style={{ marginTop: "1rem" }}
                              >
                                {professionalDetails.links.icr_agreement ? (
                                  <a
                                    href={
                                      professionalDetails.links.icr_agreement
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {"Agreement Link"}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </h5>
                            </div>
                          </li>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {resume && resume.length > 0 && (
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="justify-content-center">
                      <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">
                              Resume
                            </h4>
                          </li>
                          <br></br>
                          {resume
                            .filter((file) => file.resume_type === 2)
                            .map((file, index) => (
                              <div key={index}>
                                <div className="resume-div drop-zone">
                                  <div
                                    className="row"
                                    style={{
                                      paddingTop: "16px",
                                      paddingLeft: "16px",
                                    }}
                                  >
                                    <div className="col-sm-2 col-2 ">
                                      <img
                                        className="resume-icon-2"
                                        src="https://webcdn.imarticus.org/Placement/fluent_document-pdf-24-filled.svg"
                                      />
                                    </div>
                                    <div className="col-sm-7 col-7 ">
                                      <h5 className="resume-heading">
                                        Resume {index + 1}
                                      </h5>
                                      <p>
                                        {momentjs(file.createdAt).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}
                                      </p>
                                    </div>
                                    <div className="d-none d-lg-md col-1"></div>
                                    <div className="col-sm-2 col-2 ">
                                      <img
                                        src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                        className="as-dl-logo-job"
                                        onClick={() =>
                                          previewResumeClick(file.resume_url)
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            ))}
                          {resume
                            .filter((file) => file.resume_type === 1)
                            .map((file, index) => (
                              <div key={index}>
                                <div className="resume-div drop-zone">
                                  <div
                                    className="row"
                                    style={{
                                      paddingTop: "16px",
                                      paddingLeft: "16px",
                                    }}
                                  >
                                    <div className="col-md-2">
                                      <img
                                        className="resume-icon-2"
                                        src="https://webcdn.imarticus.org/Placement/mingcute_video-fill.svg"
                                      />
                                    </div>
                                    <div className="col-md-7">
                                      <h5 className="resume-heading">
                                        Resume {index + 1}
                                      </h5>
                                      <p>
                                        {momentjs(file.createdAt).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}
                                      </p>
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-2">
                                      <a
                                        href={file.resume_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src="https://webcdn.imarticus.org/Demo/Group55551.svg"
                                          className="as-dl-logo-job"
                                          alt="View Resume"
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            ))}
                          {/* <li className="row">
                      <div className="col-md-6">
                        <h5
                          class="modal-title profile-preview-heading"
                          htmlFor="thumbImg"
                          style={{ marginTop: "1rem" }}
                        >
                          Signed ICR Agreement:
                        </h5>
                      </div>
                      <div className="col-md-6">
                        <h5
                          class="modal-title profile-preview-detail"
                          htmlFor="thumbImg"
                          style={{ marginTop: "1rem" }}
                        >
                          {professionalDetails.links.icr_agreement || "-"}
                        </h5>
                      </div>
                    </li> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {jobsData.length > 0 && (
              <div className="container-fluid dashboard-content-2">
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container-4 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="justify-content-center">
                      <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 main-content-width">
                        <div className="edit-window-profile-details">
                          <li className="row">
                            <h4 className="profile-setting-container-8">
                              Job History
                            </h4>
                          </li>
                          <br></br>
                          <div className="row job-display-profile">
                            <div
                              className="col-md-3"
                              onClick={() => changeJobType(2)}
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className={
                                  selectedJobType === 2
                                    ? "profile-job-selected"
                                    : "profile-job"
                                }
                              >
                                {" "}
                                Applied Jobs
                              </span>
                            </div>
                            <div
                              className="col-md-4"
                              onClick={() => changeJobType(1)}
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className={
                                  selectedJobType === 1
                                    ? "profile-job-selected"
                                    : "profile-job"
                                }
                              >
                                Not Applied/Rejected Jobs
                              </span>
                            </div>
                            <div
                              className="col-md-3"
                              onClick={() => changeJobType(4)}
                              style={{ cursor: "pointer" }}
                            >
                              <span
                                className={
                                  selectedJobType === 4
                                    ? "profile-job-selected"
                                    : "profile-job"
                                }
                              >
                                Placed Jobs
                              </span>
                            </div>
                          </div>
                          <div className="row job-dtl-heading">
                            <div className="col-md-2">
                              <span className="profile-job">Job ID</span>
                            </div>
                            <div className="col-md-3">
                              <span className="profile-job">Company Name</span>
                            </div>
                            <div className="col-md-3">
                              <span className="profile-job">Job Role</span>
                            </div>
                            <div className="col-md-2">
                              <span className="profile-job">Applied On</span>
                            </div>
                            <div className="col-md-2">
                              <span className="profile-job">Job Status</span>
                            </div>
                          </div>
                          {jobsData
                            .filter((job) => {
                              if (job.job_status >= 3) {
                                if (selectedJobType === 1) {
                                  if (
                                    job.job_app_static_status === 6 ||
                                    job.job_app_static_status === 1 ||
                                    job.job_app_static_status === 7
                                  ) {
                                    return true;
                                  }
                                } else if (selectedJobType === 2) {
                                  if (job.job_app_static_status >= 2) {
                                    return true;
                                  }
                                } else if (selectedJobType === 4) {
                                  if (job.job_app_static_status === 4) {
                                    return true;
                                  }
                                }
                              }
                            })
                            .map((job, index) => (
                              <div key={index} className="row job-dtl-heading">
                                <div className="col-md-2">
                                  <span className="profile-job-dsc">
                                    {job.job_code}
                                  </span>
                                </div>
                                <div className="col-md-3">
                                  <span className="profile-job-dsc">
                                    {job.company_name}
                                  </span>
                                </div>
                                <div className="col-md-3">
                                  <span className="profile-job-dsc">
                                    {job.job_title}
                                  </span>
                                </div>
                                <div className="col-md-2">
                                  <span className="profile-job-dsc">
                                    {job.applied_on
                                      ? momentjs(job.applied_on).format(
                                          "DD-MM-YYYY"
                                        )
                                      : "NA"}
                                  </span>
                                </div>
                                <div className="col-md-2">
                                  <span className="profile-job-dsc">
                                    {STATIC_ROUNDS[job.job_app_static_status]}
                                  </span>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <Modal
            isOpen={togglePDFDoc}
            size={"md"}
            style={{ width: "700px" }}
            centered={true}
            className="resume-dsply-modal"
          >
            <ModalHeader
              className="resume-prev"
              style={{ paddingRight: "7px", width: "100%" }}
            >
              <div className="row" style={{ justifyContent: "space-between" }}>
                <div>Resume Preview </div>
                <div>
                  <img
                    src="https://webcdn.imarticus.org/Placement/Group926.svg"
                    style={{ paddingRight: "7px" }}
                    onClick={() => setTogglePDFDoc(false)}
                  />
                </div>
              </div>
            </ModalHeader>
            <ModalBody
              style={{
                maxHeight: "500px",
                // overflowY: "scroll",
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {/* <PDFReader url={resumeURL}></PDFReader> */}
              <iframe
                src={resumeURL}
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  minHeight: "500px",
                }}
                frameBorder="0"
              />
            </ModalBody>
            <ModalFooter style={{ justifyContent: "center" }}></ModalFooter>
          </Modal>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  profile: state.profile,
  course: state.course.courses[0],
});

const mapDispatchToProps = { getProfileInfo, setCurrentTab, setPageHeading };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfilePreview)
);
