import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import Cookies from "universal-cookie";
import PDFReader from "../PDFReader/PDFReader";
import "./certificatview.scss";
import { apllycert } from "../../actions/certificateAction";

const cookies = new Cookies();

const CertificateView = () => {
  let query = window.location.href;
  query = query.split("/");
  const pid = query[4];
  const cid = query[5];
  const elec_id = query[6];
  const [crtfFile, setCrtfFile] = useState("");
  const [shortedCertificationUrl, setShortedCertificationUrl] = useState("");
  const [copiedSuccess, setCopiedSuccess] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [certStatus, setCertStatus] = useState({});

  const params = useParams();
  const history = useHistory();

  const downloadCertificate = () => {
    apllycert(params.cid, params.pid, elec_id)
      .then((res) => {
        if (res.data.data) {
          let cert = res.data.data;
          if (elec_id && elec_id != "undefined") {
            console.log("----------elec_id", elec_id);
            if (elec_id && cert[elec_id] && cert[elec_id].status) {
              let obj = cert[elec_id].data;

              setCrtfFile(obj.crtf_file);
              setShortedCertificationUrl(obj.verification_url);
            }
          } else {
            if (cert["FOUNDATION"] && cert["FOUNDATION"].status) {
              let obj = cert["FOUNDATION"].data;
              setCrtfFile(obj.crtf_file);
              setShortedCertificationUrl(obj.verification_url);
            }
          }
        }
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.status === 498 &&
          err.response.data.code === 4100
        ) {
          // Handle token expiration or invalid token
        }
      });
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(shortedCertificationUrl);
    setCopiedSuccess(true);
    setTimeout(() => {
      setCopiedSuccess(false);
    }, 1000);
  };

  const previousNavigate = () => {
    window.location = document.referrer;
  };

  const downloadToClient = () => {
    window.open(
      `${ROUTES.DOWNLOAD_CRTF}?a_id=${cookies.get("aid")}&crs_id=${
        params.cid
      }&crs_token=${cookies.get("crs_tkn")}`,
      "_self"
    );
  };

  const toggle = () => {
    setShareModal(!shareModal);
  };

  useEffect(() => {
    window.FreshworksWidget?.("hide", "launcher");
    downloadCertificate();
  }, []);

  return (
    <div className="certificateViewPage">
      <div className="certi-view-page">
        <div className="certi-view-page__nav">
          <button
            className="certi-view-page__backbutton"
            onClick={previousNavigate}
          >
            <img
              src="https://cdn.pegasus.imarticus.org/certificate-controller/backButton.svg"
              alt="Back"
            />
          </button>
          <div
            className="certi-view-page__sharebutton"
            style={{ cursor: "pointer" }}
            onClick={toggle}
          >
            <span className="certi-view-page__sharebuttonText">Share</span>
            <img
              src="https://cdn.pegasus.imarticus.org/certificate-controller/shareButton.svg"
              alt="Share"
            />
          </div>
          <div
            className="certi-view-page__downloadbutton"
            onClick={downloadToClient}
            aria-hidden="false"
            style={{ cursor: "pointer" }}
          >
            <span className="certi-view-page__downloadbuttonText">
              Download PDF
            </span>
            <img
              src="https://cdn.pegasus.imarticus.org/images/downloadIcon.svg"
              alt="Download"
            />
          </div>
        </div>
        {shareModal && (
          <div className="certi-view-page__modalBackground">
            <div className="certi-view-page__modalContainer">
              <div className="certi-view-page__modalHeadingContainer">
                <h1 className="certi-view-page__modalHeading">Share</h1>
                <div className="certi-view-page__modalHeadingCloseBtn">
                  <img
                    style={{ cursor: "pointer" }}
                    src="https://cdn.pegasus.imarticus.org/images/close_icon.svg"
                    alt="Close"
                    onClick={toggle}
                  />
                </div>
              </div>
              <div className="certi-view-page__modalPageShareLinkContainer">
                <h4 className="certi-view-page__modalPageLinkHeading">
                  Page Link:
                </h4>
                <div className="certi-view-page__modalPageLinkContainer">
                  <span className="cert">{shortedCertificationUrl}</span>
                  <img
                    style={{ cursor: "pointer" }}
                    src="https://cdn.pegasus.imarticus.org/certificate-controller/copy-content.svg"
                    className="certi-view-page__modalPageLinkCopyImage"
                    alt="Copy"
                    onClick={copyUrl}
                  />
                </div>
                {copiedSuccess && (
                  <div className="certi-view-page__copiedSuccess">
                    <span>Copied</span>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div id="adobe-dc-view" className="pdf-view certi-view-page__pdf-view">
          <PDFReader url={crtfFile}></PDFReader>
        </div>
      </div>
    </div>
  );
};

export default CertificateView;
