// import ReactCrop from '../utils/ReactCropDemo';
import ReactCrop from "react-image-crop";
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import "react-image-crop/dist/ReactCrop.css";
import { image64toCanvasRef } from "../../utility/utils";
import Spinner from "../utils/Spinner";
import BrandSpinner from "./BrandSpinner";
import Cookies from "universal-cookie";
const cookies = new Cookies();
class Loader extends Component {
  constructor(props) {
    super(props);
    console.log({ props });
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      url: "",
      img_src: null,
      crop: {
        // className: "tushar ",
        aspect: 1 / 1,
      },
    };
  }

  render() {
    const { img_src } = this.state;
    return (
      <Fragment>
        <div className="loader">
          <div className="loader-msg-container">
            {this.props.brand?.["loaderColorIndex"] ? (
              <BrandSpinner color={this.props.brand?.["loaderColorIndex"]} />
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  brand: state.brand.brand,
  individualBrand: state.course.individualBrand,
});

export default withRouter(connect(mapStateToProps)(Loader));
