import React, { Component, Fragment } from "react";
import logo from "../../images/logo.svg";
import logo_mb from "../../images/imarticusLogo.svg";
import burger_icon from "../../images/burger-icon.svg";
import search_mb from "../../images/search-mb.svg";
import AccountSettingDropdown from "../dropdowns/AccountSettingDropdown";
import NotificationDropdown from "../notifications/NotificationDropdown.js";
import SearchBox from "../searchboxs/SearchBox";
import { connect } from "react-redux";
import { showFreshDesk } from "../../actions/showFreshDesk.js";
import {
  URLS,
  SIDEBARS,
  SIDEBAR_WIDTH,
  CONFIGS,
} from "../../constants/constants";
import {
  setSearch,
  showSidebarMB,
  resetBackBtn,
  setSidebar,
} from "../../actions/utilAction";
import left_blue_arrow from "../../images/left-arrow-blue.svg";
import { Link } from "react-router-dom";
import { getPrimaryEmailsWithoutModal } from "../../actions/primaryEmailActions";
import SupportIcon from "../../images/Support.svg";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_sidebar: false,
      is_search: false,
    };
  }

  componentDidUpdate(prev_props) {
    if (this.props.sidebar && this.props.sidebar != prev_props.sidebar) {
      if (this.props.sidebar == SIDEBARS.HOME_SIDEBAR) {
        document.documentElement.style.setProperty(
          "--sidebar-width",
          SIDEBAR_WIDTH.HOME_SIDEBAR
        );
      } else if (this.props.sidebar == SIDEBARS.GROUP_SIDEBAR) {
        document.documentElement.style.setProperty(
          "--sidebar-width",
          SIDEBAR_WIDTH.GROUP_SIDEBAR
        );
      }
    }
    if (
      window.location.pathname.includes("/dashboard/edit") ||
      window.location.pathname.includes("/placement-module/profile_preview/")
    ) {
      this.props.setSidebar(SIDEBARS.GROUP_SIDEBAR);
    }
  }

  searchCoursesAndGroups = () => {
    if (this.props.is_search) {
      //hit api and set state
    } else {
      //if search is not enabled, then enable search
      this.props.setSearch(!this.props.is_search);
    }
  };

  render() {
    return (
      <Fragment>
        <div className="home-dashboard-header">
          <div
            className={
              this.props.sidebar == SIDEBARS.HOME_SIDEBAR
                ? "nav-bar fixed-header"
                : "nav-bar fixed-gdtl-header"
            }
          >
            <div className="header-content">
              <div className="toggle-icon-container-mb">
                <img
                  onClick={() =>
                    this.props.showSidebarMB(!this.props.show_sidebar_mb)
                  }
                  src={burger_icon}
                  width="70%"
                ></img>
              </div>
              <div className="ecko-logo-container-mb">
                <Link to={CONFIGS.DASHBOARD_URL}>
                  {!this.props.is_data_loading && (
                    <img
                      className="logo-mb"
                      src={
                        this.props.brand
                          ? this.props.brand.pic
                          : "https://cdn.pegasus.imarticus.org/images/imarticus-new-logo-green.svg"
                      }
                    ></img>
                  )}
                </Link>
              </div>
              {/* <div className="profile-search-container-mb">
                <div className="profile-search-box"> */}
              {/* </div>
              </div> */}
              <div className="profile-search-container-mb">
                <div className="profile-search-box">
                  <NotificationDropdown />
                  <AccountSettingDropdown />
                </div>
              </div>
              <div className={!this.props.is_back ? "header-heading" : "hide"}>
                <span className="heading-text">
                  {!this.props.is_data_loading ? this.props.pg_heading : ""}
                </span>
              </div>
              <Link to={this.props.back_url} onClick={this.props.resetBackBtn}>
                <div
                  className={
                    this.props.is_back ? "header-heading back-btn" : "hide"
                  }
                >
                  <i className="fa fa-angle-down bold-angle-left"></i>
                  <span>{!this.props.is_data_loading ? "Back" : ""}</span>
                </div>
              </Link>
              <div className="header-center"></div>

              <div className="profile-btns">
                <ul className="navbar-right-top">
                  {!this.props.is_data_loading &&
                    (this.props.brand == undefined ||
                      this.props.brand.getHelpEnabled == true) && (
                      <li className="nav-item header-get-help-container">
                        <button
                          className="header-get-help"
                          onClick={() => {
                            showFreshDesk(
                              this.props.courses,
                              this.props.contact,
                              this.props.p_name,
                              this.props.email
                            );
                          }}
                        >
                          <img
                            src={
                              "https://cdn.pegasus.imarticus.org/index/help_neutral.svg"
                            }
                          ></img>
                          <span>Get help</span>
                        </button>
                      </li>
                    )}
                  <li
                    className="nav-item"
                    style={{ outline: "none", marginRight: "10px" }}
                  >
                    <NotificationDropdown />
                  </li>
                  <li className="nav-item dropdown ">
                    <button
                      className="profile-pic"
                      href="#"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img
                        src={
                          this.props.ppic && this.props.ppic != ""
                            ? this.props.ppic
                            : URLS.DEFAULT_PPIC_URL
                        }
                        alt="user"
                        className="profile-img"
                      />
                    </button>
                  </li>
                  <li className="nav-item dropdown ">
                    <AccountSettingDropdown />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={this.props.show_menu ? "header-cover" : "hide"}></div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  is_search: state.utils.is_search,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  show_menu: state.ac_dd.show_menu,
  ppic: state.profile.ppic,
  p_name: state.profile.p_name,
  email: state.primaryEmail.primary_email,
  pg_heading: state.utils.pg_heading,
  sidebar: state.utils.sidebar,
  back_url: state.utils.back_url,
  is_back: state.utils.is_back,
  is_data_loading: state.utils.is_data_loading,
  brand: state.brand?.brand || state.course.brand,
  contact: state.profile.contact,
  profile: state.profile,
  courses: state.course.courses,
});

const mapDispatchToProps = {
  setSearch,
  resetBackBtn,
  showSidebarMB,
  setSidebar,
  getPrimaryEmailsWithoutModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
