import React from "react";
import { Link } from "react-router-dom";
const ProfileDetailCard = ({ detailName, detailValue, url, ifVerified }) => {
  return (
    <div className="edit-window__view-container">
      <div className="edit-window__view-container-heading">
        {detailName}&nbsp;&nbsp;
        {ifVerified && (
          <img
            style={{ height: "16px" }}
            src={"https://cdn.pegasus.imarticus.org/courses/images/checked.svg"}
            alt={"Verified"}
          />
        )}
      </div>
      <Link to={url}>
        <div className="edit-window__view-container-detail">
          <span>{detailValue}</span>{" "}
          <img
            src="https://cdn.pegasus.imarticus.org/pgBandF/ArrowBlack.svg"
            alt=""
          />{" "}
        </div>
      </Link>
      <hr className="edit-window__view-container-line" />
    </div>
  );
};
export default ProfileDetailCard;
