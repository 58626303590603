import { connect } from 'react-redux';
import React from 'react';
import { withRouter } from "react-router";
import { Modal } from 'reactstrap';
import unchecked from '../../images/unchecked.svg';
import checked from '../../images/checked.svg';
import feed from '../../images/feed.svg';
import quiz from '../../images/quiz.svg';
import { toggleJoinGroupModal, cancelJoining, redirectToGroupPage } from '../../actions/joinGroupAction.js';

class AddPluginModal extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidUpdate() {
		
	}

	cancel = () => {
		this.props.cancelJoining();
	}
	

	render() {
		return (
			<div>
				<Modal isOpen={1==0} className={this.props.className + " ap-modal-wrapper"} centered={true}>
					<div className="ap-modal-container">
                        <div className="ap-modal">
                            <p className="heading">Available Plugins</p>
                            <div className="plugin-section">
                                <div className="checkbox-section">
                                    <img src={checked}></img>
                                </div>
                                <div className="img-section"><img src={feed}></img></div>
                                <p className="label"> Feed</p>
                            </div>
                            <div className="plugin-section">
                                <div className="checkbox-section">
                                    <img src={checked}></img>
                                </div>
                                <div className="img-section"><img src={feed}></img></div>
                                <p className="label"> Videos</p>
                            </div>
                            <div className="plugin-section">
                                <div className="checkbox-section">
                                    <img src={checked}></img>
                                </div>
                                <div className="img-section"><img src={feed}></img></div>
                                <p className="label"> Quiz</p>
                            </div>
                            <button className="add-btn">Add Plugins</button>
                        </div>
					</div>

				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => (
	{
		g_name: state.j_grp.g_name,
		g_members: state.j_grp.g_members,
		g_pic_url: state.j_grp.g_pic_url,
		toggle_j_grp_modal: state.j_grp.toggle_j_grp_modal,
		is_member: state.j_grp.is_member,
		g_id: state.j_grp.g_id,
		g_code: state.j_grp.g_code,
	})


const mapDispatchToProps = {
	toggleJoinGroupModal,
	cancelJoining,
	redirectToGroupPage,
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(AddPluginModal));
