import React from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  ModalHeader,
} from "reactstrap";
import { connect } from "react-redux";
import {
  openMobileModal,
  confirmPrimaryEmail,
  addNewSecondaryEmail,
  openAddNewMobileModal,
  isPrimaryEmailVerified,
  getSecondaryEmails,
  isMobileVerified,
  confirmMobile,
} from "../../actions/primaryEmailActions";
import EmailImg from "../../images/email-1.png";
import closeBtn from "../../images/close-btn.svg";
import MobileImg from "../../images/mobile-success-01.png";
import {
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";
import LoadingModal from "./LoadingModal";
import MobileOtpVerificationModal from "./components/MobileVerifyModal";
import InlineLoader from "../utils/InlineLoader";
import CountryCodes from "../../constants/countryCodes";

class MobileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      confirm_mobile: false,
      open_add_mobile: false,
      new_mobile: "",
      value: "select primary email",
      emails: [],
      ccod: "91",
      emailSelected: true,
    };
    // this.props.isMobileVerified();
    this.openAddNewMobile = this.openAddNewMobile.bind(this);
  }

  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };

  componentDidMount() {
    this.setState({
      confirm_mobile: this.props.confirm_mobile,
      open_add_mobile: this.props.add_new_mobile,
    });

    console.log("mount");
  }
  componentDidUpdate(prev_props) {
    if (prev_props.add_new_mobile != this.props.add_new_mobile) {
      this.setState({ open_add_mobile: this.props.add_new_mobile });
    }
    if (prev_props.confirm_mobile != this.props.confirm_mobile) {
      this.setState({ confirm_mobile: this.props.confirm_mobile });
    }
    if (this.props.verify_link != "") {
      this.props.openMobileModal(false);
    }
  }
  openAddNewMobile() {
    this.props.openAddNewMobileModal(true);
  }
  closeAddNewMobile = () => {
    this.props.openMobileModal(false);
  };

  verifyMobile = () => {
    this.props.openAddNewMobileModal(false);
  };

  validateMobile = () => {
    return (
      this.state.new_mobile.length < 16 && this.state.new_mobile.length > 5
    );
    return this.state.new_mobile.match(
      /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
    );
  };

  mobileValueChanged = (value) => {
    if (value.length > 15) return;
    if (value === "") {
      this.displayPopover(
        POPOVER_HEADINGS.INVALID_NUMBER,
        POPOVER_CONTENT.INVALID_NUMBER,
        POPOVER_TYPE.ERROR
      );
    } else {
      this.setState({ new_mobile: value });
    }
  };
  addnewMobileBtnClicked = () => {
    let m = this.state.new_mobile;
    let ccod = this.state.ccod;
    if (m === "" || ccod === "") {
      this.displayPopover(
        "OOPS!",
        "Add a Valid Mobile Number",
        POPOVER_TYPE.ERROR
      );
    } else {
      this.props.confirmMobile(m, ccod, this.setError);
    }
  };

  ccodChange = (e) => {
    let ccod = e.target.value;
    this.setState({
      ccod,
      error: null,
    });
  };

  setError = () => {
    this.setState({
      error:
        "This number is already linked to another account. Enter another mobile number or remove this number from that account",
    });
  };

  render() {
    return (
      <>
        {!this.props.hide_mob_vrfy_popup ? (
          this.state.open_add_mobile ? (
            <Modal
              isOpen={this.props.open_modal}
              className="pe-adnew"
              style={{ fontFamily: "Source Sans Pro" }}
            >
              <ModalBody>
                <img
                  src={closeBtn}
                  style={{ float: "right", padding: "8px" }}
                  onClick={this.closeAddNewMobile}
                ></img>
                <img
                  src={
                    "https://cdn.pegasus.imarticus.org/index/mobile_neutral.svg"
                  }
                  className="pe-img"
                ></img>
                <div className="pe-adnew-head" style={{ textAlign: "left" }}>
                  Verify your mobile number
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "90%",
                    margin: "auto",
                  }}
                >
                  <select
                    style={{ width: "30%" }}
                    className="pe-adnew-inp"
                    onChange={this.ccodChange}
                    value={this.state.ccod}
                  >
                    {CountryCodes.map((cc, i) => {
                      return (
                        <option
                          key={cc.code + String(i)}
                          disabled={cc.value == "_"}
                          value={cc.value}
                        >
                          {cc.display}
                        </option>
                      );
                    })}
                  </select>
                  <Input
                    type="tel"
                    onChange={(e) => this.mobileValueChanged(e.target.value)}
                    className="pe-adnew-inp"
                    placeholder="Mobile Number"
                    style={{
                      marginTop: "8px",
                      textAlignLast: "left",
                      textAlign: "left",
                      marginLeft: "18px",
                    }}
                  />
                </div>
                {this.state.error && (
                  <div className="pe-error">{this.state.error}</div>
                )}
                <Button
                  color="primary"
                  className={`pe-adnew-btn ${
                    this.validateMobile() ? "" : "pe-disable"
                  }`}
                  onClick={
                    this.validateMobile()
                      ? this.addnewMobileBtnClicked
                      : undefined
                  }
                  style={{ fontWeight: "600" }}
                >
                  Verify
                </Button>
                <div className="pe-info2" style={{ textAlign: "left" }}>
                  <em>
                    *We recommend that you confirm your mobile number so that we
                    can provide you with the best learning experience.
                  </em>
                </div>
              </ModalBody>
            </Modal>
          ) : (
            <Modal isOpen={this.props.open_modal} className="pe">
              <ModalBody>
                <MobileOtpVerificationModal
                  closeFunc={this.closeAddNewMobile}
                />
              </ModalBody>
            </Modal>
          )
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  open_modal: state.mobile.show_m_mdl,
  show_loading_modal: state.utils.is_loading_md,
  confirm_mobile: state.mobile.cnf_m_modal,
  verify_link: state.mobile.verify_link,
  add_new_mobile: state.mobile.add_new_mobile,
  hide_mob_vrfy_popup: state.profile.hide_mob_vrfy_popup,
});

const mapDispatchToProps = {
  openMobileModal,
  isPrimaryEmailVerified,
  getSecondaryEmails,
  showPopover,
  openAddNewMobileModal,
  addNewSecondaryEmail,
  confirmPrimaryEmail,
  isMobileVerified,
  confirmMobile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileModal);
