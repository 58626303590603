import React, { Component } from 'react';
import Footer from '../footers/Footer';
import create_grp from '../../images/create-grp.svg';
import { FRONTEND_ERRORS } from '../../constants/errors';
import err_chat from '../../images/err-chat.svg';
import CreateGroupForm from './CreateGroupForm';
import { connect } from 'react-redux';
import { showPopover } from '../../actions/utilAction';
import { POPOVER_HEADINGS, POPOVER_CONTENT, POPOVER_TYPE, POPOVER_HIDETIME, PAGE_HEADINGS } from '../../constants/constants';
import { createGroupBackend } from '../../actions/groupAction';
import { setPageHeading } from '../../actions/utilAction';
import cg_illustration from '../../images/cg-illustration.svg';

class CreateGroup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grp_code: '',
            is_err: false,
            err_code: '',
            err_msg: '',
        }
    }
    createGroup = (e) => {
        if (e) {
            e.preventDefault();
        }
        if (!this.state.g_name || this.state.g_name.trim().length == 0) {
            this.setState({
                is_err: true,
                err_msg: FRONTEND_ERRORS.F0006,
            })
            return;
        }
        this.props.createGroupBackend(this.state.g_name);
    }

    componentDidMount() {
        window.scrollTo(0,0);
        this.props.setPageHeading(PAGE_HEADINGS.CREATE_GROUP);
    }

    setGroupName = (e) => {
        this.setState({
            is_err: false,
            err_msg: '',
        })
        var g_name = e.target ? e.target.value.trim() : "";
        if(g_name.length > 25){
            this.displayPopover(POPOVER_HEADINGS.GRP_NAME_LENGTH_EXCEEDED,POPOVER_CONTENT.GRP_NAME_LENGTH_EXCEEDED,POPOVER_TYPE.ERROR);
            return;
        }
        this.setState({
            g_name: e.target.value
        })

    }

    displayPopover = (heading, msg, type) => {
        this.props.showPopover(heading, msg, type);
    }

    handleEnterKey = (e) => {
        if (e.key === 'Enter') {
            this.createGroup();
        }
    }

    render() {
        if (!this.state.loading) {
            return (
                <div className="dashboard-content-wrapper">
                    <div className="container-fluid dashboard-content">
                        {/* <div className="row tab-row"> */}
                        <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <p className="content-header">Create Group</p>
                            </div>
                        {/* </div> */}
                        {/* <div className="row tab-row"> */}
                        <div className="c-grp-container">
                            <div className="row">
                                <div className="c-grp-form-container">
                                    <form className="join-grp-form">
                                        <label className="jg-form-label">Enter Group Name</label><br />
                                        <input onChange={this.setGroupName} onKeyPress={this.handleEnterKey} value={this.state.g_name} className={"jg-inp " + (this.state.is_err ? "err-inp" : "")}></input>
                                        <p className={"jg-err-web " + (this.state.is_err ? "err-msg" : "hide")}>
                                            <img src={err_chat} className="err-icon"></img>{this.state.err_msg}
                                        </p>
                                        <input onClick={this.createGroup} type="submit" className="jg-btn" value="Create Group"></input>
                                        <p className={"jg-err-mb " + (this.state.is_err ? "err-msg" : "hide")}>
                                            <img src={err_chat} className="err-icon"></img>{this.state.err_msg}
                                        </p>
                                    </form>



                                </div>
                                <div className="c-grp-img-container">
                                    <div className="gc-info-container">
                                        <div className="gc-info-wrapper">
                                            <div className="gc-info">
                                                <p className="gc-heading">Create Group</p>
                                                <p className="gc-content">Learning in Imarticus happens in Learning Groups. You can start your own group and facilitate learning through the creation of courses, test series and/or facilitating discussions.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className="c-grp-ill-contianer">
                            <img className="c-grp-ill" src={cg_illustration}/>
                        </div> */}
                        <div className="cg-bg-img-container">
                                <img src={cg_illustration} className="home-bg-img" id="home-bg-id"/>
                            </div>
                        {/* </div> */}
                    </div>
                    <Footer />
                </div>
            );
        }
        else {
            return (
                <CreateGroupForm />
            )
        }
    }
}

const mapStateToProps = (state) => (
    {
        show_menu: state.ac_dd.show_menu,
        profiles: state.profile.profiles,
        p_name: state.profile.p_name,
        ppic: state.profile.ppic,
        edit_ppic: state.profile.edit_ppic,
        profile_map: state.profile.profile_map,
    })

const mapDispatchToProps = {
    showPopover,
    createGroupBackend,
    setPageHeading,
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateGroup);
