import React, { Fragment, useState, useEffect } from "react";
import { batch, connect } from "react-redux";
import Footer from "../footers/Footer";
import PoweredByImarticusFooter from "../footers/PoweredByImarticusFooter";
import { withRouter, Redirect } from "react-router";
import {
  getProfileInfo,
  disableSwitchProfileCheck,
} from "../../actions/profileAction";
import {
  toggleJoinGroupModal,
  getGroupInfo,
  redirectToCourseDashboardWithoutJoining,
} from "../../actions/joinGroupAction";
import {
  fetchMyCourses,
  addChildCourses,
  setSelectedCourse,
  fetchZoomSignatureV3,
} from "../../actions/courseAction";
import MyCourseSS from "../skeletonscreens/MyCourseSS";
//import LevelView from "./LevelsView/LevelsView";
import course_default from "../../images/course-default.svg";
import { setPageHeading } from "../../actions/utilAction";
import queryString from "query-string";
import JoinGroupModal from "../modals/JoinGroupModal";
import NoGroupForCourseModal from "../modals/NoGroupForCourseModal";
import cookie from "react-cookies";
import Cookies from "universal-cookie";
import momentjs from "moment-timezone";
import {
  PAGE_HEADINGS,
  TABS,
  URLS,
  SIDEBARS,
  SIDEBAR_WIDTH,
  CONFIGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
  BUFFER_TIME,
  LOADING_MODAL_MSG,
} from "../../constants/constants";
import TooltipItem from "../utils/BatchToolTip";
import Loader from "../utils/Loader";
import { showFreshDesk } from "../../actions/showFreshDesk";

const cookies = new Cookies();

let MyCourse = (props) => {
  const [to_dashboard, setTo_dashboard] = useState(false);
  const [open_ngfc_modal, setOpen_ngfc_modal] = useState(false);
  const [crs_name, setCrs_name] = useState("");
  //const [courseSelected, setCourseSelected] = useState(null);
  const [courseOpen, setCourseOpen] = useState(false);
  const [pendingInstallments, setPendingInstallments] = useState([]);
  const [showpendingInstallments, setShowPendingInstallments] = useState(false);
  const [loading, setLoading] = useState(false);
  const [crsTokensByBatchId, setCrsTokensByBatchId] = useState({});
  // componentDidUpdate(prev_props) {
  //   if (this.props.courses != prev_props.courses) {
  //     if (!this.props.courses || this.props.courses.length == 0) {
  //       this.setState({
  //         to_dashboard: true,
  //       });
  //     }
  //     if (this.props.is_pr_sw) {
  //       this.props.disableSwitchProfileCheck();
  //     }
  //   }
  // }

  window.addEventListener("pageshow", function (event) {
    if (event.persisted) {
      window.location.reload();
    }
  });

  var ongoing_live = [];
  var soon_live = [];
  var live_lec_ids = [];

  for (let i in props.live_lectures) {
    let liv_lec = props.live_lectures[i];
    if (liv_lec.type === 3 || liv_lec.type === 4) {
      continue;
    }
    let lec_start = new Date(liv_lec.stm);
    let lec_end = liv_lec.etm ? new Date(liv_lec.etm) : null;
    if (lec_end) {
      let current_time = new Date().getTime();
      let start_time = lec_start.getTime();
      let end_time = lec_end.getTime() + BUFFER_TIME.ZOOM_BUFFER_END_TIME;
      liv_lec.lcdt = lec_start.toDateString();
      liv_lec.lctm = lec_start.toLocaleTimeString([], {
        timeStyle: "short",
        hour12: true,
      });
      liv_lec.lcedt = lec_end.toDateString();
      liv_lec.lcetm = lec_end.toLocaleTimeString([], {
        timeStyle: "short",
        hour12: true,
      });

      if (
        current_time < start_time &&
        parseInt(start_time - current_time) < 180 * 1000 &&
        parseInt(start_time - current_time) > 0
      ) {
        soon_live.push(liv_lec);
      } else if (
        current_time > start_time &&
        current_time < end_time &&
        parseInt(current_time - start_time) > 0 &&
        parseInt(end_time - current_time) > 0
      ) {
        ongoing_live.push(liv_lec);
        live_lec_ids.push(liv_lec._id);
      }
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    props.setPageHeading(PAGE_HEADINGS.MY_COURSE);
    let pendingInstallmentsTemp = [];
    let tempCrsTokensByBatchId = {};
    props.courses.forEach((batch) => {
      tempCrsTokensByBatchId[batch._id] = batch.crs_tkn;
    });
    props.pendingInstallments.forEach((batch) => {
      // console.log({batch})

      pendingInstallmentsTemp.push({
        _id: batch._id,
        nextInstallmentTime: batch.nextInstallmentTime,
        nextInstallmentDate: batch.nextInstallmentDate,
        installmentPlan: batch.installmentPlan,
        installmentIndex: batch.installmentIndex,
        name: batch.name,
        currSymbol: batch.currSymbol,
        crs_pg_id: batch.crs_pg_id,
        "1st Line": batch.installmentPlan["1st Line"],
        "2nd Line": batch.installmentPlan["2nd Line"],
        showPaymentButton: batch.showPaymentButton,
      });
    });
    setCrsTokensByBatchId(tempCrsTokensByBatchId);
    pendingInstallmentsTemp.sort(
      (a, b) => a.nextInstallmentTime - b.nextInstallmentTime
    );
    console.log({ pendingInstallments: pendingInstallmentsTemp });
    setPendingInstallments(pendingInstallmentsTemp);

    const params = new URLSearchParams(window.location.search);
    let liveLec = params.get("liveLec") || undefined;
    let cid = params.get("cid") || undefined;
    let getHelp = params.get("gethelp") || undefined;

    if (getHelp === "true") {
      if (
        !props.is_data_loading &&
        (props.brand == undefined || props.brand.getHelpEnabled == true)
      ) {
        showFreshDesk("", props.contact, props.p_name, props.email);
      }

      console.log("nimihshhhhhhhhhhhhh", props);
    }

    if (liveLec) {
      if (live_lec_ids.includes(liveLec)) {
        joinZoomMeeting(liveLec, cid);
      }
    }
  }, []);

  let comboCourse = (id) => {
    var ccid = id;
    var cc_len = props.combo_courses.length;
    var cc = JSON.parse(JSON.stringify(props.combo_courses));
    var ic = JSON.parse(JSON.stringify(props.courses));
    var ic_len = ic.length;
    var child_arr = [];
    for (var i = 0; i < cc_len; i++) {
      if (cc[i]._id == ccid) {
        var ch_len = cc[i].child_courses.length;
        for (var j = 0; j < ch_len; j++) {
          child_arr.push(cc[i].child_courses[j]);
        }
      }
    }
    var ic_map = {};
    for (var i = 0; i < ic_len; i++) {
      if (!ic_map[ic[i]._id]) {
        ic_map[ic[i]._id] = "";
      }
      ic_map[ic[i]._id] = ic[i];
    }
    var ic_render_arr = [];
    for (var i = 0; i < child_arr.length; i++) {
      if (ic_map[child_arr[i]]) {
        ic_render_arr.push(ic_map[child_arr[i]]);
      }
    }
    var childCourseObj = {};
    childCourseObj.child_courses = ic_render_arr;
    childCourseObj.type = "CHILD_COURSES";
    props.addChildCourses(childCourseObj);
    props.history.push({
      pathname: "/dashboard/combo",
      search: queryString.stringify(
        Object.assign({}, queryString.parse(props.location.search), {
          cid: id,
        })
      ),
    });
  };

  let joinZoomMeeting = (liveLectureId, cid) => {
    console.log("In join zoomMeeting ::: ", { liveLectureId, cid });
    var expiredate = new Date();
    expiredate.setDate(expiredate.getDate() + 180);
    cookies.set("crs_tkn", crsTokensByBatchId[cid], {
      expires: expiredate,
      domain: CONFIGS.DOMAIN,
      path: "/",
    });
    cookies.set("cid", cid, {
      expires: expiredate,
      domain: CONFIGS.DOMAIN,
      path: "/",
    });
    if (!navigator.onLine) {
      return alert("You are offline. Please check your internet connection.");
    }
    setLoading(true);
    fetchZoomSignatureV3(liveLectureId)
      .then((joinUrl) => {
        window.open(joinUrl, "_self");
        // setLoading(false);
      })
      .catch((err) => {
        if (err.code && err.message) {
          setLoading(false);
          alert(err.message);
        } else {
          setLoading(false);
          alert("Something went wrong. Please try again!");
        }
        setLoading(false);
      });
  };

  let redirectToCourse = (course) => {
    console.log("\n\n\n--- In redirectToCourse --- \n\n\n");
    console.log("course", course);
    console.log("cid::", course._id);
    var expiredate = new Date();
    expiredate.setDate(expiredate.getDate() + 180);
    cookies.set("cid", course._id, {
      expires: expiredate,
      domain: CONFIGS.DOMAIN,
      path: "/",
    });
    window.history.pushState([], "Dashboard", window.location.href);
    let courseSelected = props.courses.find((el) => el._id == course._id);
    if (
      "level_labels" in courseSelected &&
      courseSelected.level_labels.length == 1
    ) {
      console.log("direct::::::::");
      let pid = cookies.get("pid");
      let gid = courseSelected.gids ? courseSelected.gids[0] : null;
      let cid = courseSelected ? courseSelected._id : null;
      console.log({ pid, gid, cid });
      if (gid && cid) {
        console.log(
          "checking for user version",
          props.profiles[0].user_version
        );
        let url = "batch" + "/" + pid + "/" + gid + "/" + cid + "/undefined";
        console.log({ url });
        props.history.push(url); //userversion key
        return;
      }
    }
    props.setSelectedCourse(courseSelected);
    setCourseOpen(true);
    // window.location.href = "/course?cid=" + course._id;
  };
  let openCourse = (course, courseForRedirect) => {
    console.log("\n\n\n--- In openCourse --- \n\n\n");
    console.log("course", course);
    console.log("cid::", course._id);
    var expiredate = new Date();
    expiredate.setDate(expiredate.getDate() + 180);
    cookies.set("cid", course._id, {
      expires: expiredate,
      domain: CONFIGS.DOMAIN,
      path: "/",
    });
    setCrs_name(course.name);
    var crs_gid = course.gids ? course.gids[0] : null;
    var is_crs_grp_joined = false;
    var crs_grp = {};
    console.log("First if :: ", { crs_gid });
    if (crs_gid) {
      for (var i = 0; i < props.groups.length; i++) {
        if (props.groups && props.groups[i] && crs_gid == props.groups[i]._id) {
          is_crs_grp_joined = true;
          break;
        }
      }
      for (var i = 0; i < props.course_groups.length; i++) {
        if (
          props.course_groups &&
          props.course_groups[i] &&
          crs_gid == props.course_groups[i]._id
        ) {
          crs_grp = props.course_groups[i];
          break;
        }
      }
    }
    console.log("Second if :: ", {
      is_crs_grp_joined,
      crs_grp,
      code: crs_grp.code,
    });
    if (!is_crs_grp_joined && crs_grp && crs_grp.code) {
      console.log("\n ---- Group not found --- \n");
      props.getGroupInfo(crs_grp.code, course._id, course.is_adpt);
      return;
    } else if (!is_crs_grp_joined && (!crs_grp || !crs_grp.code)) {
      setOpen_ngfc_modal(true);
      return;
    }
    // else if (crs_gid && course._id) {
    //   // props.redirectToCourseDashboardWithoutJoining(
    //   //   crs_gid,
    //   //   course._id,
    //   //   course.is_adpt,
    //   //   props.crs_pid
    //   // );
    // }
    console.log("\n --- Calling redirectToCourse --- \n");
    if (courseForRedirect) redirectToCourse(courseForRedirect);
    else redirectToCourse(course);
  };
  let showImg = (e) => {
    e.target.style.display = "block";
  };
  let toggleNoGroupForCourseModal = () => {
    setOpen_ngfc_modal(!open_ngfc_modal);
  };
  if (loading) {
    return <Loader />;
  }
  if (to_dashboard) {
    return <Redirect to="/dashboard" />;
  }
  if (courseOpen && props.selected_course) {
    // return <LevelView crs_gid={courseSelected.gids ? courseSelected.gids[0] : null} cid={courseSelected._id} is_adpt={courseSelected.is_adpt} crs_pid={props.crs_pid} />
    return <Redirect to={"/view/?cid=" + props.selected_course._id} />;
  }
  if (props.is_gcode_valid) {
    props.toggleJoinGroupModal(true);
  }
  if (!props.is_data_loading) {
    var rc = null;
    var course_completion = null;
    var rc_id;
    if (
      props.resume_course &&
      props.resume_course.course &&
      props.resume_course.course.length &&
      props.resume_course.course[0]
    ) {
      rc = props.resume_course.course[0];
      course_completion = rc.course_completion;
      rc_id = rc._id;
    }
    const recentActivityHandler = (e) => {
      if (!(e.target.className === "tooltipabc")) {
        openCourse(rc, "");
      }
    };

    let pluralise = (n) => {
      return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
    };
    let convertPriceToLocalString = function (amount) {
      // console.log({amount})
      return parseFloat(String(amount)).toLocaleString();
    };
    let getFormattedTime = (index) => {
      let time = pendingInstallments[index || 0].nextInstallmentDate;
      return time;
      //let tz = momentjs.tz.guess();
      //let pTim = momentjs.tz(time, tz).valueOf();
      //console.log({ pTim })
      //let pDate = momentjs(pTim).format("Do MMM, YYYY");
      //let pDate = momentjs(pTim).format("LL");
      //return pDate;
    };

    return (
      <div className="dashboard-content-wrapper">
        <div className="container-fluid dashboard-content">
          <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <p className="content-header">My Courses</p>
          </div>
          <div
            className="course-container"
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="course-content">
              {ongoing_live.map((liv_lec) => {
                return (
                  <div className="live-container" key={liv_lec._id}>
                    <div className="live-status">Ongoing Live Session</div>
                    <div className="live-info">
                      <img
                        className="icon"
                        src="https://cdn.pegasus.imarticus.org/images/live_play.png"
                      />
                      <div className="detail">
                        <div>
                          {liv_lec.nm.length > 30
                            ? liv_lec.nm.substring(0, 30) + "..."
                            : liv_lec.nm}
                        </div>
                        <div className="time">
                          Starts at {liv_lec.lcdt}&nbsp;{liv_lec.lctm} | Ends
                          at&nbsp;
                          {liv_lec.lcedt}&nbsp;{liv_lec.lcetm}
                        </div>
                      </div>
                      <button
                        className="join"
                        onClick={() => {
                          joinZoomMeeting(liv_lec._id, liv_lec.cid);
                        }}
                      >
                        Join Now
                      </button>
                    </div>
                  </div>
                );
              })}

              {soon_live.map((liv_lec) => {
                return (
                  <div className="live-container" key={liv_lec._id}>
                    <div className="live-status">Soon to be Live Session</div>
                    <div className="live-info">
                      <img
                        className="icon"
                        src="https://cdn.pegasus.imarticus.org/images/live_play.png"
                      />
                      <div className="detail">
                        <div>
                          {liv_lec.nm.length > 30
                            ? liv_lec.nm.substring(0, 30) + "..."
                            : liv_lec.nm}
                        </div>
                        <div className="time">
                          Starts at {liv_lec.lcdt}&nbsp;{liv_lec.lctm} | Ends
                          at&nbsp;
                          {liv_lec.lcedt}&nbsp;{liv_lec.lcetm}
                        </div>
                      </div>
                      <button
                        className="join"
                        onClick={() => {
                          joinZoomMeeting(liv_lec._id, liv_lec.cid);
                        }}
                      >
                        Join Now
                      </button>
                    </div>
                  </div>
                );
              })}
              {pendingInstallments.length > 0 && (
                <>
                  <div className={rc ? "crs-heading" : "hide"}>
                    Your Payment is due
                  </div>
                  <div
                    className="installmentContainer"
                    key={pendingInstallments[0]._id}
                    style={
                      pendingInstallments.length > 1
                        ? {}
                        : { marginBottom: "1rem" }
                    }
                  >
                    <div className="statement">
                      {pendingInstallments[0]["1st Line"]}
                    </div>
                    <p className="secondaryStatement">
                      {pendingInstallments[0]["2nd Line"]}
                    </p>
                    <div className="actionRow">
                      <div className="installmentRow">
                        <p className="installmentHeader">Payments:</p>
                        <div
                          className={`installmentRow vertical ${
                            pendingInstallments[0].installmentPlan?.installments
                              ?.length > 1
                              ? "installmentLine"
                              : ""
                          }`}
                        >
                          <div
                            className="installment"
                            style={{
                              position: "relative",
                              top: "-5px",
                              width: "100%",
                            }}
                          >
                            {pendingInstallments[0].installmentPlan?.installments?.map(
                              (installment, ind) => {
                                console.log({ installment });
                                if (
                                  ind < pendingInstallments[0].installmentIndex
                                ) {
                                  return (
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/payments/done.svg"
                                      alt="right"
                                      height={14}
                                      width={18}
                                      key={ind}
                                      style={{
                                        background: "white",
                                        padding: "0 2px",
                                      }}
                                    />
                                  );
                                } else {
                                  return (
                                    <span
                                      key={ind}
                                      style={{
                                        height: "14px",
                                        width: "14px",
                                        borderRadius: "50%",
                                        display: "inline-block",
                                        border: "1px solid black",
                                        // marginTop: "4px",
                                        background: "white",
                                        padding: "2px",
                                      }}
                                    />
                                  );
                                }
                              }
                            )}
                          </div>
                          <div className="installment">
                            {pendingInstallments[0].installmentPlan?.installments?.map(
                              (installment, ind) => {
                                return (
                                  <p className="amount" key={ind}>
                                    {pendingInstallments[0].currSymbol}{" "}
                                    {convertPriceToLocalString(
                                      installment.amount
                                    )}
                                  </p>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="installmentHeader"
                        style={{ marginLeft: "1.5rem" }}
                      >
                        Due Date:{" "}
                        <span style={{ color: "rgba(60, 72, 82, 0.7)" }}>
                          {getFormattedTime()}
                        </span>
                      </div>
                      {pendingInstallments[0].showPaymentButton !== false ? (
                        <button
                          role="button"
                          className="pay"
                          onClick={() => {
                            window.open(
                              `${CONFIGS.PAYMENT_INSTALLMENT_URL}${pendingInstallments[0].crs_pg_id}`
                            );
                          }}
                        >
                          Pay {pendingInstallments[0].currSymbol}{" "}
                          {convertPriceToLocalString(
                            pendingInstallments[0].installmentPlan.installments[
                              pendingInstallments[0].installmentIndex
                            ].amount
                          )}
                        </button>
                      ) : null}
                    </div>
                  </div>
                  {pendingInstallments.length > 1 && (
                    <div
                      style={{
                        fontFamily: "Source Sans Pro",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "18px",
                        lineHeight: "23px",
                        color: "var(--themeColor)",
                        textAlign: "right",
                        margin: " 0.25rem 0 1rem",
                        cursor: "pointer",
                      }}
                      role="button"
                      onClick={() =>
                        setShowPendingInstallments(!showpendingInstallments)
                      }
                    >
                      Show {showpendingInstallments ? "Less" : "More"}
                      <img
                        src="https://cdn.pegasus.imarticus.org/payments/right_dark.svg"
                        alt="expand"
                        height={11}
                        width={7}
                        style={{
                          rotate: `${
                            showpendingInstallments ? "-90deg" : "90deg"
                          }`,
                          margin: "0 3px",
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              {showpendingInstallments &&
                pendingInstallments.length > 1 &&
                pendingInstallments.map((courseInstallment, index) => {
                  if (index == 0) return null;
                  return (
                    <div
                      className="installmentContainer"
                      key={courseInstallment._id}
                    >
                      <div className="statement">
                        {courseInstallment["1st Line"]}
                      </div>
                      <p className="secondaryStatement">
                        {courseInstallment["2nd Line"]}
                      </p>
                      <div className="actionRow">
                        <div className="installmentRow">
                          <p className="installmentHeader">Payments:</p>
                          <div
                            className={`installmentRow vertical ${
                              courseInstallment.installmentPlan?.installments
                                ?.length > 1
                                ? "installmentLine"
                                : ""
                            }`}
                          >
                            <div
                              className="installment"
                              style={{
                                position: "relative",
                                top: "-5px",
                                width: "100%",
                              }}
                            >
                              {courseInstallment.installmentPlan?.installments?.map(
                                (installment, ind) => {
                                  console.log({ installment });
                                  if (
                                    ind < courseInstallment.installmentIndex
                                  ) {
                                    return (
                                      <img
                                        src="https://cdn.pegasus.imarticus.org/payments/done.svg"
                                        alt="right"
                                        height={14}
                                        width={18}
                                        key={ind}
                                        style={{
                                          background: "white",
                                          padding: "0 2px",
                                        }}
                                      />
                                    );
                                  } else {
                                    return (
                                      <span
                                        key={ind}
                                        style={{
                                          height: "14px",
                                          width: "14px",
                                          borderRadius: "50%",
                                          display: "inline-block",
                                          border: "1px solid black",
                                          // marginTop: "4px",
                                          background: "white",
                                          padding: "2px",
                                        }}
                                      />
                                    );
                                  }
                                }
                              )}
                            </div>
                            <div className="installment">
                              {courseInstallment.installmentPlan?.installments?.map(
                                (installment, ind) => {
                                  return (
                                    <p className="amount" key={ind}>
                                      {courseInstallment.currSymbol}{" "}
                                      {convertPriceToLocalString(
                                        installment.amount
                                      )}
                                    </p>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="installmentHeader"
                          style={{ marginLeft: "1.5rem" }}
                        >
                          Due Date:{" "}
                          <span style={{ color: "rgba(60, 72, 82, 0.7)" }}>
                            {getFormattedTime(index)}
                          </span>
                        </div>
                        {courseInstallment.showPaymentButton !== false ? (
                          <button
                            role="button"
                            className="pay"
                            onClick={() => {
                              window.open(
                                `${CONFIGS.PAYMENT_INSTALLMENT_URL}${courseInstallment.crs_pg_id}`
                              );
                            }}
                          >
                            Pay {courseInstallment.currSymbol}{" "}
                            {convertPriceToLocalString(
                              courseInstallment.installmentPlan.installments[
                                courseInstallment.installmentIndex
                              ].amount
                            )}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              {rc ? (
                <>
                  <div className={rc ? "crs-heading" : "hide"}>
                    Recent Activity
                  </div>
                  <div
                    className={rc ? "ic-container" : "hide"}
                    onClick={recentActivityHandler}
                  >
                    <div className="mb-section">
                      <p className="mb-heading">
                        {course_completion > 0
                          ? "Resume Course"
                          : "Start Course"}
                      </p>
                      <p className="crs-name">{rc ? rc.name : ""}</p>
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <div className="tooltipabc">
                          {rc.btch_name}
                          <div className="tooltiptext">
                            {rc.btch_name}
                            <i></i>
                          </div>
                        </div>
                      </div>
                      <div className="progress-heading">
                        <span className="progress-label">Progress</span>
                        <span className="percent-mb">
                          {rc ? rc.course_completion : ""}%
                        </span>
                      </div>
                      <div className="progress-bar-container-mb">
                        <div
                          className="progress-bar-mb"
                          style={{ width: course_completion + "%" }}
                        ></div>
                      </div>
                    </div>
                    <div className="img-section">
                      <div className="crs-img-container">
                        <img
                          className="crs-img"
                          onLoad={(e) => showImg(e)}
                          src={
                            props.resume_course &&
                            props.resume_course.course &&
                            props.resume_course.course.length &&
                            props.resume_course.course[0] &&
                            props.resume_course.course[0].crs_thumb_url
                              ? props.resume_course.course[0].crs_thumb_url
                              : course_default
                          }
                        ></img>
                      </div>
                    </div>
                    <div className="progress-section">
                      <div className="content-container">
                        <p className="crs-name">{rc ? rc.name : ""}</p>
                        <TooltipItem id="recent_course" batch={rc.btch_name} />
                        <div className="pb-container">
                          <div className="progress-bar">
                            <div
                              className="active-status"
                              style={{ width: course_completion + "%" }}
                            ></div>
                          </div>
                          <span className="percent">{course_completion}%</span>
                        </div>
                      </div>
                    </div>
                    <div className="btn-section">
                      <button
                        className={
                          course_completion == 0
                            ? "crs-start-btn"
                            : "crs-resume-btn"
                        }
                      >
                        {course_completion == 0
                          ? "Start Course"
                          : "Resume Course"}
                      </button>
                    </div>
                  </div>
                </>
              ) : null}

              <hr className={rc ? "crs_hr" : "hide"}></hr>
              <div
                className={
                  props.combo_courses && props.combo_courses.length > 0
                    ? "crs-heading"
                    : "hide"
                }
              >
                Combo Course
              </div>
              {props.combo_courses &&
                props.combo_courses.length > 0 &&
                props.combo_courses.map(
                  (
                    { _id, name, crs_thumb_url, course_completion },
                    key,
                    crs_index
                  ) => {
                    return (
                      <Fragment>
                        <div
                          className="cc-container"
                          onClick={() => {
                            // comboCourse(_id);
                            console.log("Clicked on combo course!");
                            redirectToCourse({
                              _id,
                              name,
                              crs_thumb_url,
                              course_completion,
                            });
                          }}
                          key={key}
                        >
                          <div className="mb-cc-section">
                            <div className="img-section-mb">
                              <div className="crs-img-container">
                                <img
                                  className="crs-img"
                                  onLoad={(e) => showImg(e)}
                                  src={
                                    crs_thumb_url
                                      ? crs_thumb_url
                                      : course_default
                                  }
                                ></img>
                              </div>
                            </div>
                            <div className="ccrs-name-wrapper">
                              <div className="ccrs-name-container">
                                <p className="ccrs-name">
                                  {name +
                                    "this is the most excliusive and valuable"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="img-section">
                            <div className="crs-img-container">
                              <img
                                className="crs-img"
                                onLoad={(e) => showImg(e)}
                                src={
                                  crs_thumb_url ? crs_thumb_url : course_default
                                }
                              ></img>
                            </div>
                          </div>
                          <div className="progress-section">
                            <div className="content-container">
                              <p className="crs-name">{name}</p>
                            </div>
                          </div>
                          <div className="btn-section">
                            <button
                              className={
                                1 == 0 ? "crs-start-btn" : "crs-resume-btn"
                              }
                            >
                              View Courses{" "}
                            </button>
                          </div>
                        </div>
                        <div className="bg-div-01"></div>
                        <div className="bg-div-02"></div>
                      </Fragment>
                    );
                  }
                )}
              <hr
                className={
                  props.combo_courses && props.combo_courses.length > 0
                    ? "crs_hr_2"
                    : "hide"
                }
              />
              <div
                className={
                  props.courses && props.courses.length > 0
                    ? "crs-heading"
                    : "hide"
                }
              >
                Individual Course
              </div>
              {props.courses &&
                props.courses.length > 0 &&
                props.courses.map(
                  (
                    { _id, name, crs_thumb_url, course_completion, btch_name },
                    key,
                    crs_index
                  ) => {
                    const individualCoursesHandler = (e) => {
                      if (!(e.target.className === "tooltipabc")) {
                        openCourse(props.courses_map[_id], {
                          _id,
                          name,
                          crs_thumb_url,
                          course_completion,
                        });
                      }
                    };
                    return (
                      <div
                        className="ic-container"
                        onClick={individualCoursesHandler}
                        key={key}
                      >
                        <div className="crs-cover"></div>
                        <div className="mb-section">
                          <p className="mb-heading">
                            {course_completion > 0
                              ? "Resume Course"
                              : "Start Course"}
                          </p>
                          <p className="crs-name">{name} </p>
                          <div
                            style={{
                              position: "relative",
                            }}
                          >
                            <div className="tooltipabc">
                              {btch_name}
                              <div className="tooltiptext">
                                {btch_name}
                                <i></i>
                              </div>
                            </div>
                          </div>
                          <div className="progress-heading">
                            <span className="progress-label">Progress</span>
                            <span className="percent-mb">
                              {course_completion}%
                            </span>
                          </div>
                          <div className="progress-bar-container-mb">
                            <div
                              className="progress-bar-mb"
                              style={{ width: course_completion + "%" }}
                            ></div>
                          </div>
                        </div>
                        <div className="img-section">
                          <div className="crs-img-container">
                            <img
                              className="crs-img"
                              onLoad={(e) => showImg(e)}
                              src={
                                crs_thumb_url ? crs_thumb_url : course_default
                              }
                            ></img>
                          </div>
                        </div>
                        <div className="progress-section">
                          <div className="content-container">
                            <p className="crs-name">{name}</p>
                            <TooltipItem id={_id} batch={btch_name} />
                            <div className="pb-container">
                              <div className="progress-bar">
                                <div
                                  className="active-status"
                                  style={{ width: course_completion + "%" }}
                                ></div>
                              </div>
                              <span className="percent">
                                {course_completion}%
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="btn-section">
                          <button
                            className={
                              course_completion == 0
                                ? "crs-start-btn"
                                : "crs-resume-btn"
                            }
                          >
                            {course_completion == 0
                              ? "Start Course"
                              : "Resume Course"}
                          </button>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>

            {/* {
              props.brand && 
              props.brand.poweredByEnabled &&
              props.brand.hyperlinkEnabled == false (
                <PoweredByImarticusFooter/>
              )
             } */}
          </div>
          {props.brand &&
            props.brand.poweredByEnabled == true &&
            props.brand.hyperlinkEnabled == true && (
              <div className="powered-by-container" style={{}}>
                <a className="powered-by" href={"https://www.imarticus.org"}>
                  Powered By Imarticus Learning
                </a>
              </div>
            )}
          {props.brand &&
            props.brand.poweredByEnabled == true &&
            !props.brand.hyperlinkEnabled && (
              <div className="powered-by-container" style={{}}>
                <div className="powered-by">Powered By Imarticus Learning</div>
              </div>
            )}
        </div>
        <Footer />
        <JoinGroupModal />
        <NoGroupForCourseModal
          is_open={open_ngfc_modal}
          toggleNoGroupForCourseModal={toggleNoGroupForCourseModal}
          crs_name={crs_name}
        />
      </div>
    );
  } else {
    return <MyCourseSS />;
  }
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  child_courses: state.course.child_courses,
  courses_map: state.course.courses_map,
  is_pr_sw: state.profile.is_pr_sw,
  is_data_loading: state.utils.is_data_loading,
  is_gcode_valid: state.j_grp.is_gcode_valid,
  p_name: state.profile.p_name,
  crs_pid: state.course.crs_pid,
  selected_course: state.course.selected_course,
  brand: state.brand?.brand || state.course.brand,
  pendingInstallments: state.course.pendingInstallments,
  email: state.primaryEmail.primary_email,
  contact: state.profile.contact,
});

const mapDispatchToProps = {
  getProfileInfo,
  fetchMyCourses,
  addChildCourses,
  disableSwitchProfileCheck,
  setPageHeading,
  getGroupInfo,
  toggleJoinGroupModal,
  redirectToCourseDashboardWithoutJoining,
  setSelectedCourse,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyCourse)
);
