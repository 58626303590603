// import React, { Component } from 'react'
// import { ellipsis } from 'react-multiline-ellipsis'
// import PropTypes from 'prop-types';
// class CardHeading extends Component {
//   render () {
//     return <div className="g-card-heading">{this.props.text}</div>
//   }
// }

// CardHeading.propTypes = {
//   text: PropTypes.string.isRequired,
// }

// export default ellipsis(CardHeading, 3, '...')

import React, { Component } from "react";
//import { ellipsis } from 'react-multiline-ellipsis'
import PropTypes from "prop-types";
class CardHeading extends Component {
  render() {
    return (
      <div className="c-heading-container">
        {/* <div className="gradient-cover"></div> */}
        <div className="c-heading">{this.props.text}</div>
      </div>
    );
  }
}

CardHeading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default CardHeading;

// import React, { Component } from 'react'
// import LinesEllipsis from "react-lines-ellipsis"
// import PropTypes from 'prop-types';
// class CardHeading extends Component {
//   render () {
//     return (
//       <div className="g-card-heading">
//       <LinesEllipsis
//   text={this.props.text}
//   maxLine={2}
//   ellipsis='...'
//   trimRight
//   basedOn='letters'
// /></div>
//     )
//   }
// }

// CardHeading.propTypes = {
//   text: PropTypes.string.isRequired,
// }

// export default CardHeading
