const initialState = {
  brand: null,
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_BRAND":
      return { ...state, brand: action.data };
    case "REMOVE_BRAND":
      return {
        ...state,
        brand: null,
      };
    default:
      return state;
  }
};

export default brandReducer;
