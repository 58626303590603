import React, { Component, Fragment } from "react";
import Footer from "../footers/Footer";
import Switch from "../switches/SwitchMUI";
import crs_grp from "../../images/crs-grp.svg";
import discussion from "../../images/discussion.svg";
// import { getProfileInfo} from  '../../actions/profileAction';
import { withRouter } from "react-router";
import white_cross from "../../images/white-cross.svg";
// import { fetchGroups } from '../../actions/groupAction';
import { connect } from "react-redux";
// import { fetchMyCourses } from '../../actions/courseAction';
import feed from "../../images/feed.svg";
import quiz from "../../images/quiz.svg";
import queryString from "query-string";
import video from "../../images/video.svg";
import setting_icon from "../../images/setting-icon.svg";
import { Link } from "react-router-dom";
import { Route, Redirect } from "react-router";
import small_right_blue from "../../images/small-right-blue.svg";
import small_right from "../../images/small-right.svg";
import GroupDetailDropdown from "../dropdowns/GroupDetailDropdown";
import GroupDetailSS from "../skeletonscreens/GroupDetailSS";
import AddPluginModal from "../modals/AddPluginModal";
import { openLearnerCourseDashboard } from "../../actions/courseAction";
import {
  updateGroupSequence,
  getGroupPlugins,
  redirectToLearn,
  openPlugin,
  openDiscussionPlugin,
  disableMyGroupRedirection,
} from "../../actions/groupAction";
import { setSelectedPluginData } from "../../actions/pluginAction";
import { setNotFound, setPageHeading } from "../../actions/utilAction";
import group_default from "../../images/groups-01-01.svg";
import course_default from "../../images/course-default.svg";
import { GROUP_TYPES } from "../../constants/constants";
import LeaveGroupModal from "../modals/LeaveGroupModal";
import PluginPaymentModal from "../modals/PluginPaymentModal";
import { SIDEBAR_WIDTH } from "../../constants/constants";
import SlidingStrip from "../utils/SlidingStrip";

const PLUGIN_POSITION = {
  ONE: 1,
  TWO: 2,
};

class GroupDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle_quiz: false,
      toggle_video: false,
      crs_started: true,
      is_open: false,
      open_ppm: false,
      pt: {},
      is_mb: false,
      loading: true,
    };
  }

  componentDidMount() {
    setTimeout(
      function () {
        this.setState({ loading: false });
      }.bind(this),
      1000
    );
    window.addEventListener("resize", this.resize.bind(this));
    var query_params = queryString.parse(this.props.location.search);
    if (!query_params.gid) {
      this.props.setNotFound(true);
      return;
    }
    if (this.props.gd_gname) {
      this.props.setPageHeading(this.props.gd_gname);
      this.props.updateGroupSequence(query_params.gid, this.props.groups);
    }
    // document.getElementById("img_gradient_id").style.display = "none";
    this.props.getGroupPlugins(query_params.gid, query_params.pid, false);
    if (window.innerWidth >= 482) {
      this.setPluginPositions();
    } else {
      this.setPluginPositionsForMobile();
    }
  }

  componentDidUpdate(prev_props) {
    var query_params = queryString.parse(this.props.location.search);
    if (this.props.location != prev_props.location) {
      this.props.getGroupPlugins(query_params.gid, query_params.pid, false);
      this.onRouteChanged();
    }
    if (this.props.gd_gname != prev_props.gd_gname) {
      this.props.setPageHeading(this.props.gd_gname);
      this.props.updateGroupSequence(query_params.gid, this.props.groups);
    }
    if (
      this.props.gd_video != prev_props.gd_video ||
      this.props.gd_course != prev_props.gd_course ||
      this.props.gd_feed != prev_props.gd_feed ||
      this.props.gd_quiz != prev_props.gd_quiz
    ) {
      if (window.innerWidth >= 482) {
        this.setPluginPositions();
      } else {
        this.setPluginPositionsForMobile();
      }
    }
  }
  onRouteChanged() {
    if (this.state.toggle_video) {
      this.toggleVideo();
    }
    if (this.state.toggle_quiz) {
      this.toggleQuiz();
    }
  }
  resize = () => {
    this.setPluginPositionsForMobile();
  };
  setPluginPositionsForMobile = () => {
    if (window.innerWidth < 482 && !this.state.is_mb) {
      this.setState({
        pt: {
          c: 1,
          f: 1,
          d: 1,
          q: 1,
          v: 1,
        },
        is_mb: true,
      });
    } else if (window.innerWidth >= 482 && this.state.is_mb) {
      this.setState({
        is_mb: false,
      });
      this.setPluginPositions();
    }
  };
  togglePluginPaymentModal = () => {
    this.setState({
      open_ppm: !this.state.open_ppm,
    });
  };
  showImg = (e) => {
    e.target.style.display = "block";
    document.getElementById("img_gradient_id").style.display = "block";
  };

  toggleCourse = () => {
    if (!this.state.toggle_course) {
      document.getElementById("course_id").style.height =
        30 * this.props.gd_course.length + 15 + "px";
    } else {
      document.getElementById("course_id").style.height = "0px";
    }
    this.setState({
      toggle_course: !this.state.toggle_course,
    });
  };

  redirectToLearn = () => {
    var query_params = queryString.parse(this.props.location.search);
    // this.props.redirectToLearn(query_params.gid);
    this.props.openLearnerCourseDashboard(
      query_params.gid,
      this.props.gd_course[0]
    );
  };

  toggleQuiz = () => {
    if (this.state.toggle_video) {
      this.toggleVideo();
    }
    if (!this.state.toggle_quiz) {
      document.getElementById("quiz_id").style.height =
        30 * this.props.gd_quiz.length + 15 + "px";
    } else {
      document.getElementById("quiz_id").style.height = "0px";
    }
    this.setState({
      toggle_quiz: !this.state.toggle_quiz,
    });
  };
  toggleVideo = () => {
    if (this.state.toggle_quiz) {
      this.toggleQuiz();
    }

    if (!this.state.toggle_video) {
      document.getElementById("video_id").style.height =
        30 * this.props.gd_video.length + 15 + "px";
    } else {
      document.getElementById("video_id").style.height = "0px";
    }
    this.setState({
      toggle_video: !this.state.toggle_video,
    });
  };

  openVideoPlugin = (index, _id, is_paid, is_sub) => {
    if (is_paid && !is_sub) {
      var selected_plugin = this.props.gd_video[index];
      this.props.setSelectedPluginData(selected_plugin, this.props.packages);
      this.togglePluginPaymentModal();
    } else {
      var query_params = queryString.parse(this.props.location.search);
      var gid = query_params.gid;
      var selected_plugin = this.props.gd_video[index];
      this.props.openPlugin(selected_plugin, gid, this.props.is_admin);
    }
  };

  openQuizPlugin = (index, _id, is_paid, is_sub) => {
    if (is_paid && !is_sub) {
      var selected_plugin = this.props.gd_quiz[index];
      this.props.setSelectedPluginData(selected_plugin, this.props.packages);
      this.togglePluginPaymentModal();
    } else {
      var query_params = queryString.parse(this.props.location.search);
      var gid = query_params.gid;
      var selected_plugin = this.props.gd_quiz[index];
      this.props.openPlugin(selected_plugin, gid, this.props.is_admin);
    }
  };
  openFeedPlugin = () => {
    var query_params = queryString.parse(this.props.location.search);
    var gid = query_params.gid;
    var selected_plugin = this.props.gd_feed[0];
    this.props.openPlugin(selected_plugin, gid, this.props.is_admin);
  };
  openDiscussionPlugin = () => {
    var query_params = queryString.parse(this.props.location.search);
    var gid = query_params.gid;
    this.props.openDiscussionPlugin(gid);
  };
  toggleLeaveGroupModal = () => {
    this.setState({
      is_open: !this.state.is_open,
    });
  };
  redirectToMyGroupPage = () => {
    return <Redirect to="/dashboard/group" />;
  };

  setPluginPositions = () => {
    var last_position = PLUGIN_POSITION.ONE;
    var plugin_table = {
      c: 0,
      d: PLUGIN_POSITION.ONE,
      f: 0,
      v: 0,
      q: 0,
    };
    if (this.props.gd_course && this.props.gd_course.length) {
      plugin_table.c = PLUGIN_POSITION.ONE;
      plugin_table.d = PLUGIN_POSITION.TWO;
      last_position = PLUGIN_POSITION.TWO;
    }
    if (this.props.gd_feed && this.props.gd_feed.length) {
      if (last_position == PLUGIN_POSITION.ONE) {
        plugin_table.f = PLUGIN_POSITION.TWO;
        last_position = PLUGIN_POSITION.TWO;
      } else {
        plugin_table.f = PLUGIN_POSITION.ONE;
        last_position = PLUGIN_POSITION.ONE;
      }
    }
    if (
      this.props.gd_video &&
      this.props.gd_video.length &&
      (this.props.is_admin ||
        (!this.props.gd_course.length && !this.props.is_admin))
    ) {
      if (last_position == PLUGIN_POSITION.ONE) {
        plugin_table.v = PLUGIN_POSITION.TWO;
        last_position = PLUGIN_POSITION.TWO;
      } else {
        plugin_table.v = PLUGIN_POSITION.ONE;
        last_position = PLUGIN_POSITION.ONE;
      }
    }
    if (
      this.props.gd_quiz &&
      this.props.gd_quiz.length &&
      (this.props.is_admin ||
        (!this.props.gd_course.length && !this.props.is_admin))
    ) {
      if (last_position == PLUGIN_POSITION.ONE) {
        plugin_table.q = PLUGIN_POSITION.TWO;
        last_position = PLUGIN_POSITION.TWO;
      } else {
        plugin_table.q = PLUGIN_POSITION.ONE;
        last_position = PLUGIN_POSITION.ONE;
      }
    }
    this.setState({
      pt: plugin_table,
    });
  };

  getPluginsHTML = (position) => {
    return (
      <Fragment>
        <div
          className={
            this.props.gd_course.length && position == this.state.pt.c
              ? "section-col"
              : "hide"
          }
        >
          <div
            className={this.props.gd_course.length ? "course-section" : "hide"}
          >
            <div className="section-cover" onClick={this.redirectToLearn}></div>
            <div className="section-content">
              <div className="section-img-container">
                <img
                  src={
                    "https://cdn.pegasus.imarticus.org/index/course_thumb_neutral.svg"
                  }
                ></img>
              </div>
              <div className="section-heading-container">
                <p className="section-heading">
                  Courses
                  <img
                    className={
                      this.state.toggle_course
                        ? "right-arrow-rotate"
                        : "right-arrow"
                    }
                    src={small_right}
                  />
                </p>
              </div>
            </div>
            <div id="course_id" className="section-dropdown">
              <div className="section-dropdown-content">
                <ul id="course_list" className="section-list">
                  {this.props.gd_course.map(({ name }, index) => {
                    return (
                      <li className="section-list-item">
                        {name}
                        <img className="right-arrow" src={small_right} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={position == this.state.pt.d ? "section-col" : "hide"}>
          <div
            className="discuss-section"
            onClick={() => this.openDiscussionPlugin()}
          >
            <div className="section-cover"></div>
            <div className="section-content">
              <div className="section-img-container">
                <img
                  src={
                    "https://cdn.pegasus.imarticus.org/index/discussion_neutral.svg"
                  }
                ></img>
              </div>
              <div className="section-heading-container">
                <p className="section-heading">
                  Discussion <span className="notify hide">4</span>
                  <img className="right-arrow" src={small_right} />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.props.gd_feed &&
            this.props.gd_feed.length &&
            position == this.state.pt.f
              ? "section-col"
              : "hide"
          }
        >
          <div
            onClick={() => this.openFeedPlugin()}
            className={
              this.props.gd_feed && this.props.gd_feed.length
                ? "feed-section"
                : "hide"
            }
          >
            <div className="section-cover"></div>
            <div className="section-content">
              <div className="section-img-container">
                <img src={feed}></img>
              </div>
              <div className="section-heading-container">
                <p className="section-heading">
                  Feed
                  <img className="right-arrow" src={small_right} />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.props.gd_video.length &&
            position == this.state.pt.v &&
            (this.props.is_admin ||
              (!this.props.gd_course.length && !this.props.is_admin))
              ? "section-col"
              : "hide"
          }
        >
          <div
            className={
              this.props.gd_video.length &&
              position == this.state.pt.v &&
              (this.props.is_admin ||
                (!this.props.gd_course.length && !this.props.is_admin))
                ? "video-section"
                : "hide"
            }
          >
            <div className="section-cover" onClick={this.toggleVideo}></div>
            <div className="section-content">
              <div className="section-img-container">
                <img src={video}></img>
              </div>
              <div className="section-heading-container">
                <p className="section-heading">
                  Videos
                  <img
                    className={
                      this.state.toggle_video
                        ? "down-arrow-rotate"
                        : "down-arrow"
                    }
                    src={small_right}
                  />
                </p>
              </div>
            </div>
            <div
              id={position == this.state.pt.v ? "video_id" : ""}
              className="section-dropdown"
            >
              <div className="section-dropdown-content">
                <ul className="section-list">
                  {this.props.gd_video.map(
                    ({ name, is_paid, is_sub, _id }, index) => {
                      return (
                        <li
                          className={
                            is_paid && !is_sub
                              ? "section-list-item paid-item"
                              : "section-list-item free-item"
                          }
                          onClick={() =>
                            this.openVideoPlugin(index, _id, is_paid, is_sub)
                          }
                        >
                          {name}{" "}
                          <span
                            className={
                              !is_paid
                                ? "free-tag"
                                : is_sub
                                ? "free-tag"
                                : "paid-tag"
                            }
                          >
                            {!is_paid ? "Free" : is_sub ? "Subscribed" : "Paid"}
                          </span>
                          <img className="right-arrow" src={small_right} />
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            this.props.gd_quiz.length &&
            position == this.state.pt.q &&
            (this.props.is_admin ||
              (!this.props.gd_course.length && !this.props.is_admin))
              ? "section-col"
              : "hide"
          }
        >
          <div
            className={
              this.props.gd_quiz.length &&
              position == this.state.pt.q &&
              (this.props.is_admin ||
                (!this.props.gd_course.length && !this.props.is_admin))
                ? "quiz-section"
                : "hide"
            }
          >
            <div className="section-cover" onClick={this.toggleQuiz}></div>
            <div className="section-content">
              <div className="section-img-container">
                <img src={quiz}></img>
              </div>
              <div className="section-heading-container">
                <p className="section-heading">
                  Quiz
                  <img
                    className={
                      this.state.toggle_quiz
                        ? "down-arrow-rotate"
                        : "down-arrow"
                    }
                    src={small_right}
                  />
                </p>
              </div>
            </div>
            <div
              id={position == this.state.pt.q ? "quiz_id" : ""}
              className="section-dropdown"
            >
              <div className="section-dropdown-content">
                <ul id="quiz_list" className="section-list">
                  {this.props.gd_quiz.map(
                    ({ _id, name, is_paid, is_sub }, index) => {
                      return (
                        <li
                          onClick={() =>
                            this.openQuizPlugin(index, _id, is_paid, is_sub)
                          }
                          className={
                            is_paid && !is_sub
                              ? "section-list-item paid-item"
                              : "section-list-item free-item"
                          }
                        >
                          {name}{" "}
                          <span
                            className={
                              !is_paid
                                ? "free-tag"
                                : is_sub
                                ? "free-tag"
                                : "paid-tag"
                            }
                          >
                            {!is_paid ? "Free" : is_sub ? "Subscribed" : "Paid"}
                          </span>
                          <img className="right-arrow" src={small_right} />
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className={this.props.is_admin ? "add-section hide" : "hide"}>
          <div className="section-cover"></div>+ Add Plugins
        </div>
      </Fragment>
    );
  };

  openLeaveGroupModal = () => {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
    this.toggleLeaveGroupModal();
  };

  render() {
    if (this.props.to_mgp) {
      // this.props.disableMyGroupRedirection();
      return this.redirectToMyGroupPage();
    } else if (!this.state.loading) {
      return (
        <div className="group-db-content-wrapper">
          <div className="container-fluid dashboard-content">
            {/* <div className="row gd-row"> */}
            {/* <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
								<p className="content-header" >{this.props.gd_gname}</p>
							</div> */}

            {/* </div> */}

            <div className="container grp-dtl-container">
              <div className="view-container">
                <div className="grp-section-1">
                  <div className="grp-img-section">
                    <div className="img-gradient" id="img_gradient_id"></div>
                    <p className="grp-img-name">
                      {this.props.gd_gname}
                      <br />{" "}
                      <span className="grp-img-code">
                        {this.props.gd_code
                          ? "Group Code: " + this.props.gd_code
                          : ""}
                      </span>
                    </p>

                    <img
                      className="grp-img"
                      onLoad={(e) => this.showImg(e)}
                      src={
                        this.props.gd_gpic
                          ? this.props.gd_gpic
                          : "https://cdn.pegasus.imarticus.org/index/group_default.svg"
                      }
                    ></img>
                  </div>
                  <div className="grp-img-dtl">
                    <p className="g-code">
                      <div
                        className={
                          this.props.is_admin ? "switch-container" : "hide"
                        }
                      >
                        <Switch grp_type={!this.props.gd_gtype} />
                      </div>
                      {/* Group Code: {this.props.gd_code}<br/> */}
                      {/* { this.props.is_admin ?  "Only Admin can post" : "" } */}
                      {this.props.is_admin ? "Only Admins can post" : ""}
                      <br className={this.props.is_admin ? "" : "hide"} />
                      Permission:{" "}
                      {this.props.gd_gtype == GROUP_TYPES.ONE_WAY
                        ? " Admins Only"
                        : " All Members"}
                      <br />
                    </p>
                    <div className="admin-section">
                      {/* <span className={this.props.is_admin ? "" : "hide"}>Only Admins can post</span>  */}
                      {/* <div className={this.props.is_admin ? "" : "hide"}>
										<Switch grp_type={!this.props.gd_gtype}/>
										</div> */}
                      <button
                        className={!this.props.is_admin ? "leave-btn" : "hide"}
                        onClick={this.openLeaveGroupModal}
                      >
                        Leave Group
                      </button>
                      <Link
                        to={"/dashboard/gedit?gid=" + this.props.gid}
                        className={this.props.is_admin ? "" : "hide"}
                      >
                        <img
                          src={setting_icon}
                          className={
                            this.props.is_admin ? "setting-btn" : "hide"
                          }
                        />
                      </Link>
                      {/* <GroupDetailDropdown toggleLeaveGroupModal={this.toggleLeaveGroupModal}/> */}
                    </div>
                    <div className="admin-section-mb">
                      {/* <LeaveGroupModal/> */}
                      <GroupDetailDropdown
                        toggleLeaveGroupModal={this.toggleLeaveGroupModal}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="view-container-2">
                <div className="row section-row">
                  <div className="col-1">
                    {this.getPluginsHTML(PLUGIN_POSITION.ONE)}
                  </div>
                  <div className="col-2">
                    {this.getPluginsHTML(PLUGIN_POSITION.TWO)}
                  </div>
                  {/* </div> */}
                </div>
              </div>

              <SlidingStrip />
            </div>
          </div>

          <Footer />
          <PluginPaymentModal
            open_ppm={this.state.open_ppm}
            togglePluginPaymentModal={this.togglePluginPaymentModal}
          />
          <AddPluginModal />
          <LeaveGroupModal
            is_open={this.state.is_open}
            toggleLeaveGroupModal={this.toggleLeaveGroupModal}
          />
        </div>
      );
    } else {
      return <GroupDetailSS />;
    }
  }
}

const mapStateToProps = (state) => ({
  // profiles		: state.profile.profiles,
  // default_pid		: state.profile.default_pid,
  // user_j_groups   : state.j_grp.groups,
  // courses			: state.course.courses,
  // course_groups  	: state.course.groups,
  // combo_courses	: state.course.combo_courses,
  // resume_course	: state.course.resume_course,
  // child_courses   : state.course.child_courses

  gd_gname: state.j_grp.gd_gname,
  gd_gtype: state.j_grp.gd_gtype,
  gd_gpic: state.j_grp.gd_gpic,
  gd_feed: state.j_grp.gd_feed,
  gd_quiz: state.j_grp.gd_quiz,
  gd_video: state.j_grp.gd_video,
  gd_code: state.j_grp.gd_code,
  gd_course: state.j_grp.gd_course,
  is_admin: state.j_grp.is_admin,
  to_mgp: state.j_grp.to_mgp,
  packages: state.plugin.packages,
  groups: state.j_grp.groups,
  gid: state.j_grp.gd_id,
});

const mapDispatchToProps = {
  // return {
  getGroupPlugins,
  setNotFound,
  redirectToLearn,
  openPlugin,
  openDiscussionPlugin,
  disableMyGroupRedirection,
  setSelectedPluginData,
  setPageHeading,
  updateGroupSequence,
  openLearnerCourseDashboard,
  // addChildCourses : (crsObj) =>  dispatch(crsObj)
  // }
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupDetail)
);
