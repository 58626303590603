import { useEffect, useState } from "react";
import Loader from "../utils/Loader";
import "./agreement.css";
import {
  acceptAgreement,
  getAllStudentResponse,
  getSRAgreement,
} from "../../actions/courseAction";
import moment from "moment-timezone";
import { Modal, ModalBody } from "reactstrap";
import RenderMenu from "../PDFReader/PDFReader";

const AgreementCenter = () => {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState([]);
  const [openItems, setOpenItems] = useState({});
  const [openCategories, setOpenCategories] = useState({});
  const [view, setView] = useState(false);

  const [agree, setAgree] = useState({});
  const [resp, setResp] = useState({});
  const [cat, setCat] = useState({});

  const [review, setReview] = useState(false);
  const [accept, setAccept] = useState(false);
  const [trigger, setTrigger] = useState(false);

  useEffect(() => {
    const getAgreement = async () => {
      setLoading(true);
      const response = await getAllStudentResponse();
      if (response.status === 200) {
        const value = response.data.data;
        setResponse(value.responses);
      } else {
        alert("Something went wrong");
      }
      setLoading(false);
    };

    getAgreement();
  }, [trigger]);

  const singleResponse = async ({ agree_id, resp_id, review }) => {
    setLoading(true);
    const response = await getSRAgreement({ agree_id, resp_id });
    if (response.status === 200) {
      const value = response.data.data;
      setResp(value.response);
      setAgree(value.agreement);
      setCat(value.category);
      if (review) {
        setReview(true);
      } else {
        setView(true);
      }
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  const acceptResponse = async ({ agree_id, resp_id }) => {
    setLoading(true);
    const response = await acceptAgreement({ agree_id, resp_id });
    if (response.status === 200) {
      setTrigger(!trigger);
      setAccept(false);
      setReview(false);
      setView(false);
    } else {
      alert("Something went wrong");
    }
    setLoading(false);
  };

  const toggleItem = (index) => {
    setOpenItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const toggleCategory = (agreementIndex, categoryIndex) => {
    const key = `${agreementIndex}-${categoryIndex}`;
    setOpenCategories((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className="group-db-content-wrapper"
      style={{ fontFamily: "Source Sans Pro" }}
    >
      <Modal isOpen={view} size={"xl"} centered>
        <ModalBody>
          <div style={{ fontFamily: "Source Sans Pro" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "auto",
              }}
            >
              <span style={{ fontWeight: "600", color: "#3C4852" }}>
                View Agreement
              </span>
              <span>
                <img
                  src="https://cdn.pegasus.imarticus.org/courses/images/minimise.svg"
                  alt="close"
                  onClick={() => {
                    setView(false);
                    setAgree({});
                    setResp({});
                    setCat({});
                  }}
                />
              </span>
            </div>

            <hr />

            <div
              style={{
                padding: "2rem",
              }}
            >
              <div>
                {agree && <RenderMenu url={agree?.doc_url} height="50vh" />}
              </div>

              <div className="ics_view_heads">
                <span className="ics_view_head"> Agreement Type :</span>
                <span className="ics_view_sub"> {cat && cat.name}</span>
              </div>

              <div className="ics_view_heads">
                <span className="ics_view_head"> Date of Response :</span>
                <span className="ics_view_sub">
                  {" "}
                  {resp &&
                    moment
                      .utc(resp?.createdAt)
                      .tz("Asia/Kolkata")
                      .format("DD-MM-YY, HH:mm")}
                </span>
              </div>

              <div className="ics_view_heads">
                <span className="ics_view_head"> Your Response :</span>{" "}
                {resp && resp.response === 1 ? (
                  <span className="ics_view_sub">Accepted</span>
                ) : (
                  <span className="ics_view_sub ics_red_text">Declined</span>
                )}
              </div>

              {resp && resp.remarks && (
                <div className="ics_view_heads">
                  <span className="ics_view_head"> Your reason:</span>
                  <span className="ics_view_sub2"> {resp && resp.remarks}</span>
                </div>
              )}

              <div
                style={{
                  padding: "16px 0px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button
                  className="ics_accept_btn"
                  onClick={() => {
                    setView(false);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={review} size={"xl"} centered>
        <ModalBody>
          <div style={{ fontFamily: "Source Sans Pro" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "auto",
              }}
            >
              <span style={{ fontWeight: "600", color: "#3C4852" }}>
                Review Response
              </span>
              <span>
                <img
                  src="https://cdn.pegasus.imarticus.org/courses/images/minimise.svg"
                  alt="close"
                  onClick={() => {
                    setReview(false);
                    setAgree({});
                    setResp({});
                    setCat({});
                  }}
                />
              </span>
            </div>

            <hr />

            <div
              style={{
                padding: "2rem",
              }}
            >
              <div>
                {agree && <RenderMenu url={agree?.doc_url} height="50vh" />}
              </div>

              <div className="ics_view_heads">
                <span className="ics_view_head"> Agreement Type :</span>
                <span className="ics_view_sub"> {cat && cat.name}</span>
              </div>

              <div className="ics_view_heads">
                <span className="ics_view_head"> Date of Response :</span>
                <span className="ics_view_sub">
                  {" "}
                  {resp &&
                    moment
                      .utc(resp?.createdAt)
                      .tz("Asia/Kolkata")
                      .format("DD-MM-YY, HH:mm")}
                </span>
              </div>

              <div className="ics_view_heads">
                <span className="ics_view_head"> Your Response :</span>{" "}
                {resp && resp.response === 1 ? (
                  <span className="ics_view_sub">Accepted</span>
                ) : (
                  <span className="ics_view_sub ics_red_text">Declined</span>
                )}
              </div>

              {resp && resp.remarks && (
                <div className="ics_view_heads">
                  <span className="ics_view_head"> Your reason:</span>
                  <span className="ics_view_sub2"> {resp && resp.remarks}</span>
                </div>
              )}

              <div
                style={{
                  padding: "16px 0px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button
                  className="ics_accept_btn"
                  onClick={() => {
                    setAccept(true);
                  }}
                >
                  Change My Response
                </button>

                <button
                  className="ics_accept_btn ics_background_grey"
                  style={{ marginRight: "0px" }}
                  onClick={() => {
                    setReview(false);
                  }}
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={accept}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
        centered
      >
        <ModalBody>
          <div style={{ fontFamily: "Source Sans Pro" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "auto",
              }}
            >
              <span style={{ fontWeight: "600", color: "#3C4852" }}></span>
              <span>
                <img
                  src="https://cdn.pegasus.imarticus.org/courses/images/minimise.svg"
                  alt="close"
                  onClick={() => {
                    setAccept(false);
                    setReview(false);
                    setAgree({});
                    setResp({});
                    setCat({});
                  }}
                />
              </span>
            </div>

            <div
              style={{
                padding: "2rem",
              }}
            >
              <div className="ics_review_header">
                Are you sure you want to accept?
              </div>

              <div className="ics_review_sub_head">
                Earlier you declined the agreement <b>{cat && cat.name}. </b>
              </div>

              <div className="ics_view_heads">
                <span className="ics_view_head"> Date of Last Response:</span>
                <span className="ics_view_sub">
                  {" "}
                  {resp &&
                    moment
                      .utc(resp?.createdAt)
                      .tz("Asia/Kolkata")
                      .format("DD-MM-YY, HH:mm")}
                </span>
              </div>

              {resp && resp.remarks && (
                <div className="ics_view_heads">
                  <span className="ics_view_head">
                    {" "}
                    Your Reason of Declining
                  </span>
                  <div className="ics_review_sub"> {resp && resp.remarks}</div>
                </div>
              )}

              <div
                style={{
                  padding: "16px 30px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className="ics_accept_btn"
                  onClick={() => {
                    acceptResponse({ agree_id: agree._id, resp_id: resp._id });
                  }}
                >
                  Yes I'm sure
                </button>

                <button
                  className="ics_accept_btn ics_background_grey"
                  onClick={() => {
                    setAccept(false);
                    setReview(false);
                  }}
                >
                  Go Back
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <div className="container-fluid dashboard-content ">
        <div className="ics_ac_main">
          {response && response?.length > 0 ? (
            <>
              {response?.map((elem, i) => (
                <div className="ics_ac_single" key={i}>
                  <div
                    className="ics_ac_header"
                    onClick={() => toggleItem(i)} // Toggle this specific agreement
                  >
                    <div className="ics_ac_crs_header">
                      {elem?.name} &nbsp; ({elem?.categories?.length}{" "}
                      Agreements)
                    </div>
                    <img
                      src="https://cdn.pegasus.imarticus.org/courses/images/arrow-down.svg"
                      alt=""
                      className={
                        openItems[i]
                          ? "ics_ac_down_img ics_ac_up_img"
                          : " ics_ac_down_img "
                      }
                    />
                  </div>
                  {openItems[i] && (
                    <>
                      {elem.categories &&
                        elem?.categories?.map((category, j) => (
                          <div className="ics_ac_catgory_div_main" key={j}>
                            <div className="ics_ac_catgory_div">
                              <div>
                                <div className="ics_ac_cat_head">
                                  {category?.name}
                                </div>
                                {category?.agreements[0]?.responses[0]
                                  ?.response === 1 ? (
                                  <div className="ics_ac_cat_sub">
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/images/Green-tick.svg"
                                      alt=""
                                    />{" "}
                                    &nbsp; Accepted on{" "}
                                    {moment
                                      .utc(
                                        category.agreements[0]?.responses[0]
                                          ?.createdAt
                                      )
                                      .tz("Asia/Kolkata")
                                      .format("DD-MM-YY, HH:mm")}
                                  </div>
                                ) : (
                                  <div className="ics_ac_cat_sub ics_red_text">
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/courses/images/redCross.svg"
                                      alt=""
                                    />{" "}
                                    &nbsp; Declined on{" "}
                                    {moment
                                      .utc(
                                        category.agreements[0]?.responses[0]
                                          ?.createdAt
                                      )
                                      .tz("Asia/Kolkata")
                                      .format("DD-MM-YY, HH:mm")}
                                  </div>
                                )}
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  width: "auto",
                                  alignItems: "center",
                                  flexWrap: "wrap",
                                }}
                              >
                                {/* {category?.agreements[0]?.responses[0]
                                  .response === 2 && (
                                  <div>
                                    <button
                                      className="ics_accept_btn"
                                      style={{
                                        margin: "12px 0px",
                                      }}
                                      onClick={() => {
                                        singleResponse({
                                          agree_id:
                                            category?.agreements[0]?._id,
                                          resp_id:
                                            category?.agreements[0]
                                              ?.responses[0]?._id,
                                          review: true,
                                        });
                                      }}
                                    >
                                      Review Response
                                    </button>
                                  </div>
                                )} */}

                                {!openCategories[`${i}-${j}`] && (
                                  <div
                                    className="darken-on-hover"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      margin: "0rem 1rem",
                                      background: "#28A745",
                                      borderRadius: "4px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                    onClick={() => {
                                      singleResponse({
                                        agree_id: category?.agreements[0]?._id,
                                        resp_id:
                                          category?.agreements[0]?.responses[0]
                                            ?._id,
                                      });
                                    }}
                                  >
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/partnerimarticus/eye.svg"
                                      style={{ objectFit: "contain" }}
                                      alt="btn"
                                    />
                                  </div>
                                )}

                                {!openCategories[`${i}-${j}`] ? (
                                  <div
                                    className="darken-on-hover"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      background: "#fff",
                                      borderRadius: "4px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      border: "1px solid",
                                    }}
                                    onClick={() => toggleCategory(i, j)} // Toggle the specific category within this agreement
                                  >
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/partnerimarticus/history.svg"
                                      style={{ objectFit: "contain" }}
                                      alt="btn"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="darken-on-hover"
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      background: "#575E64",
                                      margin: "0rem 1rem",
                                      borderRadius: "4px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      border: "1px solid",
                                    }}
                                    onClick={() => toggleCategory(i, j)} // Toggle the specific category within this agreement
                                  >
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/courses/images/history.svg"
                                      style={{ objectFit: "contain" }}
                                      alt="btn"
                                    />
                                  </div>
                                )}
                              </div>
                            </div>

                            {openCategories[`${i}-${j}`] && (
                              <div>
                                {category.agreements &&
                                  category.agreements.map((agreement, k) => (
                                    <div key={k}>
                                      {agreement.responses[0].response === 1 ? (
                                        <div className="ics_ac_agree_main">
                                          <div
                                            style={{
                                              color: "#035642",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {agreement.name}
                                          </div>
                                          <div className="ics_ac_agree_actions">
                                            <div className="ics_ac_cat_sub">
                                              <img
                                                src="https://cdn.pegasus.imarticus.org/images/Green-tick.svg"
                                                alt=""
                                              />{" "}
                                              &nbsp; Accepted on{" "}
                                              {moment
                                                .utc(
                                                  agreement.responses[0]
                                                    ?.createdAt
                                                )
                                                .tz("Asia/Kolkata")
                                                .format("DD-MM-YY, HH:mm")}
                                            </div>
                                            <div
                                              className="darken-on-hover"
                                              style={{
                                                width: "32px",
                                                height: "32px",
                                                background: "#28A745",
                                                borderRadius: "4px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                              onClick={() => {
                                                singleResponse({
                                                  agree_id: agreement._id,
                                                  resp_id:
                                                    agreement.responses[0]?._id,
                                                });
                                              }}
                                            >
                                              <img
                                                src="https://cdn.pegasus.imarticus.org/partnerimarticus/eye.svg"
                                                style={{
                                                  objectFit: "contain",
                                                }}
                                                alt="btn"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="ics_ac_agree_main ics_ac_decline_main">
                                          <div
                                            style={{
                                              color: "#dc3545",
                                              fontWeight: "700",
                                            }}
                                          >
                                            {agreement.name}
                                          </div>

                                          <div className="ics_ac_agree_actions">
                                            <div className="ics_ac_cat_sub ics_red_text">
                                              <img
                                                src="https://cdn.pegasus.imarticus.org/courses/images/redCross.svg"
                                                alt=""
                                              />{" "}
                                              &nbsp; Declined on{" "}
                                              {moment
                                                .utc(
                                                  agreement.responses[0]
                                                    ?.createdAt
                                                )
                                                .tz("Asia/Kolkata")
                                                .format("DD-MM-YY, HH:mm")}
                                            </div>
                                            {agreement?.responses[0]
                                              .response === 2 && (
                                              <div>
                                                <button
                                                  className="ics_accept_btn ics_acc_btn_2"
                                                  style={{
                                                    margin: "12px 0px",
                                                  }}
                                                  onClick={() => {
                                                    singleResponse({
                                                      agree_id: agreement?._id,
                                                      resp_id:
                                                        agreement?.responses[0]
                                                          ?._id,
                                                      review: true,
                                                    });
                                                  }}
                                                >
                                                  Review Response
                                                </button>
                                              </div>
                                            )}
                                            <div
                                              className="darken-on-hover"
                                              style={{
                                                width: "32px",
                                                height: "32px",
                                                background: "#28A745",
                                                borderRadius: "4px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                              onClick={() => {
                                                singleResponse({
                                                  agree_id: agreement._id,
                                                  resp_id:
                                                    agreement.responses[0]?._id,
                                                });
                                              }}
                                            >
                                              <img
                                                src="https://cdn.pegasus.imarticus.org/partnerimarticus/eye.svg"
                                                style={{
                                                  objectFit: "contain",
                                                }}
                                                alt="btn"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            )}
                          </div>
                        ))}
                    </>
                  )}
                </div>
              ))}
            </>
          ) : (
            <div className="ics_no_application">
              <img
                src="https://cdn.pegasus.imarticus.org/partnerimarticus/empty.svg"
                alt="profile"
                className=""
              />
              {/* <span className="ics_na_head">It's empty here!</span> */}
              <span className="ics_na_desc">
                There are no current agreements to view here.
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgreementCenter;
