import React, { Component } from 'react';
import Footer from '../footers/Footer';

class GroupDetailSS extends Component {
	constructor(props){
		super(props);
		this.state = {
			toggle_quiz : false,
			toggle_video : false,
			crs_started : true
		}
	}

	render() {
		return (
			<div className="group-db-content-wrapper">
				<div className="container-fluid dashboard-content">
					{/* <div className="row gd-row"> */}
						{/* <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
							<p className="content-header">Unnayan Team Banka Block</p>
						</div> */}
					{/* </div> */}
					
					<div className="container grp-dtl-container">
						<div className="view-container">
							<div className="grp-section-1">
							<div className="grp-img-section">
							</div>
							<div className="grp-img-dtl">
								<div className="dum-1"></div>
							</div>
							</div>
						</div>
						{/* <div className="view-container-2">
							<div className="crs-container">
							<div className="crs-section">							
								<div className="crs-name">
								</div>
							</div>
							<div className="crs-status">	
							</div>
						</div>
						<div className="discuss-section">
						</div>
						<div className="feed-section">
						</div>
						<div className="video-section">
						</div>
						<div className="quiz-section">
						</div>
						</div> */}
					</div>			
				</div>
			</div>
		);
	}
}

export default GroupDetailSS;
