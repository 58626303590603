import React, { Component, Fragment } from 'react';
import ErrorHeader from '../headers/ErrorHeader';
import NotFoundContent from '../homecontents/NotFoundContent';

class NotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_sidebar: false,
            is_search: false,
        };
    }
    
    render() {
        return (
            <Fragment>
                <ErrorHeader/>
                <NotFoundContent/>
            </Fragment>
        );
    }
}

export default NotFound;
