import axios from "axios";
import { CONFIGS } from "../../constants/constants";
import Cookies from "universal-cookie";
import { utils } from "../../utility/utils";
// import XMLParser from "../../constants/xml-parser";
// import { parseString } from "xml2js";
let cookies = new Cookies();

const aid = utils.getAid();
const tokn = utils.getAt();
const PID = utils.getPid();
// const pid = utils.getPid();
// console.log("These are my user values :: ", { aid, utils.getAt(), pid });

const maxWidthHeightFirstIteration = 800;
const maxWidthHeightSecondIteration = 600;
const maxImageSizeAllowed = 800 * 1024;

// const localResize = async (file, size) => {
//   // console.log("\n In localResize: ", {file, size})
//   let mimeType = file.type;
//   const fileReader = new FileReader();
//   fileReader.readAsDataURL(file);
//   fileReader.onload = function (fileReaderEvent) {
//     // console.log("fileReader.onload :: ", fileReaderEvent)
//     // const image = new Image();
//     image.src = fileReaderEvent.target.result;

//     const imageAsBase64 = fileReaderEvent.target.result;
//     const image = document.createElement("img");
//     image.src = imageAsBase64;

//     const imageResizeWidth = 100;
//     // if (image.width <= imageResizeWidth) {
//     //  return;
//     // }

//     const canvas = document.createElement("canvas");
//     canvas.width = imageResizeWidth;
//     canvas.height = ~~(image.height * (imageResizeWidth / image.width));
//     const context = canvas.getContext("2d", { alpha: false });
//     // if (!context) {
//     //  return;
//     // }
//     context.drawImage(image, 0, 0, canvas.width, canvas.height);

//     // const resizedImageBinary = canvas.toBlob();
//     const resizedImageAsBase64 = canvas.toDataURL(mimeType);
//     // image.onload = function () {
//     //   const MAXWidthHeight = size;
//     //   const resolution = MAXWidthHeight / Math.max(this.width, this.height);
//     //   const width = Math.round(this.width * resolution);
//     //   const height = Math.round(this.height * resolution);
//     //   const canvas = document.createElement("canvas");
//     //   canvas.width = width;
//     //   canvas.height = height;

//     //   const ctx = canvas.getContext("2d");
//     //   ctx.drawImage(this, 0, 0, width, height);

//     //   const dataUrl = canvas.toDataURL("image/jpeg", 0.8);
//     //   return (dataUrl);
//     // };
//   };
// };

async function localResize(file, maxWidth = 800) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      const width = img.width;
      const height = img.height;

      // Calculate new dimensions
      let newWidth = width;
      let newHeight = height;

      if (width > maxWidth) {
        newWidth = maxWidth;
        newHeight = (height * maxWidth) / width;
      }

      if (newHeight > maxWidth) {
        newHeight = maxWidth;
        newWidth = (width * maxWidth) / height;
      }

      // Create canvas
      const canvas = document.createElement("canvas");
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw image on canvas
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, newWidth, newHeight);

      // Get blob from canvas
      canvas.toBlob(resolve, file.type);
    };

    // Load image
    img.src = URL.createObjectURL(file);
  });
}

const dataURItoBlob = (dataURI) => {
  // console.log(":dataURItoBlob :: ", {dataURI})
  const binary = atob(dataURI.split(",")[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "image/jpeg" });
};

const apicalls = {
  chatMedia: async (pid, gid, url) => {
    try {
      let res = await axios.get(
        `${
          CONFIGS.API_API_ENDPOINT
        }media/getcmedia?p_id=${utils.getPid()}&g_id=${gid}&m_url=${url}&tokn=${utils.getAt()}`
      );
      console.log("In api call /media/getcmedia : ", res.data.data.signed_url);
      return res.data.data.signed_url;
    } catch (err) {
      console.log("ERror in chatmedai api call /media/getcmedia : ", err);
      return err;
    }
  },
  makeAdmin: async (pid, gid, mpid) => {
    let body = {
      admin_id: pid,
      g_id: gid,
      p_id: mpid,
      tokn: utils.getAt(),
    };
    try {
      let response = await axios.put(
        `${CONFIGS.API_API_ENDPOINT}groupmember/gadm_a`,
        body
      );
      console.log("Response in makeAdmin: ", response);
      return response;
    } catch (e) {
      console.log("Error in makeadmin : ", e);
      return alert("Something went wrong! Please try again.");
    }
  },
  removeAdmin: async (pid, gid, mpid) => {
    let body = {
      admin_id: pid,
      g_id: gid,
      p_id: mpid,
      tokn: utils.getAt(),
    };
    try {
      let response = await axios.put(
        `${CONFIGS.API_API_ENDPOINT}groupmember/gadm_r`,
        body
      );
      console.log("Response in removeAdmin: ", response);
      return response;
    } catch (e) {
      console.log("Error in removeAdmin : ", e);
      return alert("Something went wrong! Please try again.");
    }
  },
  banUser: async (pid, gid, mpid) => {
    let body = {
      admin_id: pid,
      g_id: gid,
      p_id: mpid,
      tokn: utils.getAt(),
    };
    try {
      let response = await axios.put(
        `${CONFIGS.API_API_ENDPOINT}groupmember/gmem_b`,
        body
      );
      console.log("Response in banUser: ", response);
      return response;
    } catch (e) {
      console.log("Error in banUser : ", e);
      return alert("Something went wrong! Please try again.");
    }
  },
  unBanUser: async (pid, gid, mpid) => {
    let body = {
      admin_id: pid,
      g_id: gid,
      p_id: mpid,
      tokn: utils.getAt(),
    };
    try {
      let response = await axios.put(
        `${CONFIGS.API_API_ENDPOINT}groupmember/gmem_ub`,
        body
      );
      console.log("Response in unbanUser: ", response);
      return response;
    } catch (e) {
      console.log("Error in unbanUser : ", e);
      return alert("Something went wrong! Please try again.");
    }
  },
  deleteUser: async (pid, gid, mpid) => {
    let body = {
      admin_id: pid,
      g_id: gid,
      p_id: mpid,
      tokn: utils.getAt(),
    };
    try {
      let response = await axios.put(
        `${CONFIGS.API_API_ENDPOINT}groupmember/gmem_d`,
        body
      );
      console.log("Response in deleteUser: ", response);
      return response;
    } catch (e) {
      console.log("Error in deleteUser : ", e);
      return alert("Something went wrong! Please try again.");
    }
  },
  uploadFile: async ({
    s3Params,
    file,
    fileType,
    fileName,
    ext,
    newImageMid,
  }) => {
    console.log("In Apicalls uploadFile (final one): ", {
      s3Params,
      file,
      fileType,
      fileName,
      ext,
      newImageMid,
    });

    console.log(
      "\n\n\n ===================================== Lets try to resize the image! =================================\n\n\n"
    );
    console.log("extension : ", ext);
    //doc uploading
    let fileTypes = ["pdf", "ppt", "pptx", "xls", "xlsx", "txt", "doc", "docx"];
    console.log("Before ext check : ", ext);
    if (fileTypes.includes(ext)) {
      console.log("Not an image, directly uploading : ", ext);
      return await apicalls.uploadFileToS3({
        file,
        s3Params,
        ext,
        newImageMid,
      });
    }

    // image resizing
    try {
      const resizedBlob = await localResize(file);
      const maxImageSizeAllowed = 1024 * 1024; // Adjust as needed
      if (resizedBlob.size > maxImageSizeAllowed) {
        const secondResizedBlob = await localResize(file, 600); // Adjust maxWidth as needed
        console.log({ secondResizedBlob });
        return await apicalls.uploadFileToS3({
          file: secondResizedBlob,
          s3Params,
          ext,
          newImageMid,
        });
      } else {
        console.log({ resizedBlob });
        return await apicalls.uploadFileToS3({
          file: resizedBlob,
          s3Params,
          ext,
          newImageMid,
        });
      }
    } catch (error) {
      console.error("Error while uploading file:", error);
      return new Error("Error while uploading file");
    }
  },
  uploadFileToS3: async ({ s3Params, file, ext, newImageMid }) => {
    try {
      const formData = new FormData();
      formData.append("key", newImageMid + "." + ext);
      formData.append("AWSAccessKeyId", s3Params.AWSAccessKeyId);
      let ifDoc = false;
      let fileTypes = [
        "pdf",
        "ppt",
        "pptx",
        "xls",
        "xlsx",
        "txt",
        "doc",
        "docx",
      ];
      console.log("Before ext check : ", ext);
      if (fileTypes.includes(ext)) {
        // For docs
        console.log("For docs");
        ifDoc = true;
        formData.append("acl", "public-read");
      } else {
        // for images
        console.log("For images");
        formData.append("acl", "authenticated-read");
      }

      formData.append("Policy", s3Params.s3Policy);
      formData.append("Signature", s3Params.s3Signature);
      formData.append("Content-Type", file.type);
      formData.append("success_action_status", "201");
      formData.append("file", file);
      console.log({ file: formData.get("file"), acl: formData.get("acl") });
      const base = `https://${s3Params.bucket}.s3.amazonaws.com`;
      const chat_pic_base = "https://eckcpic.s3.amazonaws.com/";
      const doc_base = "https://eckdoc.s3.amazonaws.com/";
      let config = {
        method: "POST",
        headers: {
          // "Content-Type": "multipart/form-data"
          // "Content-Type": 'application/json',
        },
        onUploadProgress: function (progress) {},
        body: formData,
      };
      console.log({ config, formData, file });
      let response = await new Promise((resolve, reject) => {
        fetch(base, config)
          .then((response) => response.text())
          .then((bodyText) => {
            return resolve(bodyText);
            // now you have the response body you can parse
          })
          .catch((err) => {
            return reject(err);
          });
      });
      // response=response.text()
      console.log({ response, "response.body": response.body });
      // const parser = new DOMParser();
      // var xml = parser.parseFromString(response.body, 'text/xml');
      // new XMLParser().parseFromString(response.body)
      console.log("\n api response :: ", {
        data: response.body,
        status: response.status,
      });
      // if (response.status === 201) {
      // var url = xml.getElementsByTagName('Location')
      // var url
      // parseString(response.body, function (err, result) {
      //   console.log({result});
      //   url = result
      //   return result;
      //  });
      let url = response.split("<Location>")[1].split("</Location>")[0];
      console.log("Splitted url : ", url, "\n\n");
      // const url = (ifDoc ? doc_base : chat_pic_base) + newImageMid + "." + ext;
      return { newImageMid, url };
      // } else {
      //   console.log("Upload failed:", response);
      //   throw new Error("Upload failed");
      // }
    } catch (error) {
      console.error("Error in final uploading file:", error);
      //  error;
    }
  },
  fetchMediaPolicy: async function (pid, gid, fileName) {
    try {
      let res = await axios.get(
        `${
          CONFIGS.API_API_ENDPOINT
        }media/cp_w?g_id=${gid}&p_id=${utils.getPid()}&tokn=${utils.getAt()}&m_od=image&f_tp=jpeg&f_nm=${fileName}`
      );
      var s3Params = res.data.data;
      return s3Params;
    } catch (error) {
      console.error("Error in fetchMediaPolicy API call:", error);
      // return error;
    }
    // .then((res) => {
    // })
  },
  fetchDocPolicy: async (pid, gid, fileType, fileName) => {
    try {
      console.log("fetchDocPolicy :: ", { pid, gid, fileType, fileName });
      let res = await axios.get(
        `${
          CONFIGS.API_API_ENDPOINT
        }media/cp_w?g_id=${gid}&p_id=${utils.getPid()}&tokn=${utils.getAt()}&m_od=doc&f_tp=${fileType}&f_nm=${fileName}`
      );
      console.log("res in fetchDocPolicy apicall : ", res);
      var s3Params = res.data.data;
      return s3Params;
    } catch (error) {
      console.error("Error in profileFind API call:", error);
      // throw error;
    }
    // })
  },
  profileFind: async (pid) => {
    let profileData = await cookies.get("Profile");
    let ep = await cookies.get("ep");

    // console.log({ profileDataInCookies: profileData });

    let dataToReturn = {
      ppic: profileData.ppic || "",
      userName: profileData.nam || "",
      primaryEmail: ep || "",
    };
    if (
      dataToReturn.userName &&
      dataToReturn.ppic &&
      dataToReturn.primaryEmail
    ) {
      // console.log("We already got the data. Woohooo one API call saved!");
      return dataToReturn;
    } else {
      let profileFindBody = {
        a_id: aid,
        tokn: utils.getAt(),
      };
      return axios
        .post(`${CONFIGS.API_API_ENDPOINT}profile/find`, profileFindBody)
        .then((res) => {
          // console.log(
          //   "Profile.find api response in discussion:: ",
          //   res.data.data
          // );
          let apiresponse = res.data.data;
          let dataToReturn = {};
          if (res.data.success) {
            dataToReturn["userName"] = apiresponse.Profile[0].nam || "";
            dataToReturn["ppic"] = apiresponse.Profile[0].ppic || "";
            dataToReturn["primaryEmail"] = apiresponse.primaryEmail || "";
          }
          return dataToReturn;
        })
        .catch((error) => {
          // console.error("Error in profileFind API call:", error);
          throw error;
        });
    }
  },
  fetchGroup: async ({ GID: gid, pid }) => {
    // console.log("Fetching group :: " + gid);
    return axios
      .get(
        `${
          CONFIGS.API_API_ENDPOINT
        }group/index?g_id=${gid}&tokn=${utils.getAt()}&fetch_inactive=true&_=${new Date().getTime()}`
      )
      .then((res) => {
        // console.log("Fetch group api response in discussion:: ", res.data.data);
        let apiresponse = res.data.data;
        return apiresponse.Group;
      })
      .catch((error) => {
        // console.error("Error in profileFind API call:", error);
        throw error;
      });
  },
  fetchMember: async ({ GID: gid, pid: PID }) => {
    let fetchGroupMemberBody = {
      g_id: gid,
      tokn: utils.getAt(),
      fetch_inactive: true,
      _: new Date().getTime(),
    };
    return axios
      .get(
        `${
          CONFIGS.API_API_ENDPOINT
        }groupmember/check?g_id=${gid}&p_id=${utils.getPid()}&tokn=${utils.getAt()}&_=${new Date().getTime()}`
      )
      .then((res) => {
        // console.log(
        //   "Fetch Member api response in discussion:: ",
        //   res.data.data
        // );
        let apiresponse = res.data.data;
        return apiresponse.member;
        // let dataToReturn = {};
        // if (res.data.success) {
        //   dataToReturn["userName"] = apiresponse.Profile[0].nam || "";
        //   dataToReturn["ppic"] = apiresponse.Profile[0].ppic || "";
        //   dataToReturn["primaryEmail"] = apiresponse.primaryEmail || "";
        // }
        // return dataToReturn;
      })
      .catch((error) => {
        // console.error("Error in profileFind API call:", error);
        throw error;
      });
  },
  getPreviousMessages: async function (
    gid,
    lsmtim,
    currentAllMessagesState,
    noOfMessages = 15,
    pid,
    fresh = false,
    whereFrom
  ) {
    console.log("In getPreviousMessages with existing :: ", {
      gid,
      lsmtim,
      currentAllMessagesState,
      noOfMessages,
      pid,
    });
    let mid = "-",
      tim = "-";
    if (fresh == false) {
      if (currentAllMessagesState.length > 0) {
        mid = currentAllMessagesState[0]._id;
        tim = currentAllMessagesState[0].tim;
      }
    }
    let p_id = utils.getPid();
    try {
      let res = await axios.get(
        `${
          CONFIGS.API_API_ENDPOINT
        }message/previous_v14?g_id=${gid}&p_id=${p_id}&tokn=${utils.getAt()}&_=${new Date().getTime()}&m_id=${mid}&tim=${tim}&ipp=${noOfMessages}&whereFrom=${whereFrom}`
      );
      let apiresponse = res.data.data;
      let cnt = 0,
        oldMsgs = [],
        newMsgs = [],
        images = [];
      let msgs = apiresponse.messages;
      console.log({ msgs_in_apicall: msgs });
      var userLastSeen = lsmtim;
      var selfPid = PID;
      msgs = msgs.reverse();
      for (let i = 0; i < msgs.length; i++) {
        let msg = msgs[i];
        cnt++;
        if (msg.from == PID) {
          msg["self"] = true;
          msg["sent"] = true;
        } else {
          msg["self"] = false;
        }

        // processDocs beforehand
        if ((msg.type === 1 && msg.fob) || msg.type === 2 || msg.type === 4) {
          let signed_url = await apicalls.chatMedia(p_id, gid, msg.body);
          msg.media_url = signed_url;
        }

        if (msg.createdAt <= userLastSeen) {
          oldMsgs.push(msg);
        } else if (
          msg.createdAt > userLastSeen &&
          (msg.from || msg.pid) !== selfPid &&
          [6, 7, 8, 9].includes(msg.type) == false // message_types ::: notification: 5,like: 6,seen: 7,admin: 8,invisible: 9,
        ) {
          newMsgs.push(msg);
        }

        if (msg.type === 2) {
          console.log({ imageMessageInPrev_v14: msg });
          images.push(msg);
        }
      }
      if (mid !== "-") {
        oldMsgs = [...oldMsgs, ...currentAllMessagesState];
      }
      return {
        msgs,
        oldMsgs,
        newMsgs,
        images,
      };
    } catch (e) {
      console.log("Error :: ", e);
      return {
        msgs: [],
        oldMsgs: [],
        newMsgs: [],
        images: [],
      };
    }
  },
  getListv16: async function ({ GID: gid, pid: PID, index }) {
    if (!index) index = 0;
    return axios
      .get(
        `${
          CONFIGS.API_API_ENDPOINT
        }groupmember/list_v16?g_id=${gid}&p_id=${utils.getPid()}&tokn=${utils.getAt()}&_=${new Date().getTime()}&ipp=10&start=${index}`
      )
      .then((res) => {
        // console.log("Get List v16 response in discussion:: ", res.data.data);
        let totalMembers = res.data.data.total;
        let members = res.data.data.members;
        let profiles = res.data.data.profiles;
        let profileMap = {};
        if (
          res.data.data.members.length > 0 &&
          res.data.data.profiles.length > 0
        ) {
          profiles.map((profile) => (profileMap[profile._id] = profile));
        }

        // console.log({ profileMap });

        return {
          profileMap,
          members,
          totalMembers,
        };
      })
      .catch((error) => {
        // console.error("Error in profileFind API call:", error);
        throw error;
      });
  },
  updateLastSeen: async function ({ GID: gid, pid: PID }) {
    console.log("GID in updateLastSeen apicalls() : ", gid);
    return axios
      .get(
        `${
          CONFIGS.API_API_ENDPOINT
        }groupmember/update_last_seen?g_id=${gid}&p_id=${utils.getPid()}&tokn=${utils.getAt()}`
      )
      .then((res) => {
        // console.log("updateLastSeen response in discussion:: ", res.data.data);
        return res.data.data;
      })
      .catch((error) => {
        // console.error("Error in updateLastSeen API call:", error);
        throw error;
      });
  },
  fetchImageLoadUrl: async function (gid, url) {
    return axios
      .get(
        `${
          CONFIGS.API_API_ENDPOINT
        }media/getcmedia?p_id=${utils.getPid()}&g_id=${gid}&tokn=${utils.getAt()}&m_url=${url}&_=${new Date().getTime()}`
      )
      .then((res) => {
        // console.log("fetchImageLoadUrl response in discussion:: ", res.data);
        return res.data;
      })
      .catch((error) => {
        // console.error("Error in fetchImageLoadUrl API call:", error);
        throw error;
      });
  },
};
export default apicalls;
