import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
const cookies = new Cookies();

export const verifyCertificate = (crt_code) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(ROUTES.VERIFY_CERTIFICATE, {
        crt_code,
      })
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};
