import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from "query-string";
// import { ROLES, PAGE_HEADINGS } from '../../constants/constants';
import { POPOVER_CONTENT, POPOVER_HEADINGS, POPOVER_TYPE, POPOVER_HIDETIME, URLS, PIC_TYPE } from '../../constants/constants';
import { updateGPicURL, updateGroupDetailsToBackend, setGroupDetailsInSettings, updateGroupDescriptionBackend } from '../../actions/groupAction';
import { showPopover, setNotFound } from '../../actions/utilAction';
import { getGroupPlugins } from '../../actions/groupAction';
import { setPageHeading } from '../../actions/utilAction';
import Loader from '../utils/Loader';

class GroupDescription extends Component {
    constructor(props){
        super(props);
        this.state = {
          desc : "",
        }
    }
    componentDidUpdate(prev_props){
        var query_params = queryString.parse(this.props.location.search);
        if(prev_props.gd_gpic != this.props.gd_gpic && this.props.gd_gpic ){
            this.props.updateGPicURL(this.props.gd_gpic);
        }
        if(prev_props.gd_gname != this.props.gd_gname && this.props.gd_gname ){
            this.setState({
                gd_gname : this.props.gd_gname
            })
        }
        if(this.props.is_admin != prev_props.is_admin){
            if(!this.props.is_admin){
                this.props.setNotFound(true);
            }
        }
        if(this.props.groups != prev_props.groups && (this.props.groups && this.props.groups.length > 0 )){
            this.props.setGroupDetailsInSettings(query_params.gid,this.props.groups);
        }
        if(this.props.gd_gname != prev_props.gd_gname){
        this.props.setPageHeading(this.props.gd_gname);
        }
        if(this.props.gd_desc != prev_props.gd_desc){
            this.setState({
                desc : this.props.gd_desc
            })
        }
    }
    updateDescription = () => {
        var query_params = queryString.parse(this.props.location.search);
        this.props.updateGroupDescriptionBackend(query_params.gid, this.state.desc.trim(),this.props.groups);
    }
    componentDidMount() {
        this.props.setPageHeading(this.props.gd_gname);
        var query_params = queryString.parse(this.props.location.search);
        if (this.props.gd_gpic) {
            this.props.updateGPicURL(this.props.gd_gpic);
            this.setState({
                gd_gname : this.props.gd_gname
            })
        }
        if(!query_params.gid){
            this.props.setNotFound(true);
            return;
        }
        this.setState({
            desc : this.props.gd_desc
        })
        this.props.setGroupDetailsInSettings(query_params.gid,this.props.groups);
        this.props.getGroupPlugins(query_params.gid,query_params.pid,true);
    }
    
    displayPopover = (heading,msg,type) => {
        this.props.showPopover(heading,msg,type);
    }
    setDesc = (e) => {
        if(e && e.target){
            if(e.target.value && e.target.value.length > 250){
            this.displayPopover(POPOVER_HEADINGS.ERROR,POPOVER_CONTENT.GDESC_LENGTH_EXCEEDED,POPOVER_TYPE.ERROR);
                return;
            }
            this.setState({
                desc : e.target.value,
            })
        }
       
    }
    
    

    render() {
        if(this.props.to_mgp){
			// this.props.disableMyGroupRedirection();
			return(
				this.redirectToMyGroupPage()
				)
        }
        if(!this.props.gsetting_is_back){
            return (
                <div className="group-db-content-wrapper">
                    <div className="container-fluid dashboard-content">
                            {/* <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <p className="content-header">Group Settings</p>
                            </div> */}
                        <form>
                            <div className=" profile-setting-container-row">
                                <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                                        <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                            <p className="edit-profile-heading">Group Description</p>
                                            
                                            <div className="pf-content-container">
                                                <textarea className="g-desc" value={this.state.desc} onChange={(e)=>this.setDesc(e)}></textarea>
                                                <input className="ps-update-btn" type="button" onClick={this.updateDescription} value="Save Details"></input>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
    
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        }
        else{
            return(
                <Loader/>
            );
        }
    }
}

const mapStateToProps = (state) => (
	{
		show_menu	    : state.ac_dd.show_menu,
		profiles	    : state.profile.profiles,
		p_name		    : state.profile.p_name,
        ppic		    : state.profile.ppic,
        edit_gpic       : state.j_grp.edit_gpic,
        profile_map     : state.profile.profile_map,
        gpic_ext        : state.j_grp.gpic_ext,
        groups          : state.j_grp.groups,
        gd_gname 		: state.j_grp.gd_gname,
		gd_gtype		: state.j_grp.gd_gtype,
		gd_gpic			: state.j_grp.gd_gpic,
		gd_feed			: state.j_grp.gd_feed,
		gd_quiz 		: state.j_grp.gd_quiz,
		gd_video		: state.j_grp.gd_video,
		gd_code			: state.j_grp.gd_code,
		gd_course		: state.j_grp.gd_course,
		is_admin 		: state.j_grp.is_admin,
        to_mgp			: state.j_grp.to_mgp,
        gid             : state.j_grp.gd_id,
        gd_desc         : state.j_grp.gd_desc,
        gsetting_is_back : state.j_grp.gsetting_is_back,
	})

const mapDispatchToProps = {
    updateGPicURL,
    updateGroupDetailsToBackend,
    showPopover,
    getGroupPlugins,
    setNotFound,
    setPageHeading,
    setGroupDetailsInSettings,
    updateGroupDescriptionBackend,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(GroupDescription);
