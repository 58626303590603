import axios from "axios";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
const cookies = new Cookies();

axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const startScorm = (pid, cid, chid, fid, start) => {
  return new Promise((resolve, reject) => {
    console.log(`startScorm`, {
      'cookies.get("pid")': cookies.get("pid"),
      'cookies.get("Profile")': cookies.get("Profile"),
    });
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.UPDATE_SCORM_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          cp_id: chid,
          scorm_id: fid,
          a_id: cookies.get("aid"),
          cl: "W",
          start,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const scormCompleted = (pid, cid, chid, fid) => {
  return new Promise((resolve, reject) => {
    console.log(`scormCompleted`);
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.UPDATE_SCORM_STATUS,
        {
          crs_id: cid,
          p_id: pid,
          cp_id: chid,
          scorm_id: fid,
          a_id: cookies.get("aid"),
          cl: "W",
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};

export const getScormData = (fid) => {
  return new Promise((resolve, reject) => {
    var configs = {};
    var headers = {};
    headers["x-access-crs-token"] = cookies.get("crs_tkn");
    configs.headers = headers;
    return axios
      .post(
        ROUTES.GET_SCORM_DATA,
        {
          a_id: cookies.get("aid"),
          scorm_id: fid,
        },
        configs
      )
      .then((response) => {
        console.log(response.data);
        return resolve(response.data);
      })
      .catch((err) => {
        console.log("Error : ", err);
        console.log({ err });
        return reject(err);
      });
  });
};
