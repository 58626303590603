import React, { Component } from "react";
import footer_logo from "../../images/footer-logo.svg";
import google_play from "../../images/google-play.svg";
import call from "../../images/call.svg";
import email from "../../images/email.svg";
import location from "../../images/location.svg";
import facebook_logo from "../../images/facebook-logo.svg";
import twitter_logo from "../../images/twitter-logo.svg";
import youtube_logo from "../../images/youtube.svg";
import instagram_logo from "../../images/instagram.svg";
import linkedin_logo from "../../images/linkedin-logo.svg";
import apple_download from "../../images/apple-download.svg";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div className="eck-footer">
        <div className="row logo-row">
          <div className="logo-row-content">
            <img className="footer-logo" src={footer_logo} alt=""></img>
            <p className="eck-title">Eckovation</p>
            <div className="dashboard-btn-wrapper">
              <Link to="/dashboard">
                <button className="dashboard-btn">Dashboard</button>
              </Link>
            </div>
          </div>
          <div className="dashboard-btn-wrapper-mb">
            <button className="dashboard-btn-mb">Dashboard</button>
          </div>
        </div>
        <div className="row content-row-1">
          <div className="col-md-2 company-col">
            <p className="footer-sub-heading">Company</p>
            <div className="row">
              <div className="col-12 footer-item">
                <a target="_blank" href="https://www.eckovation.com/about.html">
                  About Us
                </a>
              </div>
              <div className="col-12 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/contact.html"
                >
                  Contact Us
                </a>
              </div>
              <div className="col-12 footer-item">
                <a target="_blank" href="https://career.eckovation.com/">
                  Careers
                </a>
              </div>
              <div className="col-12 footer-item">
                <a target="_blank" href="">
                  Media Kit
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-4 groups-col">
            <p className="footer-sub-heading">Groups</p>
            <div className="row groups-row">
              <div className="col-6 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/join/873541"
                >
                  UPSC Preparation
                </a>
              </div>
              <div className="col-6 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/join/101010"
                >
                  Class X Boards
                </a>
              </div>
              <div className="col-6 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/join/613879"
                >
                  SSC Test Series
                </a>
              </div>
              <div className="col-6 footer-item">
                <a target="_blank" href="">
                  Class VI - X
                </a>
              </div>
              <div className="col-6 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/join/254198"
                >
                  Bank PO Test Series
                </a>
              </div>
              <div className="col-6 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/join/718654"
                >
                  GATE Exam
                </a>
              </div>
              <div className="col-6 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/join/819367"
                >
                  CAT Preparation
                </a>
              </div>
              <div className="col-6 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/join/681729"
                >
                  IIT JEE Test Series
                </a>
              </div>
            </div>
          </div>
          <hr className="hr-md"></hr>
          <div className="col-md-6 courses-col">
            <p className="footer-sub-heading">Courses</p>
            <div className="row courses-row">
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Professional Courses
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Free Course
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/course/machine-learning-value-package?utm_campaign=footer"
                >
                  Machine Learning and AI
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Test Preparation
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Minor Degree
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/course/data-science-masters-degree-program-by-eckovation?utm_campaign=footer"
                >
                  Data Science
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Curriculum Learning
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Booster Degree
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Software Testing
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Combo Course
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Emerging Technologies
                </a>
              </div>
              <div className="col-md-4 footer-item">
                <a target="_blank" href="">
                  Marketing & Management
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr className="hr" /> <hr className="hr-mb" />
        <div className="row content-row-2">
          <div className="col-md-7 block-1">
            <div className="row ba-row">
              <div className="col-md-3 blogs-col">
                <div className="row blog-row">
                  <p className="footer-sub-heading">blogs</p>
                  <div className="col-md-12 padding-0 footer-item">
                    <a
                      target="_blank"
                      href="https://beats.eckovation.com/?utm_campaign=footer"
                    >
                      Education Beats
                    </a>
                  </div>
                  <div className="col-md-12 padding-0 footer-item">
                    <a
                      target="_blank"
                      href="https://engineering.eckovation.com/?utm_campaign=footer"
                    >
                      Engineering
                    </a>
                  </div>
                  <div className="col-md-12 padding-0 footer-item">
                    <a
                      target="_blank"
                      href="https://mba.eckovation.com/?utm_campaign=footer"
                    >
                      MBA
                    </a>
                  </div>
                  <div className="col-md-12 padding-0 footer-item">
                    <a
                      target="_blank"
                      href="https://school.eckovation.com/?utm_campaign=footer"
                    >
                      School
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-9 awards-col">
                <p className="footer-sub-heading">awards</p>
                <ul className="awards-list">
                  <li className="awards-item">
                    <a target="_blank" href="">
                      CAPAM Award 2018 Innovation Incubation
                    </a>
                  </li>
                  <li className="awards-item">
                    <a target="_blank" href="">
                      Unnayan Banka Initiative Kalam Innovation Award
                    </a>
                  </li>
                  <li className="awards-item">
                    <a target="_blank" href="">
                      Prime Minister’s Excellence Award for Best Innovation 2018
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr className="hr-md"></hr>
          <div className="col-md-5 block-2">
            <div className="row">
              <div className="col-md-5 link-btns">
                <a
                  target="_blank"
                  href="https://itunes.apple.com/in/app/eckovation-connect-learn/id1067181761?mt=8"
                >
                  <img className="apple-download" src={apple_download}></img>
                </a>
                <br className="mb-br"></br>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.eckovation&hl=en"
                >
                  <img src={google_play}></img>
                </a>
                <br className="mb-br"></br>
                <button className="edu-btn">Become an Educator</button>
              </div>
              <div className="col-md-7 contacts-col">
                <p className="contact">
                  <img className="contact-img" src={location} />
                  <span className="contact-info">
                    125 A, Shahpur Jat, Siri Fort New Delhi- 110049{" "}
                  </span>
                </p>
                <p className="contact">
                  <img className="contact-img" src={call} />
                  <span className="contact-info">+91-9266677335</span>
                </p>
                <p className="contact">
                  <img className="contact-img" src={email} />
                  <span className="contact-info">info@eckovation.com</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr className="hr" />
        <hr className="hr-mb" />
        <div className="row last-row">
          <div className="col-md-4 copyright">
            {" "}
            © 2019 Eckovation Solutions Private Limited
          </div>
          <div className="col-md-4 social-links">
            <a target="_blank" href="https://www.facebook.com/eckovation/">
              <img className="social-img" src={facebook_logo} />
            </a>
            <a target="_blank" href="https://twitter.com/eckovationapp?lang=en">
              <img className="social-img" src={twitter_logo} />
            </a>
            <a target="_blank" href="https://www.instagram.com/eckovation/">
              <img className="social-img" src={instagram_logo} />
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCmf6e28YTrQhpu4rAlML54A"
            >
              <img className="social-img" src={youtube_logo} />
            </a>
            <a
              target="_blank"
              href="https://in.linkedin.com/company/eckovation"
            >
              <img className="social-img" src={linkedin_logo} />
            </a>
          </div>
          <div className="col-md-4 tos-links">
            <ul className="tos-list">
              <li className="tos-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/termsofservice.html"
                >
                  Terms of Service
                </a>
              </li>
              <li className="tos-item">
                <a target="_blank" href="">
                  Site Map
                </a>
              </li>
              <li className="tos-item">
                <a
                  target="_blank"
                  href="https://www.eckovation.com/privacypolicy.html"
                >
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
