import Cookies from "universal-cookie";
import { CONFIGS, MAIL_BODY } from "../constants/constants";
const cookies = new Cookies();
export const dispatchIndividualBrand = (brand) => (dispatch) => {
  console.log(`dispatchIndividualBrand::`, brand);
  if (brand) {
    let url = window?.location.href;
    let domain = new URL(url).hostname
      .split(".")
      .slice(0)
      .slice(-(url.length === 4 ? 3 : 2))
      .join(".");
    console.log({ domain });
    if (domain != "imarticus.org" && domain != "localhost") {
      if (brand?.pic) {
        console.log(`setting favicon`);
        let link = document.querySelector("link[rel~='icon']");
        link.href = brand.pic;
      }
      if (brand?.name) document.title = brand.name;
      if (brand?.themeColorIndex)
        document.querySelector("meta[name='theme-color']").content =
          brand.themeColorIndex;
    }
    let expirydate = new Date();
    expirydate.setDate(expirydate.getDate() + 180);
    console.log({
      data: JSON.stringify({
        loaderColorIndex: brand.loaderColorIndex,
        themeColorIndex: brand.themeColorIndex,
        ctaColorIndex: brand.ctaColorIndex,
        textColor: brand.textColor,
        pic: brand.pic,
        name: brand.name,
        whiteBackground: brand.whiteBackground,
        exploreEnabled: brand.exploreEnabled,
        getHelpEnabled: brand.getHelpEnabled,
      }),
    });
    console.log({
      options: {
        expires: expirydate,
        domain: CONFIGS.DOMAIN,
        path: "/",
      },
    });
    cookies.set(
      "brand",
      JSON.stringify({
        loaderColorIndex: brand.loaderColorIndex,
        themeColorIndex: brand.themeColorIndex,
        ctaColorIndex: brand.ctaColorIndex,
        textColor: brand.textColor,
        pic: brand.pic,
        name: brand.name,
        whiteBackground: brand.whiteBackground,
        exploreEnabled: brand.exploreEnabled,
        getHelpEnabled: brand.getHelpEnabled,
      }),
      {
        expires: expirydate,
        domain: CONFIGS.DOMAIN,
        path: "/",
      }
    );
    if (brand?.themeColorIndex) {
      document
        .querySelector(":root")
        .style.setProperty("--themeColor", brand.themeColorIndex);
    }
    if (brand?.ctaColorIndex) {
      document
        .querySelector(":root")
        .style.setProperty("--ctaColor", brand.ctaColorIndex);
    }
    if (brand?.textColor) {
      document
        .querySelector(":root")
        .style.setProperty("--textColor", brand.textColor);
    }
  } else {
    document
      .querySelector(":root")
      .style.setProperty("--themeColor", "#035642");
    document.querySelector(":root").style.setProperty("--ctaColor", "#022a22");
    document.querySelector(":root").style.setProperty("--textColor", "#fff");
    document.querySelector("link[rel~='icon']").href =
      "https://cdn.pegasus.imarticus.org/images/Favicon.png";
    document.title = "Imarticus Learning";
    document.querySelector("meta[name='theme-color']").content = "#055646";
    if (cookies.get("brand")) {
      cookies.remove("brand", {
        path: "/",
        domain: CONFIGS.DOMAIN,
      });
    }
  }
  var action_obj = {};
  action_obj.type = "UPDATE_BRAND";
  action_obj.data = brand;
  dispatch(action_obj);
};

let isImarticusLink = () => {
  let url = new URL(window.location.href);
  return url.host?.includes("imarticus.org");
};
