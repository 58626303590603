import React, { Component, Fragment } from "react";
import Cropper from "react-cropper";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  image64toCanvasRef,
  base64StringtoFile,
  downloadBase64File,
  extractImageFileExtensionFromBase64,
} from "../../utility/utils";
import { showPopover } from "../../actions/utilAction";
import {
  VALID_PROFILE_PIC_TYPES,
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
} from "../../constants/constants";
import {
  updatePicURL,
  setProfilePicExtension,
  updatePicFileObject,
  updateCroppedPPic,
} from "../../actions/profileAction";
import {
  updateGPicURL,
  updateCroppedGPic,
  setGroupPicExtension,
} from "../../actions/groupAction";
import "cropperjs/dist/cropper.css"; // see installation section above for versions of NPM older than 3.0.0
// If you choose not to use import, you need to assign Cropper to default
// var Cropper = require('react-cropper').default
import Spinner from "../utils/Spinner";
import Loader from "../utils/Loader";

class GroupPicCropper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      crop_img: "",
      orignal_img: null,
      is_loading: true,
      org_set: false,
    };
  }

  componentDidMount() {
    this.props.setOriginalPicAndCrop(this.props.edit_gpic);
  }

  _ready = () => {
    if (!this.refs.cropper) {
      return;
    }
    setTimeout(
      function () {
        if (!this.refs.cropper) {
          return;
        }
        this.refs.cropper.crop();
        this.setState({
          is_loading: false,
        });
      }.bind(this),
      1000
    );
  };
  _crop() {
    var options = {
      width: 100,
      height: 100,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: "high",
      // fillColor: '#fff'
    };

    try {
      var cropped_img = this.refs.cropper.getCroppedCanvas(options).toDataURL();
      // console.log(this.refs.cropper.getCroppedCanvas(options).toDataURL());
      this.setState({
        crop_img: cropped_img,
      });
      this.props.updateCroppedGPic(cropped_img);
    } catch {
      console.log("errrr..");
      return;
    }
  }
  getCroppedCanvas = (c) => {
    console.log(c);
  };
  cropstart = () => {
    // this.refs.cropper.crop();
  };

  picSelected = (e) => {
    // console.log("pic selected..... ");
    if (!e.target || !e.target.files[0]) {
      return;
    }
    var current_file = e.target.files[0];
    var is_type_valid = false;
    // console.log(current_file.type);
    // console.log(current_file.size);
    // console.log(current_file.width);
    // console.log(current_file.height);
    for (var i = 0; i < VALID_PROFILE_PIC_TYPES.length; i++) {
      if (current_file.type == VALID_PROFILE_PIC_TYPES[i]) {
        is_type_valid = true;
        break;
      }
    }
    if (is_type_valid) {
      // console.log("YES");
      this.props.setGroupPicExtension(current_file.type);
    } else {
      // console.log("NO");
      this.displayPopover(
        POPOVER_HEADINGS.INVALID_PP_EXT,
        POPOVER_CONTENT.INVALID_PP_EXT,
        POPOVER_TYPE.ERROR
      );
      e.target = null;
      return;
    }
    // console.log(current_file.type,"====>");
    // console.log(current_file.width);
    var updateGPicURL = this.props.updateGPicURL;
    // var updatePicFileObject = this.props.updatePicFileObject;
    const myFileItemReader = new FileReader();
    myFileItemReader.addEventListener("load", () => {
      var img = new Image();
      // alert('hello');
      img.src = myFileItemReader.result;
      // console.log(myFileItemReader.result, "=========================-----------------==========");
      img.onload = function () {
        var w = this.naturalWidth;
        var h = this.height;
        // console.log(w, h);
        updateGPicURL(myFileItemReader.result);
        // updatePicFileObject(current_file);
      };
    });
    myFileItemReader.readAsDataURL(current_file);
  };

  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };

  render() {
    return (
      <Fragment>
        <div className="ppc-container">
          <div className={this.state.is_loading ? "cropper-cover" : "hide"}>
            <Loader brand={this.state.brand} />
          </div>
          <div
            className={
              this.props.gd_gpic == this.props.edit_gpic
                ? "cropper-cover-2"
                : "hide"
            }
          ></div>
          <Cropper
            ref="cropper"
            src={this.props.edit_gpic}
            style={{ maxHeight: 300, height: "100%", width: "100%" }}
            // Cropper.js options
            aspectRatio={1 / 1}
            guides={false}
            ready={this._ready}
            cropstart={this.cropstart}
            autocrop={this.props.gd_gpic == this.props.edit_gpic ? false : true}
            autoCropArea={1}
            // cropBoxResizable={this.props.gd_gpic == this.props.edit_gpic ? false : true }
            crop={this._crop.bind(this)}
          />

          {/* <img src={this.state.crop_img}></img> */}
          <input
            type="file"
            className="hide"
            onChange={(e) => this.picSelected(e)}
            id="edit-file"
          ></input>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  gd_gpic: state.j_grp.gd_gpic,
  edit_gpic: state.j_grp.edit_gpic,
  cropped_gpic: state.profile.cropped_gpic,
});

const mapDispatchToProps = {
  showPopover,
  updateGPicURL,
  updateCroppedGPic,
  setGroupPicExtension,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupPicCropper)
);
