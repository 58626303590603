const initialState = {
  show_m_mdl: false, // show primary email verify modal
  cnf_m_modal: false,
  open_status_modal: true, // will open verify email status
  verify_link: "",
  m_verified: false,
  waiting_verification: true,
  otp_received: false,
  otp_verified: false,
  add_new_mobile: true,
  is_loading: false, // loader status for change email section
  m: undefined,
  ccod: undefined,
};

const mobileReducers = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_MOBILE_VERIFY":
      return { ...state, show_m_mdl: action.val };
    case "RESET_MOBILE_ACTIONS":
      return {
        ...state,
        otp_received: false,
        otp_verified: false,
        is_loading: false,
      };
    case "OPEN_MOBILE_STATUS_MODAL":
      return { ...state, open_status_modal: action.val };
    case "CHANGE_MOBILE_VERIFY_LINK":
      return { ...state, verify_link: action.val };
    case "OPEN_CONFIRM_MOBILE_MODAL":
      return { ...state, cnf_m_modal: action.value };
    case "ADD_NEW_MOBILE":
      return { ...state, add_new_mobile: action.value };
    case "UPDATE_MOBILE_STATUS":
      return { ...state, m_verified: action.value };
    case "MOBILE_OTP_VERIFIED":
      return {
        ...state,
        m_verified: true,
        cnf_m_modal: true,
        show_m_mdl: false,
        open_status_modal: true,
        waiting_verification: false,
      };
    case "WAITING_MOBILE_VERIFICATION":
      return { ...state, waiting_verification: action.value };
    case "MOBILE_LOADER":
      return { ...state, is_loading: action.value };
    case "VERIFY_OTP":
      return { ...state, otp_verified: action.otp_verified };
    case "OTP_RECEIVED":
      return { ...state, otp_received: action.otp_received };
    case "PRIMARY_EMAIL_UPDATE":
      return { ...state, primary_email: action.primary_email };
    case "MOBILE_UPDATE":
      return { ...state, m: action.mobile, ccod: action.ccod };
    case "UNSET_PRIMARY_EMAIL":
      return { ...state, primary_email: undefined };
    default:
      return state;
  }
};

export default mobileReducers;
