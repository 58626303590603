import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { showSidebarMB, setSidebar } from "../../actions/utilAction";
import small_left from "../../images/left-arr-white.svg";
import { withRouter } from "react-router";
import queryString from "query-string";
import white_cross from "../../images/white-cross.svg";
import { getProfileInfo } from "../../actions/profileAction";
import { CONFIGS, SIDEBARS } from "../../constants/constants";
import { Link } from "react-router-dom";
import {
  fetchGroups,
  setSelectedGroupDetails,
  setGDBack,
  resetGDBack,
  resetGSettingBack,
  setGSettingBack,
} from "../../actions/groupAction";
import { fetchMyCourses } from "../../actions/courseAction";
import eck_logo from "../../images/imarticus-new-logo.svg";
import CardHeading from "../utils/CardHeading";

class GroupSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.setSidebar(SIDEBARS.GROUP_SIDEBAR);
    var query_params = queryString.parse(this.props.location.search);
    this.setState(
      {
        current_gid: query_params.gid,
      },
      this.setSelectedGroupDetailsOnRenderChange
    );

    if (this.props.gd_is_back) {
      // alert(121);
      if (this.props.history.location.pathname == "/dashboard/group-detail") {
        this.props.history.goBack();
      }
    }
    if (this.props.gsetting_is_back) {
      // alert("111");
      // alert(this.props.gsetting_is_back);
      // alert(this.props.gd_is_back);
      this.props.resetGSettingBack();
    }
  }
  componentDidUpdate(prev_props) {
    if (prev_props.user_j_groups != this.props.user_j_groups) {
      this.setSelectedGroupDetails();
    }
    if (prev_props.location != this.props.location) {
      this.setSelectedGroupDetails();
    }

    if (this.props.gd_is_back) {
      this.props.history.goBack();
    }
    if (this.props.gsetting_is_back) {
      this.props.resetGSettingBack();
    }
  }

  setSelectedGroupDetailsOnRenderChange = () => {
    if (this.props.user_j_groups.length > 0) {
      this.setSelectedGroupDetails();
    }
  };

  changeGroup = (gid) => {
    this.props.history.push({
      pathname: "/dashboard/group-detail",
      search: queryString.stringify(
        Object.assign({}, queryString.parse(this.props.location.search), {
          gid,
        })
      ),
    });
    this.setState(
      {
        current_gid: gid,
      },
      this.setSelectedGroupDetailsOnRenderChange
    );
  };

  setSelectedGroupDetails = () => {
    var grp_obj = {};
    for (var i = 0; i < this.props.user_j_groups.length; i++) {
      if (this.props.user_j_groups[i]._id == this.state.current_gid) {
        this.props.setSelectedGroupDetails(this.props.user_j_groups[i], i);
        break;
      }
    }
  };
  closeSidebar = () => {
    this.props.showSidebarMB(false);
  };
  goBack = () => {
    console.log("goBack triggered::");
    this.props.setGDBack();
    // alert(this.props.location.pathname);
    // while(this.props.location.pathname == '/dashboard/group-detail'){
    // }
    // while(){

    // }
    // alert("1");
    // alert("2");
    // this.props.history.push({
    // pathname: '/dashboard/group',
    // search: queryString.stringify(Object.assign({}, queryString.parse(this.props.location.search), { gid }))
    // })
    // this.props.history.goBack();
  };
  goToDashboard = () => {
    this.props.history.push({
      pathname: "/dashboard/",
    });
  };

  render() {
    return (
      <Fragment>
        <div className="group-sidebar">
          <Link to="/dashboard">
            <div
              className="logo-container center-all "
              style={{
                backgroundColor: `${
                  this.props.brand && this.props.brand.whiteBackground
                    ? "white"
                    : ""
                }`,
              }}
            >
              {!this.props.is_data_loading && (
                <img
                  src={
                    this.props.brand
                      ? this.props.brand.pic
                      : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                  }
                  className="side-bar__logo-img"
                  style={{
                    maxHeight: "50px",
                    margin: "unset",
                  }}
                />
              )}
            </div>
          </Link>
          <p
            onClick={() => {
              window.location.replace(
                `${CONFIGS.WEB_DASHBOARD_BASE_URL}dashboard`
              );
            }}
            className="back-btn group1"
          >
            <img className="back-img" src={small_left}></img>Back
          </p>
          <div>
            <p className="grp-heading">My Groups</p>
            {/* <hr></hr> */}
            {this.props.user_j_groups.map(({ name, gpic, _id }, index) => {
              return (
                <Fragment>
                  {/* <p onClick={() => this.changeGroup(_id)} className={this.state.current_gid == _id ? "grp-name active" : "grp-name" }>{name}</p> */}
                  {/* <hr></hr> */}
                  <div
                    className={
                      this.state.current_gid == _id
                        ? "g-name-wrapper active"
                        : "g-name-wrapper"
                    }
                    onClick={() => this.changeGroup(_id)}
                  >
                    <p
                      className={
                        this.state.current_gid == _id ? "g-name" : "g-name"
                      }
                    >
                      {name}
                    </p>
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
        <div
          onClick={this.closeSidebar}
          className={this.props.show_sidebar_mb ? "demo" : "hide"}
        ></div>
        <div className="group-sidebar-mb">
          <div
            className={
              "left-sidebar-menu-mb " +
              (this.props.show_sidebar_mb ? "out" : "")
            }
          >
            <div className="sidebar-header">
              {!this.props.is_data_loading && (
                <img
                  onClick={this.goToDashboard}
                  src={
                    this.props.brand
                      ? this.props.brand.pic
                      : `https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg`
                  }
                  className="Logo"
                ></img>
              )}

              <button
                className={this.props.show_sidebar_mb ? "close-btn" : "hide"}
                onClick={this.closeSidebar}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  enable-background="new 0 0 40 40"
                >
                  <line
                    x1="5"
                    y1="5"
                    x2="35"
                    y2="35"
                    stroke="#fff"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                  ></line>
                  <line
                    x1="35"
                    y1="5"
                    x2="5"
                    y2="35"
                    stroke="#fff"
                    stroke-width="5"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                  ></line>
                </svg>
              </button>
            </div>

            <p className="back-btn group2" onClick={this.goBack}>
              <img className="back-img" src={small_left}></img>Back
            </p>
            <div>
              <p className="grp-heading">My Groups</p>
              {/* <hr></hr> */}
              {this.props.user_j_groups.map(({ name, _id }) => {
                return (
                  <Fragment>
                    <div
                      className={
                        this.state.current_gid == _id
                          ? "g-name-wrapper active"
                          : "g-name-wrapper"
                      }
                      onClick={() => this.changeGroup(_id)}
                    >
                      <p
                        className={
                          this.state.current_gid == _id ? "g-name" : "g-name"
                        }
                      >
                        {name}
                      </p>
                    </div>
                    {/* <p className={this.state.current_gid == _id ? "grp-name active" : "grp-name" }>{name}</p> */}
                    {/* <hr></hr> */}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  show_sidebar_mb: state.utils.show_sidebar_mb,
  current_tab: state.utils.current_tab,
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  gd_is_back: state.j_grp.gd_is_back,
  gsetting_is_back: state.j_grp.gsetting_is_back,
  brand: state.brand?.brand || state.course.brand,
  is_data_loading: state.utils.is_data_loading,
});

const mapDispatchToProps = {
  setSidebar,
  showSidebarMB,
  getProfileInfo,
  fetchGroups,
  setSelectedGroupDetails,
  setGDBack,
  resetGDBack,
  setGSettingBack,
  resetGSettingBack,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupSidebar)
);

// const mapStateToProps = (state) => (
// 	{
// 		profiles		: state.profile.profiles,
// 		default_pid		: state.profile.default_pid,
// 		user_j_groups   : state.j_grp.groups,
// 	}
// )

// const mapDispatchToProps = {
//     getProfileInfo,
// 	fetchGroups,
// }

// export default withRouter(connect(
// 	mapStateToProps,
// 	mapDispatchToProps,
// )(MyGroup));
