import React, { Component } from "react";
import Footer from "../footers/Footer";
import { fetchExploreData } from "../../actions/exploreAction.js";
import ExploreCourseAndGroup from "./ExploreCourseAndGroup";
import { connect } from "react-redux";
import RunningCourseCarousel from "../carousels/RunningCourseCarousel";
import FinanceCourseCarousel from "../carousels/RunningCourseCarousel";
import FreeCourseCarousel from "../carousels/FreeCourseCarousel";
import TrendingCourseCarousel from "../carousels/TrendingCourseCarousel";
import CourseScroller from "../scrollers/CourseScroller";
import GroupScroller from "../scrollers/GroupScroller";
import { setPageHeading } from "../../actions/utilAction";
import { EXPLORE_SECTIONS, PAGE_HEADINGS } from "../../constants/constants.js";
import JoinGroupModal from "../modals/JoinGroupModal";
import { toggleJoinGroupModal } from "../../actions/joinGroupAction.js";
import FeaturedGroupCarousel from "../carousels/FeaturedGroupCarousel";
import TrendingGroupCarousel from "../carousels/TrendingGroupCarousel";
import PoweredByImarticusFooter from "../footers/PoweredByImarticusFooter";

class Explore extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setPageHeading(PAGE_HEADINGS.EXPLORE);
    this.props.fetchExploreData();
  }
  render() {
    if (this.props.is_gcode_valid) {
      this.props.toggleJoinGroupModal(true);
    }
    return (
      <div className="dashboard-content-wrapper">
        <div className="container-fluid dashboard-content">
          {/* <div className="row"> */}
          <div
            id="explore_wrapper_id"
            className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
          >
            <p className="content-header">Explore</p>
          </div>
          {/* </div> */}
          {/* <div className="row"> */}
          <div className="ex-sm-hide explore-container">
            <div className="container explore-wrapper"></div>
            <FreeCourseCarousel ref_id={"explore_wrapper_id"} />
            <div className="mb-60"></div>
            <RunningCourseCarousel ref_id={"explore_wrapper_id"} />
            {/* <div className="mb-60"></div> */}
            {/* <FinanceCourseCarousel ref_id={"explore_wrapper_id"}/>
						<div className="mb-60"></div> */}
            {/* <TrendingCourseCarousel ref_id={"explore_wrapper_id"}/> */}
            {this.props.brand &&
              this.props.brand.poweredByEnabled == true &&
              this.props.brand.hyperlinkEnabled == true && (
                <div
                  className="powered-by-container"
                  style={{ right: 0, bottom: "24px" }}
                >
                  <a className="powered-by" href={"https://www.imarticus.org"}>
                    Powered By Imarticus Learning
                  </a>
                </div>
              )}
            {this.props.brand &&
              this.props.brand.poweredByEnabled == true &&
              this.props.brand.hyperlinkEnabled == false && (
                <div
                  className="powered-by-container"
                  style={{ bottom: "24px" }}
                >
                  <div className="powered-by">
                    Powered By Imarticus Learning
                  </div>
                </div>
              )}

            {/* Hide featured and trending group */}
            {/*
							<FeaturedGroupCarousel ref_id={"explore_wrapper_id"}/>
							<div className="mb-60"></div>
							<TrendingGroupCarousel ref_id={"explore_wrapper_id"}/>
							</div>
						*/}
          </div>
        </div>

        {/* Explore screen for mobile (scrollers) */}
        <CourseScroller
          heading={"Free Courses"}
          data={this.props.fr_crs}
          section={EXPLORE_SECTIONS.FREE_COURSES}
        />
        <CourseScroller
          heading={"Premium Programs"}
          data={this.props.rn_crs}
          section={EXPLORE_SECTIONS.RUNNING_COURSES}
        />
        {/* <CourseScroller heading={'Finance Courses'} data={this.props.fn_crs}  section={EXPLORE_SECTIONS.FINANCE_COURSES}/>
					<CourseScroller heading={'Technology Courses'} data={this.props.tn_crs} section={EXPLORE_SECTIONS.TECHNOLOGY_COURSES}/>
					<CourseScroller heading={'Analytics Courses'} data={this.props.an_crs}  section={EXPLORE_SECTIONS.ANALYTICS_COURSES}/>
					<CourseScroller heading={'Marketing Courses'} data={this.props.mn_crs} section={EXPLORE_SECTIONS.MARKETING_COURSES}/> */}
        {/* <CourseScroller heading={'Trending Courses'} data={this.props.tr_crs} section={EXPLORE_SECTIONS.TRENDING_COURSES}/> */}
        {/*  Hide featured and trending group */}
        {/*
						<GroupScroller heading={'Featured Groups'} data={this.props.tr_grp} section={EXPLORE_SECTIONS.FEATURED_GROUPS}/>
						<GroupScroller heading={'Trending Groups'} data={this.props.tr_grp} section={EXPLORE_SECTIONS.TRENDING_GROUPS}/>
					*/}
        <JoinGroupModal />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tr_crs: state.explore.tr_crs,
  fr_crs: state.explore.fr_crs,
  ft_grp: state.explore.ft_grp,
  tr_grp: state.explore.tr_grp,
  toggle_j_grp_modal: state.j_grp.toggle_j_grp_modal,
  is_gcode_valid: state.j_grp.is_gcode_valid,
  rn_crs: state.explore.rn_crs,
  fn_crs: state.explore.fn_crs,
  tn_crs: state.explore.tn_crs,
  an_crs: state.explore.an_crs,
  mn_crs: state.explore.mn_crs,
  brand: state.brand?.brand || state.course.brand,
});

const mapDispatchToProps = {
  fetchExploreData,
  toggleJoinGroupModal,
  setPageHeading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Explore);
