import axios from "axios";
import cookie from "react-cookies";
import Cookies from "universal-cookie";
import { ROUTES } from "../constants/routes";
import { CONFIGS } from "../constants/constants";
import {
  BACKEND_ERRORS,
  BACKEND_ERROR_CODES,
  ERROR_MESSAGES,
  FRONTEND_ERRORS,
} from "../constants/errors";
import { utils } from "../utility/utils";
import { renewAccessToken } from "./utilAction";

const cookies = new Cookies();

export const toggleJoinGroupModal = (value) => (dispatch) => {
  dispatch({
    type: "TOGGLE_GRP_JOIN_MODAL",
    payload: {
      value,
    },
  });
};

export const redirectToCourseDashboardWithoutJoining =
  (gid, crs_id, is_adpt, crs_pid) => (dispatch) => {
    if (is_adpt) {
      var url =
        CONFIGS.ADAPTIVE_WEB_ENDPOINT +
        "#/?pid=" +
        utils.getPid() +
        "&gid=" +
        gid +
        "&cid=" +
        crs_id;
    } else {
      var url =
        CONFIGS.WEB_DASHBOARD_BASE_URL +
        "batch/" +
        crs_pid +
        "/" +
        gid +
        "/" +
        crs_id;
    }
    var win = window.open(url, "_self");
    win.focus();
  };

export const redirectToCourseDashboard =
  (gcode, groups, gid, crs_id, is_adpt) => (dispatch) => {
    var data = {
      code: gcode,
      p_id: utils.getPid(),
      tokn: cookies.get("at"),
    };
    axios
      .post(ROUTES.JOINGROUP, data)
      .then((jgrp_info) => {
        var res = jgrp_info.data.data;
        var joined_grp = res.Group;
        var action = {};
        action.type = "IS_GCODE_VALID";
        action.is_valid = false;
        dispatch(action);
        action = {};
        action.type = "TOGGLE_GRP_JOIN_MODAL";
        action.payload = {};
        action.payload.value = false;
        dispatch(action);

        var grp_to_save = {};
        var updated_groups = [];
        grp_to_save.act = joined_grp.act;
        grp_to_save.cat = joined_grp.createdAt;
        grp_to_save.code = joined_grp.code;
        grp_to_save.gpic = joined_grp.gpic;
        grp_to_save.gtyp = joined_grp.gtyp;
        grp_to_save.i_brd = joined_grp.i_brd;
        grp_to_save.name = joined_grp.name;
        grp_to_save.plgn = joined_grp.plgn;
        grp_to_save._id = joined_grp._id;

        for (var i = 0; i < groups.length; i++) {
          updated_groups.push(groups[i]);
        }
        updated_groups.push(grp_to_save);
        updated_groups.sort((a, b) => a.name.localeCompare(b.name));

        action = {};
        action.type = "UPDATE_USER_GROUPS";
        action.groups = updated_groups;
        dispatch(action);
        console.log("\n\n", { is_adpt });
        if (is_adpt) {
          var url =
            CONFIGS.ADAPTIVE_WEB_ENDPOINT +
            "#/?pid=" +
            utils.getPid() +
            "&gid=" +
            gid +
            "&cid=" +
            crs_id;
        } else {
          var url =
            CONFIGS.WEB_DASHBOARD_BASE_URL +
            "batch/" +
            utils.getPid() +
            "/" +
            gid +
            "/" +
            crs_id +
            "/undefined";
        }
        console.log({ url });
        window.location.href = url;
        // var win = window.open(url, "_blank");
        // win.focus();
      })
      .catch((err) => {
        var err_obj = {};
        if (
          err.response &&
          err.response.data.code == BACKEND_ERROR_CODES.GROUP_NOT_FOUND
        ) {
          err_obj.form_incomplete = true;
          err_obj.err_code = BACKEND_ERROR_CODES.GROUP_NOT_FOUND;
          err_obj.err_msg = BACKEND_ERRORS[BACKEND_ERROR_CODES.GROUP_NOT_FOUND];
          err_obj.type = "IS_GCODE_VALID";
          err_obj.is_valid = false;
          dispatch(err_obj);
        } else {
          utils.handleErrors(err, dispatch);
        }
      });
  };

export const getGroupInfo = (code, crs_id, is_adpt) => (dispatch) => {
  var g_info_route = ROUTES.G_INFO + "?code=" + code + "&pid=" + utils.getPid();
  let config = {
    headers: {
      "x-access-token": cookies.get("at"),
      "Content-Type": "application/json",
    },
  };
  axios
    .get(g_info_route, config)
    .then((grp_info) => {
      var gcodeObj = {};
      gcodeObj.is_valid = true;
      gcodeObj.g_code = code;
      gcodeObj.g_id = grp_info.data.data.gid;
      gcodeObj.g_name = grp_info.data.data.name;
      gcodeObj.g_pic_url = grp_info.data.data.gpic;
      gcodeObj.g_members = utils.convertMemberCountToWords(
        grp_info.data.data.g_mem
      );
      gcodeObj.form_incomplete = false;
      gcodeObj.err_code = "";
      gcodeObj.err_msg = "";
      gcodeObj.selected_crs_id = crs_id;
      gcodeObj.selected_crs_is_adpt = is_adpt;
      gcodeObj.type = "IS_GCODE_VALID";
      gcodeObj.is_member = grp_info.data.data.is_member;
      dispatch(gcodeObj);
    })
    .catch((err) => {
      var err_obj = {};
      if (
        err.response &&
        err.response.data.code == BACKEND_ERROR_CODES.GROUP_NOT_FOUND
      ) {
        err_obj.form_incomplete = true;
        err_obj.err_code = BACKEND_ERROR_CODES.GROUP_NOT_FOUND;
        err_obj.err_msg = BACKEND_ERRORS[BACKEND_ERROR_CODES.GROUP_NOT_FOUND];
        err_obj.type = "IS_GCODE_VALID";
        err_obj.is_valid = false;
        dispatch(err_obj);
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

export const openJoinGroupModal = (grp) => (dispatch) => {
  // var action = {};
  // action.type = "OPEN_JOIN_GRP_MODAL";
  // action.toggle_j_grp_modal =  true,
  // action.g_name = grp.name,
  // action.g_pic_url: grp.gpic,
  // action.g_members: action.g_members,
  // is_member: action.is_member,
  // g_code: action.g_code,
};
export const redirectToGroupPageWithoutJoining =
  (g_id, groups) => (dispatch) => {
    var action = {};
    action.groups = groups;
    action.type = "SET_JOIN_GRP_URL";
    action.gd_url = {};
    action.gd_url.path = "/dashboard/group-detail";
    action.gd_url.gid = g_id;
    dispatch(action);
  };
export const redirectToGroupPage = (gcode, groups) => (dispatch) => {
  var j_grp_route = ROUTES.JOINGROUP;
  var data = {
    code: gcode,
    p_id: utils.getPid(),
    tokn: cookies.get("at"),
  };
  axios
    .post(j_grp_route, data)
    .then((jgrp_info) => {
      var res = jgrp_info.data.data;
      var joined_grp = res.Group;
      var grp_to_save = {};
      var updated_groups = [];
      grp_to_save.act = joined_grp.act;
      grp_to_save.cat = joined_grp.createdAt;
      grp_to_save.code = joined_grp.code;
      grp_to_save.gpic = joined_grp.gpic;
      grp_to_save.gtyp = joined_grp.gtyp;
      grp_to_save.i_brd = joined_grp.i_brd;
      grp_to_save.name = joined_grp.name;
      grp_to_save.plgn = joined_grp.plgn;
      grp_to_save._id = joined_grp._id;

      for (var i = 0; i < groups.length; i++) {
        updated_groups.push(groups[i]);
      }
      updated_groups.push(grp_to_save);
      updated_groups.sort((a, b) => a.name.localeCompare(b.name));
      var actionObj = {};
      var g_id = res.Group._id;
      var g_code = res.Group.code;
      var p_id = res.GroupMember.pid;
      actionObj.groups = updated_groups;
      actionObj.type = "SET_JOIN_GRP_URL";
      actionObj.gd_url = {};
      actionObj.gd_url.path = "/dashboard/group-detail";
      actionObj.gd_url.gid = g_id;
      dispatch(actionObj);
    })
    .catch((err) => {
      var err_obj = {};
      if (
        err.response &&
        err.response.data.code == BACKEND_ERROR_CODES.GROUP_NOT_FOUND
      ) {
        err_obj.form_incomplete = true;
        err_obj.err_code = BACKEND_ERROR_CODES.GROUP_NOT_FOUND;
        err_obj.err_msg = BACKEND_ERRORS[BACKEND_ERROR_CODES.GROUP_NOT_FOUND];
        err_obj.type = "IS_GCODE_VALID";
        err_obj.is_valid = false;
        dispatch(err_obj);
      } else {
        utils.handleErrors(err, dispatch);
      }
    });
};

export const cancelJoining = () => (dispatch) => {
  dispatch({
    type: "CANCEL_JOINING",
  });
};

export const raiseCodeInvalidError =
  (form_incomplete, err_code, err_msg) => (dispatch) => {
    var err_obj = {};
    err_obj.is_valid = false;
    err_obj.form_incomplete = form_incomplete;
    err_obj.err_code = err_code;
    err_obj.err_msg = err_msg;
    err_obj.type = "IS_GCODE_VALID";
    dispatch(err_obj);
  };
