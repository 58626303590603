import React, { Component } from "react";
import Footer from "../footers/Footer";
import { connect } from "react-redux";
import {
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";
import LoadingModal from "../modals/LoadingModal";
import err_chat from "../../images/err-chat.svg";
import success from "../../images/success.svg";
import { PAGE_HEADINGS } from "../../constants/constants";
import { setPageHeading } from "../../actions/utilAction";
import Loader from "../utils/Loader";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import {
  getSecondaryEmails,
  resetPrimaryEmailActions,
  updateSecondaryEmailtoBackend,
  removeEmailfromBackend,
  verifyOtpFromBackend,
  getOtpCall,
  resendOtpFromBackend,
} from "../../actions/primaryEmailActions";
import EmailImg from "../../images/email-1.svg";
class EditEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      value: "please select email",
      emails: [],
      email_selected: false,
      is_otp_null: false,
      otp: "",
      timer: "0:00",
      time: 300,
      change_email: false,
      email: "",
      email_changed: false,
    };
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.dropdownUpdate = this.dropdownUpdate.bind(this);
  }
  componentDidUpdate(prev_props) {
    if (
      prev_props.otp_received != this.props.otp_received &&
      this.props.otp_received
    ) {
      this.setState({ otp_received: this.props.otp_received });
      setInterval(() => {
        var ms = this.state.time;
        var min = Math.floor((ms / 60) << 0);
        var sec = Math.floor(ms % 60);
        var time = this.state.time - 1;
        this.setState({ timer: min + ":" + sec, time: time });
      }, 1000);
    }
    if (
      prev_props.otp_verified != this.props.otp_verified &&
      this.props.otp_verified
    ) {
      this.setState({ otp_verified: this.props.otp_verified });
    }
    if (prev_props.emails != this.props.emails) {
      this.setState({ emails: this.props.emails });
    }
    if (prev_props.email_changed != this.props.email_changed) {
      this.setState({ email_changed: this.props.email_changed });
    }
  }
  componentDidMount() {
    this.props.getSecondaryEmails();
    this.props.setPageHeading(PAGE_HEADINGS.PROFILE_SETTINGS);
    this.setState({
      otp_received: this.props.otp_received,
      otp_verified: this.props.otp_verified,
      email_changed: this.props.email_changed,
    });
  }
  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };

  dropdownToggle() {
    let value = this.state.isOpen;
    this.setState({ isOpen: !value });
  }
  dropdownUpdate(val) {
    this.setState({ value: val, emailSelected: true });
  }
  removeEmail = () => {
    let mail = this.state.value;
    console.log("mail:", mail);
    if (mail === "please select email") {
      this.displayPopover(
        "OOPS",
        "please select an email first",
        POPOVER_TYPE.ERROR
      );
    } else {
      this.props.removeEmailfromBackend({ email: mail });
    }
  };
  changeEmail = () => {
    let mail = this.state.value;
    console.log("mail:", mail);
    if (mail === "please select email") {
      this.displayPopover(
        "OOPS!",
        "please select an email first",
        POPOVER_TYPE.ERROR
      );
    } else {
      this.setState({ change_email: true });
    }
  };
  setOTP = (value) => {
    this.setState({ otp: value, is_otp_null: false });
  };
  verifyOTP = () => {
    if (this.state.otp === "") {
      this.setState({ is_otp_null: true });
    } else {
      var otp_obj = {
        otp: this.state.otp,
        email: this.state.value,
      };
      this.props.verifyOtpFromBackend(otp_obj);
    }
  };
  getCall = () => {
    var otp_ob = {
      cl: "W",
      lng: "en",
      vrsn: "1.0.0",
    };
    this.props.getOtpCall(otp_ob);
  };

  resendOTP = () => {
    this.props.resendOtpFromBackend();
  };
  onEmailChange = (value) => {
    if (value === "") {
      this.displayPopover(
        POPOVER_HEADINGS.INVALID_EMAIL,
        POPOVER_CONTENT.INVALID_EMAIL,
        POPOVER_TYPE.ERROR
      );
      this.setState({ email: "" });
      value = "";
    } else {
      this.setState({ email: value });
    }
  };

  verifyEmailId = () => {
    let email = this.state.email;
    let old_email = this.state.value;
    if (email === "") {
      this.displayPopover(
        "OOPS!",
        "please add an email first.",
        POPOVER_TYPE.ERROR
      );
    } else {
      email = email.trim();
      var email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!email_regex.test(email)) {
        this.displayPopover(
          POPOVER_HEADINGS.INVALID_EMAIL,
          POPOVER_CONTENT.INVALID_EMAIL,
          POPOVER_TYPE.ERROR
        );
      } else {
        this.props.updateSecondaryEmailtoBackend(email, old_email);
      }
    }
  };

  finishClicked = () => {
    this.setState({
      isOpen: false,
      value: "please select email",
      email_selected: false,
      otp_received: false,
      otp_verified: false,
      is_otp_null: false,
      otp: "",
      timer: "0:00",
      time: 300,
      change_email: false,
      email: "",
      email_changed: false,
    });
    this.props.resetPrimaryEmailActions();
  };

  render() {
    if (!this.props.is_loading) {
      return (
        <div className="group-db-content-wrapper">
          <div className="container-fluid dashboard-content">
            {/* <div className="row tab-row"> */}
            <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <p className="content-header">Profile Settings</p>
            </div>
            {/* </div> */}
            {!this.state.email_changed ? (
              !this.state.change_email ? (
                !this.state.otp_verified ? (
                  !this.state.otp_received ? (
                    <div className=" profile-setting-container-row">
                      <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="row">
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                          <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <p className="chng-pwd-heading">
                              Change/Remove Email ID
                            </p>
                            <div className="pf-content-container">
                              <div className="ps-inp-container">
                                <Dropdown
                                  isOpen={this.state.isOpen}
                                  toggle={this.dropdownToggle}
                                >
                                  <DropdownToggle className="pe-cng-drpdwn">
                                    {this.state.value}
                                    {this.state.isOpen ? (
                                      <i className="fa fa-angle-up"></i>
                                    ) : (
                                      <i className="fa fa-angle-down"></i>
                                    )}
                                  </DropdownToggle>
                                  <DropdownMenu
                                    modifiers={{
                                      setMaxHeight: {
                                        enabled: true,
                                        order: 890,
                                        fn: (data) => {
                                          return {
                                            ...data,
                                            styles: {
                                              ...data.styles,
                                              overflow: "auto",
                                              fontFamily: "Source Sans Pro",
                                              maxHeight: "200px",
                                              minWidth: "90%",
                                            },
                                          };
                                        },
                                      },
                                    }}
                                  >
                                    {this.state.emails.map((vl, key) => (
                                      <DropdownItem
                                        key={key}
                                        onClick={() => this.dropdownUpdate(vl)}
                                      >
                                        {vl}
                                      </DropdownItem>
                                    ))}
                                  </DropdownMenu>
                                </Dropdown>
                                <p
                                  className={
                                    "epf-err-msg " +
                                    (this.state.is_email_null
                                      ? "err-msg"
                                      : "hide")
                                  }
                                >
                                  <img
                                    src={err_chat}
                                    className="err-icon"
                                  ></img>{" "}
                                  Email can't be empty!
                                </p>
                              </div>
                              <div className="ps-updt-mail">
                                <button
                                  className="btn btn-outline-danger ps-updt-mail-btn-1"
                                  onClick={this.removeEmail}
                                >
                                  {" "}
                                  Remove
                                </button>
                                <button
                                  className="btn btn-primary ps-updt-mail-btn"
                                  onClick={this.changeEmail}
                                >
                                  {" "}
                                  Change
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className=" profile-setting-container-row">
                      <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className="row">
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                          <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <p className="chng-pwd-heading">Verify OTP</p>
                            <div className="pf-content-container">
                              <div className="ps-inp-container">
                                <input
                                  placeholder="One Time Password"
                                  className={
                                    !this.state.is_otp_null
                                      ? "ps-inp"
                                      : "ps-inp err-inp"
                                  }
                                  type="text"
                                  value={this.state.otp}
                                  onChange={(e) => this.setOTP(e.target.value)}
                                ></input>
                                <div className="pwd-otp">
                                  {/* <span className='pwd-otp-gt-cl' onClick={this.getCall}> Get on call</span> */}
                                  <span className="pwd-otp-tmr">
                                    {" "}
                                    {this.state.timer}
                                  </span>
                                  <span
                                    className="pwd-otp-rs"
                                    onClick={this.resendOTP}
                                  >
                                    {" "}
                                    Resend OTP
                                  </span>
                                </div>
                                <p
                                  className={
                                    "epf-err-msg " +
                                    (this.state.get_call ? "info-msg" : "hide")
                                  }
                                >
                                  You Will Recieve Call Shortly!
                                </p>
                                <p
                                  className={
                                    "epf-err-msg " +
                                    (this.state.is_otp_null
                                      ? "err-msg"
                                      : "hide")
                                  }
                                >
                                  <img
                                    src={err_chat}
                                    className="err-icon"
                                  ></img>{" "}
                                  OTP can't be empty!
                                </p>
                              </div>
                              <input
                                className="ps-update-btn"
                                type="button"
                                onClick={this.verifyOTP}
                                value="Verify OTP"
                              ></input>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                        </div>
                      </div>
                    </div>
                  )
                ) : (
                  <div className=" profile-setting-container-row">
                    <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                        <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                          <p className="chng-pwd-heading">
                            <img src={success} className="pwd-scs" />
                            <br />
                            Awesome!
                          </p>

                          <div className="pf-content-container">
                            <div className="ps-inp-container">
                              <div className="pwd-msg-sc">
                                Your Email has been removed successfully.
                              </div>
                              <Button
                                className="sc-rm-fnsh"
                                color="primary"
                                onClick={this.finishClicked}
                              >
                                Finish
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className=" profile-setting-container-row">
                  <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="row">
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                      <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <p className="chng-pwd-heading">Change Email ID</p>
                        <div className="pf-content-container">
                          <div className="ps-inp-container">
                            <img className="pe-img" src={EmailImg} />
                            <input
                              placeholder="Enter New Email ID"
                              className={
                                !this.state.is_otp_null
                                  ? "ps-inp"
                                  : "ps-inp err-inp"
                              }
                              type="text"
                              value={this.state.email}
                              onChange={(e) =>
                                this.onEmailChange(e.target.value)
                              }
                            ></input>
                            <p
                              className={
                                "epf-err-msg " +
                                (this.state.is_otp_null ? "err-msg" : "hide")
                              }
                            >
                              <img src={err_chat} className="err-icon"></img>{" "}
                              Email can't be empty!
                            </p>
                          </div>
                          <input
                            className="ps-update-btn"
                            type="button"
                            onClick={this.verifyEmailId}
                            value="Verify Email ID"
                          ></input>
                        </div>
                      </div>
                      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                    <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <p className="chng-pwd-heading">
                        <img src={success} className="pwd-scs" />
                        <br />
                        Awesome!
                      </p>

                      <div className="pf-content-container">
                        <div className="ps-inp-container">
                          <div className="pwd-msg-sc">
                            Your Email ID has been changed successfully.
                          </div>
                          <Button
                            className="sc-rm-fnsh"
                            color="primary"
                            onClick={this.finishClicked}
                          >
                            Finish
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                  </div>
                </div>
              </div>
            )}

            <LoadingModal />
          </div>
          <Footer />
        </div>
      );
    } else {
      return <Loader />;
    }
  }
}

const mapStateToProps = (state) => ({
  otp_received: state.primaryEmail.otp_received,
  otp_verified: state.primaryEmail.otp_verified,
  email_changed: state.primaryEmail.secondary_email_changed,
  emails: state.primaryEmail.secondary_emails,
  is_loading: state.primaryEmail.is_loading,
});

const mapDispatchToProps = {
  removeEmailfromBackend,
  resetPrimaryEmailActions,
  updateSecondaryEmailtoBackend,
  getSecondaryEmails,
  verifyOtpFromBackend,
  getOtpCall,
  resendOtpFromBackend,
  showPopover,
  setPageHeading,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditEmail);
