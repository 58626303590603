import { Link } from "react-router-dom";
import { Modal } from "reactstrap";

const BlockPlacement = ({ blockPlacement, setBlockPlacement }) => {
  return (
    <div>
      <Modal
        isOpen={blockPlacement}
        size={"l"}
        centered
        style={{ fontFamily: "Source Sans Pro" }}
      >
        <div
          style={{
            padding: "2rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <span></span>
            <span>
              {/* <img
                src="https://cdn.pegasus.imarticus.org/courses/images/minimise.svg"
                alt="close"
                onClick={() => {
                  setBlockPlacement(false);
                }}
              /> */}
            </span>
          </div>

          <div
            style={{
              color: "#A09FA0",
              textTransform: "uppercase",
              fontSize: "24px",
              fontWeight: "700",
            }}
          >
            Your Placement access is locked
          </div>

          <img
            style={{
              marginTop: "2rem",
            }}
            src="https://cdn.pegasus.imarticus.org/courses/images/blockcrs.svg"
            alt="pic"
          ></img>

          <div style={{ textAlign: "center", padding: "1rem" }}>
            Since you declined the agreement, you will not get access to our
            placement services. Contact your LG or change your response from the
            Agreement Center to regain access.
          </div>
          <Link to="/dashboard/agreementcenter">
            <button
              style={{
                background: "#035642",
                padding: "0.4rem 1.5rem",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Go to Agreement Center
            </button>
          </Link>
        </div>
      </Modal>
    </div>
  );
};

export default BlockPlacement;
