import React, { Component } from "react";
import Footer from "../footers/Footer";
import ImageCrop from "../utils/ImageCrop";
// import Cp from '../utils/croppie';
import { connect } from "react-redux";
import {
  updatePicURL,
  updateProfileDetailsToBackend,
} from "../../actions/profileAction";
import EditProfileModal from "../modals/EditProfileModal";
import {
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
  URLS,
  PIC_TYPE,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";
import LoadingModal from "../modals/LoadingModal";
import err_chat from "../../images/err-chat.svg";
import pencil from "../../images/pencil.svg";
import DeletePhotoModal from "../modals/DeletePhotoModal";
import DeletePGModal from "../modals/DeletePGModal";
import { PAGE_HEADINGS } from "../../constants/constants";
import { setPageHeading } from "../../actions/utilAction";
import {
  WebEngageDataLayer,
  WebEngageRegisteredEvents,
} from "../../services/helper";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      img_src: null,
      open_edit_modal: false,
      p_name: "",
      current_ppic: URLS.DEFAULT_PPIC_URL,
      is_pname_null: false,
      is_dpm_open: false,
    };
  }
  choosePic = () => {
    // document.getElementById("edit-file").click();
  };
  componentDidUpdate(prev_props) {
    if (prev_props.ppic != this.props.ppic && this.props.ppic) {
      this.props.updatePicURL(this.props.ppic);
      // this.setState({
      //     current_ppic : this.props.ppic
      // })
    }
    if (prev_props.p_name != this.props.p_name && this.props.p_name) {
      this.setState({
        p_name: this.props.p_name,
      });
    }
  }
  componentDidMount() {
    this.props.setPageHeading(PAGE_HEADINGS.PROFILE_SETTINGS);
    if (this.props.ppic) {
      this.props.updatePicURL(this.props.ppic);
      this.setState({
        p_name: this.props.p_name,
      });
    }
  }
  toggleDeletePhotoModal = () => {
    this.setState({
      is_dpm_open: !this.state.is_dpm_open,
    });
  };
  toggleDeletePGModal = () => {
    this.setState({
      is_dpgm_open: !this.state.is_dpgm_open,
    });
  };
  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };
  toggleEditModal = () => {
    this.setState({
      open_edit_modal: !this.state.open_edit_modal,
    });
  };

  updateProfileDetails = () => {
    var update_obj = {};
    var current_name = this.profileNameToSave();
    if (!current_name || current_name == "") {
      this.setState({
        is_pname_null: true,
      });
      return;
    }
    if (current_name != this.props.p_name) {
      update_obj.name = current_name;
    }
    if (this.props.edit_ppic != this.props.ppic) {
      update_obj.ppic = this.props.edit_ppic;
      update_obj.ppic_ext = this.props.ppic_ext;
      // update_obj.ppic_file = this.props.edit_ppic_file;
    }
    if (Object.keys(update_obj).length > 0) {
      this.props.updateProfileDetailsToBackend(update_obj, this.props.profiles);
      console.log(this.props);
      let data = {
        name:
          current_name != this.props.p_name ? current_name : this.props.p_name,
        email: this.props.email,
        mobile: this.props.contact,
        timeZone: this.props.time_zone,
      };
      WebEngageDataLayer(data, WebEngageRegisteredEvents.PROFILEUPDATED);
    }
  };

  profileNameToSave = () => {
    var name = this.state.p_name.trim();
    var temp = name.split(" ");
    name = "";
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].length > 0 && temp[i] != " ") {
        name = name + temp[i].trim() + " ";
      }
    }
    name = name.trim();
    return name;
  };

  convertDataURIToBlob = (dataURI) => {
    const type = dataURI.split(";")[0].split("/")[1];
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {}
    array.push(binary.charCodeAt(i));
    return new Blob([new Uint8Array(array)], { type: "image/" + type });
  };

  setProfileName = (e) => {
    if (this.validateProfileName(e.target.value)) {
      this.setState({
        p_name: e.target.value,
        is_pname_null: false,
      });
    }
  };
  validateProfileName = (name) => {
    var special_char_format = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]/;
    if (name && !isNaN(name)) {
      this.displayPopover(
        POPOVER_HEADINGS.NAME_START_WITH_NUM,
        POPOVER_CONTENT.NAME_START_WITH_NUM,
        POPOVER_TYPE.ERROR
      );
      return false;
    } else if (name.includes(" .") || name.includes("..") || name == ".") {
      this.displayPopover(
        POPOVER_HEADINGS.INVALID_NAME,
        POPOVER_CONTENT.INVALID_NAME,
        POPOVER_TYPE.ERROR
      );
      return false;
    } else if (name.length > 50) {
      this.displayPopover(
        POPOVER_HEADINGS.P_NAME_LENGTH_EXCEEDED,
        POPOVER_CONTENT.P_NAME_LENGTH_EXCEEDED,
        POPOVER_TYPE.ERROR
      );
      return false;
    } else if (special_char_format.test(name)) {
      // alert("special character")
      this.displayPopover(
        POPOVER_HEADINGS.SPECIAL_CHARACTER,
        POPOVER_CONTENT.SPECIAL_CHARACTER,
        POPOVER_TYPE.ERROR
      );
      return false;
    }
    return true;
  };

  render() {
    return (
      <div className="group-db-content-wrapper">
        <div className="container-fluid dashboard-content">
          {/* <div className="row tab-row"> */}
          <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <p className="content-header">Profile Settings</p>
          </div>
          {/* </div> */}
          <form>
            <div className=" profile-setting-container-row">
              <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="row">
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                  <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                    <p className="edit-profile-heading">Edit Profile</p>
                    <div className="img-container">
                      <img
                        onClick={this.toggleEditModal}
                        className="profile-img"
                        src={this.props.edit_ppic}
                      ></img>
                      <div className="edit-img-container">
                        <div
                          className="edit-img"
                          onClick={this.toggleEditModal}
                        >
                          <img src={pencil} />
                        </div>
                      </div>
                    </div>
                    <div className="pf-content-container">
                      <p className="pf-name">Profile Name</p>
                      <div className="ps-inp-container">
                        <input
                          className={
                            !this.state.is_pname_null
                              ? "ps-inp"
                              : "ps-inp err-inp"
                          }
                          type="text"
                          value={this.state.p_name}
                          onChange={(e) => this.setProfileName(e)}
                        ></input>
                        <p
                          className={
                            "epf-err-msg " +
                            (this.state.is_pname_null ? "err-msg" : "hide")
                          }
                        >
                          <img src={err_chat} className="err-icon"></img>{" "}
                          Profile Name can't be empty!
                        </p>
                      </div>
                      <input
                        className="ps-update-btn"
                        type="button"
                        onClick={this.updateProfileDetails}
                        value="Save Details"
                      ></input>
                      {/* <input className="dp-btn" onClick={this.toggleDeletePGModal} type="button"  value="Delete Profile"></input> */}
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                </div>
              </div>
            </div>
            <LoadingModal />
            {/* <div className="test1"><img src={this.state.url}></img></div> */}
            {/* <input type="file" onChange= {this.picSelected}  id="file" name="file"></input> */}
            {/* <ImageCrop img_src={this.props.edit_ppic}/> */}
            {/* <Resize/> */}
            {/* <Cp/> */}
            <EditProfileModal
              open={this.state.open_edit_modal}
              toggleEditModal={this.toggleEditModal}
              toggleDeletePhotoModal={this.toggleDeletePhotoModal}
              is_dpm_open={this.state.is_dpm_open}
              is_dpgm_open={this.state.is_dpgm_open}
              current_ppic={this.state.current_ppic}
            />

            <DeletePhotoModal
              is_dpm_open={this.state.is_dpm_open}
              toggleEditModal={this.toggleEditModal}
              pic_type={PIC_TYPE.PROFILE_PIC}
              toggleDeletePhotoModal={this.toggleDeletePhotoModal}
            />

            <DeletePGModal
              pic_type={PIC_TYPE.PROFILE_PIC}
              is_dpgm_open={this.state.is_dpgm_open}
              toggleDeletePGModal={this.toggleDeletePGModal}
            />

            {/* <Croppie url="https://cdn.pegasus.imarticus.org/images/Full-Stack.png"/> */}
          </form>
        </div>
        <Footer />
      </div>
    );
  }
}

// export default EditProfile;

const mapStateToProps = (state) => ({
  show_menu: state.ac_dd.show_menu,
  profiles: state.profile.profiles,
  p_name: state.profile.p_name,
  ppic: state.profile.ppic,
  edit_ppic: state.profile.edit_ppic,
  profile_map: state.profile.profile_map,
  ppic_ext: state.profile.ppic_ext,
  // edit_ppic_file : state.profile.edit_ppic_file,
  email: state.profile.email,
  contact: state.profile.contact,
  time_zone: state.profile.tz,
});

const mapDispatchToProps = {
  updatePicURL,
  updateProfileDetailsToBackend,
  showPopover,
  setPageHeading,
  // showMenu,
  // logoutUser,
  // switchUserProfile
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
