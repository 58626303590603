import React, { Component, Fragment } from "react";
// import prev_btn from "../../images/carousel-prev.svg";
// import next_btn from "../../images/carousel-next.svg";
// import prev_btn_d from "../../images/carousel-prev-d.svg";
// import next_btn_d from "../../images/carousel-next-d.svg";
import CardHeading from "../utils/CardHeading";
import { connect } from "react-redux";
import { setFreeCoursesToRender } from "../../actions/exploreAction";
import { RESOLUTION_MODES } from "../../constants/constants.js";
import CourseCarouselSS from "../skeletonscreens/CourseCarouselSS";
import course_default from "../../images/course-default.svg";
import {
  toggleJoinGroupModal,
  getGroupInfo,
} from "../../actions/joinGroupAction.js";

let prev_btn = "https://cdn.pegasus.imarticus.org/index/carouselNext.svg",
  next_btn = "https://cdn.pegasus.imarticus.org/index/carouselNext.svg",
  prev_btn_d =
    "https://cdn.pegasus.imarticus.org/index/carouselNextDisabled.svg",
  next_btn_d =
    "https://cdn.pegasus.imarticus.org/index/carouselNextDisabled.svg";
class TrendingCourseCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      width_to_scroll: "",
      // length: 4,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    if (document.getElementById(this.props.ref_id)) {
      var join_grp_container_width = document.getElementById(
        this.props.ref_id
      ).offsetWidth;
      this.setState({
        width_to_scroll: join_grp_container_width + 26,
      });
    }
  }

  componentDidUpdate(prev_props) {
    if (prev_props.fr_crs_to_render != this.props.fr_crs_to_render) {
      this.resize();
    }
    if (prev_props.fr_crs != this.props.fr_crs) {
      if (
        window.innerWidth > 700 &&
        window.innerWidth < 1140 &&
        this.state.mode != RESOLUTION_MODES.TABLET
      ) {
        //code to change the data in 3 3 pairs
        this.mapTrendingGroupsForTablet();
      } else if (
        window.innerWidth >= 1140 &&
        this.state.mode != RESOLUTION_MODES.WEB
      ) {
        //code to change the data in 4 4 pairs
        this.mapTrendingGroupsForWeb();
      } else if (window.innerWidth <= 700) {
        this.mapTrendingGroupsForMobile();
      }
    }
  }

  redirectToCoursePage = (u_tag) => {
    if (u_tag) {
      // var crs_url = CONFIGS.COURSE_PAGE_BASE_URL + u_tag;
      var crs_url = u_tag;
      // alert(crs_url);
      var win = window.open(crs_url);
      win.focus();
    }
  };

  mapTrendingGroupsForWeb = () => {
    var fr_crs = this.props.fr_crs;
    // [ [{},{},{},{}], [{},{},{},{}], [{},{},{},{}] ]
    var fr_crs_to_render = [];
    var crs_in_section = 0;
    var section = [];
    for (var i = 0; i < fr_crs.length; i++) {
      if (crs_in_section == 0 || crs_in_section == 1 || crs_in_section == 2) {
        section.push(fr_crs[i]);
        crs_in_section++;
      } else {
        section.push(fr_crs[i]);
        fr_crs_to_render.push(section);
        crs_in_section = 0;
        section = [];
      }
      if (i == fr_crs.length - 1 && crs_in_section != 0) {
        fr_crs_to_render.push(section);
        section = [];
      }
    }
    this.props.setFreeCoursesToRender(fr_crs_to_render);
  };

  mapTrendingGroupsForTablet = () => {
    var fr_crs = this.props.fr_crs;
    // [ [{},{},{}], [{},{},{}], [{},{},{}] ]
    var fr_crs_to_render = [];
    var crs_in_section = 0;
    var section = [];
    for (var i = 0; i < fr_crs.length; i++) {
      if (crs_in_section == 0 || crs_in_section == 1) {
        section.push(fr_crs[i]);
        crs_in_section++;
      } else {
        section.push(fr_crs[i]);
        fr_crs_to_render.push(section);
        crs_in_section = 0;
        section = [];
      }
      if (i == fr_crs.length - 1 && crs_in_section != 0) {
        fr_crs_to_render.push(section);
        section = [];
      }
    }
    this.props.setFreeCoursesToRender(fr_crs_to_render);
  };

  mapTrendingGroupsForMobile = () => {
    var fr_crs = this.props.fr_crs;
    // [ [{},{}], [{},{}], [{},{}] ]
    var fr_crs_to_render = [];
    var crs_in_section = 0;
    var section = [];
    for (var i = 0; i < fr_crs.length; i++) {
      if (crs_in_section == 0) {
        section.push(fr_crs[i]);
        crs_in_section++;
      } else {
        section.push(fr_crs[i]);
        fr_crs_to_render.push(section);
        crs_in_section = 0;
        section = [];
      }
      if (i == fr_crs.length - 1 && crs_in_section != 0) {
        fr_crs_to_render.push(section);
        section = [];
      }
    }
    this.props.setFreeCoursesToRender(fr_crs_to_render);
  };

  resize() {
    // var g_elem =  document.getElementById('grp_container_id');
    // var cr_elem = document.getElementById('carousel_sec_container_id');
    // var elems = document.getElementsByClassName("carousel-sec-container");
    if (document.getElementById(this.props.ref_id)) {
      var join_grp_container_width = document.getElementById(
        this.props.ref_id
      ).offsetWidth;

      this.setState({
        width_to_scroll: join_grp_container_width + 26,
        count: 0,
      });
      var join_grp_container_width = document.getElementById(
        this.props.ref_id
      ).offsetWidth;
      var sec_elems = document.getElementsByClassName("carousel-sec-container");
      for (var i = 0; i < sec_elems.length; i++) {
        sec_elems[i].style.width = join_grp_container_width + 26 + "px";
      }
      var car_container = document.getElementById("fc_carousel_container_id");
      if (car_container) {
        car_container.style.marginLeft = 0 + "px";
      }
    }
  }

  showImg = (e) => {
    e.target.style.display = "block";
  };

  joinOrViewGroup = (code) => {
    this.props.getGroupInfo(code);
  };

  nextSlide = () => {
    var car_container = document.getElementById("fc_carousel_container_id");

    if (
      car_container &&
      Number.isNaN(parseInt(car_container.style.marginLeft))
    ) {
      car_container.style.marginLeft = 0 + "px";
    }
    if (
      car_container &&
      parseInt(car_container.style.marginLeft) - this.state.width_to_scroll !=
        car_container.offsetWidth * -1
    ) {
      car_container.style.marginLeft =
        parseInt(car_container.style.marginLeft) -
        this.state.width_to_scroll +
        "px";
      this.setState({
        count: this.state.count + 1,
      });
    }
  };

  prevSlide = () => {
    var car_container = document.getElementById("fc_carousel_container_id");
    if (
      car_container &&
      Number.isNaN(parseInt(car_container.style.marginLeft))
    ) {
      car_container.style.marginLeft = 0 + "px";
    }
    if (car_container && parseInt(car_container.style.marginLeft) != 0) {
      car_container.style.marginLeft =
        parseInt(car_container.style.marginLeft) +
        this.state.width_to_scroll +
        "px";
      this.setState({
        count: this.state.count - 1,
      });
    }
  };
  render() {
    if (this.props.fr_crs_to_render) {
      return (
        <Fragment>
          <div className="carousel-header">
            <p className="carousel-heading">Free Courses</p>
            <div
              className={
                this.state.count == 0 &&
                this.state.count == this.props.fr_crs_to_render.length - 1
                  ? "hide"
                  : "carousel-btn-container"
              }
            >
              <button
                onClick={this.prevSlide}
                disabled={this.state.count == 0 ? true : false}
                className="prev-btn"
              >
                <img
                  className="btn-src"
                  src={this.state.count == 0 ? prev_btn_d : prev_btn}
                ></img>
              </button>
              <button
                onClick={this.nextSlide}
                disabled={
                  this.state.count == this.props.fr_crs_to_render.length - 1
                    ? true
                    : false
                }
                className="next-btn"
              >
                <img
                  className="btn-src"
                  src={
                    this.state.count == this.props.fr_crs_to_render.length - 1
                      ? next_btn_d
                      : next_btn
                  }
                ></img>
              </button>
            </div>
          </div>
          <div className="carousel-wrapper">
            <div className="carousel-container" id="fc_carousel_container_id">
              {this.props.fr_crs_to_render.map((section, dump_index) => {
                return (
                  <div className="carousel-sec-container">
                    {section.map(
                      (
                        { gpic, name, size, code, rtg, rtng, u_tag },
                        section_index
                      ) => {
                        return (
                          <div className="carousel-card-container">
                            <div className="carousel-card">
                              <div
                                className="cc-cover"
                                onClick={() => this.redirectToCoursePage(u_tag)}
                              ></div>
                              <div className="cc-img-container">
                                <img
                                  onLoad={(e) => this.showImg(e)}
                                  className="cc-img"
                                  src={gpic ? gpic : course_default}
                                ></img>
                              </div>
                              <div className="card-content">
                                <div className="card-heading-container">
                                  <CardHeading text={name} />
                                </div>
                                <p className="card-enroll">
                                  {size}{" "}
                                  {size > 1 ? "Enrollments" : "Enrollment"}
                                </p>
                                <div className="rating-container">
                                  <div
                                    class="star-rating srtng_p"
                                    data-rating={rtng}
                                  ></div>
                                </div>
                                <div className="rating-text">{rtg} Ratings</div>
                                {/* <div className="card-btn-container"><button className="card-btn" onClick={() => this.joinOrViewGroup(code)}>Join Group</button></div> */}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <CourseCarouselSS heading={"Free Courses"} />;
    }
  }
}

const mapStateToProps = (state) => ({
  fr_crs: state.explore.fr_crs,
  fr_crs_to_render: state.explore.fr_crs_to_render,
});

const mapDispatchToProps = {
  // fetchExploreData,
  toggleJoinGroupModal,
  getGroupInfo,
  setFreeCoursesToRender,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrendingCourseCarousel);
