import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import blankProfile from "./img/blank_profile.png";
import uploadIcon from "./img/uploadIcon.svg";

export default function UploadDoc({
  PID,
  toggleUploadDoc,
  toggleUploadDocHandler,
  uploadDoc,
  handleUploadedDoc,
  uploadStatus,
  fileName,
}) {
  const handleChange = (event) => {
    console.log(event.target.files);
    if (event.target.files.length > 0) {
      handleUploadedDoc(event.target.files[0]);
    } else return false;
  };

  const docNameCheck = (docName) => {
    if (docName.length > 12) {
      let split = docName.split(".");
      let ext = split[split.length - 1];
      let toRenderName = docName.substring(0, 10);
      return toRenderName + "...." + ext;
    } else {
      return docName;
    }
  };
  return (
    <Modal
      isOpen={toggleUploadDoc}
      toggle={toggleUploadDocHandler}
      size={"sm"}
      style={{ width: "400px" }}
    >
      <ModalHeader toggle={toggleUploadDocHandler}>Upload Document</ModalHeader>
      <ModalBody
        style={{
          maxHeight: "400px",
          overflowY: "scroll",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <span class="select-wrapper">
          <input
            type="file"
            name="file"
            id="image_src"
            className="image_src"
            onChange={handleChange}
            accept=".pdf, .xlsx, .docx, .xml, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          />
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "basline",
          }}
        >
          <label
            htmlFor="image_src"
            style={{ alignSelf: "center", cursor: "pointer" }}
          >
            {fileName
              ? docNameCheck(fileName)
              : "Click me to upload a document!"}
          </label>
          <small> Max size: 1 MB</small>
        </div>
      </ModalBody>
      <ModalFooter style={{ justifyContent: "center" }}>
        {console.log("UPload status: ", uploadStatus)}
        <Button
          className={uploadStatus && "disableButton"}
          color={!uploadStatus || !fileName ? "primary" : "disabled"}
          onClick={uploadDoc}
        >
          {!uploadStatus || !fileName ? "Upload" : uploadStatus}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
