import { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { getAllAgreement, submitResponse } from "../../actions/courseAction";
import Loader from "../utils/Loader";
import RenderMenu from "../PDFReader/PDFReader";
import "./agreement.css";
import moment from "moment-timezone";

const Agreement = ({ crs_id, name, showAgreements, setShowAgreements }) => {
  const [loading, setLoading] = useState(false);
  const [agreements, setAgreements] = useState([]);
  const [curr, setCurr] = useState(1);
  const [active, setActive] = useState({});
  const [check, setCheck] = useState(false);
  const [remark, setRemark] = useState("");
  const [showDecline, setShowDecline] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [success, setSuccess] = useState(false);
  const [decline, setDecline] = useState(false);
  const [dec, setDec] = useState({});
  const [count, setCount] = useState(0);

  useEffect(() => {
    const getAgreement = async () => {
      setLoading(true);
      const response = await getAllAgreement({
        crs_id,
      });
      if (response.status === 200) {
        const value = response.data.data;
        setAgreements(value.agreements);
        setActive(value?.agreements[0]);
        setShowDecline(false);
        setCheck(false);

        let counting = 0;
        for (let i = 0; i < value.agreements?.length; i++) {
          if (value.agreements[i]?.response) {
            counting += 1;
          }
        }

        setCount(counting);
      } else {
        alert("Something went wrong");
      }
      setLoading(false);
    };

    getAgreement();
  }, [crs_id, trigger]);

  const submitHandler = async ({ isAccepted, elem }) => {
    setLoading(true);

    if (active && active.agreement_id) {
      const response = await submitResponse({
        agree_id: active.agreement_id,
        response: isAccepted,
        remark: remark,
      });
      if (response.status === 200) {
        console.log(response);
        setCheck(false);
        setDec(elem);
        if (isAccepted === 1) {
          setSuccess(true);
        } else {
          setDecline(true);
        }
        setTrigger(!trigger);
      } else {
        alert("Something went wrong");
      }
    } else {
      alert("Please select response");
    }

    setLoading(false);
  };

  return loading ? (
    <div className="ics_loader">
      <Loader />
    </div>
  ) : (
    <div>
      <Modal
        size="xl"
        style={{ zIndex: "1" }}
        isOpen={showAgreements}
        className="ics_modal_agreement"
      >
        <ModalBody>
          {agreements && agreements.length > 0 ? (
            <div style={{ fontFamily: "Source Sans Pro" }}>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#3C4852",
                }}
              >
                Sign Agreement ( {curr} of {agreements.length} )
              </div>

              <hr />

              <div className="ics_container_main">
                <div className="ics_top_tabs">
                  {agreements &&
                    agreements.map((elem, i) => {
                      return (
                        <div
                          key={i}
                          onClick={() => {
                            setActive(elem);
                            setCurr(i + 1);
                          }}
                          className={
                            active?.agreement_id === elem.agreement_id
                              ? "ics_tabs ics_active_tab"
                              : "ics_tabs"
                          }
                          style={
                            active?.response?.response === 1
                              ? { borderColor: "#28A745" }
                              : active?.response?.response === 2
                              ? { borderColor: "#DC3545" }
                              : {}
                          }
                        >
                          {elem?.response?.response === 1 ? (
                            <img
                              src="https://cdn.pegasus.imarticus.org/courses/images/greenTick.svg"
                              alt=""
                            />
                          ) : elem?.response?.response === 2 ? (
                            <img
                              src="https://cdn.pegasus.imarticus.org/courses/images/redCross.svg"
                              alt=""
                            />
                          ) : (
                            <img
                              src="https://cdn.pegasus.imarticus.org/courses/images/greyClock.svg"
                              alt=""
                            />
                          )}{" "}
                          &nbsp;
                          {elem?.response?.response === 1 ? (
                            <span
                              style={{
                                color: "#28A745",
                              }}
                            >
                              {elem.category?.name}
                            </span>
                          ) : elem?.response?.response === 2 ? (
                            <span
                              style={{
                                color: "#DC3545",
                              }}
                            >
                              {elem.category?.name}
                            </span>
                          ) : (
                            <span>{elem.category?.name}</span>
                          )}
                        </div>
                      );
                    })}
                </div>
                <div
                  style={{
                    padding: "1rem",
                  }}
                >
                  <div>
                    <div className="ics_fill_containers">
                      <RenderMenu
                        height="50vh"
                        url={active?.agreement?.doc_url || ""}
                      />
                    </div>
                  </div>

                  {active?.response?.response === 1 ? (
                    <div className="ics_fill_containers">
                      <img
                        src="https://cdn.pegasus.imarticus.org/courses/images/greenInfo.svg"
                        alt=""
                      />
                      <span
                        style={{
                          color: "#035642",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        &nbsp; You accepted this agreement on{" "}
                        {moment
                          .utc(active?.response?.createdAt)
                          .tz("Asia/Kolkata")
                          .format("DD-MM-YYYY, HH:mm:ss")}
                      </span>
                    </div>
                  ) : active?.response?.response === 2 &&
                    !active?.response?.retrigger ? (
                    <div className="ics_fill_containers">
                      <img
                        src="https://cdn.pegasus.imarticus.org/courses/images/errorInfo.svg"
                        alt=""
                      />
                      <span
                        style={{
                          color: "#DC3545",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {" "}
                        &nbsp; You declined this agreement on{" "}
                        {moment
                          .utc(active?.response?.createdAt)
                          .tz("Asia/Kolkata")
                          .format("DD-MM-YY, HH:mm:ss")}
                      </span>

                      <div
                        style={{
                          color: "#DC3545",
                          marginTop: "1rem",
                        }}
                      >
                        {active.response.remarks && (
                          <span>Your Remark: {active.response.remarks}</span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="ics_fill_containers ics_acc_contain">
                        <input
                          type="checkbox"
                          className="ics_acc_check"
                          checked={check}
                          onChange={() => {
                            setCheck(!check);
                          }}
                          style={{
                            width: "24px",
                            height: "24px",
                          }}
                        />{" "}
                        &nbsp;
                        <span
                          style={{
                            color: "#3C4852",
                            fontSize: "16px",
                          }}
                        >
                          By checking this box, I acknowledge that I have read
                          and understood the {active && active.category?.name}.
                        </span>
                      </div>

                      <div className="ics_fill_containers">
                        <span
                          style={{
                            color: "#3C4852",
                            fontSize: "20px",
                          }}
                        >
                          My Name:{" "}
                        </span>{" "}
                        <span
                          style={{
                            color: "#035642",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          {name && name}
                        </span>
                      </div>

                      <div className="ics_fill_containers">
                        <span
                          style={{
                            color: "#3C4852",
                            fontSize: "20px",
                          }}
                        >
                          Date and Time of Response:{" "}
                        </span>{" "}
                        <span
                          style={{
                            color: "#035642",
                            fontSize: "20px",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          {new Date().toLocaleDateString()}:{" "}
                          {new Date().toLocaleTimeString()}
                        </span>
                      </div>

                      <div className="ics_fill_containers">
                        <img
                          src="https://cdn.pegasus.imarticus.org/courses/images/greenInfo.svg"
                          alt=""
                          style={{
                            marginTop: "-8px",
                          }}
                        />
                        <span
                          style={{
                            color: "#035642",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          {" "}
                          &nbsp; If you accept, you will not be able to change
                          your decision later.
                        </span>
                      </div>

                      <div
                        style={{
                          padding: "16px 30px",
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <button
                          className="ics_accept_btn"
                          disabled={!check}
                          onClick={() => {
                            submitHandler({ isAccepted: 1 });
                          }}
                        >
                          Accept
                        </button>
                        <button
                          className="ics_accept_btn ics_red"
                          onClick={() => {
                            // submitHandler({ isAccepted: 2 });

                            setShowDecline(true);
                            setRemark();
                          }}
                        >
                          Decline
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                style={{
                  padding: "16px 0px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <button
                  className="ics_accept_btn"
                  onClick={() => {
                    setShowAgreements(false);
                    window.location.reload();
                  }}
                  disabled={agreements.length !== count}
                >
                  Close
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showDecline}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
        centered
      >
        <ModalBody>
          <div
            style={{
              fontFamily: "Source Sans Pro",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <div className="ics_decline_head">
              Do you really want to decline this agreement?
            </div>
            <div className="ics_decline_sub_head">
              You will lose access to our{" "}
              {active?.agreement?.block_crs && "course content"}{" "}
              {active?.agreement?.block_placement && "placement services"}{" "}
              {active?.agreement?.block_certi && "certificate generation"} .
            </div>
            <div className="ics_ques_head">
              What is your reason for declining?
            </div>

            <textarea
              className="ics_textarea"
              value={remark}
              onChange={(e) => {
                setRemark(e.target.value);
              }}
              placeholder="Enter your reasons for declining the agreement here. (max. 120 words)"
              maxLength={120}
            ></textarea>

            <div className="ics_decline_btns_container">
              <button
                onClick={() => {
                  setShowDecline(false);
                }}
                className="ics_decline_btn1"
              >
                Go Back
              </button>
              <button
                className="ics_decline_btn2"
                onClick={() => {
                  submitHandler({ isAccepted: 2, elem: active });
                }}
                disabled={!remark}
              >
                Yes, Decline
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={success}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
        centered
      >
        <ModalBody>
          <div
            style={{
              fontFamily: "Source Sans Pro",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <div className="ics_decline_head">
              Your responses have been successfully recorded
            </div>
            <div
              className="ics_decline_sub_head"
              style={{
                color: "#3C4852",
              }}
            >
              You can view your policies in the profile section later.
            </div>

            <img
              src="https://cdn.pegasus.imarticus.org/courses/images/success_tick.svg"
              alt=""
              style={{
                marginTop: "1rem",
              }}
            />
            <div className="ics_decline_btns_container">
              <button
                className="ics_decline_btn2"
                style={{
                  backgroundColor: "#035642",
                }}
                onClick={() => {
                  setSuccess(false);
                }}
              >
                My Courses
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={decline}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
        centered
      >
        <ModalBody>
          <div
            style={{
              fontFamily: "Source Sans Pro",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              padding: "1rem",
            }}
          >
            <div className="ics_decline_head">Agreement declined!</div>

            <img
              src="https://cdn.pegasus.imarticus.org/courses/images/fail_tick.svg"
              alt=""
              style={{
                marginTop: "1rem",
              }}
            />

            <div
              className="ics_decline_sub_head"
              style={{
                color: "#3C4852",
              }}
            >
              You have declined the agreement <b>{dec?.category?.name}</b>
            </div>

            <div className="ics_decline_sub_head ics_f18">
              You will lose access to our{" "}
              {dec?.agreement?.block_crs && "course content"}{" "}
              {dec?.agreement?.block_placement && "placement services"}{" "}
              {dec?.agreement?.block_certi && "certificate generation"} .
            </div>

            <div className="ics_ques_head">
              What is your reason for declining?
            </div>

            <div className="ics_decline_remark">{remark}</div>

            <div className="ics_decline_btns_container">
              <button
                className="ics_decline_btn2"
                style={{
                  backgroundColor: "#035642",
                }}
                onClick={() => {
                  setDecline(false);
                }}
              >
                My Courses
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default Agreement;
