import React from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { connect } from "react-redux";
import {
  openMobileModal,
  confirmPrimaryEmailToken,
  openVerifyMobileModal,
  confirmPrimaryEmail,
  addNewSecondaryEmail,
  openAddNewEmailModal,
  isPrimaryEmailVerified,
  isMobileVerified,
} from "../../actions/primaryEmailActions";
import EmailImg2 from "../../images/email-alert-01.png";
import EmailImg3 from "../../images/email-failed-01.png";
import EmailImg4 from "../../images/email-success-01.png";
import closeBtn from "../../images/close-btn.svg";
import mobileOtpImg from "../../images/mobile-otp-01.png";
import {
  CONFIGS,
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
} from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";

class MobileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open_modal: false,
      is_waiting: true,
      m_verified: false,
      verify_link: "",
    };
    // this.props.isMobileVerified();
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.setState({
      is_waiting: this.props.m_waiting,
      m_verified: this.props.m_verified,
    });
  }
  componentDidUpdate(prev_props) {
    if (prev_props.verify_link != this.props.verify_link) {
      console.log("link update:", this.props.verify_link);
      this.setState({ verify_link: this.props.verify_link });
      if (this.props.verify_link !== "") {
        this.props.confirmPrimaryEmailToken(this.props.verify_link);
      }
    }
    if (prev_props.open_modal != this.props.open_modal) {
      this.setState({ open_modal: this.props.open_modal });
    }
    if (prev_props.m_waiting != this.props.m_waiting) {
      this.setState({ is_waiting: this.props.m_waiting });
    }
    if (prev_props.m_verified != this.props.m_verified) {
      this.setState({ m_verified: this.props.m_verified });
    }
  }
  toggle() {
    this.props.openMobileModal(false);
    this.props.openVerifyMobileModal(false);
    window.location.reload();
  }

  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };

  render() {
    return (
      <>
        {this.state.m_verified && (
          <Modal isOpen={this.props.open_modal} className="pe">
            <ModalBody>
              <img
                src={closeBtn}
                style={{ float: "right", padding: "8px" }}
                onClick={this.toggle}
              ></img>
              <img src={mobileOtpImg} className="pe-img" />
              <div className="pe-head">Congratulations!</div>
              <div className="pe-vrfy-info">
                You have successfully verified your mobile number
              </div>
              <Button
                color="primary"
                className="pe-vrfy-btn"
                onClick={this.toggle}
              >
                Finish
              </Button>
            </ModalBody>
          </Modal>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  open_modal: state.mobile.open_status_modal,
  verify_link: state.mobile.verify_link,
  m_verified: state.mobile.m_verified,
  m_waiting: state.mobile.waiting_verification,
});

const mapDispatchToProps = {
  openMobileModal,
  isPrimaryEmailVerified,
  showPopover,
  openAddNewEmailModal,
  addNewSecondaryEmail,
  confirmPrimaryEmail,
  openVerifyMobileModal,
  confirmPrimaryEmailToken,
  isMobileVerified,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileModal);
