import React, {Component, Fragment} from 'react';
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import {image64toCanvasRef, base64StringtoFile, downloadBase64File, extractImageFileExtensionFromBase64} from '../../utility/utils';
import { showPopover } from '../../actions/utilAction';
import { VALID_PROFILE_PIC_TYPES, POPOVER_CONTENT, POPOVER_HEADINGS, POPOVER_TYPE, POPOVER_HIDETIME } from '../../constants/constants';
import { updatePicURL, setProfilePicExtension, updatePicFileObject, updateCroppedPPic } from '../../actions/profileAction';
import 'cropperjs/dist/cropper.css'; // see installation section above for versions of NPM older than 3.0.0
// If you choose not to use import, you need to assign Cropper to default
// var Cropper = require('react-cropper').default
import Spinner from '../utils/Spinner'

class ProfilePicCropper extends Component {
  constructor(props){
    super(props);
    this.state = {
      crop_img : "",
      orignal_img : null,
      is_loading : true,
      org_set: false
    }
  }

  componentDidMount(){
    this.props.setOriginalPicAndCrop(this.props.edit_ppic);
    // this.setState({
      // orignal_img : this.props.edit_ppic,
  // },this.props.setOriginalPicAndCrop(this.state.orignal_img))  
}
// componentDidUpdate(prev_props){
//   if(prev_props.edit_ppic != this.props.edit_ppic && !this.state.org_set){
//     this.props.setOriginalPicAndCrop(this.props.edit_ppic);
//     this.setState({
//       org_set: true,
//     })
//   }
// }
// componentDidUpdate(prev_props){
//   if(this.props.edit_ppic != this.)
// }
  // componentDidUpdate(prev_props){
  //   if(){
  //     var options = {
  //       width : 100,
  //       height : 100
  //     }
  //   }
  // }
  _ready = () => {
    if(!this.refs.cropper){
      return
    }
    // alert("ready");
    // this.refs.cropper.crop();
    // this.refs.cropper.move(0, -1);
    setTimeout(
      function () {
        if(!this.refs.cropper){
          return
        }
        this.refs.cropper.crop();
        this.setState({
          is_loading: false
        })
      }
        .bind(this),
      1000
    );
  }
  _crop(){
    // alert("called");
    var options = {
      width : 100,
      height : 100,
      imageSmoothingEnabled : true,
      imageSmoothingQuality : "high"
      // fillColor: '#fff'
    }
    // image in dataUrl
    
  
    try{
      var cropped_img = this.refs.cropper.getCroppedCanvas(options).toDataURL();
      // var cropped_file = base64StringtoFile(cropped_img,"demo.png")
      this.setState({
        crop_img : cropped_img,
      })
      this.props.updateCroppedPPic(cropped_img);
      // this.props.updatePicURL(cropped_img);   
    }
    catch{

      return;
    }
   
  }
  getCroppedCanvas=(c)=>{
  }
  cropstart = () => {
    // alert("hello");
    // this.refs.cropper.crop();
  }

  picSelected = (e) => {
    if (!e.target || !e.target.files[0]) {
      return;
    }
    var current_file = e.target.files[0];
    var is_type_valid = false;
    for (var i = 0; i < VALID_PROFILE_PIC_TYPES.length; i++) {
      if (current_file.type == VALID_PROFILE_PIC_TYPES[i]) {
        is_type_valid = true;
        break;
      }
    }
    if (is_type_valid) {
      this.props.setProfilePicExtension(current_file.type);
    }
    else {
      this.displayPopover(POPOVER_HEADINGS.INVALID_PP_EXT, POPOVER_CONTENT.INVALID_PP_EXT, POPOVER_TYPE.ERROR);
      e.target = null;
      return;
    }
    var updatePicURL = this.props.updatePicURL;
    // var updatePicFileObject = this.props.updatePicFileObject;
    const myFileItemReader = new FileReader()
    myFileItemReader.addEventListener("load", () => {
      var img = new Image();
      // alert('hello');
      img.src = myFileItemReader.result;
      img.onload = function () {
        var w = this.naturalWidth;
        var h = this.height;
        updatePicURL(myFileItemReader.result);
        // updatePicFileObject(current_file);
      }
    })
    myFileItemReader.readAsDataURL(current_file);
  }   

  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  }
 
  render() {
      return (
        <Fragment>
          <div className="ppc-container">
          <div className={this.state.is_loading ? "cropper-cover" : "hide"}>
            <Spinner/>
          </div>
          <div className={this.props.ppic == this.props.edit_ppic ? "cropper-cover-2" : "hide"}></div>
        <Cropper
          ref='cropper'
          src={this.props.edit_ppic}
          style={{maxHeight: 300, height:'100%', width: '100%'}}
          // Cropper.js options
          aspectRatio={1 / 1}
          guides={false}
          ready={this._ready}
          cropstart={this.cropstart}
          autocrop={this.props.ppic == this.props.edit_ppic ? false : true}
          autoCropArea={1}
          // cropBoxResizable={this.props.ppic == this.props.edit_ppic ? false : true }
          crop={this._crop.bind(this)} />
          
          {/* <img src={this.state.crop_img}></img> */}
          <input type="file" className="hide" onChange={(e)=>this.picSelected(e)} id="edit-file"></input>
          </div>
          </Fragment>
      );
    }
   
    
  }


const mapStateToProps = (state) => (
	{
	    	ppic		    :  state.profile.ppic,
        edit_ppic   :  state.profile.edit_ppic,
        cropped_ppic:  state.profile.cropped_ppic,
	})


const mapDispatchToProps = {
	showPopover,
  updatePicURL,
  updateCroppedPPic,
  setProfilePicExtension,
}

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(ProfilePicCropper));