import React, { Component } from "react";
import { TIMEZONES } from "../../../constants/constants";
import {
  Button,
  //Input,
  //Modal,
  //ModalBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { connect } from "react-redux";
import InlineLoader from "../../utils/InlineLoader";
import { updateTimeZone } from "../../../actions/profileAction";
import { editTimeZone } from "../../../apis/editapis";
//import { setLoading } from "../../../actions/utilAction";
import {
  WebEngageDataLayer,
  WebEngageRegisteredEvents,
} from "../../../services/helper";

class EditTimeZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timezone: undefined,
      list: false,
      loading: false,
      success: "",
      error: "",
    };
  }
  setTimezone = (item) => {
    this.setState({ timezone: item });
  };
  componentWillReceiveProps(nextProps) {
    console.log(nextProps);
    this.setState({ timezone: nextProps.tz });
  }
  toggleList = () => {
    // const list = this.state.list;
    this.setState({ list: !this.state.list });
  };
  handlerSubmitTimeZone = async () => {
    try {
      this.setState({ loading: true });
      this.setState({ error: null });
      this.setState({ success: null });

      const response = await editTimeZone(this.state.timezone);
      this.props.updateTimeZone(response.data.account.tz);

      this.setState({ loading: false });
      this.setState({ success: true });
      let data = {
        email: this.props.email,
        name: this.props.name,
        mobile: this.props.contact,
        timezone: this.state.timezone,
      };

      console.log({ data });
      WebEngageDataLayer(data, WebEngageRegisteredEvents.PROFILEUPDATED);
      // Remove the success message after 2.5 seconds.
      setTimeout(() => this.setState({ success: null }), 2500);
    } catch (error) {
      this.setState({ loading: false });

      this.setState({ error });
      // Remove the success message after 2.5 seconds.
      setTimeout(() => this.setState({ error: null }), 2500);
    }
  };
  render() {
    const { list, timezone, loading, error, success } = this.state;
    return (
      <div className="group-db-content-wrapper">
        <div className="container-fluid dashboard-content">
          <div className=" profile-setting-container-row">
            <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row justify-content-center">
                <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 "></div>
                <div className="pf-content-container">
                  <p className="edit-profile-heading">Edit Timezone</p>
                  <p className="pf-name">Select Timezone</p>
                  <Dropdown isOpen={list} toggle={() => this.toggleList()}>
                    <DropdownToggle className="pe-drpdwn">
                      {timezone}
                      {!list ? (
                        <i className="fa fa-angle-up"></i>
                      ) : (
                        <i className="fa fa-angle-down"></i>
                      )}
                    </DropdownToggle>
                    <DropdownMenu
                      modifiers={{
                        setMaxHeight: {
                          enabled: true,
                          order: 890,
                          fn: (data) => {
                            return {
                              ...data,
                              styles: {
                                ...data.styles,
                                overflow: "auto",
                                fontFamily: "Source Sans Pro",
                                maxHeight: "300px",
                                minWidth: "300px",
                              },
                            };
                          },
                        },
                      }}
                    >
                      {TIMEZONES.map((item) => {
                        return (
                          <DropdownItem
                            key={item}
                            onClick={() => this.setTimezone(item)}
                          >
                            {item}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </Dropdown>
                  <Button
                    color="primary"
                    disabled={loading}
                    onClick={this.handlerSubmitTimeZone}
                    className="pe-cnf-btn__otp"
                    style={{ width: "375px" }}
                  >
                    {!loading ? "Edit TimeZone" : <InlineLoader />}
                  </Button>
                  {error && <p align="center">{error}</p>}
                  {success && (
                    <p align="center">TimeZone updated successfully!</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
// const EditTimezone = () => {
//   const profile = useSelector((state) => state.profile);
//   const [timezone, setTimezone] = useState("");
//   const [list, setList] = useState(false);
//   const toggleList = () => {
//     setList((prev) => !prev);
//   };
//   return (
//     <div className="group-db-content-wrapper">
//       <div className="container-fluid dashboard-content">
//         <div className=" profile-setting-container-row">
//           <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
//             <div className="row justify-content-center">
//               <div className="ps-main-content col-xl-8 col-lg-8 col-md-6 col-sm-6 "></div>
//               <div className="pf-content-container">
//                 <p className="edit-profile-heading">Edit Timezone</p>
//                 <p className="pf-name">Select Timezone</p>
//                 <Dropdown isOpen={list} toggle={toggleList}>
//                   <DropdownToggle className="pe-drpdwn">
//                     {timezone}
//                     {!list ? (
//                       <i className="fa fa-angle-up"></i>
//                     ) : (
//                       <i className="fa fa-angle-down"></i>
//                     )}
//                   </DropdownToggle>
//                   <DropdownMenu
//                     modifiers={{
//                       setMaxHeight: {
//                         enabled: true,
//                         order: 890,
//                         fn: (data) => {
//                           return {
//                             ...data,
//                             styles: {
//                               ...data.styles,
//                               overflow: "auto",
//                               fontFamily: "Source Sans Pro",
//                               maxHeight: "100px",
//                               minWidth: "300px",
//                             },
//                           };
//                         },
//                       },
//                     }}
//                   >
//                     {TIMEZONES.map((item) => {
//                       return (
//                         <DropdownItem
//                           key={item}
//                           onClick={() => setTimezone(item)}
//                         >
//                           {item}
//                         </DropdownItem>
//                       );
//                     })}
//                   </DropdownMenu>
//                 </Dropdown>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
const mapStateToProps = (state) => ({
  tz: state.profile.tz,
  contact: state.profile.contact,
  email: state.profile.email,
  name: state.profile.p_name,
});

const mapDispatchToProps = {
  updateTimeZone,
};
export default connect(mapStateToProps, mapDispatchToProps)(EditTimeZone);
